import React from 'react';

import LEAccordionHeader from '../../../components/LEAccordion/LEAccordionHeader';
import Subtitle from '../../../components/Subtitle';
import { LDFlags } from '../../../constants/flags';
import { useFlag } from '../../../hooks';
import { capitalizeFirstLetter } from '../../../utils/text/text';
import { PAYOFF_PAGE_VARIATIONS } from '../constants';

interface Props {
  make: string;
  model: string;
}

const Header: React.FC<Props> = ({ make, model }) => {
  const payoffPageVariation = useFlag(LDFlags.PAYOFF_PAGE_VARIATION);

  return (
    <>
      <LEAccordionHeader>
        {payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation0
          ? "Let's Talk Lease"
          : 'Your Payoff Amount'}
      </LEAccordionHeader>
      {payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation0 && (
        <>
          <Subtitle textAlign="center" w={{ base: '100%', lg: '75%' }} mb="10px">
            We need the Payoff Amount for your {capitalizeFirstLetter(make?.toLowerCase())}{' '}
            {capitalizeFirstLetter(model?.toLocaleLowerCase())}... what the heck is that?!
          </Subtitle>
        </>
      )}
    </>
  );
};

export default Header;
