import { Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React from 'react';

import { helpAtom } from '../..';

const Subtitle: React.FC = () => {
  const help = useAtomValue(helpAtom);

  return help ? (
    <Text>
      <Text as="b">Lease End</Text> will get your payoff amount
    </Text>
  ) : (
    <Text>
      <Text as="b">You</Text> will get your payoff amount
    </Text>
  );
};

export default Subtitle;
