import { Divider, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { MdCheck } from '@react-icons/all-files/md/MdCheck';
import { MdThumbUp } from '@react-icons/all-files/md/MdThumbUp';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineUpdate } from 'react-icons/md';

import Popover from '../../../../components/Popover';

export interface TimelineIconProps {
  status: 'upcoming' | 'inprogress' | 'completed';
  label: string;
  subLabel?: string;
  first?: boolean;
  last?: boolean;
  divider?: boolean;
  tooltip?: string;
  refreshTooltip?: boolean;
}

const sizeToFontMap = {
  '8px': '8px',
  '24px': '12px',
  '36px': '16px',
  '48px': '24px',
  '60px': '32px',
};

const TimelineIcon = ({
  status,
  label,
  subLabel,
  last,
  first,
  divider,
  tooltip,
  refreshTooltip, // resets the state of the tooltip by changing the key
}: TimelineIconProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const bg = {
    upcoming: 'silver',
    inprogress: 'oceanBoatBlue',
    completed: 'leaseEndGreen',
  }[status];

  const isInProgress = status === 'inprogress';
  const isCompleted = status === 'completed';
  const isUpcoming = status === 'upcoming';

  const isFocused = isInProgress || (isCompleted && last);

  const mt = {
    base: isFocused ? '0px' : '6px',
    md: isFocused ? '0px' : '6px',
    lg: last || first || isInProgress ? '0px' : isUpcoming ? '22px' : '14px',
  };

  const boxSize = {
    base: isFocused ? '60px' : '48px',
    md: isFocused ? '48px' : '36px',
    lg: last || first ? '60px' : isInProgress ? '48px' : isUpcoming ? '8px' : '24px',
  };

  const fontSize = {
    base: sizeToFontMap[boxSize.base as keyof typeof sizeToFontMap],
    md: sizeToFontMap[boxSize.md as keyof typeof sizeToFontMap],
    lg: sizeToFontMap[boxSize.lg as keyof typeof sizeToFontMap],
  };

  return (
    <Flex direction="column" alignItems="center" position="relative">
      <Flex
        boxSize={boxSize}
        mb="10px"
        bg={bg}
        borderRadius="50%"
        color="white"
        fontSize={fontSize}
        alignItems="center"
        justifyContent="center"
        mt={mt}
        position="relative"
        zIndex="1"
        {...(isInProgress && { border: '3px solid white' })}
      >
        {last ? (
          <MdThumbUp />
        ) : isCompleted ? (
          <MdCheck />
        ) : isInProgress ? (
          <MdOutlineUpdate />
        ) : (
          isMobile && <BsThreeDots />
        )}
      </Flex>
      <Popover label={tooltip} key={`${refreshTooltip}`} placement="top">
        <Text
          fontSize={isFocused ? '16px' : '14px'}
          color={isInProgress || isCompleted ? 'black' : 'silver'}
          fontWeight={isInProgress || first || last ? 'bold' : isUpcoming ? 'semibold' : 'normal'}
          textAlign="center"
          maxW="125px"
        >
          {label}
        </Text>
      </Popover>
      <Text fontSize="14px" color="silver" textAlign="center">
        {subLabel}
      </Text>
      {divider && (
        <Divider
          borderColor={isCompleted ? 'leaseEndGreen' : 'silver'}
          borderBottomWidth="2px"
          position="absolute"
          w="100%"
          right="50%"
          top="24.5px"
        />
      )}
    </Flex>
  );
};

export default TimelineIcon;
