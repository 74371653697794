import { ListItem, OrderedList, UnorderedList } from '@chakra-ui/react';

import { LDFlags } from '../../../constants/flags';
import { Instruction } from '../../../gql/prs/types';
import { useFlag } from '../../../hooks';
import { PAYOFF_PAGE_VARIATIONS } from '../../../pages/PayoffInstructions/constants';
import Description from '../../Description';

const Instructions = ({ instructions }: { instructions: Instruction[] }) => {
  const payoffPageVariation = useFlag(LDFlags.PAYOFF_PAGE_VARIATION);

  const List =
    payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation0 ? UnorderedList : OrderedList;

  return (
    <List mb={4}>
      {instructions.map(({ details, text }) => (
        <ListItem key={`instruction-${text.slice(10)}`} mt={2}>
          <Description fontSize={{ md: '16px' }}>{text}</Description>
          {details && (
            <List listStyleType="upper-alpha">
              {Array.isArray(details) ? (
                details.map((detail) => (
                  <ListItem>
                    <Description fontSize={{ md: '14px' }}>{detail}</Description>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <Description fontSize={{ md: '14px' }}>{details}</Description>
                </ListItem>
              )}
            </List>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default Instructions;
