import { ExtraTempInfoInputType } from '../../gql/generated/graphql';

export const AFFILIATE_PARAMS = {
  AFFILIATE_ID: 'affiliate_id',
};

export const SHOPPING_CART_PARAMS = {
  PAYOFF: 'payoff',
  CREDIT_SCORE: 'credit_score',
  DOWN_PAYMENT: 'down_payment',
  TERM: 'term',
};

const getShoppingCart = (search: string) => {
  const params = new URLSearchParams(search);
  const payoff = params.get(SHOPPING_CART_PARAMS.PAYOFF);
  const creditScore = params.get(SHOPPING_CART_PARAMS.CREDIT_SCORE);
  const downPayment = params.get(SHOPPING_CART_PARAMS.DOWN_PAYMENT);
  const term = params.get(SHOPPING_CART_PARAMS.TERM);

  if (!payoff && !creditScore && !downPayment && !term) {
    return undefined;
  }

  return {
    buyout: payoff ? Number(payoff) : undefined,
    credit_score: creditScore ? Number(creditScore) : undefined,
    down_payment: downPayment ? Number(downPayment) : undefined,
    loan_term: term ? Number(term) : undefined,
  };
};

// TODO-JK:: add/test zip and shopping cart
export const FLOW_ENTRY_PARAMS = {
  ZIP: 'zip',
  VIN: 'vin',
  LICENSE_PLATE: 'license_plate',
  STATE: 'state',
};

export const getFlowParams = (search: string) => {
  const params = new URLSearchParams(search);
  const vin = params.get(FLOW_ENTRY_PARAMS.VIN) || undefined;
  const licensePlate = params.get(FLOW_ENTRY_PARAMS.LICENSE_PLATE) || undefined;
  const licensePlateState = params.get(FLOW_ENTRY_PARAMS.STATE) || undefined;
  const zip = params.get(FLOW_ENTRY_PARAMS.ZIP) || undefined;

  return {
    vin,
    licensePlate,
    licensePlateState,
    zip,
    shoppingCart: getShoppingCart(search),
  };
};

export type FlowParams = ReturnType<typeof getFlowParams>;

export interface PrequalParams {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  prequalId: string;
}

export const getExtraInfo = (flowParams?: FlowParams, prequalParams?: PrequalParams | null) => {
  let extraInfo: ExtraTempInfoInputType = {
    zip: flowParams?.zip,
    shopping_cart: flowParams?.shoppingCart,
  };

  if (prequalParams) {
    extraInfo = {
      ...extraInfo,
      first_name: prequalParams.firstName,
      last_name: prequalParams.lastName,
      address_line: prequalParams.address,
      city: prequalParams.city,
      state: prequalParams.state,
      zip: prequalParams.zip,
      email: prequalParams.email,
      phone_number: prequalParams.phone,
      prequal_id: prequalParams.prequalId,
    };
  }

  return extraInfo;
};
