import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  GUID: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Void: { input: any; output: any };
};

export type Auth0Role = {
  __typename?: 'Auth0Role';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Auth0RoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum Auth0RoleName {
  AccountingTeam = 'AccountingTeam',
  Admin = 'Admin',
  CSuite = 'CSuite',
  CustomerService = 'CustomerService',
  D2dAdmin = 'D2dAdmin',
  D2dCloser = 'D2dCloser',
  D2dDev = 'D2dDev',
  D2dSetter = 'D2dSetter',
  D2dSetterLead = 'D2dSetterLead',
  FinanceTeam = 'FinanceTeam',
  FundingClerk = 'FundingClerk',
  FundingClerkManager = 'FundingClerkManager',
  InboundFinancialSpecialist = 'InboundFinancialSpecialist',
  InboundSalesAdvisor = 'InboundSalesAdvisor',
  InboundSalesPodLead = 'InboundSalesPodLead',
  OutboundAdvisorManager = 'OutboundAdvisorManager',
  OutboundSetter = 'OutboundSetter',
  OutboundWebAdvisor = 'OutboundWebAdvisor',
  PayoffClerk = 'PayoffClerk',
  PayoffManager = 'PayoffManager',
  StructuringAssistant = 'StructuringAssistant',
  StructuringManager = 'StructuringManager',
  TitleClerk = 'TitleClerk',
  TitleManager = 'TitleManager',
  WebAdmin = 'WebAdmin',
}

export type AutoStructureBankLogo = {
  __typename?: 'AutoStructureBankLogo';
  bank_name?: Maybe<Scalars['String']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
};

export type CallsPagination = {
  __typename?: 'CallsPagination';
  calls?: Maybe<Array<Maybe<Call>>>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  height?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  xPosition?: Maybe<Scalars['Float']['output']>;
  yPosition?: Maybe<Scalars['Float']['output']>;
};

export type CreateTransactionInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  conditions?: InputMaybe<TtConditionFilterInput>;
  parameters?: InputMaybe<TtFeesInput>;
  processor?: InputMaybe<Processor>;
  state?: InputMaybe<StateAbbreviation>;
  types?: InputMaybe<Array<InputMaybe<TtProductType>>>;
};

export type CreateUserInput = {
  auth0Roles: Array<InputMaybe<Auth0RoleInput>>;
  email: Scalars['String']['input'];
  huntGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  recruiterId?: InputMaybe<Scalars['String']['input']>;
};

export type CreditAppInput = {
  cobuyer?: InputMaybe<CustomerInput>;
  contact?: InputMaybe<DealContactInput>;
  customer: CustomerInput;
  financial_info: FinancialInfoInput;
  id: Scalars['ID']['input'];
  odometer_status?: InputMaybe<OdometerStatus>;
  second_contact?: InputMaybe<DealContactInput>;
  state: Scalars['String']['input'];
};

export type CreditApplicationSubmission = {
  __typename?: 'CreditApplicationSubmission';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_applications?: Maybe<Array<Maybe<CreditApplication>>>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type CreditApplicationSubmissionFilter = {
  dealIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type D2dDocument = {
  __typename?: 'D2dDocument';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DdSoftPull = {
  __typename?: 'DDSoftPull';
  cobuyer?: Maybe<Prequalification>;
  customer: Prequalification;
};

export type DashboardDocumentUpload = {
  __typename?: 'DashboardDocumentUpload';
  deal: Deal;
  fileType: Scalars['String']['output'];
};

export type DateFilter = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealDocument = {
  __typename?: 'DealDocument';
  data?: Maybe<Scalars['String']['output']>;
  dealJacketId?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  docStatCode?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  signAttributes?: Maybe<Array<Maybe<DealDocumentSignAttribute>>>;
  signatureStatus?: Maybe<DocumentSignatureStatusEnum>;
  wetSignature?: Maybe<Scalars['Boolean']['output']>;
};

export type DealDocumentSignAttribute = {
  __typename?: 'DealDocumentSignAttribute';
  actorName?: Maybe<Scalars['String']['output']>;
  actorType?: Maybe<ActorType>;
  dateCoordinates?: Maybe<Coordinate>;
  dateFieldName?: Maybe<Scalars['String']['output']>;
  initialsType?: Maybe<Scalars['Boolean']['output']>;
  signCaptureTms?: Maybe<Scalars['String']['output']>;
  signFieldName?: Maybe<Scalars['String']['output']>;
  signPadText?: Maybe<Scalars['String']['output']>;
  signRequired?: Maybe<Scalars['Boolean']['output']>;
  signatureCoordinates?: Maybe<Coordinate>;
};

export enum DealMediaSourceEnum {
  System = 'system',
  User = 'user',
}

export enum DealMediaStateEnum {
  Active = 'active',
  Pending = 'pending',
}

export enum DealSortableColumnsEnum {
  CreatedAt = 'created_at',
  CustomerName = 'customer_name',
  DealStage = 'deal_stage',
}

export enum DealSource {
  All = 'all',
  CallIn = 'call_in',
  D2d = 'd2d',
  Outbound = 'outbound',
  Web = 'web',
}

export enum DealStateEnumV2 {
  AtAuction = 'at_auction',
  Booted = 'booted',
  Closed = 'closed',
  Closing = 'closing',
  Estimate = 'estimate',
  Finalized = 'finalized',
  Floor = 'floor',
  Funded = 'funded',
  PaidOff = 'paid_off',
  ReadyForPickup = 'ready_for_pickup',
  SendPayoff = 'send_payoff',
  SentToProcessor = 'sent_to_processor',
  Signatures = 'signatures',
  Signed = 'signed',
  SoftClose = 'soft_close',
  Sold = 'sold',
  Structuring = 'structuring',
  StructuringInProgress = 'structuring_in_progress',
  TitleReceived = 'title_received',
  TitleSent = 'title_sent',
  WaitingForTitle = 'waiting_for_title',
}

export enum DealType {
  Acquisition = 'acquisition',
  Buyout = 'buyout',
  Refi = 'refi',
}

export enum DealTypeV2 {
  Acquisition = 'acquisition',
  Buyout = 'buyout',
  Refi = 'refi',
}

export type DealWithErrors = {
  __typename?: 'DealWithErrors';
  deal?: Maybe<Deal>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DealsConnection = {
  __typename?: 'DealsConnection';
  items: Array<Maybe<Deal>>;
  pagination: PaginationType;
  total?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type DealsFilter = {
  closerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['GUID']['input']>>>;
  created_at?: InputMaybe<DateFilter>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  isFinalized?: InputMaybe<Scalars['Boolean']['input']>;
  leaseIds?: InputMaybe<Array<InputMaybe<Scalars['GUID']['input']>>>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  setterIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source?: InputMaybe<DealSource>;
  state?: InputMaybe<Array<InputMaybe<DealStateEnumV2>>>;
  type?: InputMaybe<DealTypeV2>;
  updated_at?: InputMaybe<DateFilter>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum DocumentProgressStatus {
  Failed = 'failed',
  GenerateDocuments = 'generate_documents',
  PendingLender = 'pending_lender',
  PendingR1 = 'pending_r1',
  ReadyForSignatures = 'ready_for_signatures',
  Required = 'required',
  SentForSignatures = 'sent_for_signatures',
  Signed = 'signed',
  UploadDocuments = 'upload_documents',
}

export enum DocumentSignatureStatusEnum {
  Completed = 'completed',
  InProcess = 'in_process',
  Unstarted = 'unstarted',
}

export type DownPaymentReceived = {
  __typename?: 'DownPaymentReceived';
  amount: Scalars['Float']['output'];
  dealId: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DriversLicenseGql = {
  __typename?: 'DriversLicenseGQL';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ExperianOutput = {
  __typename?: 'ExperianOutput';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  riskModel?: Maybe<ExperianRiskModel>;
};

export type ExperianRiskModel = {
  __typename?: 'ExperianRiskModel';
  score?: Maybe<Scalars['String']['output']>;
};

export type ExternalCustomerInput = {
  dashboard_visited?: InputMaybe<Scalars['Boolean']['input']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_authenticated_route?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalDealInputType = {
  auto_import_variation?: InputMaybe<AutoImportVariation>;
};

export type ExtractedDocumentGql = {
  __typename?: 'ExtractedDocumentGQL';
  documentName?: Maybe<Scalars['ID']['output']>;
  fields?: Maybe<Array<Maybe<ExtractedField>>>;
  key?: Maybe<Scalars['String']['output']>;
};

export type ExtractedField = {
  __typename?: 'ExtractedField';
  confidence?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FeeGql = {
  __typename?: 'FeeGQL';
  baseTaxAmount?: Maybe<Scalars['Float']['output']>;
  docFeeTaxAmount?: Maybe<Scalars['Float']['output']>;
  feeProcessor?: Maybe<Scalars['String']['output']>;
  registrationTransferFee?: Maybe<Scalars['Float']['output']>;
  salesTaxRate?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<StateAbbreviation>;
  taxProcessor?: Maybe<Scalars['String']['output']>;
  totalFeeAmount?: Maybe<Scalars['Float']['output']>;
  warrantyTaxAmount?: Maybe<Scalars['Float']['output']>;
};

export type FilterType = {
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  banks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  closedDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  closedDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  dealStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasTitleReceived?: InputMaybe<Scalars['Boolean']['input']>;
  lienholders?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  problemDeals?: InputMaybe<Scalars['Boolean']['input']>;
  processors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  titleDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  titleDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  titlingPods?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  usStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vins?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HuntGroup = {
  __typename?: 'HuntGroup';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  default_queue?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  ring_all?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type KbbSelectedOptionsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lendingOptionPrice?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  removed?: InputMaybe<Scalars['Boolean']['input']>;
  retailOptionPrice?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type KbbSelectedOptionsObject = {
  __typename?: 'KbbSelectedOptionsObject';
  selected_options?: Maybe<Array<Maybe<KbbSelectedOptionsType>>>;
};

export type KbbSelectedOptionsObjectInput = {
  selected_options?: InputMaybe<Array<InputMaybe<KbbSelectedOptionsInput>>>;
};

export enum LeExperianErrorCodeEnum {
  Frozen = 'FROZEN',
  Locked = 'LOCKED',
  NoRecordFound = 'NO_RECORD_FOUND',
}

export type Lender = {
  __typename?: 'Lender';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OdometerStatus {
  Changed = 'Changed',
  Verified = 'Verified',
  Warning = 'Warning',
}

export type PaginatedDeals = {
  __typename?: 'PaginatedDeals';
  deals?: Maybe<Array<Maybe<Deal>>>;
  dealsMaxAge?: Maybe<Scalars['DateTime']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  results?: Maybe<Array<Maybe<User>>>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type PaginationInputType = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationType = {
  __typename?: 'PaginationType';
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
};

export enum PaperworkType {
  Adobe = 'adobe',
  Esign = 'esign',
  Paper = 'paper',
}

export type PrequalificationCreditReport = {
  __typename?: 'PrequalificationCreditReport';
  addresses?: Maybe<Array<PrequalificationCreditReportAddress>>;
  employments?: Maybe<Array<PrequalificationCreditReportEmployment>>;
  informationalMessages?: Maybe<Array<Scalars['String']['output']>>;
  names?: Maybe<PrequalificationCreditReportNames>;
  scoreFactors?: Maybe<Array<PrequalificationCreditScoreScoreFactor>>;
  tradelines?: Maybe<PrequalificationCreditReportTradelines>;
};

export type PrequalificationCreditReportAddress = {
  __typename?: 'PrequalificationCreditReportAddress';
  city?: Maybe<Scalars['String']['output']>;
  firstReportedDate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetPrefix?: Maybe<Scalars['String']['output']>;
  streetSuffix?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportEmployment = {
  __typename?: 'PrequalificationCreditReportEmployment';
  addressExtraLine?: Maybe<Scalars['String']['output']>;
  addressFirstLine?: Maybe<Scalars['String']['output']>;
  addressSecondLine?: Maybe<Scalars['String']['output']>;
  firstReportedDate?: Maybe<Scalars['String']['output']>;
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportName = {
  __typename?: 'PrequalificationCreditReportName';
  firstName: Scalars['String']['output'];
  generationCode?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  secondSurname?: Maybe<Scalars['String']['output']>;
  surname: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportNames = {
  __typename?: 'PrequalificationCreditReportNames';
  aliases: Array<Maybe<PrequalificationCreditReportName>>;
  customerName?: Maybe<PrequalificationCreditReportName>;
};

export type PrequalificationCreditReportTradeline = {
  __typename?: 'PrequalificationCreditReportTradeline';
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountType?: Maybe<Scalars['String']['output']>;
  amount1?: Maybe<Scalars['String']['output']>;
  amount1Qualifier?: Maybe<Scalars['String']['output']>;
  amount2?: Maybe<Scalars['String']['output']>;
  amount2Qualifier?: Maybe<Scalars['String']['output']>;
  amountPastDue?: Maybe<Scalars['String']['output']>;
  balanceAmount?: Maybe<Scalars['String']['output']>;
  balanceDate?: Maybe<Scalars['String']['output']>;
  delinquency30Days?: Maybe<Scalars['String']['output']>;
  delinquency60Days?: Maybe<Scalars['String']['output']>;
  delinquency90to180Days?: Maybe<Scalars['String']['output']>;
  ecoa?: Maybe<Scalars['String']['output']>;
  kob?: Maybe<Scalars['String']['output']>;
  monthlyPaymentAmount?: Maybe<Scalars['String']['output']>;
  monthsHistory?: Maybe<Scalars['String']['output']>;
  openDate?: Maybe<Scalars['String']['output']>;
  openOrClosed?: Maybe<Scalars['String']['output']>;
  paymentHistory?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriberName?: Maybe<Scalars['String']['output']>;
  terms?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportTradelines = {
  __typename?: 'PrequalificationCreditReportTradelines';
  installment: Array<PrequalificationCreditReportTradeline>;
  revolving: Array<PrequalificationCreditReportTradeline>;
  unknown: Array<PrequalificationCreditReportTradeline>;
};

export type PrequalificationCreditReports = {
  __typename?: 'PrequalificationCreditReports';
  cobuyer?: Maybe<PrequalificationCreditReport>;
  customer: PrequalificationCreditReport;
};

export type PrequalificationCreditScoreScoreFactor = {
  __typename?: 'PrequalificationCreditScoreScoreFactor';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationRequest = {
  __typename?: 'PrequalificationRequest';
  first_name?: Maybe<Scalars['String']['output']>;
  gross_income?: Maybe<Scalars['Float']['output']>;
  income_sources?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  lending_book_value?: Maybe<Scalars['Float']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  pay_frequency?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum Processor {
  Atc = 'Atc',
  DlrDmv = 'DlrDmv',
  TaterTitle = 'TaterTitle',
  Vitu = 'Vitu',
}

export type R1ContractValidationErrors = {
  __typename?: 'R1ContractValidationErrors';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type R1ContractValidationErrorsInput = {
  errors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type R1ContractValidationWarnings = {
  __typename?: 'R1ContractValidationWarnings';
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type R1ContractValidationWarningsInput = {
  warnings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum ReferralSourceEnum {
  DidNotAsk = 'did_not_ask',
  InternetSearch = 'internet_search',
  Mailer = 'mailer',
  Other = 'other',
  RecommendationFromFriend = 'recommendation_from_friend',
  SocialMediaOrBlogPost = 'social_media_or_blog_post',
  Text = 'text',
  TrustedReview = 'trusted_review',
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  acquisitionDealInfoUpsert?: Maybe<Deal>;
  addDocumentsToMediaCenter?: Maybe<Scalars['Boolean']['output']>;
  addLendersToCreditApplicationV2: CreditApplication;
  addParticipant?: Maybe<ResultAddParticipant>;
  applyDocumentSignature?: Maybe<Scalars['Void']['output']>;
  archivePod?: Maybe<Scalars['Void']['output']>;
  auctionUpsert?: Maybe<Auction>;
  automateCreditApplication?: Maybe<Deal>;
  buyerCobuyerSwap?: Maybe<Deal>;
  callOut?: Maybe<Call>;
  cancelPayoffRequest?: Maybe<PayoffRequest>;
  completedPayoffRequestsJob?: Maybe<Scalars['Void']['output']>;
  conditionReportUpsert?: Maybe<ConditionReportUpsert>;
  createAffiliateTemporaryInfo?: Maybe<TemporaryInfo>;
  createArticle?: Maybe<Article>;
  createCreditApplicationV2: CreditApplication;
  createDealV2: Deal;
  createDownPaymentLink?: Maybe<Scalars['String']['output']>;
  createExternalDealUploadUrl?: Maybe<ExternalDealUploadUrl>;
  createExternalTempInfoUploadUrl?: Maybe<ExternalTempInfoUploadUrl>;
  createFeedback?: Maybe<Scalars['Void']['output']>;
  createJDPBookSheet?: Maybe<Scalars['Boolean']['output']>;
  createKBBBookSheet?: Maybe<Scalars['Boolean']['output']>;
  createManualEntryTemporaryInfo?: Maybe<TemporaryInfo>;
  createNewEstimateAndSetFollowUp?: Maybe<Deal>;
  createNewEstimateFromFiles?: Maybe<CreateNewEstimateFromFiles>;
  createOptEvent?: Maybe<OptEvent>;
  createPayoffRequest?: Maybe<PayoffRequest>;
  createPotentialUser?: Maybe<PotentialUser>;
  createReview?: Maybe<Review>;
  createStructuringFollowUp?: Maybe<Scalars['Boolean']['output']>;
  createTemporaryInfo?: Maybe<TemporaryInfo>;
  createTransaction?: Maybe<Scalars['Float']['output']>;
  createUser?: Maybe<User>;
  creditAppUpsert?: Maybe<Deal>;
  customDatesUpdate?: Maybe<DealDates>;
  customerInfoUpsert?: Maybe<Deal>;
  dealAssign?: Maybe<Deal>;
  dealCompleted?: Maybe<Deal>;
  dealDetailNetsuite?: Maybe<Deal>;
  dealFinalizeAcquisition?: Maybe<Scalars['Boolean']['output']>;
  dealHasProblemSwitch?: Maybe<Deal>;
  dealInfoUpsert?: Maybe<Deal>;
  dealSeen?: Maybe<Scalars['Boolean']['output']>;
  dealTagsUpsert: Array<Tag>;
  dealUnwound?: Maybe<Deal>;
  dealUpdateAssignments?: Maybe<Deal>;
  dealUpdateMissingRequiredDocuments?: Maybe<Scalars['Boolean']['output']>;
  dealUpdateRequestBoot?: Maybe<Deal>;
  dealUpdateState?: Maybe<DealWithErrors>;
  dealUpdateStateAndSendEmails?: Maybe<Deal>;
  dealUpdateType?: Maybe<Deal>;
  deleteAndReplaceFiles?: Maybe<Scalars['Boolean']['output']>;
  deleteArticle?: Maybe<Article>;
  deleteComFlowFile?: Maybe<Scalars['Void']['output']>;
  deleteContracts?: Maybe<Scalars['Boolean']['output']>;
  deleteCreditApplicationV2: Scalars['ID']['output'];
  deleteD2dDocument?: Maybe<Scalars['Boolean']['output']>;
  deleteDealV2: Scalars['Int']['output'];
  deleteReview?: Maybe<Review>;
  deleteStructuringFollowUp?: Maybe<Scalars['Boolean']['output']>;
  determineApprovalMutation?: Maybe<DetermineApproval>;
  documentsInfoUpdate?: Maybe<Scalars['Void']['output']>;
  editD2dDocumentName?: Maybe<Scalars['Boolean']['output']>;
  endSalesFlow?: Maybe<Scalars['Boolean']['output']>;
  estimateUpsert?: Maybe<Deal>;
  executeCardDownPayment?: Maybe<FinixTransfer>;
  executeDownPayment?: Maybe<FinixTransfer>;
  executeExternalSoftPull?: Maybe<Scalars['Boolean']['output']>;
  externalCustomerUpdate?: Maybe<Customer>;
  externalUpdateDeal?: Maybe<Deal>;
  fileSend?: Maybe<Message>;
  finalizeR1Signing?: Maybe<Scalars['Void']['output']>;
  financialInfoAcquisitionUpsert?: Maybe<FinancialInfoAcquisition>;
  followUpDelete?: Maybe<FollowUp>;
  followUpInsert?: Maybe<FollowUp>;
  followUpUpdate?: Maybe<FollowUp>;
  generateAndUploadRateExceptionForm?: Maybe<Scalars['Boolean']['output']>;
  getMarketPrequalification: MarketPrequalification;
  hangUpCall?: Maybe<Scalars['Boolean']['output']>;
  importDeal?: Maybe<Deal>;
  linkAccount?: Maybe<Scalars['Void']['output']>;
  linkUnlinkedCustomers?: Maybe<Scalars['Boolean']['output']>;
  logSigningError?: Maybe<SigningError>;
  logViewedDocumentEvent?: Maybe<Scalars['Void']['output']>;
  manuallySelectBank: FinancialInfo;
  mediaDelete?: Maybe<Scalars['Boolean']['output']>;
  mediaDeleteFromCom?: Maybe<Scalars['Boolean']['output']>;
  mediaInsert?: Maybe<DealMedia>;
  mediaInsertCom?: Maybe<DealMedia>;
  mediaRename?: Maybe<Scalars['Boolean']['output']>;
  mediaUpdate?: Maybe<DealMedia>;
  mediaUpdateCom?: Maybe<DealMedia>;
  mergeCreditApplicationV2?: Maybe<Scalars['Boolean']['output']>;
  mergeFiles?: Maybe<MergedDocuments>;
  messageSend?: Maybe<Message>;
  mockDealProcessing?: Maybe<Scalars['Void']['output']>;
  newEstimateUpsert?: Maybe<Deal>;
  noteCreate?: Maybe<Note>;
  noteDelete?: Maybe<Note>;
  noteUpdate?: Maybe<Note>;
  notificationSeen?: Maybe<Scalars['Boolean']['output']>;
  notificationsSeen?: Maybe<Scalars['Boolean']['output']>;
  optOut?: Maybe<Scalars['Void']['output']>;
  payoffRequestCancel?: Maybe<PayoffRequest>;
  payoffRequestConfirm?: Maybe<PayoffRequest>;
  payoffRequestFail?: Maybe<PayoffRequest>;
  payoffRequestFailureRequest?: Maybe<PayoffRequest>;
  payoffRequestUpdate?: Maybe<PayoffRequest>;
  payoffUpdate?: Maybe<Payoff>;
  podUpsert?: Maybe<Pod>;
  podUpsertAndPodUsersUpdate?: Maybe<BasePod>;
  processPaperwork?: Maybe<Deal>;
  registerDealer?: Maybe<Scalars['String']['output']>;
  registerProduct?: Maybe<Scalars['String']['output']>;
  removeDuplicateFinancialInfos?: Maybe<Scalars['Boolean']['output']>;
  resendEmails?: Maybe<Scalars['Boolean']['output']>;
  resetPasswordAndSendEmail?: Maybe<Scalars['Void']['output']>;
  revivePod?: Maybe<Pod>;
  saveConsent: SaveConsent;
  saveNewCustomerCallOutcome?: Maybe<Call>;
  selectCreditDecision?: Maybe<SelectCreditDecision>;
  selectCreditDecisionV2?: Maybe<CreditDecision>;
  sendContracts?: Maybe<Scalars['Boolean']['output']>;
  sendDocumentsWithAdobeAndUpdateState?: Maybe<Deal>;
  sendPaymentEstimateEmailAndText?: Maybe<Scalars['Boolean']['output']>;
  setReadyForSignaturesStatus?: Maybe<Scalars['Boolean']['output']>;
  skipTimelineStep?: Maybe<Deal>;
  softDeleteUser?: Maybe<Scalars['Boolean']['output']>;
  ssnLogCreate?: Maybe<SsnLog>;
  storeSignatureData?: Maybe<Scalars['Boolean']['output']>;
  submitCreditApplicationV2: CreditApplication;
  submitToRouteOne?: Maybe<Deal>;
  temporaryInfoImport?: Maybe<Deal>;
  temporaryInfoUpsert?: Maybe<TemporaryInfo>;
  temporaryInfoUpsertAndImport?: Maybe<TemporaryInfo>;
  trackComEvent?: Maybe<Scalars['Void']['output']>;
  unlinkTemporaryInfo?: Maybe<TemporaryInfo>;
  updateArticle?: Maybe<Article>;
  updateCar?: Maybe<Car>;
  updateCarRegistrationExpiration?: Maybe<Car>;
  updateCreditAppOnDeal?: Maybe<UpdateCreditAppOnDealResponse>;
  updateCreditApplicationV2: CreditApplication;
  updateCustomer?: Maybe<Customer>;
  updateCustomerAndAddress?: Maybe<Customer>;
  updateDealNeedsElectronicSignatureVerification?: Maybe<Deal>;
  updateDealV2: Deal;
  updateFinancialInfo?: Maybe<FinancialInfo>;
  updateInsuranceInfo?: Maybe<Customer>;
  updatePayoffClerk?: Maybe<Scalars['Boolean']['output']>;
  updateProcessor?: Maybe<FinancialInfo>;
  updateProofOfInsurance?: Maybe<ProofOfInsurance>;
  updateReview?: Maybe<Review>;
  updateStructuringFollowUpNotes?: Maybe<Scalars['Boolean']['output']>;
  updateUser?: Maybe<User>;
  updateUserCallStatus?: Maybe<User>;
  updateUserSettings?: Maybe<User>;
  uploadDocument?: Maybe<ExtractedDocumentGql>;
  uploadDocumentToRouteOne?: Maybe<Scalars['Boolean']['output']>;
  uploadDriversLicense?: Maybe<DriversLicenseGql>;
  vehicleInfoUpsert?: Maybe<Deal>;
  verifyAccount?: Maybe<Scalars['Void']['output']>;
  voidContract?: Maybe<Scalars['Boolean']['output']>;
};

export type RootMutationTypeAcquisitionDealInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeAddDocumentsToMediaCenterArgs = {
  deal_id: Scalars['ID']['input'];
  files?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
};

export type RootMutationTypeAddLendersToCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
  lenderIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeAddParticipantArgs = {
  agent_phone?: InputMaybe<Scalars['String']['input']>;
  conference_command?: InputMaybe<Scalars['String']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  hunt_group_slug?: InputMaybe<Scalars['String']['input']>;
  new_agent_phone?: InputMaybe<Scalars['String']['input']>;
  new_agent_user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeApplyDocumentSignatureArgs = {
  dealId: Scalars['ID']['input'];
  dealJacketID: Scalars['String']['input'];
  documentID: Scalars['String']['input'];
  documentName: Scalars['String']['input'];
  isCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSignature?: InputMaybe<Scalars['Boolean']['input']>;
  signatureData: Scalars['String']['input'];
  signatureFieldName: Scalars['String']['input'];
};

export type RootMutationTypeArchivePodArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type RootMutationTypeAuctionUpsertArgs = {
  auction?: InputMaybe<AuctionInput>;
};

export type RootMutationTypeAutomateCreditApplicationArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootMutationTypeBuyerCobuyerSwapArgs = {
  cobuyer_id: Scalars['ID']['input'];
  customer_id: Scalars['ID']['input'];
  deal_id: Scalars['ID']['input'];
};

export type RootMutationTypeCallOutArgs = {
  calledNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeConditionReportUpsertArgs = {
  conditionReport?: InputMaybe<ConditionReportUpsertInput>;
};

export type RootMutationTypeCreateAffiliateTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateArticleArgs = {
  article?: InputMaybe<ArticleInput>;
};

export type RootMutationTypeCreateCreditApplicationV2Args = {
  input: CreditApplicationCreateInput;
};

export type RootMutationTypeCreateDealV2Args = {
  input: DealInput;
};

export type RootMutationTypeCreateExternalDealUploadUrlArgs = {
  dealId: Scalars['ID']['input'];
  fileDescription: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type RootMutationTypeCreateExternalTempInfoUploadUrlArgs = {
  fileDescription: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  guid: Scalars['ID']['input'];
};

export type RootMutationTypeCreateFeedbackArgs = {
  deal_id: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  improvements: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
};

export type RootMutationTypeCreateJdpBookSheetArgs = {
  customerState: Scalars['String']['input'];
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateKbbBookSheetArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateManualEntryTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']['input']>;
  extra_info?: InputMaybe<ExtraTempInfoInputType>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  license_plate?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateNewEstimateAndSetFollowUpArgs = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  date_utc: Scalars['DateTime']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  no_email?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone_number: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateNewEstimateFromFilesArgs = {
  data?: InputMaybe<TemporaryDataInput>;
  files?: InputMaybe<Array<InputMaybe<NewEstimateFileInput>>>;
};

export type RootMutationTypeCreateOptEventArgs = {
  event: OptEventInput;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreatePayoffRequestArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeCreatePotentialUserArgs = {
  potential_user?: InputMaybe<PotentialUserInput>;
};

export type RootMutationTypeCreateReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export type RootMutationTypeCreateStructuringFollowUpArgs = {
  dealId: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

export type RootMutationTypeCreateTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']['input']>;
  extra_info?: InputMaybe<ExtraTempInfoInputType>;
  ignore_existing_deal?: InputMaybe<Scalars['Boolean']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateTransactionArgs = {
  input?: InputMaybe<CreateTransactionInput>;
};

export type RootMutationTypeCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type RootMutationTypeCreditAppUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeCustomDatesUpdateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  date_key: Scalars['String']['input'];
  deal_id: Scalars['ID']['input'];
};

export type RootMutationTypeCustomerInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeDealAssignArgs = {
  assignee_id?: InputMaybe<Scalars['String']['input']>;
  deal_id: Scalars['ID']['input'];
  deal_state?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
};

export type RootMutationTypeDealCompletedArgs = {
  id: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

export type RootMutationTypeDealDetailNetsuiteArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDealFinalizeAcquisitionArgs = {
  address_line: Scalars['String']['input'];
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  cash_to_customer: Scalars['Float']['input'];
  city: Scalars['String']['input'];
  county: Scalars['String']['input'];
  dob: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  mileage?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
  zip: Scalars['String']['input'];
};

export type RootMutationTypeDealHasProblemSwitchArgs = {
  has_problem: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeDealInfoUpsertArgs = {
  car?: InputMaybe<CarInput>;
  customer?: InputMaybe<CustomerInput>;
  financialInfo?: InputMaybe<FinancialInfoInput>;
};

export type RootMutationTypeDealSeenArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeDealTagsUpsertArgs = {
  dealId: Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']>;
};

export type RootMutationTypeDealUnwoundArgs = {
  boot_reason: BootReasonInput;
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDealUpdateAssignmentsArgs = {
  closer2_id?: InputMaybe<Scalars['String']['input']>;
  closer_id?: InputMaybe<Scalars['String']['input']>;
  deal_source?: InputMaybe<Scalars['String']['input']>;
  funding_clerk_id?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  setter_id?: InputMaybe<Scalars['String']['input']>;
  structuring_manager_id?: InputMaybe<Scalars['String']['input']>;
  title_clerk2_id?: InputMaybe<Scalars['String']['input']>;
  title_clerk_id?: InputMaybe<Scalars['String']['input']>;
  titling_pod_id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DealType>;
};

export type RootMutationTypeDealUpdateMissingRequiredDocumentsArgs = {
  id: Scalars['ID']['input'];
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']['input']>;
  new_missing_required_external_documents: Scalars['Boolean']['input'];
};

export type RootMutationTypeDealUpdateRequestBootArgs = {
  boot_deal?: InputMaybe<Scalars['Boolean']['input']>;
  boot_reason: BootReasonInput;
  id: Scalars['ID']['input'];
  partial_note?: InputMaybe<NoteInput>;
  request_boot: Scalars['Boolean']['input'];
};

export type RootMutationTypeDealUpdateStateArgs = {
  clearSetDate?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeDealUpdateStateAndSendEmailsArgs = {
  contactType?: InputMaybe<ContactType>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  dealIsSigningOnCom?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isR1ContractUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  isR1ContractValidated?: InputMaybe<Scalars['Boolean']['input']>;
  state: Scalars['String']['input'];
};

export type RootMutationTypeDealUpdateTypeArgs = {
  id: Scalars['ID']['input'];
  type: DealType;
};

export type RootMutationTypeDeleteAndReplaceFilesArgs = {
  deal_id: Scalars['ID']['input'];
  files_to_delete?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
  files_to_upload?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
};

export type RootMutationTypeDeleteArticleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeDeleteComFlowFileArgs = {
  key: Scalars['String']['input'];
};

export type RootMutationTypeDeleteContractsArgs = {
  filePaths: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeDeleteCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteD2dDocumentArgs = {
  key: Scalars['String']['input'];
};

export type RootMutationTypeDeleteDealV2Args = {
  id: Scalars['Int']['input'];
};

export type RootMutationTypeDeleteReviewArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeDeleteStructuringFollowUpArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDetermineApprovalMutationArgs = {
  data: DetermineApprovalInput;
};

export type RootMutationTypeDocumentsInfoUpdateArgs = {
  data: DocumentsInfoUpdateInput;
};

export type RootMutationTypeEditD2dDocumentNameArgs = {
  key: Scalars['String']['input'];
  newKey: Scalars['String']['input'];
};

export type RootMutationTypeEndSalesFlowArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  importType: ImportType;
  reason: EndSalesFlowReasonEnum;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeEstimateUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeExecuteCardDownPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fraudSessionId?: InputMaybe<Scalars['String']['input']>;
  idempotencyId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeExecuteDownPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fraudSessionId?: InputMaybe<Scalars['String']['input']>;
  idempotencyId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  plaidAccountId?: InputMaybe<Scalars['String']['input']>;
  plaidToken?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeExecuteExternalSoftPullArgs = {
  devCreditScore?: InputMaybe<Scalars['Int']['input']>;
  devOverride?: InputMaybe<Scalars['Boolean']['input']>;
  isHardPull?: InputMaybe<Scalars['Boolean']['input']>;
  paymentEstimateInput: PaymentEstimateInput;
};

export type RootMutationTypeExternalCustomerUpdateArgs = {
  customer?: InputMaybe<ExternalCustomerInput>;
  customerId: Scalars['ID']['input'];
};

export type RootMutationTypeExternalUpdateDealArgs = {
  data?: InputMaybe<ExternalDealInputType>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeFileSendArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeFinalizeR1SigningArgs = {
  dealId: Scalars['ID']['input'];
  dealJacketID: Scalars['String']['input'];
};

export type RootMutationTypeFinancialInfoAcquisitionUpsertArgs = {
  car_id?: InputMaybe<Scalars['ID']['input']>;
  clear_override?: InputMaybe<Scalars['Boolean']['input']>;
  financialInfoAcquisition?: InputMaybe<FinancialInfoAcquisitionInput>;
  id: Scalars['ID']['input'];
  mmr?: InputMaybe<Scalars['Float']['input']>;
  override?: InputMaybe<Scalars['Boolean']['input']>;
  state: Scalars['String']['input'];
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
};

export type RootMutationTypeFollowUpDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeFollowUpInsertArgs = {
  date_utc: Scalars['DateTime']['input'];
  deal_id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  tz: Scalars['String']['input'];
};

export type RootMutationTypeFollowUpUpdateArgs = {
  date_utc: Scalars['DateTime']['input'];
  deal_id: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  tz: Scalars['String']['input'];
};

export type RootMutationTypeGetMarketPrequalificationArgs = {
  addressLine?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  prequalId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeHangUpCallArgs = {
  conference_id: Scalars['String']['input'];
};

export type RootMutationTypeImportDealArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeLinkAccountArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  ssn_last_4?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeLogSigningErrorArgs = {
  error: SigningErrorInput;
};

export type RootMutationTypeLogViewedDocumentEventArgs = {
  documentViewedEvent: SigningAuditDocumentViewedEvent;
};

export type RootMutationTypeManuallySelectBankArgs = {
  bank: Scalars['String']['input'];
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeMediaDeleteArgs = {
  filePath: Scalars['String']['input'];
};

export type RootMutationTypeMediaDeleteFromComArgs = {
  dealId: Scalars['ID']['input'];
  filePath: Scalars['String']['input'];
};

export type RootMutationTypeMediaInsertArgs = {
  dealId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeMediaInsertComArgs = {
  dealId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type RootMutationTypeMediaRenameArgs = {
  dealId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type RootMutationTypeMediaUpdateArgs = {
  dealId: Scalars['ID']['input'];
  has_verified_digital_signature?: InputMaybe<Scalars['Boolean']['input']>;
  has_wet_signature?: InputMaybe<Scalars['Boolean']['input']>;
  is_notarized?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  newFileName?: InputMaybe<Scalars['String']['input']>;
  oldKey: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeMediaUpdateComArgs = {
  dealId: Scalars['ID']['input'];
  newFileName?: InputMaybe<Scalars['String']['input']>;
  oldKey: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeMergeCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
  imported_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<DealStateEnumV2>;
};

export type RootMutationTypeMergeFilesArgs = {
  deal_id: Scalars['ID']['input'];
  docs?: InputMaybe<Array<InputMaybe<DealMediaInput>>>;
  missing_required_media_list?: InputMaybe<Array<InputMaybe<DealMediaTypeEnum>>>;
};

export type RootMutationTypeMessageSendArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeMockDealProcessingArgs = {
  deal_id: Scalars['ID']['input'];
  has_gap: Scalars['Boolean']['input'];
  has_vsc: Scalars['Boolean']['input'];
  is_conditioned: Scalars['Boolean']['input'];
};

export type RootMutationTypeNewEstimateUpsertArgs = {
  newEstimateUpsertInput?: InputMaybe<NewEstimateUpsertInput>;
};

export type RootMutationTypeNoteCreateArgs = {
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeNoteDeleteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeNoteUpdateArgs = {
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeNotificationSeenArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  notificationId: Scalars['ID']['input'];
  seen: Scalars['Boolean']['input'];
};

export type RootMutationTypeNotificationsSeenArgs = {
  dealId: Scalars['ID']['input'];
  notificationIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type RootMutationTypeOptOutArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypePayoffRequestCancelArgs = {
  payoffRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypePayoffRequestConfirmArgs = {
  payoffRequest: PayoffRequestConfirmInput;
};

export type RootMutationTypePayoffRequestFailArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffRequestFailureRequestArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffRequestUpdateArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffUpdateArgs = {
  payoffInfo?: InputMaybe<PayoffInput>;
};

export type RootMutationTypePodUpsertArgs = {
  pod?: InputMaybe<BasePodInput>;
};

export type RootMutationTypePodUpsertAndPodUsersUpdateArgs = {
  newPod: PodInput;
  oldPod?: InputMaybe<PodInput>;
};

export type RootMutationTypeProcessPaperworkArgs = {
  id: Scalars['ID']['input'];
  paperwork_type: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type RootMutationTypeRegisterDealerArgs = {
  dealer?: InputMaybe<DealerInput>;
};

export type RootMutationTypeRegisterProductArgs = {
  dealer_id: Scalars['ID']['input'];
  product_id: Scalars['ID']['input'];
  provider_dealer_code: Scalars['String']['input'];
};

export type RootMutationTypeResendEmailsArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeResetPasswordAndSendEmailArgs = {
  access_token?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeRevivePodArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type RootMutationTypeSaveConsentArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ConsentNameEnumType>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type RootMutationTypeSaveNewCustomerCallOutcomeArgs = {
  call_id: Scalars['String']['input'];
  outcome?: InputMaybe<Scalars['String']['input']>;
  outcome_description?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSelectCreditDecisionArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeSelectCreditDecisionV2Args = {
  amountFinanced: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  moneyDown: Scalars['Float']['input'];
  sellRate: Scalars['Float']['input'];
};

export type RootMutationTypeSendContractsArgs = {
  deal_id: Scalars['ID']['input'];
  keys: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeSendDocumentsWithAdobeAndUpdateStateArgs = {
  deal_id: Scalars['ID']['input'];
  keys: Array<InputMaybe<Scalars['String']['input']>>;
  state: DealStateEnum;
};

export type RootMutationTypeSendPaymentEstimateEmailAndTextArgs = {
  data?: InputMaybe<PaymentEstimateInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSetReadyForSignaturesStatusArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  documentProgressStatus?: InputMaybe<DocumentProgressStatus>;
  jacketId?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSkipTimelineStepArgs = {
  documentProgressStatus?: InputMaybe<DocumentProgressStatus>;
  jacketId?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSoftDeleteUserArgs = {
  id: Scalars['String']['input'];
};

export type RootMutationTypeSsnLogCreateArgs = {
  log?: InputMaybe<SsnLogInput>;
};

export type RootMutationTypeStoreSignatureDataArgs = {
  customer_id: Scalars['ID']['input'];
  initials_data_url?: InputMaybe<Scalars['String']['input']>;
  initials_strokes?: InputMaybe<Scalars['String']['input']>;
  signature_data_url?: InputMaybe<Scalars['String']['input']>;
  signature_strokes?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSubmitCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
  lenderIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeSubmitToRouteOneArgs = {
  deal?: InputMaybe<DealInput>;
  lenderIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RootMutationTypeTemporaryInfoImportArgs = {
  id: Scalars['ID']['input'];
  importType?: InputMaybe<ImportType>;
  transformType: TransformType;
};

export type RootMutationTypeTemporaryInfoUpsertArgs = {
  info?: InputMaybe<TemporaryInfoInput>;
};

export type RootMutationTypeTemporaryInfoUpsertAndImportArgs = {
  info?: InputMaybe<TemporaryInfoInput>;
  transformType?: InputMaybe<TransformType>;
};

export type RootMutationTypeTrackComEventArgs = {
  approvalOnDealProcessing?: InputMaybe<Scalars['Boolean']['input']>;
  dealId: Scalars['ID']['input'];
  finishedDocumentsScreen?: InputMaybe<Scalars['Boolean']['input']>;
  reachedDocumentsScreen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeUnlinkTemporaryInfoArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateArticleArgs = {
  article?: InputMaybe<ArticleInput>;
};

export type RootMutationTypeUpdateCarArgs = {
  car?: InputMaybe<CarInput>;
};

export type RootMutationTypeUpdateCarRegistrationExpirationArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  registration_expiration?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RootMutationTypeUpdateCreditAppOnDealArgs = {
  input: CreditAppInput;
};

export type RootMutationTypeUpdateCreditApplicationV2Args = {
  input: CreditApplicationUpdateInput;
};

export type RootMutationTypeUpdateCustomerArgs = {
  customer?: InputMaybe<CustomerInput>;
};

export type RootMutationTypeUpdateCustomerAndAddressArgs = {
  customer?: InputMaybe<CustomerInput>;
};

export type RootMutationTypeUpdateDealNeedsElectronicSignatureVerificationArgs = {
  deal_id: Scalars['ID']['input'];
  needs_electronic_signature_verification: Scalars['Boolean']['input'];
};

export type RootMutationTypeUpdateDealV2Args = {
  input: DealInput;
};

export type RootMutationTypeUpdateFinancialInfoArgs = {
  input: UpdateFinancialInfoInput;
};

export type RootMutationTypeUpdateInsuranceInfoArgs = {
  expiration_date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  insurance_company?: InputMaybe<Scalars['String']['input']>;
  policy_no?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdatePayoffClerkArgs = {
  payoff_clerk_id?: InputMaybe<Scalars['String']['input']>;
  payoff_request_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeUpdateProcessorArgs = {
  deal_id: Scalars['ID']['input'];
  fi_id: Scalars['ID']['input'];
  processor?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdateProofOfInsuranceArgs = {
  customer_id: Scalars['ID']['input'];
  proof_of_insurance?: InputMaybe<ProofOfInsuranceInput>;
};

export type RootMutationTypeUpdateReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export type RootMutationTypeUpdateStructuringFollowUpNotesArgs = {
  id: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

export type RootMutationTypeUpdateUserArgs = {
  allAuth0RoleIds: Array<Scalars['String']['input']>;
  updateUserInput: UpdateUserInput;
};

export type RootMutationTypeUpdateUserCallStatusArgs = {
  status: Scalars['String']['input'];
};

export type RootMutationTypeUpdateUserSettingsArgs = {
  auto_assign_deals: Scalars['Boolean']['input'];
  hours: Scalars['String']['input'];
  id: Scalars['String']['input'];
  on_vacation: Scalars['Boolean']['input'];
  overnight_deals: Scalars['Boolean']['input'];
};

export type RootMutationTypeUploadDocumentArgs = {
  bytes?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<TtDocumentName>;
};

export type RootMutationTypeUploadDocumentToRouteOneArgs = {
  doc?: InputMaybe<UploadDocumentsInput>;
  jacketId?: InputMaybe<Scalars['String']['input']>;
  withCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeUploadDriversLicenseArgs = {
  bytes?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeVehicleInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeVerifyAccountArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  ssn_last_4?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeVoidContractArgs = {
  deal_id: Scalars['ID']['input'];
  void_gap?: InputMaybe<Scalars['Boolean']['input']>;
  void_vsc?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  acquisitionOffer?: Maybe<AcquisitionOffer>;
  addressDetail?: Maybe<Address>;
  articleImageUploadUrl?: Maybe<ArticleImageUploadUrl>;
  articles?: Maybe<Array<Maybe<Article>>>;
  auctionAverageTransportCost?: Maybe<Scalars['Int']['output']>;
  auctionDistances?: Maybe<AuctionDistances>;
  auctions?: Maybe<Array<Maybe<Auction>>>;
  bankInfo?: Maybe<Bank>;
  bankLogo?: Maybe<Scalars['String']['output']>;
  banks?: Maybe<Array<Maybe<Bank>>>;
  bootedDeals?: Maybe<Array<Maybe<Deal>>>;
  calculateOptions?: Maybe<Array<Maybe<KbbOption>>>;
  callInProgress?: Maybe<Conference>;
  carDetailLicensePlate?: Maybe<Car>;
  carDetailVin?: Maybe<Car>;
  checkExistingAccountByEmail?: Maybe<Scalars['Boolean']['output']>;
  checkFlowStatus?: Maybe<FlowStatus>;
  completedDeals?: Maybe<Array<Maybe<Deal>>>;
  createFetchUrlsFromCom: Array<Scalars['String']['output']>;
  creditApplicationSubmissions: Array<Maybe<CreditApplicationSubmission>>;
  creditApplicationV2?: Maybe<CreditApplication>;
  creditDecisionV2?: Maybe<CreditDecision>;
  currentConsentAgreementText?: Maybe<ConsentAgreement>;
  customer?: Maybe<Customer>;
  dashboardDeals?: Maybe<Array<Maybe<Deal>>>;
  deal?: Maybe<Deal>;
  dealAdvisors?: Maybe<Deal>;
  dealStates?: Maybe<Array<Maybe<DealState>>>;
  dealV2?: Maybe<Deal>;
  dealerProducts?: Maybe<Array<Maybe<Product>>>;
  dealsV2: DealsConnection;
  dealsWithNotification?: Maybe<DealsWithNotification>;
  decodeAffiliateCarDetails?: Maybe<TemporaryData>;
  decryptSSN?: Maybe<Scalars['String']['output']>;
  determineApprovalQuery?: Maybe<DetermineApproval>;
  duplicateDeals?: Maybe<Array<Maybe<Deal>>>;
  experianCreditReport?: Maybe<PrequalificationCreditReports>;
  fetchProofOfInsurance?: Maybe<ProofOfInsurance>;
  followUp?: Maybe<FollowUp>;
  gapRates?: Maybe<Array<Maybe<Rate>>>;
  generateContracts?: Maybe<Contracts>;
  generateDocuments?: Maybe<Documents>;
  generatePurchaseOrder?: Maybe<PurchaseOrder>;
  generateR1EContract: Scalars['Boolean']['output'];
  generateTestDocuments?: Maybe<Scalars['Boolean']['output']>;
  getAllHuntGroups?: Maybe<Array<Maybe<HuntGroup>>>;
  getApprovedCreditDecisionsByDealId?: Maybe<Array<Maybe<CreditDecision>>>;
  getAuth0Roles?: Maybe<Array<Auth0Role>>;
  getAvailableUsers?: Maybe<Array<Maybe<GetAvailableUsers>>>;
  getBase64StringFromS3Object?: Maybe<Scalars['String']['output']>;
  getByAuth0Id?: Maybe<Customer>;
  getComPackageOptions?: Maybe<GetPackageOptions>;
  getCreditDecisionByR1FSID?: Maybe<CreditDecision>;
  getD2dDocuments?: Maybe<Array<Maybe<D2dDocument>>>;
  getDDSoftPull: DdSoftPull;
  getDownPaymentAmountByMethod?: Maybe<Scalars['Float']['output']>;
  getExternalAutoStructureBankLogos?: Maybe<Array<Maybe<AutoStructureBankLogo>>>;
  getFees?: Maybe<FeeGql>;
  getFollowUpDealsPaginated?: Maybe<PaginatedDeals>;
  getOperatingHoursAndTimeSlots?: Maybe<OperatingHoursTimeSlots>;
  getR1CustomerCopy?: Maybe<Array<Maybe<DealDocument>>>;
  getR1DealDocument?: Maybe<DealDocument>;
  getTwilioNumbers?: Maybe<Array<Maybe<TwilioNumber>>>;
  getUnlinkedDeals?: Maybe<Array<Maybe<Deal>>>;
  getUploadUrl?: Maybe<MediaUploadUrl>;
  getUserCallHistory?: Maybe<CallsPagination>;
  getUserCreationData?: Maybe<UserCreationData>;
  getUserTwilioNumber?: Maybe<Scalars['String']['output']>;
  getUsers?: Maybe<PaginatedUser>;
  getWaitingCalls?: Maybe<GetWaitingCalls>;
  getWarrantyInfo?: Maybe<CarWarranty>;
  inbox?: Maybe<InboxMessagesType>;
  jdpValues?: Maybe<Car>;
  jurisdiction?: Maybe<TtJurisdiction>;
  kbbValues?: Maybe<KbbValuesResult>;
  kbbVin?: Maybe<KbbVinObjectResult>;
  keyMetrics?: Maybe<Array<Maybe<KeyMetric>>>;
  laneKeyMetrics?: Maybe<Array<Maybe<LaneKeyMetric>>>;
  leaderboardSetter: Array<LeaderboardSetterStats>;
  lenders?: Maybe<Array<Maybe<Lender>>>;
  lienholderUtility?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  loggedUser?: Maybe<User>;
  media?: Maybe<Array<Maybe<DealMedia>>>;
  mediaFromCom?: Maybe<Array<DealMedia>>;
  messages?: Maybe<MessagesWithErrors>;
  messagesDbPaginated?: Maybe<MessagesDbPaginated>;
  metricsPages?: Maybe<Array<Maybe<MetricsPages>>>;
  mmr?: Maybe<MmrResult>;
  notes?: Maybe<Array<Maybe<Note>>>;
  paymentEstimate?: Maybe<PaymentEstimate>;
  paymentOptions?: Maybe<PaymentOptions>;
  payoffDashboardDeals?: Maybe<PayoffDashboard>;
  payoffRequest?: Maybe<PayoffRequest>;
  pod?: Maybe<Pod>;
  podHours?: Maybe<Array<Maybe<PodHours>>>;
  pods?: Maybe<Array<Maybe<Pod>>>;
  podsAll?: Maybe<Array<Maybe<Pod>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  providers?: Maybe<Array<Maybe<Provider>>>;
  retrieveDealDocsUploadedToR1?: Maybe<Array<Maybe<DealDocument>>>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  searchDeals?: Maybe<Array<Deal>>;
  searchWebLeads?: Maybe<Array<Maybe<TemporaryInfo>>>;
  sendPurchaseOrder?: Maybe<Scalars['Boolean']['output']>;
  startR1DocumentsFlow?: Maybe<Scalars['Boolean']['output']>;
  structuringFollowUp?: Maybe<StructuringFollowUp>;
  structuringFollowUpDeals?: Maybe<PaginatedDeals>;
  surcharges?: Maybe<Array<Maybe<Surcharge>>>;
  tags: Array<Tag>;
  templateTexts?: Maybe<Array<Maybe<Message_Template>>>;
  temporaryInfo?: Maybe<TemporaryInfo>;
  temporaryInfoAuthenticated?: Maybe<TemporaryInfo>;
  titlingDashboardDeals?: Maybe<PaginatedDeals>;
  titlingPodsAll?: Maybe<Array<Maybe<Pod>>>;
  titlingUsers?: Maybe<Array<Maybe<PodUser>>>;
  totalCompletedDeals?: Maybe<Scalars['Int']['output']>;
  userDeal?: Maybe<Deal>;
  userDealReady?: Maybe<DealReadyType>;
  userDeals?: Maybe<Array<Maybe<Deals>>>;
  userPermissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userTitlingPods?: Maybe<Array<Maybe<Pod>>>;
  users?: Maybe<Array<Maybe<User>>>;
  usersByRole?: Maybe<Array<Maybe<UserByRole>>>;
  validZip?: Maybe<Scalars['Boolean']['output']>;
  validateBankContract?: Maybe<Scalars['Boolean']['output']>;
  validateCreditApplicationV2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleMileage?: Maybe<VehicleMileageGql>;
  visibleArticles?: Maybe<VisibleArticles>;
  visibleReviews?: Maybe<Array<Maybe<Review>>>;
  vscRates?: Maybe<Array<Maybe<Rate>>>;
};

export type RootQueryTypeAcquisitionOfferArgs = {
  accidents: AccidentsEnumType;
  book_value?: InputMaybe<Scalars['Int']['input']>;
  lienholder?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  mmr_value?: InputMaybe<Scalars['Float']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeAddressDetailArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeAuctionAverageTransportCostArgs = {
  customerAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeAuctionDistancesArgs = {
  data?: InputMaybe<AuctionDistancesInput>;
};

export type RootQueryTypeBankInfoArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeBankLogoArgs = {
  bank_name: Scalars['String']['input'];
};

export type RootQueryTypeBanksArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  lienholderName?: InputMaybe<Scalars['String']['input']>;
  selectedBank?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeBootedDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  end_date: Scalars['DateTime']['input'];
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  start_date: Scalars['DateTime']['input'];
  types: Array<InputMaybe<DealType>>;
  unread_messages_filter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeCalculateOptionsArgs = {
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  configToModify?: InputMaybe<Array<InputMaybe<KbbConfigType>>>;
  startingVehicleOptions?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  vin: Scalars['String']['input'];
};

export type RootQueryTypeCarDetailLicensePlateArgs = {
  license_plate_number: Scalars['String']['input'];
  license_plate_state: Scalars['String']['input'];
};

export type RootQueryTypeCarDetailVinArgs = {
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCheckExistingAccountByEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCheckFlowStatusArgs = {
  guid?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCompletedDealsArgs = {
  address_state?: InputMaybe<Scalars['String']['input']>;
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']['input']>;
  deal_state?: InputMaybe<Scalars['String']['input']>;
  end_date: Scalars['DateTime']['input'];
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  start_date: Scalars['DateTime']['input'];
  types: Array<InputMaybe<DealType>>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCreateFetchUrlsFromComArgs = {
  keys: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootQueryTypeCreditApplicationSubmissionsArgs = {
  filter?: InputMaybe<CreditApplicationSubmissionFilter>;
};

export type RootQueryTypeCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeCreditDecisionV2Args = {
  id: Scalars['Int']['input'];
};

export type RootQueryTypeCurrentConsentAgreementTextArgs = {
  agreementType?: InputMaybe<ConsentAgreementTypeEnum>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeCustomerArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeDashboardDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types: Array<InputMaybe<DealType>>;
  unread_messages_filter?: InputMaybe<Scalars['Boolean']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeDealArgs = {
  id: Scalars['ID']['input'];
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeDealAdvisorsArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeDealStatesArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeDealV2Args = {
  id?: InputMaybe<Scalars['Int']['input']>;
  leaseId?: InputMaybe<Scalars['GUID']['input']>;
};

export type RootQueryTypeDealerProductsArgs = {
  dealer_id: Scalars['ID']['input'];
};

export type RootQueryTypeDealsV2Args = {
  filter?: InputMaybe<DealsFilter>;
  pagination?: InputMaybe<PaginationInputType>;
  sorting?: InputMaybe<SortingTypeV2>;
};

export type RootQueryTypeDealsWithNotificationArgs = {
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeDecodeAffiliateCarDetailsArgs = {
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeDecryptSsnArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeDetermineApprovalQueryArgs = {
  data?: InputMaybe<DetermineApprovalQueryInput>;
};

export type RootQueryTypeDuplicateDealsArgs = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  home_phone_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeExperianCreditReportArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeFetchProofOfInsuranceArgs = {
  customer_id: Scalars['ID']['input'];
};

export type RootQueryTypeFollowUpArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGapRatesArgs = {
  bank?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<RateInput>;
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGenerateContractsArgs = {
  data?: InputMaybe<ContractsInput>;
};

export type RootQueryTypeGenerateDocumentsArgs = {
  data?: InputMaybe<DocumentsInput>;
};

export type RootQueryTypeGeneratePurchaseOrderArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGenerateR1EContractArgs = {
  dealJacketId: Scalars['String']['input'];
};

export type RootQueryTypeGenerateTestDocumentsArgs = {
  update_deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGetAllHuntGroupsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeGetApprovedCreditDecisionsByDealIdArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeGetBase64StringFromS3ObjectArgs = {
  key: Scalars['String']['input'];
};

export type RootQueryTypeGetComPackageOptionsArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeGetCreditDecisionByR1FsidArgs = {
  deal_id: Scalars['ID']['input'];
  r1_fsid: Scalars['String']['input'];
};

export type RootQueryTypeGetDdSoftPullArgs = {
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  paymentEstimateInput: PaymentEstimateInput;
  shouldForceRetry?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetDownPaymentAmountByMethodArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  paymentType?: InputMaybe<PaymentInstrumentType>;
};

export type RootQueryTypeGetFeesArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  bookValue?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cobuyerAddress?: InputMaybe<Scalars['String']['input']>;
  cobuyerCity?: InputMaybe<Scalars['String']['input']>;
  cobuyerFirstName?: InputMaybe<Scalars['String']['input']>;
  cobuyerLastName?: InputMaybe<Scalars['String']['input']>;
  cobuyerMiddleName?: InputMaybe<Scalars['String']['input']>;
  cobuyerState?: InputMaybe<StateAbbreviation>;
  cobuyerZip?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['String']['input']>;
  docFee?: InputMaybe<Scalars['Float']['input']>;
  estimatedPayoff?: InputMaybe<Scalars['Float']['input']>;
  financialInfoId?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<TtFuelType>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  newBank?: InputMaybe<Scalars['String']['input']>;
  newBankAddress?: InputMaybe<Scalars['String']['input']>;
  newBankAddress2?: InputMaybe<Scalars['String']['input']>;
  newBankCity?: InputMaybe<Scalars['String']['input']>;
  newBankState?: InputMaybe<StateAbbreviation>;
  newBankZip?: InputMaybe<Scalars['String']['input']>;
  newBankZipPlus4?: InputMaybe<Scalars['String']['input']>;
  odometerReading?: InputMaybe<Scalars['Int']['input']>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  previouslyTitledState?: InputMaybe<StateAbbreviation>;
  retailBookValue?: InputMaybe<Scalars['Float']['input']>;
  signedDate?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateAbbreviation>;
  transferringPlates?: InputMaybe<Scalars['Boolean']['input']>;
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  vehicleType?: InputMaybe<TtVehicleType>;
  vin?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeGetFollowUpDealsPaginatedArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  boot_requested?: InputMaybe<Scalars['Boolean']['input']>;
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  results_per_page?: InputMaybe<Scalars['Int']['input']>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sort_column: Scalars['String']['input'];
  sort_direction: Scalars['String']['input'];
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeGetR1CustomerCopyArgs = {
  dealJacketID: Scalars['String']['input'];
  includeExtraPDFBinaries?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetR1DealDocumentArgs = {
  dealJacketID: Scalars['String']['input'];
  documentID: Scalars['String']['input'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetUploadUrlArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  fileName: Scalars['String']['input'];
  useD2dDocumentCenterBucket?: InputMaybe<Scalars['Boolean']['input']>;
  useD2dVoiceRecordingsBucket?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetUserCallHistoryArgs = {
  items_per_page?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeGetUsersArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  podId?: InputMaybe<Scalars['ID']['input']>;
  roleNames?: InputMaybe<Array<InputMaybe<Auth0RoleName>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  teamType?: InputMaybe<TeamType>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RootQueryTypeGetWarrantyInfoArgs = {
  make: Scalars['String']['input'];
  mileage: Scalars['Int']['input'];
  year: Scalars['String']['input'];
};

export type RootQueryTypeInboxArgs = {
  filterBy?: InputMaybe<InboxFilterByEnum>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InboxOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  unreadOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeJdpValuesArgs = {
  mileage: Scalars['Float']['input'];
  stateCode?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
};

export type RootQueryTypeJurisdictionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateAbbreviation>;
};

export type RootQueryTypeKbbValuesArgs = {
  data: KbbValuesDataType;
};

export type RootQueryTypeKbbVinArgs = {
  vin: Scalars['String']['input'];
};

export type RootQueryTypeKeyMetricsArgs = {
  end_date: Scalars['DateTime']['input'];
  pod_ids: Array<InputMaybe<Scalars['String']['input']>>;
  start_date: Scalars['DateTime']['input'];
};

export type RootQueryTypeLaneKeyMetricsArgs = {
  podId?: InputMaybe<Scalars['ID']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  today?: InputMaybe<Scalars['DateTime']['input']>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeLeaderboardSetterArgs = {
  after: Scalars['DateTime']['input'];
  before: Scalars['DateTime']['input'];
  teamType?: InputMaybe<TeamType>;
};

export type RootQueryTypeLendersArgs = {
  query: Scalars['String']['input'];
};

export type RootQueryTypeLienholderUtilityArgs = {
  make?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeMediaArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaFromComArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeMessagesArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeMessagesDbPaginatedArgs = {
  dealId: Scalars['String']['input'];
  dealPhoneNumber: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};

export type RootQueryTypeMmrArgs = {
  color: Scalars['String']['input'];
  grade: Scalars['Float']['input'];
  mileage: Scalars['Int']['input'];
  state: Scalars['String']['input'];
  trim: Scalars['String']['input'];
  vin: Scalars['String']['input'];
};

export type RootQueryTypeNotesArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypePaymentEstimateArgs = {
  data?: InputMaybe<PaymentEstimateInput>;
};

export type RootQueryTypePaymentOptionsArgs = {
  data?: InputMaybe<PaymentOptionsInput>;
};

export type RootQueryTypePayoffRequestArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypePodArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypePodsArgs = {
  hideArchived?: InputMaybe<Scalars['Boolean']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pod_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  team_type?: InputMaybe<Array<InputMaybe<TeamType>>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeProductsArgs = {
  provider_id: Scalars['ID']['input'];
};

export type RootQueryTypeRetrieveDealDocsUploadedToR1Args = {
  dealJacketID: Scalars['String']['input'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeSearchDealsArgs = {
  query: Scalars['String']['input'];
};

export type RootQueryTypeSearchWebLeadsArgs = {
  query: Scalars['String']['input'];
};

export type RootQueryTypeSendPurchaseOrderArgs = {
  deal_id: Scalars['ID']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeStartR1DocumentsFlowArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeStructuringFollowUpArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeStructuringFollowUpDealsArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  bootRequested?: InputMaybe<Scalars['Boolean']['input']>;
  dealTypes: Array<InputMaybe<DealType>>;
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  podId?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sortColumn: StructuringFollowUpSortColumn;
  sortDirection: StructuringFollowUpSortDirection;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootQueryTypeSurchargesArgs = {
  product_id: Scalars['Int']['input'];
};

export type RootQueryTypeTagsArgs = {
  input?: InputMaybe<TagsInput>;
};

export type RootQueryTypeTemporaryInfoArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeTemporaryInfoAuthenticatedArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeTitlingDashboardDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<FilterType>;
  includeTitleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  sorting?: InputMaybe<SortingType>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeTitlingUsersArgs = {
  titling_pod_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeUserDealArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeUserDealReadyArgs = {
  tempInfoId: Scalars['ID']['input'];
};

export type RootQueryTypeUserPermissionsArgs = {
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeUsersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  include_accounting_clerks?: InputMaybe<Scalars['Boolean']['input']>;
  include_financial_specialists?: InputMaybe<Scalars['Boolean']['input']>;
  include_structuring_managers?: InputMaybe<Scalars['Boolean']['input']>;
  only_active?: InputMaybe<Scalars['Boolean']['input']>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeUsersByRoleArgs = {
  role: Auth0RoleName;
};

export type RootQueryTypeValidZipArgs = {
  zipCode: Scalars['String']['input'];
};

export type RootQueryTypeValidateBankContractArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeValidateCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeVehicleMileageArgs = {
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeVisibleArticlesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeVscRatesArgs = {
  data?: InputMaybe<RateInput>;
  deal_id: Scalars['ID']['input'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  inProgressCalls?: Maybe<Array<Maybe<Conference>>>;
  onContractValidation?: Maybe<Array<Maybe<Deal>>>;
  onDashboardDocumentUpload?: Maybe<DashboardDocumentUpload>;
  onDealComplete?: Maybe<Scalars['Boolean']['output']>;
  onDealUpdate?: Maybe<Array<Maybe<Deal>>>;
  onDownPaymentReceived?: Maybe<DownPaymentReceived>;
  onFollowUpUpdate?: Maybe<Array<Maybe<Deal>>>;
  onNewMessage?: Maybe<Message>;
  onNoDealNotification?: Maybe<Notification>;
  onPayoffRequestStatusUpdate?: Maybe<PayoffRequestStatusUpdate>;
  onPayoffRequestUpdate?: Maybe<PayoffRequestPopulated>;
  onReceivedMessage?: Maybe<MessageData>;
  onStructuringFollowUpUpdate?: Maybe<Array<Maybe<Deal>>>;
  onTransactionCreated?: Maybe<FinancialInfo>;
  onWaitingQueue?: Maybe<OnWaitingQueue>;
  switchCallStatus?: Maybe<Scalars['String']['output']>;
  switchCallStatusIndividual?: Maybe<Scalars['String']['output']>;
};

export type RootSubscriptionTypeOnContractValidationArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  fundingClerkOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootSubscriptionTypeOnDealCompleteArgs = {
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootSubscriptionTypeOnDealUpdateArgs = {
  dealIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnFollowUpUpdateArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']['input']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnNewMessageArgs = {
  dealPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RootSubscriptionTypeOnPayoffRequestStatusUpdateArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootSubscriptionTypeOnReceivedMessageArgs = {
  activePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  dealPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isNotification?: InputMaybe<Scalars['Boolean']['input']>;
  isWidgetOpen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootSubscriptionTypeOnStructuringFollowUpUpdateArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  podId?: InputMaybe<Scalars['ID']['input']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnTransactionCreatedArgs = {
  dealId: Scalars['ID']['input'];
};

export type SaveConsent = {
  __typename?: 'SaveConsent';
  consent?: Maybe<Consent>;
  isNew?: Maybe<Scalars['Boolean']['output']>;
};

export type SelectCreditDecision = {
  __typename?: 'SelectCreditDecision';
  creditDecision: CreditDecision;
  financialInfo: FinancialInfo;
};

export type SignatureData = {
  __typename?: 'SignatureData';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  initials_data_url?: Maybe<Scalars['String']['output']>;
  initials_strokes?: Maybe<Scalars['String']['output']>;
  signature_data_url?: Maybe<Scalars['String']['output']>;
  signature_strokes?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum SigningErrorEnum {
  ApplyDealerSignature = 'APPLY_DEALER_SIGNATURE',
  ApplySignature = 'APPLY_SIGNATURE',
  FinalizeSigning = 'FINALIZE_SIGNING',
  GenerationDate = 'GENERATION_DATE',
  RetrieveDocuments = 'RETRIEVE_DOCUMENTS',
}

export enum SigningSolutionEnum {
  AdobeSign = 'adobe_sign',
  RouteOne = 'route_one',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortingType = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export type SortingTypeV2 = {
  direction?: InputMaybe<SortDirectionEnum>;
  field?: InputMaybe<DealSortableColumnsEnum>;
};

export enum StateAbbreviation {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Pw = 'PW',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StructuringFollowUp = {
  __typename?: 'StructuringFollowUp';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StructuringFollowUpStatus>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type StructuringFollowUpInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creator_id?: InputMaybe<Scalars['ID']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StructuringFollowUpStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum StructuringFollowUpSortColumn {
  Advisor = 'advisor',
  Customer = 'customer',
  Date = 'date',
  DealState = 'dealState',
  StructuringManager = 'structuringManager',
}

export enum StructuringFollowUpSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum StructuringFollowUpStatus {
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
}

export type TtConditionFilterInput = {
  canVerifyDigitalSignatures?: InputMaybe<Scalars['Boolean']['input']>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  driversLicenseAddress?: InputMaybe<Scalars['String']['input']>;
  driversLicenseState?: InputMaybe<Scalars['String']['input']>;
  hasCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionState?: InputMaybe<Scalars['String']['input']>;
  lienholder?: InputMaybe<Scalars['String']['input']>;
  movedStateDuringLease?: InputMaybe<Scalars['Boolean']['input']>;
  newBank?: InputMaybe<TtNewBankEnum>;
  transferringPlates?: InputMaybe<Scalars['Boolean']['input']>;
  transferringRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleAge?: InputMaybe<Scalars['Int']['input']>;
};

export type TtDocumentConditionsFields = {
  __typename?: 'TTDocumentConditionsFields';
  description?: Maybe<Scalars['String']['output']>;
  variables?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TtDocumentFields = {
  __typename?: 'TTDocumentFields';
  conditions?: Maybe<Array<Maybe<TtDocumentConditionsFields>>>;
  documentTypeId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  supportsImageExtraction?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<TtDocumentTypeFields>;
  validations?: Maybe<Array<Maybe<TtValidation>>>;
};

export enum TtDocumentName {
  AcuraSpecificOdometerStatement = 'AcuraSpecificOdometerStatement',
  BackOfDriversLicense = 'BackOfDriversLicense',
  BillOfSale = 'BillOfSale',
  CobuyerBackOfDriversLicense = 'CobuyerBackOfDriversLicense',
  CobuyerDriversLicense = 'CobuyerDriversLicense',
  CobuyerJointTenancyWithRightsOfSurvivorship = 'CobuyerJointTenancyWithRightsOfSurvivorship',
  CobuyerOdometerStatement = 'CobuyerOdometerStatement',
  CobuyerPowerOfAttorney = 'CobuyerPowerOfAttorney',
  CobuyerRegistrationSpecificPowerOfAttorney = 'CobuyerRegistrationSpecificPowerOfAttorney',
  CobuyerSecurePowerOfAttorney = 'CobuyerSecurePowerOfAttorney',
  CobuyerStateSpecificOdometerStatement = 'CobuyerStateSpecificOdometerStatement',
  CobuyerStateSpecificPowerOfAttorney = 'CobuyerStateSpecificPowerOfAttorney',
  CobuyerSupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause = 'CobuyerSupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause',
  DriversLicense = 'DriversLicense',
  ElectronicRegistrationAndTitleAgreement = 'ElectronicRegistrationAndTitleAgreement',
  ElectronicSignatureAttestation = 'ElectronicSignatureAttestation',
  ElectronicSignatureCertification = 'ElectronicSignatureCertification',
  Emissions = 'Emissions',
  GmLeasePacket = 'GMLeasePacket',
  HondaSpecificOdometerStatement = 'HondaSpecificOdometerStatement',
  IdahoSalesTaxExemption = 'IdahoSalesTaxExemption',
  JointOdometerStatement = 'JointOdometerStatement',
  LicensePlate = 'LicensePlate',
  Odometer = 'Odometer',
  OdometerStatement = 'OdometerStatement',
  OhioSpecificHondaSpecificPowerOfAttorney = 'OhioSpecificHondaSpecificPowerOfAttorney',
  PlateTransferLetter = 'PlateTransferLetter',
  PorscheSpecificOdometerStatement = 'PorscheSpecificOdometerStatement',
  PorscheSpecificPayoffInstructions = 'PorscheSpecificPayoffInstructions',
  PowerOfAttorney = 'PowerOfAttorney',
  ProofOfInsurance = 'ProofOfInsurance',
  ProofOfResidence = 'ProofOfResidence',
  ProofOfResidence2 = 'ProofOfResidence2',
  PropertyTaxReceipt = 'PropertyTaxReceipt',
  PurchaseOrder = 'PurchaseOrder',
  Registration = 'Registration',
  RegistrationSpecificPowerOfAttorney = 'RegistrationSpecificPowerOfAttorney',
  SafetyInspection = 'SafetyInspection',
  SalesTaxExemption = 'SalesTaxExemption',
  SecurePowerOfAttorney = 'SecurePowerOfAttorney',
  StateSpecificBillOfSale = 'StateSpecificBillOfSale',
  StateSpecificDamageDisclosure = 'StateSpecificDamageDisclosure',
  StateSpecificOdometerStatement = 'StateSpecificOdometerStatement',
  StateSpecificPowerOfAttorney = 'StateSpecificPowerOfAttorney',
  SupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause = 'SupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause',
  TitleApp = 'TitleApp',
  VinInspection = 'VINInspection',
}

export type TtDocumentTypeFields = {
  __typename?: 'TTDocumentTypeFields';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parameters?: Maybe<Array<Maybe<TtParameterFields>>>;
};

export type TtFeesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bookValue?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cobuyerAddress?: InputMaybe<Scalars['String']['input']>;
  cobuyerCity?: InputMaybe<Scalars['String']['input']>;
  cobuyerFirstName?: InputMaybe<Scalars['String']['input']>;
  cobuyerLastName?: InputMaybe<Scalars['String']['input']>;
  cobuyerMiddleName?: InputMaybe<Scalars['String']['input']>;
  cobuyerState?: InputMaybe<StateAbbreviation>;
  cobuyerZip?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['String']['input']>;
  docFee?: InputMaybe<Scalars['Float']['input']>;
  estimatedPayoff?: InputMaybe<Scalars['Float']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<TtFuelType>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  newBank?: InputMaybe<Scalars['String']['input']>;
  newBankAddress?: InputMaybe<Scalars['String']['input']>;
  newBankAddress2?: InputMaybe<Scalars['String']['input']>;
  newBankCity?: InputMaybe<Scalars['String']['input']>;
  newBankState?: InputMaybe<StateAbbreviation>;
  newBankZip?: InputMaybe<Scalars['String']['input']>;
  newBankZipPlus4?: InputMaybe<Scalars['String']['input']>;
  odometerReading?: InputMaybe<Scalars['Int']['input']>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  previouslyTitledState?: InputMaybe<StateAbbreviation>;
  retailBookValue?: InputMaybe<Scalars['Float']['input']>;
  signedDate?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateAbbreviation>;
  transferringPlates?: InputMaybe<Scalars['Boolean']['input']>;
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  vehicleType?: InputMaybe<TtVehicleType>;
  vin?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum TtFuelType {
  Diesel = 'Diesel',
  Electric = 'Electric',
  Gasoline = 'Gasoline',
  Hybrid = 'Hybrid',
  PlugInHybrid = 'PlugInHybrid',
}

export enum TtGetFeesSourceType {
  ComDevTool = 'COM_DEV_TOOL',
  ComEnterPayoff = 'COM_ENTER_PAYOFF',
  ComImportInfo = 'COM_IMPORT_INFO',
  ComPaymentCalculator = 'COM_PAYMENT_CALCULATOR',
  ComPrequalification = 'COM_PREQUALIFICATION',
  ComSoftPull = 'COM_SOFT_PULL',
  D2DGetTaxesAndFees = 'D2D_GET_TAXES_AND_FEES',
  D2DPrequalification = 'D2D_PREQUALIFICATION',
  DdAutoStructure = 'DD_AUTO_STRUCTURE',
  DdCalcFees = 'DD_CALC_FEES',
  DdPaymentEstimate = 'DD_PAYMENT_ESTIMATE',
  DdPayoffTax = 'DD_PAYOFF_TAX',
  DdPrequalification = 'DD_PREQUALIFICATION',
  MktPaymentCalculator = 'MKT_PAYMENT_CALCULATOR',
  NoneGetTaxesAndFeesInput = 'NONE_GET_TAXES_AND_FEES_INPUT',
  NonePaymentEstimateLogic = 'NONE_PAYMENT_ESTIMATE_LOGIC',
}

export type TtJurisdiction = {
  __typename?: 'TTJurisdiction';
  allowPlateTransfer?: Maybe<Scalars['Boolean']['output']>;
  allowRegistrationTransfer?: Maybe<Scalars['Boolean']['output']>;
  hasRegistrationOnly?: Maybe<Scalars['Boolean']['output']>;
  hasTitleAndRegistration?: Maybe<Scalars['Boolean']['output']>;
  hasTitleOnly?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<TtttProductFieldsConnection>;
  registrationProduct?: Maybe<TtProductFields>;
  requireWalkIn?: Maybe<Scalars['Boolean']['output']>;
  supportedProcessors?: Maybe<Array<Maybe<Processor>>>;
  titleProduct?: Maybe<TtProductFields>;
};

export type TtJurisdictionProductsArgs = {
  filter?: InputMaybe<TtProductFilter>;
};

export enum TtNewBankEnum {
  Afcu = 'AFCU',
  AllyFinancial = 'AllyFinancial',
  BankOfAmerica = 'BankOfAmerica',
  CapitalOne = 'CapitalOne',
  Chase = 'Chase',
  FifthThird = 'FifthThird',
  FirstInterstate = 'FirstInterstate',
  Iccu = 'ICCU',
  LentegrityAutoFinance = 'LentegrityAutoFinance',
  Lookout = 'Lookout',
  Mechanics = 'Mechanics',
  MountainAmerica = 'MountainAmerica',
  Pnc = 'PNC',
  PrestigeFinancial = 'PrestigeFinancial',
  Santander = 'Santander',
  Td = 'TD',
  Upgrade = 'Upgrade',
  WellsFargo = 'WellsFargo',
  Westlake = 'Westlake',
  Wings = 'Wings',
}

export type TtPageInfo = {
  __typename?: 'TTPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type TtParameterFields = {
  __typename?: 'TTParameterFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TtProductFields = {
  __typename?: 'TTProductFields';
  documents?: Maybe<TtttDocumentFieldsConnection>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<TtProductType>;
  validations?: Maybe<Array<Maybe<TtValidation>>>;
};

export type TtProductFieldsDocumentsArgs = {
  conditions?: InputMaybe<TtConditionFilterInput>;
};

export type TtProductFilter = {
  types?: InputMaybe<Array<InputMaybe<TtProductType>>>;
};

export enum TtProductType {
  Registration = 'Registration',
  Title = 'Title',
}

export type TtttDocumentFieldsConnection = {
  __typename?: 'TTTTDocumentFieldsConnection';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<TtDocumentFields>>>;
  pageInfo?: Maybe<TtPageInfo>;
};

export type TtttProductFieldsConnection = {
  __typename?: 'TTTTProductFieldsConnection';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<TtProductFields>>>;
  pageInfo?: Maybe<TtPageInfo>;
};

export type TtValidation = {
  __typename?: 'TTValidation';
  description?: Maybe<Scalars['String']['output']>;
  internalDescription?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum TtVehicleType {
  ExtendedCabPickup = 'ExtendedCabPickup',
  Motorhome = 'Motorhome',
  MultipurposePassengerVehicle = 'MultipurposePassengerVehicle',
  PassengerCar = 'PassengerCar',
  Pickup = 'Pickup',
  Suv = 'SUV',
  Sedan = 'Sedan',
  Truck = 'Truck',
}

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  display_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_active: Scalars['Boolean']['output'];
  is_dashboard_visible: Scalars['Boolean']['output'];
  slug: TagSlugEnum;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum TagSlugEnum {
  ContractExpired = 'contract_expired',
  CreditFrozen = 'credit_frozen',
  DirectPay = 'direct_pay',
  DoubleTax = 'double_tax',
  DownPaymentCompleted = 'down_payment_completed',
  EmailPreferred = 'email_preferred',
  HeldAtBank = 'held_at_bank',
  NeedsCobuyer = 'needs_cobuyer',
  NeedsConsent = 'needs_consent',
  NeedsIncome = 'needs_income',
  NeedsMoneyDown = 'needs_money_down',
  NeedsRecontracting = 'needs_recontracting',
  PayoffFailed = 'payoff_failed',
  PayoffVerified = 'payoff_verified',
  PromoJuly2024 = 'promo_july2024',
  ProofOfIncome = 'proof_of_income',
  ProofOfResidency = 'proof_of_residency',
  ReleaseOfInterest = 'release_of_interest',
  Repo = 'repo',
  SentToBank = 'sent_to_bank',
  SpanishSpeaker = 'spanish_speaker',
  TextPreferred = 'text_preferred',
  TwoCarDriver = 'two_car_driver',
  VerifyingInfo = 'verifying_info',
  VscGapContracts = 'vsc_gap_contracts',
  WaitingForDownPayment = 'waiting_for_down_payment',
  WaitingForInterview = 'waiting_for_interview',
  WaitingForPayoff = 'waiting_for_payoff',
  WaitingForPics = 'waiting_for_pics',
  WalkIn = 'walk_in',
}

export type TagsInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TitleRegistrationOption {
  TitleAndNewRegistration = 'title_and_new_registration',
  TitleAndRegistrationTransfer = 'title_and_registration_transfer',
  TitleOnly = 'title_only',
}

export type UpdateCreditAppOnDealResponse = {
  __typename?: 'UpdateCreditAppOnDealResponse';
  cobuyer?: Maybe<Customer>;
  contact?: Maybe<DealContact>;
  customer: Customer;
  financial_info: FinancialInfo;
  id: Scalars['Int']['output'];
  odometer_status?: Maybe<OdometerStatus>;
  second_contact?: Maybe<DealContact>;
  state: Scalars['String']['output'];
};

export type UpdateUserInput = {
  auth0Roles: Array<InputMaybe<Auth0RoleInput>>;
  email: Scalars['String']['input'];
  huntGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  recruiterId?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreationData = {
  __typename?: 'UserCreationData';
  huntGroups?: Maybe<Array<HuntGroup>>;
  pods?: Maybe<Array<Pod>>;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  roles?: Maybe<Array<Maybe<Auth0Role>>>;
};

export type VehicleMileageGql = {
  __typename?: 'VehicleMileageGQL';
  estimatedAverageMiles?: Maybe<Scalars['Float']['output']>;
  eventCount?: Maybe<Scalars['Int']['output']>;
  lastOdometer?: Maybe<Scalars['Int']['output']>;
  lastOdometerDate?: Maybe<Scalars['String']['output']>;
  resultCode?: Maybe<Scalars['Int']['output']>;
  resultMessage?: Maybe<Scalars['String']['output']>;
  rollback?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export enum AccidentsEnumType {
  NoAccidents = 'no_accidents',
  OneAccident = 'one_accident',
  TwoOrMoreAccidents = 'two_or_more_accidents',
}

export type AcquisitionOffer = {
  __typename?: 'acquisitionOffer';
  appraisedValue?: Maybe<Scalars['Float']['output']>;
  estimatedFees?: Maybe<Scalars['Float']['output']>;
  offer?: Maybe<Scalars['Float']['output']>;
};

export enum ActorType {
  Applicant = 'Applicant',
  Buyer = 'Buyer',
  CoApplicant = 'CoApplicant',
  CoBuyer = 'CoBuyer',
  Customer = 'Customer',
  Dealer = 'Dealer',
  Guarantor = 'Guarantor',
  Sales = 'Sales',
}

export type AddDocumentsToMediaCenterInput = {
  key?: InputMaybe<Scalars['String']['input']>;
};

export type Address = {
  __typename?: 'address';
  address_line?: Maybe<Scalars['String']['output']>;
  address_line_2?: Maybe<Scalars['String']['output']>;
  address_type?: Maybe<AddressTypeEnum>;
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  monthly_payment?: Maybe<Scalars['Float']['output']>;
  months_at_home?: Maybe<Scalars['Int']['output']>;
  moved_states?: Maybe<Scalars['Boolean']['output']>;
  residence_type?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  time_zone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  years_at_home?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  address_type?: InputMaybe<AddressTypeEnum>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  months_at_home?: InputMaybe<Scalars['Int']['input']>;
  moved_states?: InputMaybe<Scalars['Boolean']['input']>;
  residence_type?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  years_at_home?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressTypeEnum {
  Buyer = 'Buyer',
  CoBuyer = 'CoBuyer',
  Current = 'Current',
  DriversLicense = 'DriversLicense',
  Previous = 'Previous',
}

export type Article = {
  __typename?: 'article';
  com_visible?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  thumbnail_key?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ArticleImageUploadUrl = {
  __typename?: 'articleImageUploadUrl';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ArticleInput = {
  com_visible?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  thumbnail_key?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentLog = {
  __typename?: 'assignmentLog';
  assigned_at?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type AssignmentLogInput = {
  assigned_at?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentLogs = {
  __typename?: 'assignmentLogs';
  logs?: Maybe<Array<Maybe<AssignmentLog>>>;
};

export type AssignmentLogsInput = {
  logs?: InputMaybe<Array<InputMaybe<AssignmentLogInput>>>;
};

export type Auction = {
  __typename?: 'auction';
  address_line?: Maybe<Scalars['String']['output']>;
  average_transport_cost?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AuctionDistances = {
  __typename?: 'auctionDistances';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  transport_cost?: Maybe<Scalars['Int']['output']>;
};

export type AuctionDistancesInput = {
  auctions?: InputMaybe<Array<InputMaybe<Destinations>>>;
  customerAddress?: InputMaybe<Scalars['String']['input']>;
  customerState?: InputMaybe<Scalars['String']['input']>;
};

export type AuctionInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  average_transport_cost?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum AutoImportVariation {
  DisplayCustomizePackage = 'displayCustomizePackage',
  ImmediateImport = 'immediateImport',
}

export type Bank = {
  __typename?: 'bank';
  active?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  auto_structure_buyout?: Maybe<Scalars['Boolean']['output']>;
  auto_structure_refi?: Maybe<Scalars['Boolean']['output']>;
  bank_markups?: Maybe<Array<Maybe<BankMarkup>>>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  r1_fsid?: Maybe<Scalars['String']['output']>;
  reserve_structure?: Maybe<Array<Maybe<ReserveStructure>>>;
  signing_solution?: Maybe<SigningSolutionEnum>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  use_in_states?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type BankInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  auto_structure_buyout?: InputMaybe<Scalars['Boolean']['input']>;
  auto_structure_refi?: InputMaybe<Scalars['Boolean']['input']>;
  bank_markups?: InputMaybe<Array<InputMaybe<BankMarkupInput>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  r1_fsid?: InputMaybe<Scalars['String']['input']>;
  reserve_structure?: InputMaybe<Array<InputMaybe<ReserveStructureInput>>>;
  signing_solution?: InputMaybe<SigningSolutionEnum>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_in_states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type BankMarkup = {
  __typename?: 'bankMarkup';
  bank_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  max_markup?: Maybe<Scalars['Float']['output']>;
  term_max?: Maybe<Scalars['Int']['output']>;
  term_min?: Maybe<Scalars['Int']['output']>;
};

export type BankMarkupInput = {
  bank_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  max_markup?: InputMaybe<Scalars['Float']['input']>;
  term_max?: InputMaybe<Scalars['Int']['input']>;
  term_min?: InputMaybe<Scalars['Int']['input']>;
};

export type BasePod = {
  __typename?: 'basePod';
  archived?: Maybe<Scalars['Boolean']['output']>;
  closer_commission_rate?: Maybe<Scalars['Float']['output']>;
  closer_commission_type?: Maybe<CommissionType>;
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  manager_commission_rate?: Maybe<Scalars['Float']['output']>;
  manager_commission_type?: Maybe<CommissionType>;
  name?: Maybe<Scalars['String']['output']>;
  parent_pod_id?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  problem_solver?: Maybe<Scalars['Boolean']['output']>;
  setter_commission_rate?: Maybe<Scalars['Float']['output']>;
  setter_commission_type?: Maybe<CommissionType>;
  special_commission_rate?: Maybe<Scalars['Float']['output']>;
  special_commission_type?: Maybe<CommissionType>;
  team_type?: Maybe<TeamType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  us_states_object?: Maybe<UsStatesObject>;
  vsc_markup?: Maybe<Scalars['Int']['output']>;
  vsc_multiplier?: Maybe<Scalars['Float']['output']>;
};

export type BasePodInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  closer_commission_type?: InputMaybe<CommissionType>;
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  manager_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  manager_commission_type?: InputMaybe<CommissionType>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_pod_id?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  problem_solver?: InputMaybe<Scalars['Boolean']['input']>;
  setter_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission_type?: InputMaybe<CommissionType>;
  special_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  special_commission_type?: InputMaybe<CommissionType>;
  team_type?: InputMaybe<TeamType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  us_states_object?: InputMaybe<UsStatesInputObject>;
  vsc_markup?: InputMaybe<Scalars['Int']['input']>;
  vsc_multiplier?: InputMaybe<Scalars['Float']['input']>;
};

export type BaseUser = {
  __typename?: 'baseUser';
  auto_assign_deals?: Maybe<Scalars['Boolean']['output']>;
  call_status?: Maybe<Scalars['String']['output']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  on_vacation?: Maybe<Scalars['Boolean']['output']>;
  overnight_deals?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  recruiter_id?: Maybe<Scalars['String']['output']>;
  twilio_number?: Maybe<Scalars['String']['output']>;
};

export type BaseUserInput = {
  auto_assign_deals?: InputMaybe<Scalars['Boolean']['input']>;
  call_status?: InputMaybe<Scalars['String']['input']>;
  can_claim_as_closer?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  on_vacation?: InputMaybe<Scalars['Boolean']['input']>;
  overnight_deals?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  recruiter_id?: InputMaybe<Scalars['String']['input']>;
  twilio_number?: InputMaybe<Scalars['String']['input']>;
};

export type BootReasonInput = {
  author_id?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type Call = {
  __typename?: 'call';
  callDuration?: Maybe<Scalars['String']['output']>;
  callStatus?: Maybe<Scalars['String']['output']>;
  calledCity?: Maybe<Scalars['String']['output']>;
  calledNumber?: Maybe<Scalars['String']['output']>;
  calledState?: Maybe<Scalars['String']['output']>;
  callerName?: Maybe<Scalars['String']['output']>;
  caller_city?: Maybe<Scalars['String']['output']>;
  caller_state?: Maybe<Scalars['String']['output']>;
  conferenceId?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  flow?: Maybe<Scalars['String']['output']>;
  fromNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isNewCustomer?: Maybe<Scalars['Boolean']['output']>;
  new_customer_call_outcome?: Maybe<Scalars['String']['output']>;
  new_customer_call_outcome_description?: Maybe<Scalars['String']['output']>;
  outcome?: Maybe<Scalars['String']['output']>;
  pod_id?: Maybe<Scalars['ID']['output']>;
  recordingSid?: Maybe<Scalars['String']['output']>;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  selection?: Maybe<Scalars['String']['output']>;
  selection_message?: Maybe<Scalars['String']['output']>;
  twilioNumber?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']['output']>;
};

export type Car = {
  __typename?: 'car';
  book_value?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jdp_adjusted_clean_retail?: Maybe<Scalars['Float']['output']>;
  jdp_adjusted_clean_trade?: Maybe<Scalars['Float']['output']>;
  jdp_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  jdp_trim_body?: Maybe<Scalars['String']['output']>;
  jdp_valuation_date?: Maybe<Scalars['Date']['output']>;
  jdp_vehicle_accessories_object?: Maybe<JdpVehicleAccessoriesObject>;
  kbb_lending_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_lending_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_selected_options_object?: Maybe<KbbSelectedOptionsObject>;
  kbb_trim_name?: Maybe<Scalars['String']['output']>;
  kbb_valuation_date?: Maybe<Scalars['String']['output']>;
  kbb_vehicle_name?: Maybe<Scalars['String']['output']>;
  license_plate_number?: Maybe<Scalars['String']['output']>;
  license_plate_state?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  mmr?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  odometer_status?: Maybe<OdometerStatus>;
  payoff?: Maybe<Payoff>;
  registration_expiration?: Maybe<Scalars['DateTime']['output']>;
  retail_book_value?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type CarInput = {
  book_value?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jdp_adjusted_clean_retail?: InputMaybe<Scalars['Float']['input']>;
  jdp_adjusted_clean_trade?: InputMaybe<Scalars['Float']['input']>;
  jdp_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  jdp_trim_body?: InputMaybe<Scalars['String']['input']>;
  jdp_valuation_date?: InputMaybe<Scalars['Date']['input']>;
  jdp_vehicle_accessories_object?: InputMaybe<JdpVehicleAccessoriesObjectInput>;
  kbb_lending_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_lending_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_selected_options_object?: InputMaybe<KbbSelectedOptionsObjectInput>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  kbb_valuation_date?: InputMaybe<Scalars['String']['input']>;
  kbb_vehicle_name?: InputMaybe<Scalars['String']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  mmr?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  odometer_status?: InputMaybe<OdometerStatus>;
  payoff?: InputMaybe<PayoffInput>;
  registration_expiration?: InputMaybe<Scalars['DateTime']['input']>;
  retail_book_value?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CarWarranty = {
  __typename?: 'carWarranty';
  full_miles?: Maybe<Scalars['Int']['output']>;
  full_status?: Maybe<Scalars['String']['output']>;
  full_years?: Maybe<Scalars['Int']['output']>;
  power_train_miles?: Maybe<Scalars['Int']['output']>;
  power_train_status?: Maybe<Scalars['String']['output']>;
  power_train_years?: Maybe<Scalars['Int']['output']>;
};

export enum CardType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  HsaFsa = 'HSA_FSA',
  NonReloadablePrepaid = 'NON_RELOADABLE_PREPAID',
  ReloadablePrepaid = 'RELOADABLE_PREPAID',
  Unknown = 'UNKNOWN',
}

export enum CommissionType {
  Flat = 'flat',
  Percentage = 'percentage',
}

export type ConditionRejection = {
  __typename?: 'conditionRejection';
  conditionRejectionText?: Maybe<Scalars['String']['output']>;
};

export type ConditionReport = {
  __typename?: 'conditionReport';
  accidents?: Maybe<AccidentsEnumType>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  exterior?: Maybe<Array<Maybe<ExteriorEnumType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  interior?: Maybe<Array<Maybe<InteriorEnumType>>>;
  lights_on_dash?: Maybe<Scalars['Boolean']['output']>;
  overall_condition?: Maybe<OverallConditionEnumType>;
  score?: Maybe<Scalars['Float']['output']>;
  smoked_in?: Maybe<Scalars['Boolean']['output']>;
  tires?: Maybe<TiresEnumType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ConditionReportInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  score?: InputMaybe<Scalars['Float']['input']>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConditionReportUpsert = {
  __typename?: 'conditionReportUpsert';
  accidents?: Maybe<AccidentsEnumType>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  exterior?: Maybe<Array<Maybe<ExteriorEnumType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  interior?: Maybe<Array<Maybe<InteriorEnumType>>>;
  lights_on_dash?: Maybe<Scalars['Boolean']['output']>;
  overall_condition?: Maybe<OverallConditionEnumType>;
  smoked_in?: Maybe<Scalars['Boolean']['output']>;
  tires?: Maybe<TiresEnumType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ConditionReportUpsertInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type Conference = {
  __typename?: 'conference';
  agent_number?: Maybe<Scalars['String']['output']>;
  agent_user_id?: Maybe<Scalars['String']['output']>;
  conference_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['Int']['output']>;
  deal_state?: Maybe<Scalars['String']['output']>;
  deal_type?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  hunt_group_slug?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_outbound?: Maybe<Scalars['Boolean']['output']>;
  is_thunder?: Maybe<Scalars['Boolean']['output']>;
  next_participant?: Maybe<Scalars['String']['output']>;
  participants_count?: Maybe<Scalars['Int']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  transferred_from?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Consent = {
  __typename?: 'consent';
  affiliate_id?: Maybe<Scalars['Int']['output']>;
  consent_agreement_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ConsentNameEnumType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type ConsentAgreement = {
  __typename?: 'consentAgreement';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_active?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ConsentAgreementTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export enum ConsentAgreementTypeEnum {
  AchPayment = 'ach_payment',
  HardPull = 'hard_pull',
  SoftPull = 'soft_pull',
  TermsAndConditions = 'terms_and_conditions',
}

export enum ConsentNameEnumType {
  AchPayment = 'ach_payment',
  CreditScoreHardPull = 'credit_score_hard_pull',
  CreditScoreSoftPull = 'credit_score_soft_pull',
  HistoricalCreditScoreHardPull = 'historical_credit_score_hard_pull',
  TermsAndConditions = 'terms_and_conditions',
}

export enum ContactType {
  Email = 'email',
  Text = 'text',
}

export type ContractTerm = {
  __typename?: 'contractTerm';
  financedTermQuantity?: Maybe<Scalars['Int']['output']>;
  totalDownPaymentAmount?: Maybe<Scalars['Float']['output']>;
};

export type Contracts = {
  __typename?: 'contracts';
  files?: Maybe<Array<Maybe<Files>>>;
};

export type ContractsInput = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateNewEstimateFromFiles = {
  __typename?: 'createNewEstimateFromFiles';
  deal?: Maybe<Deal>;
  uploadUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CreditApplication = {
  __typename?: 'creditApplication';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_application_data?: Maybe<CreditApplicationData>;
  credit_decisions?: Maybe<Array<Maybe<CreditDecision>>>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  r1_conversation_id?: Maybe<Scalars['String']['output']>;
  r1_credit_app_guid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type CreditApplicationAddress = {
  __typename?: 'creditApplicationAddress';
  address_line?: Maybe<Scalars['String']['output']>;
  address_line_2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  monthly_payment?: Maybe<Scalars['Float']['output']>;
  months_at_home?: Maybe<Scalars['Int']['output']>;
  residence_type?: Maybe<ResidenceEnumType>;
  state?: Maybe<Scalars['String']['output']>;
  years_at_home?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type CreditApplicationAddressInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  months_at_home?: InputMaybe<Scalars['Int']['input']>;
  residence_type?: InputMaybe<ResidenceEnumType>;
  state?: InputMaybe<Scalars['String']['input']>;
  years_at_home?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreditApplicationBuyer = {
  __typename?: 'creditApplicationBuyer';
  address?: Maybe<CreditApplicationAddress>;
  dob?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employment?: Maybe<CreditApplicationEmployment>;
  first_name?: Maybe<Scalars['String']['output']>;
  home_phone_number?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  marital_status?: Maybe<MaritalStatusEnum>;
  middle_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  prev_address?: Maybe<CreditApplicationAddress>;
  prev_employment?: Maybe<CreditApplicationEmployment>;
  ssn?: Maybe<Scalars['String']['output']>;
};

export type CreditApplicationBuyerInput = {
  address?: InputMaybe<CreditApplicationAddressInput>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<CreditApplicationEmploymentInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  home_phone_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<MaritalStatusEnum>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prev_address?: InputMaybe<CreditApplicationAddressInput>;
  prev_employment?: InputMaybe<CreditApplicationEmploymentInput>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type CreditApplicationCar = {
  __typename?: 'creditApplicationCar';
  book_value?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  kbb_trim_name?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  retail_book_value?: Maybe<Scalars['Float']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type CreditApplicationCarInput = {
  book_value?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  retail_book_value?: InputMaybe<Scalars['Float']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CreditApplicationCreateInput = {
  credit_application_data: CreditApplicationDataInput;
  deal_id: Scalars['ID']['input'];
};

export type CreditApplicationData = {
  __typename?: 'creditApplicationData';
  car?: Maybe<CreditApplicationCar>;
  cobuyer?: Maybe<CreditApplicationBuyer>;
  customer?: Maybe<CreditApplicationBuyer>;
  finance?: Maybe<CreditApplicationFinance>;
  relationshipToBuyer?: Maybe<RelationToBuyerEnum>;
};

export type CreditApplicationDataInput = {
  car?: InputMaybe<CreditApplicationCarInput>;
  cobuyer?: InputMaybe<CreditApplicationBuyerInput>;
  customer?: InputMaybe<CreditApplicationBuyerInput>;
  finance?: InputMaybe<CreditApplicationFinanceInput>;
  relationshipToBuyer?: InputMaybe<RelationToBuyerEnum>;
};

export type CreditApplicationEmployment = {
  __typename?: 'creditApplicationEmployment';
  gross_income?: Maybe<Scalars['Float']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  months_at_job?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pay_frequency?: Maybe<IncomeIntervalEnumType>;
  phone_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmploymentStatusType>;
  years_at_job?: Maybe<Scalars['Int']['output']>;
};

export type CreditApplicationEmploymentInput = {
  gross_income?: InputMaybe<Scalars['Float']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  months_at_job?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pay_frequency?: InputMaybe<IncomeIntervalEnumType>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmploymentStatusType>;
  years_at_job?: InputMaybe<Scalars['Int']['input']>;
};

export type CreditApplicationFinance = {
  __typename?: 'creditApplicationFinance';
  amount_financed?: Maybe<Scalars['Float']['output']>;
  base_tax_amount?: Maybe<Scalars['Float']['output']>;
  buyer_not_lessee?: Maybe<Scalars['Boolean']['output']>;
  days_to_payment?: Maybe<Scalars['Int']['output']>;
  doc_fee?: Maybe<Scalars['Float']['output']>;
  gap_price?: Maybe<Scalars['Float']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  new_registration_fee?: Maybe<Scalars['Float']['output']>;
  payment?: Maybe<Scalars['Float']['output']>;
  payoff?: Maybe<Scalars['Float']['output']>;
  registration_transfer_fee?: Maybe<Scalars['Float']['output']>;
  sell_rate?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  title_fee?: Maybe<Scalars['Float']['output']>;
  vsc_price?: Maybe<Scalars['Float']['output']>;
  vsc_term?: Maybe<Scalars['String']['output']>;
  warranty_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type CreditApplicationFinanceInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type CreditApplicationUpdateInput = {
  credit_application_data: CreditApplicationDataInput;
  id: Scalars['ID']['input'];
};

export type CreditDecision = {
  __typename?: 'creditDecision';
  annual_percentage_rate?: Maybe<Scalars['Float']['output']>;
  application_status?: Maybe<CreditDecisionStatusEnum>;
  bank?: Maybe<Bank>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  max_markup?: Maybe<Scalars['Float']['output']>;
  r1_application_number?: Maybe<Scalars['String']['output']>;
  r1_conversation_id?: Maybe<Scalars['String']['output']>;
  r1_credit_decision_data?: Maybe<R1CreditDecisionData>;
  r1_fsid?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  tier?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum CreditDecisionStatusEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  I = 'I',
  N = 'N',
  O = 'O',
  P = 'P',
  R = 'R',
  S = 'S',
}

export enum CreditScore {
  BelowAverage = 'BelowAverage',
  Fair = 'Fair',
  Good = 'Good',
  Great = 'Great',
}

export enum CreditScoreTierEnumType {
  Fair = 'fair',
  Good = 'good',
  NoResponse = 'no_response',
  Poor = 'poor',
  VeryGood = 'very_good',
}

export type Customer = {
  __typename?: 'customer';
  address?: Maybe<Address>;
  auth0_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dashboard_visited?: Maybe<Scalars['Boolean']['output']>;
  dl_expiration_date?: Maybe<Scalars['DateTime']['output']>;
  dob?: Maybe<Scalars['Date']['output']>;
  drivers_license_address?: Maybe<Address>;
  email?: Maybe<Scalars['String']['output']>;
  employment?: Maybe<Employment>;
  finished_signing?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  has_same_address_as_cobuyer?: Maybe<Scalars['Boolean']['output']>;
  home_phone_number?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  last_authenticated_route?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  marital_status?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  name_suffix?: Maybe<Scalars['String']['output']>;
  no_email?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  prequalification?: Maybe<Prequalification>;
  prev_address?: Maybe<Address>;
  prev_employment?: Maybe<Employment>;
  proof_of_insurance?: Maybe<ProofOfInsurance>;
  relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  sent_account_email?: Maybe<Scalars['Boolean']['output']>;
  sent_review_email?: Maybe<Scalars['Boolean']['output']>;
  signature_data?: Maybe<SignatureData>;
  ssn?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerInput = {
  address?: InputMaybe<AddressInput>;
  auth0_id?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dashboard_visited?: InputMaybe<Scalars['Boolean']['input']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']['input']>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  drivers_license_address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<EmploymentInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  home_phone_number?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_authenticated_route?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  name_suffix?: InputMaybe<Scalars['String']['input']>;
  no_email?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prev_address?: InputMaybe<AddressInput>;
  prev_employment?: InputMaybe<EmploymentInput>;
  proof_of_insurance?: InputMaybe<ProofOfInsuranceInput>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  sent_account_email?: InputMaybe<Scalars['Boolean']['input']>;
  sent_review_email?: InputMaybe<Scalars['Boolean']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Deal = {
  __typename?: 'deal';
  auto_import_variation?: Maybe<AutoImportVariation>;
  boot_reason?: Maybe<Scalars['JSONObject']['output']>;
  car?: Maybe<Car>;
  closer?: Maybe<User>;
  closer2?: Maybe<User>;
  closer2_id?: Maybe<Scalars['ID']['output']>;
  closer_id?: Maybe<Scalars['ID']['output']>;
  cobuyer?: Maybe<Customer>;
  completion_date_tz?: Maybe<Scalars['String']['output']>;
  completion_date_utc?: Maybe<Scalars['DateTime']['output']>;
  condition_report?: Maybe<ConditionReport>;
  consents?: Maybe<Array<Maybe<Consent>>>;
  contact?: Maybe<DealContact>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creation_date_tz?: Maybe<Scalars['String']['output']>;
  creation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  credit_applications?: Maybe<Array<Maybe<CreditApplication>>>;
  customer?: Maybe<Customer>;
  deal_assignment_log?: Maybe<DealAssignmentLog>;
  deal_dates?: Maybe<DealDates>;
  deal_states?: Maybe<Array<Maybe<DealState>>>;
  default_sort_date?: Maybe<Scalars['DateTime']['output']>;
  direct_pay_state?: Maybe<Scalars['Boolean']['output']>;
  document_info?: Maybe<DocumentInfo>;
  document_progress_status?: Maybe<Scalars['String']['output']>;
  extracted_document_data?: Maybe<ExtractedDocumentData>;
  finalized_date?: Maybe<Scalars['DateTime']['output']>;
  financial_info?: Maybe<FinancialInfo>;
  financial_info_acquisition?: Maybe<FinancialInfoAcquisition>;
  follow_up?: Maybe<FollowUp>;
  force_title_only_helper_link?: Maybe<ForceTitleOnlyHelperLink>;
  funding_clerk?: Maybe<User>;
  funding_clerk_id?: Maybe<Scalars['ID']['output']>;
  hasRecentCommunication?: Maybe<Scalars['Boolean']['output']>;
  has_problem?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  import_type?: Maybe<ImportType>;
  imported_date_utc?: Maybe<Scalars['DateTime']['output']>;
  isCobuyer?: Maybe<Scalars['Boolean']['output']>;
  lane_arrival_utc?: Maybe<Scalars['DateTime']['output']>;
  last_outgoing_communication_date_utc?: Maybe<Scalars['DateTime']['output']>;
  lease_id?: Maybe<Scalars['GUID']['output']>;
  marketing_source?: Maybe<Scalars['String']['output']>;
  missing_required_external_documents?: Maybe<Scalars['Boolean']['output']>;
  needs_electronic_signature_verification?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  opened_by_financial_specialist?: Maybe<Scalars['DateTime']['output']>;
  paperwork_type?: Maybe<PaperworkType>;
  pod?: Maybe<Pod>;
  pod_id?: Maybe<Scalars['ID']['output']>;
  product?: Maybe<Product>;
  r1_contract_generation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_manually_validated?: Maybe<Scalars['Boolean']['output']>;
  r1_contract_validation_errors_object?: Maybe<R1ContractValidationErrors>;
  r1_contract_validation_started_at?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_validation_warnings_object?: Maybe<R1ContractValidationWarnings>;
  r1_contract_worksheet_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_jacket_id?: Maybe<Scalars['String']['output']>;
  r1_jacket_id_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  referral_source?: Maybe<ReferralSource>;
  request_boot?: Maybe<Scalars['Boolean']['output']>;
  sales_visibility?: Maybe<Scalars['Boolean']['output']>;
  second_contact?: Maybe<DealContact>;
  set_date?: Maybe<Scalars['DateTime']['output']>;
  setter?: Maybe<User>;
  setter_id?: Maybe<Scalars['ID']['output']>;
  signing_on_com?: Maybe<Scalars['Boolean']['output']>;
  sold_date?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  structuring_follow_up?: Maybe<StructuringFollowUp>;
  structuring_manager?: Maybe<User>;
  structuring_manager_id?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Tag>>;
  title_clerk?: Maybe<User>;
  title_clerk2?: Maybe<User>;
  title_clerk2_id?: Maybe<Scalars['ID']['output']>;
  title_clerk_id?: Maybe<Scalars['ID']['output']>;
  titling_pod?: Maybe<Pod>;
  titling_pod_id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<DealType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealAssignmentLog = {
  __typename?: 'dealAssignmentLog';
  assignment_logs_object?: Maybe<AssignmentLogs>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealAssignmentLogInput = {
  assignment_logs_object?: InputMaybe<AssignmentLogsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealContact = {
  __typename?: 'dealContact';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealContactInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealDates = {
  __typename?: 'dealDates';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  custom_dates?: Maybe<Scalars['JSON']['output']>;
  dates?: Maybe<Scalars['JSON']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealDatesInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_dates?: InputMaybe<Scalars['JSON']['input']>;
  dates?: InputMaybe<Scalars['JSON']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealInput = {
  auto_import_variation?: InputMaybe<AutoImportVariation>;
  boot_reason?: InputMaybe<Scalars['JSONObject']['input']>;
  car?: InputMaybe<CarInput>;
  closer2_id?: InputMaybe<Scalars['ID']['input']>;
  closer_id?: InputMaybe<Scalars['ID']['input']>;
  cobuyer?: InputMaybe<CustomerInput>;
  completion_date_tz?: InputMaybe<Scalars['String']['input']>;
  completion_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  condition_report?: InputMaybe<ConditionReportInput>;
  contact?: InputMaybe<DealContactInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_date_tz?: InputMaybe<Scalars['String']['input']>;
  creation_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerInput>;
  deal_assignment_log?: InputMaybe<DealAssignmentLogInput>;
  deal_dates?: InputMaybe<DealDatesInput>;
  deal_states?: InputMaybe<Array<InputMaybe<DealStateInput>>>;
  direct_pay_state?: InputMaybe<Scalars['Boolean']['input']>;
  document_info?: InputMaybe<DocumentInfoInput>;
  document_progress_status?: InputMaybe<Scalars['String']['input']>;
  financial_info?: InputMaybe<FinancialInfoInput>;
  financial_info_acquisition?: InputMaybe<FinancialInfoAcquisitionInput>;
  follow_up?: InputMaybe<FollowUpInput>;
  funding_clerk_id?: InputMaybe<Scalars['ID']['input']>;
  has_problem?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  import_type?: InputMaybe<ImportType>;
  imported_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  lease_id?: InputMaybe<Scalars['GUID']['input']>;
  marketing_source?: InputMaybe<Scalars['String']['input']>;
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']['input']>;
  needs_electronic_signature_verification?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
  opened_by_financial_specialist?: InputMaybe<Scalars['DateTime']['input']>;
  paperwork_type?: InputMaybe<PaperworkType>;
  pod?: InputMaybe<PodInput>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  r1_contract_generation_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  r1_contract_manually_validated?: InputMaybe<Scalars['Boolean']['input']>;
  r1_contract_validation_errors_object?: InputMaybe<R1ContractValidationErrorsInput>;
  r1_contract_validation_started_at?: InputMaybe<Scalars['DateTime']['input']>;
  r1_contract_validation_warnings_object?: InputMaybe<R1ContractValidationWarningsInput>;
  r1_contract_worksheet_created_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  r1_jacket_id?: InputMaybe<Scalars['String']['input']>;
  r1_jacket_id_created_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  referral_source?: InputMaybe<ReferralSourceInput>;
  request_boot?: InputMaybe<Scalars['Boolean']['input']>;
  sales_visibility?: InputMaybe<Scalars['Boolean']['input']>;
  second_contact?: InputMaybe<DealContactInput>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  setter_id?: InputMaybe<Scalars['ID']['input']>;
  signing_on_com?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  structuring_follow_up?: InputMaybe<StructuringFollowUpInput>;
  structuring_manager_id?: InputMaybe<Scalars['ID']['input']>;
  title_clerk2_id?: InputMaybe<Scalars['ID']['input']>;
  title_clerk_id?: InputMaybe<Scalars['ID']['input']>;
  titling_pod?: InputMaybe<PodInput>;
  titling_pod_id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DealType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealMedia = {
  __typename?: 'dealMedia';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  has_verified_digital_signature?: Maybe<Scalars['Boolean']['output']>;
  has_wet_signature?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_notarized?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  signed_url?: Maybe<Scalars['String']['output']>;
  source?: Maybe<DealMediaSourceEnum>;
  state?: Maybe<DealMediaStateEnum>;
  type?: Maybe<DealMediaTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uploaded_by_customer?: Maybe<Scalars['Boolean']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
  wet_signature_required?: Maybe<Scalars['Boolean']['output']>;
};

export type DealMediaInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  has_verified_digital_signature?: InputMaybe<Scalars['Boolean']['input']>;
  has_wet_signature?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_notarized?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  signed_url?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<DealMediaSourceEnum>;
  state?: InputMaybe<DealMediaStateEnum>;
  type?: InputMaybe<DealMediaTypeEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  uploaded_by_customer?: InputMaybe<Scalars['Boolean']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  wet_signature_required?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DealMediaTypeEnum {
  AcuraSpecificOdometerDoc = 'acura_specific_odometer_doc',
  AgreementToFurnishInsurance = 'agreement_to_furnish_insurance',
  BackOfCoBuyersInsurance = 'back_of_co_buyers_insurance',
  BackOfCoBuyersLicense = 'back_of_co_buyers_license',
  BackOfDriversLicense = 'back_of_drivers_license',
  BackOfInsuranceCard = 'back_of_insurance_card',
  BookSheet = 'book_sheet',
  BuyersGuide = 'buyers_guide',
  CaliforniaStatementOfFacts = 'california_statement_of_facts',
  CaliforniaStatementOfFactsCobuyer = 'california_statement_of_facts_cobuyer',
  CobuyerRegistrationSpecificPowerOfAttorney = 'cobuyer_registration_specific_power_of_attorney',
  CobuyerSecurePowerOfAttorney = 'cobuyer_secure_power_of_attorney',
  CobuyerStateSpecificOdoDoc = 'cobuyer_state_specific_odo_doc',
  ColoradoCobuyerSupplementalIdentificationClause = 'colorado_cobuyer_supplemental_identification_clause',
  ColoradoJointTenancy = 'colorado_joint_tenancy',
  ColoradoSupplementalIdentificationClause = 'colorado_supplemental_identification_clause',
  ConnecticutElectronicRegistrationAndTitleAgreement = 'connecticut_electronic_registration_and_title_agreement',
  Contract = 'contract',
  CreditApplication = 'credit_application',
  CreditApproval = 'credit_approval',
  DamageDisclosure = 'damage_disclosure',
  DemandLetter = 'demand_letter',
  DownPayment = 'down_payment',
  ESignConsent = 'e_sign_consent',
  ElectronicSignatureAttestation = 'electronic_signature_attestation',
  ElectronicSignatureCertification = 'electronic_signature_certification',
  ElectronicSignatureVerification = 'electronic_signature_verification',
  Emissions = 'emissions',
  FrontOfCoBuyersInsurance = 'front_of_co_buyers_insurance',
  FrontOfCoBuyersLicense = 'front_of_co_buyers_license',
  FrontOfDriversLicense = 'front_of_drivers_license',
  FrontOfInsuranceCard = 'front_of_insurance_card',
  GmLeasePacket = 'gm_lease_packet',
  HondaSpecificOdometerDoc = 'honda_specific_odometer_doc',
  IdahoSalesTaxExemption = 'idaho_sales_tax_exemption',
  LeaseAgreement = 'lease_agreement',
  LeaseEndAcqContract = 'lease_end_acq_contract',
  LeaseEndCashback = 'lease_end_cashback',
  LeaseendOdoDoc = 'leaseend_odo_doc',
  LeaseendOdoDocCobuyer = 'leaseend_odo_doc_cobuyer',
  LeaseendOdoDocJoint = 'leaseend_odo_doc_joint',
  LicensePlate = 'license_plate',
  MembershipForm = 'membership_form',
  OdoDoc = 'odo_doc',
  OdometerPicture = 'odometer_picture',
  Other = 'other',
  PayoffAuthorization = 'payoff_authorization',
  PayoffDocs = 'payoff_docs',
  PhysicalSignatureCoverLetter = 'physical_signature_cover_letter',
  PicturesOfVehicle = 'pictures_of_vehicle',
  PlateTransferLetter = 'plate_transfer_letter',
  PorscheSpecificOdometerDoc = 'porsche_specific_odometer_doc',
  PorscheSpecificPayoffInstructions = 'porsche_specific_payoff_instructions',
  PowerOfAttorney = 'power_of_attorney',
  PowerOfAttorneyCobuyer = 'power_of_attorney_cobuyer',
  PrivacyPolicy = 'privacy_policy',
  ProofOfIncome = 'proof_of_income',
  ProofOfResidence = 'proof_of_residence',
  ProofOfResidence2 = 'proof_of_residence2',
  PropertyTaxReceipt = 'property_tax_receipt',
  PurchaseOrder = 'purchase_order',
  R1EContract = 'r1_e_contract',
  RateExceptionForm = 'rate_exception_form',
  ReassignmentDisclosure = 'reassignment_disclosure',
  Registration = 'registration',
  RegistrationComplete = 'registration_complete',
  RegistrationSpecificPowerOfAttorney = 'registration_specific_power_of_attorney',
  SafetyInspection = 'safety_inspection',
  SalesTaxExemption = 'sales_tax_exemption',
  SecurePowerOfAttorney = 'secure_power_of_attorney',
  SignedDocs = 'signed_docs',
  StateAndLienholderSpecificPowerOfAttorney = 'state_and_lienholder_specific_power_of_attorney',
  StateSpecificDamageDisclosure = 'state_specific_damage_disclosure',
  StateSpecificDocs = 'state_specific_docs',
  StateSpecificOdoDoc = 'state_specific_odo_doc',
  StateSpecificPowerOfAttorney = 'state_specific_power_of_attorney',
  StateSpecificPowerOfAttorneyCobuyer = 'state_specific_power_of_attorney_cobuyer',
  StateSpecificPurchaseOrder = 'state_specific_purchase_order',
  TaterDocs = 'tater_docs',
  TaxExemption = 'tax_exemption',
  TempTag = 'temp_tag',
  TexasHidalgoDoc = 'texas_hidalgo_doc',
  Title = 'title',
  TitleApplication = 'title_application',
  TitlePacket = 'title_packet',
  TitleRelease = 'title_release',
  VinInspection = 'vin_inspection',
  VscAndGap = 'vsc_and_gap',
  WetSignature = 'wet_signature',
}

export enum DealReadyType {
  Conditioned = 'CONDITIONED',
  Failed = 'FAILED',
  ImmediateImport = 'IMMEDIATE_IMPORT',
  Loading = 'LOADING',
  Ready = 'READY',
}

export type DealState = {
  __typename?: 'dealState';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_date_utc?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']['output']>;
};

export enum DealStateEnum {
  Auction = 'AUCTION',
  Booted = 'BOOTED',
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Estimate = 'ESTIMATE',
  Finalized = 'FINALIZED',
  Floor = 'FLOOR',
  Funded = 'FUNDED',
  PaidOff = 'PAID_OFF',
  ReadyForPickup = 'READY_FOR_PICKUP',
  SendPayoff = 'SEND_PAYOFF',
  SentForSign = 'SENT_FOR_SIGN',
  SentToProcessor = 'SENT_TO_PROCESSOR',
  Signed = 'SIGNED',
  SoftClose = 'SOFT_CLOSE',
  Sold = 'SOLD',
  Structuring = 'STRUCTURING',
  StructuringProgress = 'STRUCTURING_PROGRESS',
  TitleReceived = 'TITLE_RECEIVED',
  TitleSent = 'TITLE_SENT',
  WaitingForTitle = 'WAITING_FOR_TITLE',
}

export type DealStateInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserInput>;
  user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type DealerInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  dealership_name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  observes_daylight_saving?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Deals = {
  __typename?: 'deals';
  auto_import_variation?: Maybe<AutoImportVariation>;
  boot_reason?: Maybe<Scalars['JSONObject']['output']>;
  car?: Maybe<Car>;
  closer2_id?: Maybe<Scalars['ID']['output']>;
  closer_id?: Maybe<Scalars['ID']['output']>;
  cobuyer?: Maybe<Customer>;
  completion_date_tz?: Maybe<Scalars['String']['output']>;
  completion_date_utc?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creation_date_tz?: Maybe<Scalars['String']['output']>;
  creation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  customer?: Maybe<Customer>;
  document_progress_status?: Maybe<Scalars['String']['output']>;
  finalized_date?: Maybe<Scalars['DateTime']['output']>;
  funding_clerk_id?: Maybe<Scalars['ID']['output']>;
  hasRecentCommunication?: Maybe<Scalars['Boolean']['output']>;
  has_problem?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  import_type?: Maybe<ImportType>;
  imported_date_utc?: Maybe<Scalars['DateTime']['output']>;
  isCobuyer?: Maybe<Scalars['Boolean']['output']>;
  lease_id?: Maybe<Scalars['GUID']['output']>;
  marketing_source?: Maybe<Scalars['String']['output']>;
  missing_required_external_documents?: Maybe<Scalars['Boolean']['output']>;
  needs_electronic_signature_verification?: Maybe<Scalars['Boolean']['output']>;
  opened_by_financial_specialist?: Maybe<Scalars['DateTime']['output']>;
  paperwork_type?: Maybe<PaperworkType>;
  pod_id?: Maybe<Scalars['ID']['output']>;
  r1_contract_generation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_manually_validated?: Maybe<Scalars['Boolean']['output']>;
  r1_contract_validation_errors_object?: Maybe<R1ContractValidationErrors>;
  r1_contract_validation_started_at?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_validation_warnings_object?: Maybe<R1ContractValidationWarnings>;
  r1_contract_worksheet_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_jacket_id?: Maybe<Scalars['String']['output']>;
  r1_jacket_id_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  request_boot?: Maybe<Scalars['Boolean']['output']>;
  sales_visibility?: Maybe<Scalars['Boolean']['output']>;
  set_date?: Maybe<Scalars['DateTime']['output']>;
  setter_id?: Maybe<Scalars['ID']['output']>;
  signing_on_com?: Maybe<Scalars['Boolean']['output']>;
  sold_date?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  structuring_manager_id?: Maybe<Scalars['ID']['output']>;
  title_clerk2_id?: Maybe<Scalars['ID']['output']>;
  title_clerk_id?: Maybe<Scalars['ID']['output']>;
  titling_pod_id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<DealType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealsWithNotification = {
  __typename?: 'dealsWithNotification';
  bootedDealsNotificationsMessages?: Maybe<Scalars['Int']['output']>;
  bootedDealsNotificationsNotMessages?: Maybe<Scalars['Int']['output']>;
  completedDealsNotificationsMessages?: Maybe<Scalars['Int']['output']>;
  completedDealsNotificationsNotMessages?: Maybe<Scalars['Int']['output']>;
  followUpDealsNotificationsMessages?: Maybe<Scalars['Int']['output']>;
  followUpDealsNotificationsNotMessages?: Maybe<Scalars['Int']['output']>;
};

export type Decision = {
  __typename?: 'decision';
  conditionRejection?: Maybe<Array<Maybe<ConditionRejection>>>;
  decisionComments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  financing?: Maybe<Financing>;
  stipulations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Deductible = {
  __typename?: 'deductible';
  amount?: Maybe<Scalars['String']['output']>;
  dealer_cost?: Maybe<Scalars['String']['output']>;
  deductible_type?: Maybe<Scalars['String']['output']>;
  f_i_markup?: Maybe<Scalars['String']['output']>;
  max_retail_price?: Maybe<Scalars['String']['output']>;
  min_retail_price?: Maybe<Scalars['String']['output']>;
  rate_id?: Maybe<Scalars['Int']['output']>;
  reduced_amount?: Maybe<Scalars['String']['output']>;
  retail_price?: Maybe<Scalars['String']['output']>;
};

export type Destinations = {
  address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DetermineApproval = {
  __typename?: 'determineApproval';
  approved?: Maybe<Scalars['Boolean']['output']>;
  failedCriteria?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DetermineApprovalInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  book_value?: InputMaybe<Scalars['Float']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  kbb_trim?: InputMaybe<Scalars['String']['input']>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  temporary_info_id?: InputMaybe<Scalars['String']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type DetermineApprovalQueryInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  book_value?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  financial_info_acquisition_id?: InputMaybe<Scalars['ID']['input']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  kbb_trim?: InputMaybe<Scalars['String']['input']>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  mileage?: InputMaybe<Scalars['Float']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentInfo = {
  __typename?: 'documentInfo';
  adobe_agreement_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentInfoInput = {
  adobe_agreement_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Documents = {
  __typename?: 'documents';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  files?: Maybe<Array<Maybe<Files>>>;
};

export type DocumentsInfoUpdateInput = {
  hasCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  movedStates?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vehicleYear?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentsInput = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  internal_media?: InputMaybe<Array<InputMaybe<DealMediaTypeEnum>>>;
};

export enum DownPaymentStatus {
  Completed = 'COMPLETED',
  ManuallyCompleted = 'MANUALLY_COMPLETED',
  Na = 'NA',
  Requested = 'REQUESTED',
  Sent = 'SENT',
}

export type Employment = {
  __typename?: 'employment';
  customer_id?: Maybe<Scalars['ID']['output']>;
  employment_type?: Maybe<EmploymentTypeType>;
  gross_income?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  months_at_job?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pay_frequency?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmploymentStatusType>;
  years_at_job?: Maybe<Scalars['Int']['output']>;
};

export enum EmploymentEnumType {
  FullTime = 'full_time',
  PartTime = 'part_time',
}

export type EmploymentInput = {
  customer_id?: InputMaybe<Scalars['ID']['input']>;
  employment_type?: InputMaybe<EmploymentTypeType>;
  gross_income?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  months_at_job?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pay_frequency?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmploymentStatusType>;
  years_at_job?: InputMaybe<Scalars['Int']['input']>;
};

export enum EmploymentStatusType {
  Employed = 'employed',
  NotEmployed = 'not_employed',
  Retired = 'retired',
}

export enum EmploymentTypeType {
  Current = 'current',
  Previous = 'previous',
}

export enum EndSalesFlowReasonEnum {
  ConditionedFailedDealProcessing = 'CONDITIONED_FAILED_DEAL_PROCESSING',
  DocumentsFinishedFlow = 'DOCUMENTS_FINISHED_FLOW',
  FailedDealProcessing = 'FAILED_DEAL_PROCESSING',
  FinishedFlow = 'FINISHED_FLOW',
  ImmediateImport = 'IMMEDIATE_IMPORT',
  NoKbbValuesFailure = 'NO_KBB_VALUES_FAILURE',
  OneDayInactivity = 'ONE_DAY_INACTIVITY',
  TimedOut = 'TIMED_OUT',
}

export enum ErrorCodeEnum {
  CardPaymentLimitExceeded = 'CARD_PAYMENT_LIMIT_EXCEEDED',
  CardTypeMismatch = 'CARD_TYPE_MISMATCH',
  Forbidden = 'FORBIDDEN',
  ResetGuidError = 'RESET_GUID_ERROR',
  TempInfoNotFound = 'TEMP_INFO_NOT_FOUND',
  Unauthenticated = 'UNAUTHENTICATED',
}

export enum ExteriorEnumType {
  DentsOrDings = 'dents_or_dings',
  GlassWindshieldDamage = 'glass_windshield_damage',
  HailDamage = 'hail_damage',
  MinorScuffs = 'minor_scuffs',
  PaintDamage = 'paint_damage',
  Rusting = 'rusting',
}

export type ExternalDealUploadUrl = {
  __typename?: 'externalDealUploadUrl';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ExternalTempInfoUploadUrl = {
  __typename?: 'externalTempInfoUploadUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type ExtraTempInfoInputType = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prequal_id?: InputMaybe<Scalars['ID']['input']>;
  shopping_cart?: InputMaybe<TemporaryShoppingCartDataInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractedDocumentData = {
  __typename?: 'extractedDocumentData';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  dl_address?: Maybe<Scalars['String']['output']>;
  dl_city?: Maybe<Scalars['String']['output']>;
  dl_expiration_date?: Maybe<Scalars['String']['output']>;
  dl_first_name?: Maybe<Scalars['String']['output']>;
  dl_last_name?: Maybe<Scalars['String']['output']>;
  dl_middle_name?: Maybe<Scalars['String']['output']>;
  dl_state?: Maybe<Scalars['String']['output']>;
  dl_zip?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  insurance_company?: Maybe<Scalars['String']['output']>;
  insurance_company_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_expiration?: Maybe<Scalars['String']['output']>;
  insurance_expiration_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_name?: Maybe<Scalars['String']['output']>;
  insurance_name_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_policy_number?: Maybe<Scalars['String']['output']>;
  insurance_policy_number_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_state?: Maybe<Scalars['String']['output']>;
  insurance_state_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_vin?: Maybe<Scalars['String']['output']>;
  insurance_vin_confidence?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Files = {
  __typename?: 'files';
  filename?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mediaType?: Maybe<DealMediaTypeEnum>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FinancialInfo = {
  __typename?: 'financialInfo';
  amount_financed?: Maybe<Scalars['Float']['output']>;
  approval_on_deal_processing?: Maybe<Scalars['DateTime']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  bank_fees?: Maybe<Scalars['Float']['output']>;
  base_tax_amount?: Maybe<Scalars['Float']['output']>;
  buy_rate?: Maybe<Scalars['Float']['output']>;
  buyer_not_lessee?: Maybe<Scalars['Boolean']['output']>;
  closer_commission?: Maybe<Scalars['Float']['output']>;
  com_rate_markup?: Maybe<Scalars['Float']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_card_payment_amount_limit?: Maybe<Scalars['Float']['output']>;
  credit_debit_payment_amount_allowed?: Maybe<Scalars['Float']['output']>;
  credit_payment_amount_allowed?: Maybe<Scalars['Float']['output']>;
  credit_payment_amount_limit?: Maybe<Scalars['Float']['output']>;
  days_to_payment?: Maybe<Scalars['Int']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  debit_payment_amount_allowed?: Maybe<Scalars['Float']['output']>;
  debit_payment_amount_limit?: Maybe<Scalars['Float']['output']>;
  doc_fee?: Maybe<Scalars['Float']['output']>;
  down_payment_status?: Maybe<DownPaymentStatus>;
  fee_processor?: Maybe<Scalars['String']['output']>;
  finished_documents_screen?: Maybe<Scalars['DateTime']['output']>;
  first_payment_date?: Maybe<Scalars['Date']['output']>;
  gap_cost?: Maybe<Scalars['Float']['output']>;
  gap_price?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  needs_temporary_registration_tags?: Maybe<Scalars['Boolean']['output']>;
  new_lienholder?: Maybe<Bank>;
  new_registration_fee?: Maybe<Scalars['Float']['output']>;
  option_type?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Scalars['Float']['output']>;
  pen_gap_form_id?: Maybe<Scalars['Int']['output']>;
  pen_gap_rate_id?: Maybe<Scalars['Int']['output']>;
  pen_gap_session_id?: Maybe<Scalars['String']['output']>;
  pen_vsc_form_id?: Maybe<Scalars['Int']['output']>;
  pen_vsc_rate_id?: Maybe<Scalars['Int']['output']>;
  pen_vsc_session_id?: Maybe<Scalars['String']['output']>;
  plate_transfer?: Maybe<Scalars['Boolean']['output']>;
  processor?: Maybe<Scalars['String']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  quick_notes?: Maybe<Scalars['String']['output']>;
  reached_documents_screen?: Maybe<Scalars['DateTime']['output']>;
  registration_transfer_fee?: Maybe<Scalars['Float']['output']>;
  reserve?: Maybe<Scalars['Float']['output']>;
  sales_tax_rate?: Maybe<Scalars['Float']['output']>;
  selected_credit_decision?: Maybe<CreditDecision>;
  selected_credit_decision_id?: Maybe<Scalars['ID']['output']>;
  sell_rate?: Maybe<Scalars['Float']['output']>;
  setter_commission?: Maybe<Scalars['Float']['output']>;
  tax_processor?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  title_fee?: Maybe<Scalars['Float']['output']>;
  title_registration_option?: Maybe<TitleRegistrationOption>;
  tt_transaction_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_entered_reserve?: Maybe<Scalars['Float']['output']>;
  vsc_cost?: Maybe<Scalars['Float']['output']>;
  vsc_price?: Maybe<Scalars['Float']['output']>;
  vsc_term?: Maybe<Scalars['String']['output']>;
  vsc_type?: Maybe<Scalars['String']['output']>;
  warranty_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type FinancialInfoAcquisition = {
  __typename?: 'financialInfoAcquisition';
  appraised_value?: Maybe<Scalars['Float']['output']>;
  auction_fees?: Maybe<Scalars['Float']['output']>;
  auction_id?: Maybe<Scalars['ID']['output']>;
  cash_to_customer?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  estimated_dealer_fees?: Maybe<Scalars['Float']['output']>;
  gross_profit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_approved?: Maybe<Scalars['Boolean']['output']>;
  kbb_lending?: Maybe<Scalars['Float']['output']>;
  max_cash_to_customer?: Maybe<Scalars['Float']['output']>;
  max_total_cost?: Maybe<Scalars['Float']['output']>;
  offer?: Maybe<Scalars['Float']['output']>;
  overriding_user_id?: Maybe<Scalars['String']['output']>;
  sell_price?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
  transport_cost?: Maybe<Scalars['Float']['output']>;
};

export type FinancialInfoAcquisitionInput = {
  appraised_value?: InputMaybe<Scalars['Float']['input']>;
  auction_fees?: InputMaybe<Scalars['Float']['input']>;
  auction_id?: InputMaybe<Scalars['ID']['input']>;
  cash_to_customer?: InputMaybe<Scalars['Float']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  estimated_dealer_fees?: InputMaybe<Scalars['Float']['input']>;
  gross_profit?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  kbb_lending?: InputMaybe<Scalars['Float']['input']>;
  max_cash_to_customer?: InputMaybe<Scalars['Float']['input']>;
  max_total_cost?: InputMaybe<Scalars['Float']['input']>;
  offer?: InputMaybe<Scalars['Float']['input']>;
  overriding_user_id?: InputMaybe<Scalars['String']['input']>;
  sell_price?: InputMaybe<Scalars['Float']['input']>;
  total_cost?: InputMaybe<Scalars['Float']['input']>;
  transport_cost?: InputMaybe<Scalars['Float']['input']>;
};

export type FinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  approval_on_deal_processing?: InputMaybe<Scalars['DateTime']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  bank_fees?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buy_rate?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission?: InputMaybe<Scalars['Float']['input']>;
  com_rate_markup?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']['input']>;
  finished_documents_screen?: InputMaybe<Scalars['DateTime']['input']>;
  first_payment_date?: InputMaybe<Scalars['Date']['input']>;
  gap_cost?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']['input']>;
  new_lienholder?: InputMaybe<BankInput>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  option_type?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']['input']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']['input']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  processor?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['Float']['input']>;
  quick_notes?: InputMaybe<Scalars['String']['input']>;
  reached_documents_screen?: InputMaybe<Scalars['DateTime']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  reserve?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']['input']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission?: InputMaybe<Scalars['Float']['input']>;
  tax_processor?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']['input']>;
  vsc_cost?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  vsc_type?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type Financing = {
  __typename?: 'financing';
  annualPercentageRate?: Maybe<Scalars['Float']['output']>;
  downPaymentAmount?: Maybe<Scalars['Float']['output']>;
  finalAmount?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
};

export type FinixTransfer = {
  __typename?: 'finixTransfer';
  amount?: Maybe<Scalars['Float']['output']>;
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<CardType>;
  failureCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maskedNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<PaymentState>;
};

export type FlowStatus = {
  __typename?: 'flowStatus';
  car?: Maybe<Car>;
  customerId?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  inFlow?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FollowUp = {
  __typename?: 'followUp';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']['output']>;
  date_utc?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FollowUpStatusEnum>;
  type?: Maybe<FollowUpTypeEnum>;
  tz?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type FollowUpInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creator_id?: InputMaybe<Scalars['ID']['input']>;
  date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FollowUpStatusEnum>;
  type?: InputMaybe<FollowUpTypeEnum>;
  tz?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum FollowUpStatusEnum {
  Completed = 'completed',
  Deleted = 'deleted',
  Due = 'due',
  Scheduled = 'scheduled',
  Upcoming = 'upcoming',
}

export enum FollowUpTypeEnum {
  Call = 'call',
  Email = 'email',
  SendContracts = 'send_contracts',
  Text = 'text',
}

export type ForceTitleOnlyHelperLink = {
  __typename?: 'forceTitleOnlyHelperLink';
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GetAvailableUsers = {
  __typename?: 'getAvailableUsers';
  auto_assign_deals?: Maybe<Scalars['Boolean']['output']>;
  call_status?: Maybe<Scalars['String']['output']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  in_call?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  on_vacation?: Maybe<Scalars['Boolean']['output']>;
  overnight_deals?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  recruiter_id?: Maybe<Scalars['String']['output']>;
  twilio_number?: Maybe<Scalars['String']['output']>;
};

export type GetPackageOptions = {
  __typename?: 'getPackageOptions';
  gapMonthlyCost?: Maybe<Scalars['Float']['output']>;
  isGapOffered?: Maybe<Scalars['Boolean']['output']>;
  isVscOffered?: Maybe<Scalars['Boolean']['output']>;
  vscPackageOptions?: Maybe<Vsc>;
};

export type GetWaitingCalls = {
  __typename?: 'getWaitingCalls';
  data?: Maybe<Scalars['JSONObject']['output']>;
};

export enum ImportType {
  AcquisitionAutoImport = 'ACQUISITION_AUTO_IMPORT',
  AcquisitionFlowFinished = 'ACQUISITION_FLOW_FINISHED',
  AutoStructureConditioned = 'AUTO_STRUCTURE_CONDITIONED',
  AutoStructureFailure = 'AUTO_STRUCTURE_FAILURE',
  AutoStructureSuccessImmediateImport = 'AUTO_STRUCTURE_SUCCESS_IMMEDIATE_IMPORT',
  AutoStructureTimeout = 'AUTO_STRUCTURE_TIMEOUT',
  DocumentsPageFinished = 'DOCUMENTS_PAGE_FINISHED',
  FrozenOrLockedCredit = 'FROZEN_OR_LOCKED_CREDIT',
  HighLtvRatioAutoImport = 'HIGH_LTV_RATIO_AUTO_IMPORT',
  InactivityImport = 'INACTIVITY_IMPORT',
  ManualImportPostHardPull = 'MANUAL_IMPORT_POST_HARD_PULL',
  ManualImportPostSoftPull = 'MANUAL_IMPORT_POST_SOFT_PULL',
  ManualImportPreSoftPull = 'MANUAL_IMPORT_PRE_SOFT_PULL',
  NoKbbValuesFailure = 'NO_KBB_VALUES_FAILURE',
  OtherLienholder = 'OTHER_LIENHOLDER',
  PayoffRequestFailure = 'PAYOFF_REQUEST_FAILURE',
  PayoffRequestSuccess = 'PAYOFF_REQUEST_SUCCESS',
  PostHardPullAutoImport = 'POST_HARD_PULL_AUTO_IMPORT',
  SoftPullFailure = 'SOFT_PULL_FAILURE',
  SoftPullFailureAutoImport = 'SOFT_PULL_FAILURE_AUTO_IMPORT',
  SoftPullSuccessAutoImport = 'SOFT_PULL_SUCCESS_AUTO_IMPORT',
}

export enum InboxFilterByEnum {
  All = 'all',
  AllSalesPods = 'all_sales_pods',
  AllTitlePods = 'all_title_pods',
  MyLanes = 'my_lanes',
  MyTeams = 'my_teams',
  SalesPod = 'sales_pod',
  TitlePod = 'title_pod',
  YourMessages = 'your_messages',
}

export type InboxMessagesType = {
  __typename?: 'inboxMessagesType';
  messages?: Maybe<Array<Maybe<Message>>>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export enum InboxOrderByEnum {
  DateNewest = 'date_newest',
  DateOldest = 'date_oldest',
  Deal = 'deal',
}

export enum IncomeIntervalEnumType {
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export enum InteriorEnumType {
  EntertainmentConsoleDamage = 'entertainment_console_damage',
  RipsOrTears = 'rips_or_tears',
  SmellsOrOdors = 'smells_or_odors',
  Stains = 'stains',
}

export type JdpVehicleAccessories = {
  __typename?: 'jdpVehicleAccessories';
  acccode?: Maybe<Scalars['String']['output']>;
  accdesc?: Maybe<Scalars['String']['output']>;
  accessorycategory?: Maybe<Scalars['String']['output']>;
  excludes?: Maybe<Scalars['String']['output']>;
  includes?: Maybe<Scalars['String']['output']>;
  isadded?: Maybe<Scalars['Int']['output']>;
  isincluded?: Maybe<Scalars['Int']['output']>;
  loan?: Maybe<Scalars['Int']['output']>;
  retail?: Maybe<Scalars['Int']['output']>;
  tradein?: Maybe<Scalars['Int']['output']>;
};

export type JdpVehicleAccessoriesInput = {
  acccode?: InputMaybe<Scalars['String']['input']>;
  accdesc?: InputMaybe<Scalars['String']['input']>;
  accessorycategory?: InputMaybe<Scalars['String']['input']>;
  excludes?: InputMaybe<Scalars['String']['input']>;
  includes?: InputMaybe<Scalars['String']['input']>;
  isadded?: InputMaybe<Scalars['Int']['input']>;
  isincluded?: InputMaybe<Scalars['Int']['input']>;
  loan?: InputMaybe<Scalars['Int']['input']>;
  retail?: InputMaybe<Scalars['Int']['input']>;
  tradein?: InputMaybe<Scalars['Int']['input']>;
};

export type JdpVehicleAccessoriesObject = {
  __typename?: 'jdpVehicleAccessoriesObject';
  vehicle_accessories?: Maybe<Array<Maybe<JdpVehicleAccessories>>>;
};

export type JdpVehicleAccessoriesObjectInput = {
  vehicle_accessories?: InputMaybe<Array<InputMaybe<JdpVehicleAccessoriesInput>>>;
};

export type KbbConfigType = {
  action?: InputMaybe<Scalars['String']['input']>;
  optionId?: InputMaybe<Scalars['Int']['input']>;
};

export type KbbOption = {
  __typename?: 'kbbOption';
  categoryGroup?: Maybe<Scalars['String']['output']>;
  hasRelationships?: Maybe<Scalars['Boolean']['output']>;
  isConfigurable?: Maybe<Scalars['Boolean']['output']>;
  isConsumer?: Maybe<Scalars['Boolean']['output']>;
  isTypical?: Maybe<Scalars['Boolean']['output']>;
  isVinDecoded?: Maybe<Scalars['Boolean']['output']>;
  optionName?: Maybe<Scalars['String']['output']>;
  optionType?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
  vehicleOptionId?: Maybe<Scalars['Int']['output']>;
};

export type KbbOptionInput = {
  categoryGroup?: InputMaybe<Scalars['String']['input']>;
  hasRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  isConfigurable?: InputMaybe<Scalars['Boolean']['input']>;
  isConsumer?: InputMaybe<Scalars['Boolean']['input']>;
  isTypical?: InputMaybe<Scalars['Boolean']['input']>;
  isVinDecoded?: InputMaybe<Scalars['Boolean']['input']>;
  optionName?: InputMaybe<Scalars['String']['input']>;
  optionType?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  vehicleOptionId?: InputMaybe<Scalars['Int']['input']>;
};

export type KbbSelectedOptionsType = {
  __typename?: 'kbbSelectedOptionsType';
  id?: Maybe<Scalars['ID']['output']>;
  lendingOptionPrice?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  removed?: Maybe<Scalars['Boolean']['output']>;
  retailOptionPrice?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type KbbValuesDataType = {
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>>>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  vehicleOptionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type KbbValuesResult = {
  __typename?: 'kbbValuesResult';
  kbbSelectedOptions?: Maybe<Array<Maybe<KbbSelectedOptionsType>>>;
  lending?: Maybe<Scalars['Float']['output']>;
  lendingMileageAdjustment?: Maybe<Scalars['Float']['output']>;
  lendingOptionAdjustment?: Maybe<Scalars['Float']['output']>;
  lendingOptionPrices?: Maybe<Scalars['JSON']['output']>;
  retail?: Maybe<Scalars['Float']['output']>;
  retailMileageAdjustment?: Maybe<Scalars['Float']['output']>;
  retailOptionAdjustment?: Maybe<Scalars['Float']['output']>;
  retailOptionPrices?: Maybe<Scalars['JSON']['output']>;
  valuationDate?: Maybe<Scalars['String']['output']>;
};

export type KbbVinObjectResult = {
  __typename?: 'kbbVinObjectResult';
  vinResults?: Maybe<Array<Maybe<KbbVinResult>>>;
  warning?: Maybe<Scalars['Boolean']['output']>;
};

export type KbbVinResult = {
  __typename?: 'kbbVinResult';
  makeName?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  trimName?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleOptions?: Maybe<Array<Maybe<KbbOption>>>;
  yearId?: Maybe<Scalars['String']['output']>;
};

export type KeyMetric = {
  __typename?: 'keyMetric';
  keyMetric?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Value>;
};

export type LaneKeyMetric = {
  __typename?: 'laneKeyMetric';
  day_count?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type LeaderboardSetterStats = {
  __typename?: 'leaderboardSetterStats';
  numApps?: Maybe<Scalars['Int']['output']>;
  repId?: Maybe<Scalars['String']['output']>;
  repName?: Maybe<Scalars['String']['output']>;
  teamType?: Maybe<TeamType>;
};

export enum MaritalStatusEnum {
  Married = 'married',
  NotMarried = 'not_married',
  Separated = 'separated',
  Widowed = 'widowed',
}

export type MarketPrequalification = {
  __typename?: 'marketPrequalification';
  creditScoreTier: Scalars['String']['output'];
  prequalId: Scalars['ID']['output'];
};

export type MediaListData = {
  __typename?: 'mediaListData';
  contentType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MediaListDataInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MediaListInputObj = {
  data?: InputMaybe<Array<InputMaybe<MediaListDataInput>>>;
};

export type MediaListObj = {
  __typename?: 'mediaListObj';
  data?: Maybe<Array<Maybe<MediaListData>>>;
};

export type MediaUploadUrl = {
  __typename?: 'mediaUploadUrl';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MergedDocuments = {
  __typename?: 'mergedDocuments';
  filename?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Message = {
  __typename?: 'message';
  body?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateCreatedTz?: Maybe<Scalars['String']['output']>;
  deals?: Maybe<Array<Maybe<Deal>>>;
  from?: Maybe<Scalars['String']['output']>;
  fromCustomer?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mediaListObj?: Maybe<MediaListObj>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  senderName?: Maybe<Scalars['String']['output']>;
  sid?: Maybe<Scalars['String']['output']>;
};

export type MessageData = {
  __typename?: 'messageData';
  deals?: Maybe<Array<Maybe<Deal>>>;
  message?: Maybe<Message>;
  messageDetail?: Maybe<MessageDetail>;
};

export type MessageDetail = {
  __typename?: 'messageDetail';
  dealIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  dealStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shortNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Message_Template = {
  __typename?: 'message_template';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type MessagesDbPaginated = {
  __typename?: 'messagesDbPaginated';
  hasMoreMessages: Scalars['Boolean']['output'];
  messages: Array<Maybe<Message>>;
};

export type MessagesWithErrors = {
  __typename?: 'messagesWithErrors';
  hasErrors?: Maybe<Scalars['Boolean']['output']>;
  messages?: Maybe<Array<Maybe<Message>>>;
};

export type MetricsPages = {
  __typename?: 'metricsPages';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  iframe_url?: Maybe<Scalars['String']['output']>;
  link_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permission_name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type MmrResult = {
  __typename?: 'mmrResult';
  average?: Maybe<Scalars['Int']['output']>;
};

export type NewEstimateCustomerInput = {
  address?: InputMaybe<AddressInput>;
  auth0_id?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dashboard_visited?: InputMaybe<Scalars['Boolean']['input']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']['input']>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<EmploymentInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  home_phone_number?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_authenticated_route?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  name_suffix?: InputMaybe<Scalars['String']['input']>;
  no_email?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  sent_account_email?: InputMaybe<Scalars['Boolean']['input']>;
  sent_review_email?: InputMaybe<Scalars['Boolean']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewEstimateFileInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type NewEstimateFinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  approval_on_deal_processing?: InputMaybe<Scalars['DateTime']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  bank_fees?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buy_rate?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission?: InputMaybe<Scalars['Float']['input']>;
  com_rate_markup?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']['input']>;
  finished_documents_screen?: InputMaybe<Scalars['DateTime']['input']>;
  first_payment_date?: InputMaybe<Scalars['Date']['input']>;
  gap_cost?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']['input']>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  option_type?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']['input']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']['input']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  processor?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['Float']['input']>;
  quick_notes?: InputMaybe<Scalars['String']['input']>;
  reached_documents_screen?: InputMaybe<Scalars['DateTime']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  reserve?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']['input']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission?: InputMaybe<Scalars['Float']['input']>;
  tax_processor?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']['input']>;
  vsc_cost?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  vsc_type?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type NewEstimateUpsertInput = {
  car?: InputMaybe<CarInput>;
  closer_id?: InputMaybe<Scalars['String']['input']>;
  creation_date_tz?: InputMaybe<Scalars['String']['input']>;
  creation_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<NewEstimateCustomerInput>;
  financial_info?: InputMaybe<NewEstimateFinancialInfoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']['input']>;
  needs_electronic_signature_verification?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  opened_by_financial_specialist?: InputMaybe<Scalars['DateTime']['input']>;
  referral_source?: InputMaybe<ReferralSourceInput>;
  request_boot?: InputMaybe<Scalars['Boolean']['input']>;
  setter_id?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DealType>;
};

export type Note = {
  __typename?: 'note';
  author?: Maybe<User>;
  author_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creation_date_tz?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  note_type?: Maybe<Scalars['String']['output']>;
  notification_id?: Maybe<Scalars['ID']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  pinned?: Maybe<Scalars['Boolean']['output']>;
  recordingid?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type NoteInput = {
  author_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_date_tz?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  note_type?: InputMaybe<Scalars['String']['input']>;
  notification_id?: InputMaybe<Scalars['ID']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  pinned?: InputMaybe<Scalars['Boolean']['input']>;
  recordingid?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Notification = {
  __typename?: 'notification';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_id?: Maybe<Scalars['ID']['output']>;
  deal?: Maybe<Deal>;
  deal_contact_id?: Maybe<Scalars['ID']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  seen?: Maybe<Scalars['Boolean']['output']>;
  text_message?: Maybe<TextMessage>;
  text_message_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_id?: InputMaybe<Scalars['ID']['input']>;
  deal?: InputMaybe<DealInput>;
  deal_contact_id?: InputMaybe<Scalars['ID']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  seen?: InputMaybe<Scalars['Boolean']['input']>;
  text_message?: InputMaybe<TextMessageInput>;
  text_message_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OnWaitingQueue = {
  __typename?: 'onWaitingQueue';
  data?: Maybe<Array<Maybe<WaitingCall>>>;
  hunt_group_slug?: Maybe<Scalars['String']['output']>;
};

export type OperatingHoursTimeSlots = {
  __typename?: 'operatingHoursTimeSlots';
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  utcTimeSlots?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
};

export type OptEvent = {
  __typename?: 'optEvent';
  action?: Maybe<OptEventTypeEnum>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  opt_in_at?: Maybe<Scalars['DateTime']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  source?: Maybe<OptEventSourceEnum>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type OptEventInput = {
  action?: InputMaybe<OptEventTypeEnum>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  opt_in_at?: InputMaybe<Scalars['DateTime']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<OptEventSourceEnum>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum OptEventSourceEnum {
  Com = 'com',
  HubspotMigration = 'hubspot_migration',
  PreFlowPaymentCalculator = 'pre_flow_payment_calculator',
  ReOptDateSelected = 're_opt_date_selected',
  Verse = 'verse',
}

export enum OptEventTypeEnum {
  OptIn = 'opt_in',
  OptOut = 'opt_out',
}

export enum OverallConditionEnumType {
  LikeNew = 'like_new',
  NeedsWork = 'needs_work',
  SlightlyUsed = 'slightly_used',
}

export type PaymentEstimate = {
  __typename?: 'paymentEstimate';
  estimatedAnnualPercentageRate: Scalars['Float']['output'];
  estimatedTaxesAndFees: Scalars['Float']['output'];
  feeDetails: PaymentEstimateFeeDetails;
  paymentEstimateHigh: Scalars['Float']['output'];
  paymentEstimateLow: Scalars['Float']['output'];
  totalEstimatedLoan: Scalars['Float']['output'];
};

export type PaymentEstimateFeeDetails = {
  __typename?: 'paymentEstimateFeeDetails';
  baseTaxAmount: Scalars['Float']['output'];
  docFee: Scalars['Float']['output'];
  docFeeTaxAmount: Scalars['Float']['output'];
  registrationFee: Scalars['Float']['output'];
  titleFee: Scalars['Float']['output'];
  warrantyTaxAmount: Scalars['Float']['output'];
};

export type PaymentEstimateInput = {
  creditScore: CreditScore;
  feesInputFromDD?: InputMaybe<TtFeesInput>;
  financialInfoId?: InputMaybe<Scalars['ID']['input']>;
  fuelType?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  moneyDown: Scalars['Float']['input'];
  payoff: Scalars['Float']['input'];
  term: Scalars['Int']['input'];
  ttGetFeesSource?: InputMaybe<TtGetFeesSourceType>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export enum PaymentInstrumentType {
  All = 'ALL',
  BankAccount = 'BANK_ACCOUNT',
  PaymentCard = 'PAYMENT_CARD',
}

export enum PaymentMethod {
  BankAccount = 'BANK_ACCOUNT',
  Credit = 'CREDIT',
  CreditDebit = 'CREDIT_DEBIT',
  Debit = 'DEBIT',
}

export type PaymentOptions = {
  __typename?: 'paymentOptions';
  gap?: Maybe<PaymentOptionsGqlTypes>;
  noProducts?: Maybe<PaymentOptionsGqlTypes>;
  vsc?: Maybe<PaymentOptionsGqlTypes>;
  vscPlusGap?: Maybe<PaymentOptionsGqlTypes>;
};

export type PaymentOptionsGqlTypes = {
  __typename?: 'paymentOptionsGQLTypes';
  amountFinanced?: Maybe<Scalars['Float']['output']>;
  buyPayment?: Maybe<Scalars['Float']['output']>;
  closerCommission?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  reserve?: Maybe<Scalars['Float']['output']>;
  sellPayment?: Maybe<Scalars['Float']['output']>;
  setterCommission?: Maybe<Scalars['Float']['output']>;
};

export type PaymentOptionsInput = {
  bank?: InputMaybe<Scalars['String']['input']>;
  bankFees?: InputMaybe<Scalars['Float']['input']>;
  banks?: InputMaybe<Array<InputMaybe<BankInput>>>;
  baseTaxAmount?: InputMaybe<Scalars['Float']['input']>;
  buyRate?: InputMaybe<Scalars['Float']['input']>;
  carMileage?: InputMaybe<Scalars['Int']['input']>;
  carYear?: InputMaybe<Scalars['Int']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  closer2Id?: InputMaybe<Scalars['String']['input']>;
  closerId?: InputMaybe<Scalars['String']['input']>;
  daysToPayment?: InputMaybe<Scalars['Int']['input']>;
  docFee?: InputMaybe<Scalars['Float']['input']>;
  gapCost?: InputMaybe<Scalars['Float']['input']>;
  gapPrice?: InputMaybe<Scalars['Float']['input']>;
  moneyDown?: InputMaybe<Scalars['Float']['input']>;
  newRegistrationFee?: InputMaybe<Scalars['Float']['input']>;
  prequalificationCreditScore?: InputMaybe<Scalars['Int']['input']>;
  registrationTransferFee?: InputMaybe<Scalars['Float']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  setterId?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  titleFee?: InputMaybe<Scalars['Float']['input']>;
  titleRegistrationOption?: InputMaybe<TitleRegistrationOption>;
  userEnteredReserve?: InputMaybe<Scalars['Float']['input']>;
  vehiclePayoff?: InputMaybe<Scalars['Float']['input']>;
  vscCost?: InputMaybe<Scalars['Float']['input']>;
  vscPrice?: InputMaybe<Scalars['Float']['input']>;
  warrantyTaxAmount?: InputMaybe<Scalars['Float']['input']>;
};

export enum PaymentState {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Returned = 'RETURNED',
  Succeeded = 'SUCCEEDED',
  Unknown = 'UNKNOWN',
}

export type Payoff = {
  __typename?: 'payoff';
  account_number?: Maybe<Scalars['String']['output']>;
  cap_cost?: Maybe<Scalars['Float']['output']>;
  cap_reduction?: Maybe<Scalars['Float']['output']>;
  car_id?: Maybe<Scalars['ID']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  estimated_payoff?: Maybe<Scalars['Float']['output']>;
  good_through_date?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lease_term?: Maybe<Scalars['Float']['output']>;
  lender_name?: Maybe<Scalars['String']['output']>;
  lienholder_name?: Maybe<Scalars['String']['output']>;
  lienholder_slug?: Maybe<Scalars['String']['output']>;
  maturity_date?: Maybe<Scalars['Date']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  money_factor?: Maybe<Scalars['Float']['output']>;
  msrp?: Maybe<Scalars['Float']['output']>;
  next_payment_date?: Maybe<Scalars['Date']['output']>;
  old_lease_payment?: Maybe<Scalars['Float']['output']>;
  payoff_includes_sales_tax?: Maybe<Scalars['Boolean']['output']>;
  remaining_payments?: Maybe<Scalars['Float']['output']>;
  residual_amount?: Maybe<Scalars['Float']['output']>;
  residual_percentage?: Maybe<Scalars['Float']['output']>;
  sales_price?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff_entered_manually?: Maybe<Scalars['Boolean']['output']>;
  termination_fees?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_entered_total_payoff?: Maybe<Scalars['Float']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
  verification_status?: Maybe<PayoffVerificationStatus>;
};

export type PayoffDashboard = {
  __typename?: 'payoffDashboard';
  payoffRequests?: Maybe<Array<Maybe<PayoffRequestPopulated>>>;
};

export type PayoffInput = {
  account_number?: InputMaybe<Scalars['String']['input']>;
  cap_cost?: InputMaybe<Scalars['Float']['input']>;
  cap_reduction?: InputMaybe<Scalars['Float']['input']>;
  car_id?: InputMaybe<Scalars['ID']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  estimated_payoff?: InputMaybe<Scalars['Float']['input']>;
  good_through_date?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lease_term?: InputMaybe<Scalars['Float']['input']>;
  lender_name?: InputMaybe<Scalars['String']['input']>;
  lienholder_name?: InputMaybe<Scalars['String']['input']>;
  lienholder_slug?: InputMaybe<Scalars['String']['input']>;
  maturity_date?: InputMaybe<Scalars['Date']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  money_factor?: InputMaybe<Scalars['Float']['input']>;
  msrp?: InputMaybe<Scalars['Float']['input']>;
  next_payment_date?: InputMaybe<Scalars['Date']['input']>;
  old_lease_payment?: InputMaybe<Scalars['Float']['input']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']['input']>;
  remaining_payments?: InputMaybe<Scalars['Float']['input']>;
  residual_amount?: InputMaybe<Scalars['Float']['input']>;
  residual_percentage?: InputMaybe<Scalars['Float']['input']>;
  sales_price?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']['input']>;
  termination_fees?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
  verification_status?: InputMaybe<PayoffVerificationStatus>;
};

export type PayoffRequest = {
  __typename?: 'payoffRequest';
  completed_date?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fail_reason?: Maybe<Scalars['JSONObject']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']['output']>;
  payoff_clerk_id?: Maybe<Scalars['String']['output']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']['output']>;
  sales_tax?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
};

export type PayoffRequestConfirmInput = {
  completed_date?: InputMaybe<Scalars['DateTime']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  double_tax_applied?: InputMaybe<Scalars['Boolean']['input']>;
  fail_reason?: InputMaybe<Scalars['JSONObject']['input']>;
  good_through_date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  needs_payoff_documents?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_clerk_id?: InputMaybe<Scalars['String']['input']>;
  payoff_documents_uploaded?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']['input']>;
  sales_tax?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PayoffRequestStatus>;
  temporary_info_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
};

export type PayoffRequestInput = {
  completed_date?: InputMaybe<Scalars['DateTime']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  fail_reason?: InputMaybe<Scalars['JSONObject']['input']>;
  good_through_date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  needs_payoff_documents?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_clerk_id?: InputMaybe<Scalars['String']['input']>;
  payoff_documents_uploaded?: InputMaybe<Scalars['Boolean']['input']>;
  sales_tax?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<PayoffRequestStatus>;
  temporary_info_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
};

export type PayoffRequestPopulated = {
  __typename?: 'payoffRequestPopulated';
  completed_date?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fail_reason?: Maybe<Scalars['JSONObject']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']['output']>;
  payoff_clerk?: Maybe<User>;
  payoff_clerk_id?: Maybe<Scalars['String']['output']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']['output']>;
  sales_tax?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info?: Maybe<TemporaryInfo>;
  temporary_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
};

export enum PayoffRequestStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  FailureRequested = 'FAILURE_REQUESTED',
  Fetching = 'FETCHING',
  Pending = 'PENDING',
}

export type PayoffRequestStatusUpdate = {
  __typename?: 'payoffRequestStatusUpdate';
  completed_date?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fail_reason?: Maybe<Scalars['JSONObject']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']['output']>;
  payoff_clerk_id?: Maybe<Scalars['String']['output']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']['output']>;
  sales_tax?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
};

export enum PayoffVerificationStatus {
  CustomerProvided = 'customer_provided',
  Edited = 'edited',
  Verified = 'verified',
  VerifiedBySalesRep = 'verified_by_sales_rep',
}

export type Pod = {
  __typename?: 'pod';
  archived?: Maybe<Scalars['Boolean']['output']>;
  child_pods?: Maybe<Array<Maybe<BasePod>>>;
  closer_commission_rate?: Maybe<Scalars['Float']['output']>;
  closer_commission_type?: Maybe<CommissionType>;
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  leads?: Maybe<Array<Maybe<BaseUser>>>;
  manager_commission_rate?: Maybe<Scalars['Float']['output']>;
  manager_commission_type?: Maybe<CommissionType>;
  managers?: Maybe<Array<Maybe<BaseUser>>>;
  name?: Maybe<Scalars['String']['output']>;
  parent_pod?: Maybe<BasePod>;
  parent_pod_id?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pod_users?: Maybe<Array<Maybe<PodUser>>>;
  problem_solver?: Maybe<Scalars['Boolean']['output']>;
  setter_commission_rate?: Maybe<Scalars['Float']['output']>;
  setter_commission_type?: Maybe<CommissionType>;
  special_commission_rate?: Maybe<Scalars['Float']['output']>;
  special_commission_type?: Maybe<CommissionType>;
  team_type?: Maybe<TeamType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  us_states_object?: Maybe<UsStatesObject>;
  usersWithoutTeamRole?: Maybe<Array<Maybe<BaseUser>>>;
  vsc_markup?: Maybe<Scalars['Int']['output']>;
  vsc_multiplier?: Maybe<Scalars['Float']['output']>;
};

export type PodHours = {
  __typename?: 'podHours';
  beginTime?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type PodInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  child_pods?: InputMaybe<Array<InputMaybe<BasePodInput>>>;
  closer_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  closer_commission_type?: InputMaybe<CommissionType>;
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  leads?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  manager_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  manager_commission_type?: InputMaybe<CommissionType>;
  managers?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_pod?: InputMaybe<BasePodInput>;
  parent_pod_id?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pod_users?: InputMaybe<Array<InputMaybe<PodUserInput>>>;
  problem_solver?: InputMaybe<Scalars['Boolean']['input']>;
  setter_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission_type?: InputMaybe<CommissionType>;
  special_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  special_commission_type?: InputMaybe<CommissionType>;
  team_type?: InputMaybe<TeamType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  us_states_object?: InputMaybe<UsStatesInputObject>;
  users?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  usersWithoutTeamRole?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  vsc_markup?: InputMaybe<Scalars['Int']['input']>;
  vsc_multiplier?: InputMaybe<Scalars['Float']['input']>;
};

export type PodUser = {
  __typename?: 'podUser';
  id?: Maybe<Scalars['ID']['output']>;
  pod_id?: Maybe<Scalars['Int']['output']>;
  team_role?: Maybe<TeamRole>;
  user?: Maybe<BaseUser>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type PodUserInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pod_id?: InputMaybe<Scalars['Int']['input']>;
  team_role?: InputMaybe<TeamRole>;
  user?: InputMaybe<BaseUserInput>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export enum PotentialRoleEnumType {
  Closer = 'closer',
  NotAssigned = 'not_assigned',
  Setter = 'setter',
  SetterLead = 'setter_lead',
}

export type PotentialUser = {
  __typename?: 'potentialUser';
  added_to_testflight: Scalars['Boolean']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  email_created: Scalars['Boolean']['output'];
  employment_status: EmploymentEnumType;
  hired: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  interview_scheduled: Scalars['Boolean']['output'];
  login_created: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  potential_role: PotentialRoleEnumType;
  reached_out: Scalars['Boolean']['output'];
  referral_code?: Maybe<Scalars['String']['output']>;
  signup_date: Scalars['DateTime']['output'];
  slack_created: Scalars['Boolean']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zip: Scalars['Int']['output'];
};

export type PotentialUserInput = {
  email: Scalars['String']['input'];
  employment_status: EmploymentEnumType;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  referral_code?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['Int']['input'];
};

export type Prequalification = {
  __typename?: 'prequalification';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_score?: Maybe<Scalars['Int']['output']>;
  credit_score_tier?: Maybe<CreditScoreTierEnumType>;
  customer_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  input?: Maybe<PrequalificationRequest>;
  ltv?: Maybe<Scalars['Float']['output']>;
  output?: Maybe<ExperianOutput>;
  payment_to_income?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Product = {
  __typename?: 'product';
  gap_selected?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_type?: Maybe<Scalars['String']['output']>;
  provider_dealer_code_name?: Maybe<Scalars['String']['output']>;
  provider_id?: Maybe<Scalars['String']['output']>;
  selected_package?: Maybe<Scalars['String']['output']>;
};

export type ProofOfInsurance = {
  __typename?: 'proofOfInsurance';
  company_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  policy_number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ProofOfInsuranceInput = {
  company_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  policy_number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Provider = {
  __typename?: 'provider';
  name?: Maybe<Scalars['String']['output']>;
  provider_id?: Maybe<Scalars['String']['output']>;
};

export type PurchaseOrder = {
  __typename?: 'purchaseOrder';
  files?: Maybe<Array<Maybe<Files>>>;
};

export type R1CreditDecisionData = {
  __typename?: 'r1CreditDecisionData';
  applicationNumber?: Maybe<Scalars['String']['output']>;
  applicationStatus?: Maybe<CreditDecisionStatusEnum>;
  applicationStatusDetail?: Maybe<Scalars['String']['output']>;
  contractTerm?: Maybe<ContractTerm>;
  decision?: Maybe<Decision>;
};

export type Rate = {
  __typename?: 'rate';
  coverage_name?: Maybe<Scalars['String']['output']>;
  deductible?: Maybe<Deductible>;
  form_id?: Maybe<Scalars['Int']['output']>;
  form_name?: Maybe<Scalars['String']['output']>;
  form_number?: Maybe<Scalars['String']['output']>;
  product_type?: Maybe<Scalars['String']['output']>;
  session_id?: Maybe<Scalars['String']['output']>;
  term_miles?: Maybe<Scalars['String']['output']>;
  term_months?: Maybe<Scalars['String']['output']>;
};

export type RateInput = {
  apr?: InputMaybe<Scalars['Float']['input']>;
  effectiveDate?: InputMaybe<Scalars['String']['input']>;
  financeTerms?: InputMaybe<Scalars['Int']['input']>;
  financeTermsMileage?: InputMaybe<Scalars['Int']['input']>;
  financedAmount?: InputMaybe<Scalars['Float']['input']>;
  fuelType?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  msrp?: InputMaybe<Scalars['Float']['input']>;
  purchasePrice?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferralSource = {
  __typename?: 'referralSource';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  other_source_description?: Maybe<Scalars['String']['output']>;
  source_name?: Maybe<ReferralSourceEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ReferralSourceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  other_source_description?: InputMaybe<Scalars['String']['input']>;
  source_name?: InputMaybe<ReferralSourceEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum RelationToBuyerEnum {
  Child = 'child',
  Other = 'other',
  Parent = 'parent',
  ResidesWith = 'resides_with',
  Spouse = 'spouse',
}

export type ReserveStructure = {
  __typename?: 'reserveStructure';
  bank_id?: Maybe<Scalars['ID']['output']>;
  flat_percentage?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  rate_adj_max?: Maybe<Scalars['Float']['output']>;
  rate_adj_min?: Maybe<Scalars['Float']['output']>;
  term_max?: Maybe<Scalars['Int']['output']>;
  term_min?: Maybe<Scalars['Int']['output']>;
};

export type ReserveStructureInput = {
  bank_id?: InputMaybe<Scalars['ID']['input']>;
  flat_percentage?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  rate_adj_max?: InputMaybe<Scalars['Float']['input']>;
  rate_adj_min?: InputMaybe<Scalars['Float']['input']>;
  term_max?: InputMaybe<Scalars['Int']['input']>;
  term_min?: InputMaybe<Scalars['Int']['input']>;
};

export enum ResidenceEnumType {
  Buying = 'buying',
  Family = 'family',
  Own = 'own',
  Rent = 'rent',
}

export type ResultAddParticipant = {
  __typename?: 'resultAddParticipant';
  call_already_answered?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Review = {
  __typename?: 'review';
  com_visible?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ReviewInput = {
  com_visible?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  review?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SigningAuditDocumentViewedEvent = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  document_id?: InputMaybe<Scalars['String']['input']>;
  document_name?: InputMaybe<Scalars['String']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SigningError = {
  __typename?: 'signingError';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  error_detail?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  error_type?: Maybe<SigningErrorEnum>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type SigningErrorInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  error_detail?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  error_type?: InputMaybe<SigningErrorEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SsnLog = {
  __typename?: 'ssnLog';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  temp_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type SsnLogInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  temp_info_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type Surcharge = {
  __typename?: 'surcharge';
  car_status?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export enum TeamRole {
  Lead = 'lead',
  Manager = 'manager',
}

export enum TeamType {
  D2d = 'd2d',
  Inbound = 'inbound',
  Outbound = 'outbound',
  Region = 'region',
  Titling = 'titling',
}

export type TemporaryData = {
  __typename?: 'temporaryData';
  address_line?: Maybe<Scalars['String']['output']>;
  address_line_2?: Maybe<Scalars['String']['output']>;
  book_value?: Maybe<Scalars['Float']['output']>;
  cash_to_customer?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cobuyer_address_line?: Maybe<Scalars['String']['output']>;
  cobuyer_address_line_2?: Maybe<Scalars['String']['output']>;
  cobuyer_city?: Maybe<Scalars['String']['output']>;
  cobuyer_county?: Maybe<Scalars['String']['output']>;
  cobuyer_dob?: Maybe<Scalars['DateTime']['output']>;
  cobuyer_email?: Maybe<Scalars['String']['output']>;
  cobuyer_employer_name?: Maybe<Scalars['String']['output']>;
  cobuyer_employer_phone_number?: Maybe<Scalars['String']['output']>;
  cobuyer_employment_status?: Maybe<Scalars['String']['output']>;
  cobuyer_first_name?: Maybe<Scalars['String']['output']>;
  cobuyer_job_title?: Maybe<Scalars['String']['output']>;
  cobuyer_last_name?: Maybe<Scalars['String']['output']>;
  cobuyer_marital_status?: Maybe<MaritalStatusEnum>;
  cobuyer_months_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_pay_frequency?: Maybe<Scalars['String']['output']>;
  cobuyer_phone_number?: Maybe<Scalars['String']['output']>;
  cobuyer_prequalification_errors?: Maybe<Array<Maybe<LeExperianErrorCodeEnum>>>;
  cobuyer_previous_address_line?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_address_line_2?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_city?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_county?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_employer_name?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_employer_phone_number?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_job_title?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_months_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_previous_state?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_years_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_previous_zip?: Maybe<Scalars['String']['output']>;
  cobuyer_primary_residence_monthly_payment?: Maybe<Scalars['Float']['output']>;
  cobuyer_primary_residence_months?: Maybe<Scalars['Int']['output']>;
  cobuyer_primary_residence_type?: Maybe<Scalars['String']['output']>;
  cobuyer_primary_residence_years?: Maybe<Scalars['Int']['output']>;
  cobuyer_relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  cobuyer_salary?: Maybe<Scalars['Float']['output']>;
  cobuyer_secondary_residence_months?: Maybe<Scalars['Int']['output']>;
  cobuyer_secondary_residence_years?: Maybe<Scalars['Int']['output']>;
  cobuyer_state?: Maybe<Scalars['String']['output']>;
  cobuyer_years_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_zip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  condition_report?: Maybe<Scalars['JSON']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  credit_score?: Maybe<Scalars['Int']['output']>;
  current_accordion_panel?: Maybe<Scalars['String']['output']>;
  device_type?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  doc_fee?: Maybe<Scalars['Float']['output']>;
  down_payment?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employer_name?: Maybe<Scalars['String']['output']>;
  employer_phone_number?: Maybe<Scalars['String']['output']>;
  employment_status?: Maybe<Scalars['String']['output']>;
  estimated_dealer_fees?: Maybe<Scalars['Float']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  has_full_ssn?: Maybe<Scalars['Boolean']['output']>;
  has_same_address_as_cobuyer?: Maybe<Scalars['Boolean']['output']>;
  high_ltv_ratio?: Maybe<Scalars['Boolean']['output']>;
  include_cobuyer?: Maybe<Scalars['Boolean']['output']>;
  is_hard_pull?: Maybe<Scalars['Boolean']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  kbb_all_options?: Maybe<Scalars['JSON']['output']>;
  kbb_lending_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_lending_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_selected_options?: Maybe<Scalars['JSON']['output']>;
  kbb_trim_name?: Maybe<Scalars['String']['output']>;
  kbb_valuation_date?: Maybe<Scalars['String']['output']>;
  kbb_vehicle_id?: Maybe<Scalars['Int']['output']>;
  kbb_vehicle_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_url?: Maybe<Scalars['String']['output']>;
  lh_account_number?: Maybe<Scalars['String']['output']>;
  license_plate_number?: Maybe<Scalars['String']['output']>;
  license_plate_state?: Maybe<Scalars['String']['output']>;
  lienholder?: Maybe<Scalars['String']['output']>;
  lienholderLogo?: Maybe<Scalars['String']['output']>;
  lienholder_slug?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  manual_entry?: Maybe<Scalars['Boolean']['output']>;
  marital_status?: Maybe<MaritalStatusEnum>;
  middle_name?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  mmr_value?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  months_at_job?: Maybe<Scalars['Int']['output']>;
  moved_states?: Maybe<Scalars['Boolean']['output']>;
  old_lease_payment?: Maybe<Scalars['Float']['output']>;
  other_lienholder?: Maybe<Scalars['String']['output']>;
  pay_frequency?: Maybe<Scalars['String']['output']>;
  payment_estimate_high?: Maybe<Scalars['Float']['output']>;
  payment_estimate_low?: Maybe<Scalars['Float']['output']>;
  payoff_includes_sales_tax?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  prequal_id?: Maybe<Scalars['ID']['output']>;
  prequalification_errors?: Maybe<Array<Maybe<LeExperianErrorCodeEnum>>>;
  prequalification_success?: Maybe<Scalars['Boolean']['output']>;
  previous_address_line?: Maybe<Scalars['String']['output']>;
  previous_address_line_2?: Maybe<Scalars['String']['output']>;
  previous_city?: Maybe<Scalars['String']['output']>;
  previous_county?: Maybe<Scalars['String']['output']>;
  previous_employer_name?: Maybe<Scalars['String']['output']>;
  previous_employer_phone_number?: Maybe<Scalars['String']['output']>;
  previous_job_title?: Maybe<Scalars['String']['output']>;
  previous_months_at_job?: Maybe<Scalars['Int']['output']>;
  previous_state?: Maybe<Scalars['String']['output']>;
  previous_years_at_job?: Maybe<Scalars['Int']['output']>;
  previous_zip?: Maybe<Scalars['String']['output']>;
  primary_residence_monthly_payment?: Maybe<Scalars['Float']['output']>;
  primary_residence_months?: Maybe<Scalars['Int']['output']>;
  primary_residence_type?: Maybe<Scalars['String']['output']>;
  primary_residence_years?: Maybe<Scalars['Int']['output']>;
  query_params?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  retail_book_value?: Maybe<Scalars['Float']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff_entered_manually?: Maybe<Scalars['Boolean']['output']>;
  scheduled_call_datetime?: Maybe<Scalars['DateTime']['output']>;
  scheduled_call_datetime_tz?: Maybe<Scalars['String']['output']>;
  secondary_residence_months?: Maybe<Scalars['Int']['output']>;
  secondary_residence_years?: Maybe<Scalars['Int']['output']>;
  shopping_cart?: Maybe<TemporaryShoppingCartData>;
  ssn?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  taxes?: Maybe<Scalars['Decimal']['output']>;
  taxes_fees?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  title_only?: Maybe<Scalars['Boolean']['output']>;
  uploaded_files?: Maybe<Scalars['JSON']['output']>;
  user_entered_total_payoff?: Maybe<Scalars['Float']['output']>;
  vehicle_offer?: Maybe<Scalars['Float']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
  vehicle_registration_fee?: Maybe<Scalars['Float']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
  years_at_job?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type TemporaryDataInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  book_value?: InputMaybe<Scalars['Float']['input']>;
  cash_to_customer?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cobuyer_address_line?: InputMaybe<Scalars['String']['input']>;
  cobuyer_address_line_2?: InputMaybe<Scalars['String']['input']>;
  cobuyer_city?: InputMaybe<Scalars['String']['input']>;
  cobuyer_county?: InputMaybe<Scalars['String']['input']>;
  cobuyer_dob?: InputMaybe<Scalars['DateTime']['input']>;
  cobuyer_email?: InputMaybe<Scalars['String']['input']>;
  cobuyer_employer_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  cobuyer_employment_status?: InputMaybe<Scalars['String']['input']>;
  cobuyer_first_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_job_title?: InputMaybe<Scalars['String']['input']>;
  cobuyer_last_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_marital_status?: InputMaybe<MaritalStatusEnum>;
  cobuyer_months_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_pay_frequency?: InputMaybe<Scalars['String']['input']>;
  cobuyer_phone_number?: InputMaybe<Scalars['String']['input']>;
  cobuyer_prequalification_errors?: InputMaybe<Array<InputMaybe<LeExperianErrorCodeEnum>>>;
  cobuyer_previous_address_line?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_address_line_2?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_city?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_county?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_employer_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_job_title?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_months_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_previous_state?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_years_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_previous_zip?: InputMaybe<Scalars['String']['input']>;
  cobuyer_primary_residence_monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  cobuyer_primary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_primary_residence_type?: InputMaybe<Scalars['String']['input']>;
  cobuyer_primary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  cobuyer_salary?: InputMaybe<Scalars['Float']['input']>;
  cobuyer_secondary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_secondary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_ssn?: InputMaybe<Scalars['String']['input']>;
  cobuyer_state?: InputMaybe<Scalars['String']['input']>;
  cobuyer_years_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_zip?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  condition_report?: InputMaybe<Scalars['JSON']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  credit_score?: InputMaybe<Scalars['Int']['input']>;
  current_accordion_panel?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer_name?: InputMaybe<Scalars['String']['input']>;
  employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  employment_status?: InputMaybe<Scalars['String']['input']>;
  estimated_dealer_fees?: InputMaybe<Scalars['Float']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  good_through_date?: InputMaybe<Scalars['DateTime']['input']>;
  has_full_ssn?: InputMaybe<Scalars['Boolean']['input']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  high_ltv_ratio?: InputMaybe<Scalars['Boolean']['input']>;
  include_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  is_hard_pull?: InputMaybe<Scalars['Boolean']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  kbb_all_options?: InputMaybe<Scalars['JSON']['input']>;
  kbb_lending_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_lending_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_selected_options?: InputMaybe<Scalars['JSON']['input']>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  kbb_valuation_date?: InputMaybe<Scalars['String']['input']>;
  kbb_vehicle_id?: InputMaybe<Scalars['Int']['input']>;
  kbb_vehicle_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_url?: InputMaybe<Scalars['String']['input']>;
  lh_account_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  lienholder?: InputMaybe<Scalars['String']['input']>;
  lienholderLogo?: InputMaybe<Scalars['String']['input']>;
  lienholder_slug?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  manual_entry?: InputMaybe<Scalars['Boolean']['input']>;
  marital_status?: InputMaybe<MaritalStatusEnum>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  mmr_value?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  months_at_job?: InputMaybe<Scalars['Int']['input']>;
  moved_states?: InputMaybe<Scalars['Boolean']['input']>;
  old_lease_payment?: InputMaybe<Scalars['Float']['input']>;
  other_lienholder?: InputMaybe<Scalars['String']['input']>;
  pay_frequency?: InputMaybe<Scalars['String']['input']>;
  payment_estimate_high?: InputMaybe<Scalars['Float']['input']>;
  payment_estimate_low?: InputMaybe<Scalars['Float']['input']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prequal_id?: InputMaybe<Scalars['ID']['input']>;
  prequalification_errors?: InputMaybe<Array<InputMaybe<LeExperianErrorCodeEnum>>>;
  prequalification_success?: InputMaybe<Scalars['Boolean']['input']>;
  previous_address_line?: InputMaybe<Scalars['String']['input']>;
  previous_address_line_2?: InputMaybe<Scalars['String']['input']>;
  previous_city?: InputMaybe<Scalars['String']['input']>;
  previous_county?: InputMaybe<Scalars['String']['input']>;
  previous_employer_name?: InputMaybe<Scalars['String']['input']>;
  previous_employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  previous_job_title?: InputMaybe<Scalars['String']['input']>;
  previous_months_at_job?: InputMaybe<Scalars['Int']['input']>;
  previous_state?: InputMaybe<Scalars['String']['input']>;
  previous_years_at_job?: InputMaybe<Scalars['Int']['input']>;
  previous_zip?: InputMaybe<Scalars['String']['input']>;
  primary_residence_monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  primary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  primary_residence_type?: InputMaybe<Scalars['String']['input']>;
  primary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  retail_book_value?: InputMaybe<Scalars['Float']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled_call_datetime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduled_call_datetime_tz?: InputMaybe<Scalars['String']['input']>;
  secondary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  secondary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  shopping_cart?: InputMaybe<TemporaryShoppingCartDataInput>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxes?: InputMaybe<Scalars['Decimal']['input']>;
  taxes_fees?: InputMaybe<Scalars['Float']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_only?: InputMaybe<Scalars['Boolean']['input']>;
  uploaded_files?: InputMaybe<Scalars['JSON']['input']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_offer?: InputMaybe<Scalars['Float']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  years_at_job?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type TemporaryInfo = {
  __typename?: 'temporaryInfo';
  consents?: Maybe<Array<Maybe<Consent>>>;
  data: TemporaryData;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<TemporaryInfoStatusEnumType>;
};

export type TemporaryInfoInput = {
  data: TemporaryDataInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TemporaryInfoStatusEnumType>;
};

export enum TemporaryInfoStatusEnumType {
  Imported = 'imported',
  InProgress = 'in_progress',
}

export type TemporaryShoppingCartData = {
  __typename?: 'temporaryShoppingCartData';
  buyout?: Maybe<Scalars['Float']['output']>;
  credit_score?: Maybe<Scalars['Int']['output']>;
  down_payment?: Maybe<Scalars['Float']['output']>;
  estimated_fees?: Maybe<Scalars['Float']['output']>;
  loan_term?: Maybe<Scalars['Int']['output']>;
  payment_estimate_high?: Maybe<Scalars['Float']['output']>;
  payment_estimate_low?: Maybe<Scalars['Float']['output']>;
};

export type TemporaryShoppingCartDataInput = {
  buyout?: InputMaybe<Scalars['Float']['input']>;
  credit_score?: InputMaybe<Scalars['Int']['input']>;
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  estimated_fees?: InputMaybe<Scalars['Float']['input']>;
  loan_term?: InputMaybe<Scalars['Int']['input']>;
  payment_estimate_high?: InputMaybe<Scalars['Float']['input']>;
  payment_estimate_low?: InputMaybe<Scalars['Float']['input']>;
};

export type TextMessage = {
  __typename?: 'textMessage';
  account_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  date_created?: Maybe<Scalars['String']['output']>;
  date_sent?: Maybe<Scalars['String']['output']>;
  date_updated?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  media?: Maybe<Scalars['String']['output']>;
  media_list_object?: Maybe<MediaListObj>;
  message?: Maybe<Scalars['String']['output']>;
  message_id?: Maybe<Scalars['String']['output']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  recipient_phone_number?: Maybe<Scalars['String']['output']>;
  resolved_media?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type TextMessageInput = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_created?: InputMaybe<Scalars['String']['input']>;
  date_sent?: InputMaybe<Scalars['String']['input']>;
  date_updated?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<Scalars['String']['input']>;
  media_list_object?: InputMaybe<MediaListInputObj>;
  message?: InputMaybe<Scalars['String']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
  recipient_phone_number?: InputMaybe<Scalars['String']['input']>;
  resolved_media?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserInput>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export enum TiresEnumType {
  Fine = 'fine',
  NeedReplacing = 'need_replacing',
  New = 'new',
}

export enum TransformType {
  AcquisitionApproved = 'ACQUISITION_APPROVED',
  AcquisitionNotApproved = 'ACQUISITION_NOT_APPROVED',
  AutoStructureBegin = 'AUTO_STRUCTURE_BEGIN',
  CallBackPayoff = 'CALL_BACK_PAYOFF',
  FrozenOrLockedCredit = 'FROZEN_OR_LOCKED_CREDIT',
  HighLtvRatioAutoImport = 'HIGH_LTV_RATIO_AUTO_IMPORT',
  InactivityImport = 'INACTIVITY_IMPORT',
  ManualImportPostSoftPull = 'MANUAL_IMPORT_POST_SOFT_PULL',
  ManualImportPreSoftPull = 'MANUAL_IMPORT_PRE_SOFT_PULL',
  NoKbbFailure = 'NO_KBB_FAILURE',
  OtherLienholder = 'OTHER_LIENHOLDER',
  PayoffRequestFailure = 'PAYOFF_REQUEST_FAILURE',
  PayoffRequestSuccess = 'PAYOFF_REQUEST_SUCCESS',
  SoftPullFailure = 'SOFT_PULL_FAILURE',
  SoftPullFailureAutoImport = 'SOFT_PULL_FAILURE_AUTO_IMPORT',
  SoftPullSuccessAutoImport = 'SOFT_PULL_SUCCESS_AUTO_IMPORT',
}

export type TwilioNumber = {
  __typename?: 'twilioNumber';
  friendlyName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type UpdateFinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  approval_on_deal_processing?: InputMaybe<Scalars['DateTime']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  bank_fees?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buy_rate?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission?: InputMaybe<Scalars['Float']['input']>;
  com_rate_markup?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  deal_id: Scalars['ID']['input'];
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']['input']>;
  finished_documents_screen?: InputMaybe<Scalars['DateTime']['input']>;
  first_payment_date?: InputMaybe<Scalars['Date']['input']>;
  gap_cost?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  money_down?: InputMaybe<Scalars['Float']['input']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']['input']>;
  new_lienholder?: InputMaybe<BankInput>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  option_type?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']['input']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']['input']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  processor?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['Float']['input']>;
  quick_notes?: InputMaybe<Scalars['String']['input']>;
  reached_documents_screen?: InputMaybe<Scalars['DateTime']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  reserve?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']['input']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission?: InputMaybe<Scalars['Float']['input']>;
  tax_processor?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']['input']>;
  vsc_cost?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  vsc_type?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type UploadDocumentsInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UsStatesInputObject = {
  states?: InputMaybe<Array<InputMaybe<StateAbbreviation>>>;
};

export type UsStatesObject = {
  __typename?: 'usStatesObject';
  states?: Maybe<Array<Maybe<StateAbbreviation>>>;
};

export type User = {
  __typename?: 'user';
  auth0_roles?: Maybe<UserRoles>;
  auto_assign_deals?: Maybe<Scalars['Boolean']['output']>;
  call_status?: Maybe<Scalars['String']['output']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  huntGroups?: Maybe<Array<Maybe<HuntGroup>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  on_vacation?: Maybe<Scalars['Boolean']['output']>;
  overnight_deals?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  pods?: Maybe<Array<Maybe<Pod>>>;
  recruiter_id?: Maybe<Scalars['String']['output']>;
  titlingPods?: Maybe<Array<Maybe<Pod>>>;
  twilio_number?: Maybe<Scalars['String']['output']>;
};

export type UserByRole = {
  __typename?: 'userByRole';
  email?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  auto_assign_deals?: InputMaybe<Scalars['Boolean']['input']>;
  call_status?: InputMaybe<Scalars['String']['input']>;
  can_claim_as_closer?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  on_vacation?: InputMaybe<Scalars['Boolean']['input']>;
  overnight_deals?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  pods?: InputMaybe<Array<InputMaybe<PodInput>>>;
  recruiter_id?: InputMaybe<Scalars['String']['input']>;
  titlingPods?: InputMaybe<Array<InputMaybe<PodInput>>>;
  twilio_number?: InputMaybe<Scalars['String']['input']>;
};

export type Value = {
  __typename?: 'value';
  acquisition?: Maybe<Scalars['Float']['output']>;
  buyout?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type VisibleArticles = {
  __typename?: 'visibleArticles';
  results?: Maybe<Array<Maybe<Article>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Vsc = {
  __typename?: 'vsc';
  noProductsPmt?: Maybe<Scalars['Float']['output']>;
  startingIndex?: Maybe<Scalars['Int']['output']>;
  vscOffers?: Maybe<Array<Maybe<VscOffers>>>;
};

export type VscCoverages = {
  __typename?: 'vscCoverages';
  category?: Maybe<Scalars['String']['output']>;
  components?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type VscDetails = {
  __typename?: 'vscDetails';
  cost?: Maybe<Scalars['Float']['output']>;
  deductible?: Maybe<Scalars['Float']['output']>;
  miles?: Maybe<Scalars['Int']['output']>;
  payment?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  years?: Maybe<Scalars['Float']['output']>;
};

export type VscOffers = {
  __typename?: 'vscOffers';
  coverage?: Maybe<Array<Maybe<VscCoverages>>>;
  details?: Maybe<VscDetails>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type WaitingCall = {
  __typename?: 'waitingCall';
  conference_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['Int']['output']>;
  deal_state?: Maybe<Scalars['String']['output']>;
  deal_type?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  hunt_group_slug?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_thunder?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  processing_started_at?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  transferred_from?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type AddressDetailQueryVariables = Exact<{
  address?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddressDetailQuery = {
  __typename?: 'RootQueryType';
  addressDetail?:
    | {
        __typename?: 'address';
        city?: string | null | undefined;
        state?: string | null | undefined;
        county?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ValidZipQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;

export type ValidZipQuery = { __typename?: 'RootQueryType'; validZip?: boolean | null | undefined };

export type ResetPasswordAndSendEmailMutationVariables = Exact<{
  access_token?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResetPasswordAndSendEmailMutation = {
  __typename?: 'RootMutationType';
  resetPasswordAndSendEmail?: any | null | undefined;
};

export type CheckExistingAccountByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type CheckExistingAccountByEmailQuery = {
  __typename?: 'RootQueryType';
  checkExistingAccountByEmail?: boolean | null | undefined;
};

export type CheckFlowStatusQueryVariables = Exact<{
  guid?: InputMaybe<Scalars['String']['input']>;
}>;

export type CheckFlowStatusQuery = {
  __typename?: 'RootQueryType';
  checkFlowStatus?:
    | {
        __typename?: 'flowStatus';
        inFlow?: boolean | null | undefined;
        guid?: string | null | undefined;
        url?: string | null | undefined;
        dealId?: string | null | undefined;
        customerId?: string | null | undefined;
        car?:
          | {
              __typename?: 'car';
              id?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              year?: string | null | undefined;
              mileage?: number | null | undefined;
              vin?: string | null | undefined;
              vehicle_type?: string | null | undefined;
              color?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BankLogoQueryVariables = Exact<{
  bank_name: Scalars['String']['input'];
}>;

export type BankLogoQuery = { __typename?: 'RootQueryType'; bankLogo?: string | null | undefined };

export type GetExternalAutoStructureBankLogosQueryVariables = Exact<{ [key: string]: never }>;

export type GetExternalAutoStructureBankLogosQuery = {
  __typename?: 'RootQueryType';
  getExternalAutoStructureBankLogos?:
    | Array<
        | {
            __typename?: 'AutoStructureBankLogo';
            bank_name?: string | null | undefined;
            logo_url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CurrentConsentAgreementTextQueryVariables = Exact<{
  agreementType: ConsentAgreementTypeEnum;
  version?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CurrentConsentAgreementTextQuery = {
  __typename?: 'RootQueryType';
  currentConsentAgreementText?:
    | {
        __typename?: 'consentAgreement';
        id?: string | null | undefined;
        text?: string | null | undefined;
      }
    | null
    | undefined;
};

export type SaveConsentMutationVariables = Exact<{
  type: ConsentNameEnumType;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SaveConsentMutation = {
  __typename?: 'RootMutationType';
  saveConsent: {
    __typename?: 'SaveConsent';
    consent?: { __typename?: 'consent'; id?: string | null | undefined } | null | undefined;
  };
};

export type GetApprovedCreditDecisionsByDealIdQueryVariables = Exact<{
  dealId: Scalars['ID']['input'];
}>;

export type GetApprovedCreditDecisionsByDealIdQuery = {
  __typename?: 'RootQueryType';
  getApprovedCreditDecisionsByDealId?:
    | Array<
        | {
            __typename?: 'creditDecision';
            id?: string | null | undefined;
            bank?:
              | {
                  __typename?: 'bank';
                  name?: string | null | undefined;
                  logo_url?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ExternalCustomerUpdateMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  customer?: InputMaybe<ExternalCustomerInput>;
}>;

export type ExternalCustomerUpdateMutation = {
  __typename?: 'RootMutationType';
  externalCustomerUpdate?:
    | { __typename?: 'customer'; id?: string | null | undefined }
    | null
    | undefined;
};

export type GetByAuth0IdQueryVariables = Exact<{ [key: string]: never }>;

export type GetByAuth0IdQuery = {
  __typename?: 'RootQueryType';
  getByAuth0Id?:
    | {
        __typename?: 'customer';
        first_name?: string | null | undefined;
        last_name?: string | null | undefined;
        full_name?: string | null | undefined;
        email?: string | null | undefined;
      }
    | null
    | undefined;
};

export type LinkAccountMutationVariables = Exact<{
  ssn_last_4?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LinkAccountMutation = {
  __typename?: 'RootMutationType';
  linkAccount?: any | null | undefined;
};

export type OptOutMutationVariables = Exact<{
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type OptOutMutation = { __typename?: 'RootMutationType'; optOut?: any | null | undefined };

export type VerifyAccountMutationVariables = Exact<{
  ssn_last_4: Scalars['String']['input'];
  deal_id: Scalars['ID']['input'];
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type VerifyAccountMutation = {
  __typename?: 'RootMutationType';
  verifyAccount?: any | null | undefined;
};

export type LinkUnlinkedCustomersMutationVariables = Exact<{ [key: string]: never }>;

export type LinkUnlinkedCustomersMutation = {
  __typename?: 'RootMutationType';
  linkUnlinkedCustomers?: boolean | null | undefined;
};

export type ExternalUpdateDealMutationVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']['input']>;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
  data: ExternalDealInputType;
}>;

export type ExternalUpdateDealMutation = {
  __typename?: 'RootMutationType';
  externalUpdateDeal?: { __typename?: 'deal'; id?: string | null | undefined } | null | undefined;
};

export type DealAdvisorsQueryVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DealAdvisorsQuery = {
  __typename?: 'RootQueryType';
  dealAdvisors?:
    | {
        __typename?: 'deal';
        setter?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
        closer?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
        funding_clerk?:
          | { __typename?: 'user'; name?: string | null | undefined }
          | null
          | undefined;
        title_clerk?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export type TrackComEventMutationVariables = Exact<{
  dealId: Scalars['ID']['input'];
  approvalOnDealProcessing?: InputMaybe<Scalars['Boolean']['input']>;
  reachedDocumentsScreen?: InputMaybe<Scalars['Boolean']['input']>;
  finishedDocumentsScreen?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type TrackComEventMutation = {
  __typename?: 'RootMutationType';
  trackComEvent?: any | null | undefined;
};

export type TotalCompletedDealsQueryVariables = Exact<{ [key: string]: never }>;

export type TotalCompletedDealsQuery = {
  __typename?: 'RootQueryType';
  totalCompletedDeals?: number | null | undefined;
};

export type GetUnlinkedDealsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUnlinkedDealsQuery = {
  __typename?: 'RootQueryType';
  getUnlinkedDeals?:
    | Array<
        | {
            __typename?: 'deal';
            id?: string | null | undefined;
            isCobuyer?: boolean | null | undefined;
            type?: DealType | null | undefined;
            car?:
              | {
                  __typename?: 'car';
                  year?: string | null | undefined;
                  make?: string | null | undefined;
                  model?: string | null | undefined;
                  vin?: string | null | undefined;
                  vehicle_type?: string | null | undefined;
                  color?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type DocumentsInfoUpdateMutationVariables = Exact<{
  data: DocumentsInfoUpdateInput;
}>;

export type DocumentsInfoUpdateMutation = {
  __typename?: 'RootMutationType';
  documentsInfoUpdate?: any | null | undefined;
};

export type CompletedPayoffRequestsJobMutationVariables = Exact<{ [key: string]: never }>;

export type CompletedPayoffRequestsJobMutation = {
  __typename?: 'RootMutationType';
  completedPayoffRequestsJob?: any | null | undefined;
};

export type CreateDownPaymentLinkMutationVariables = Exact<{ [key: string]: never }>;

export type CreateDownPaymentLinkMutation = {
  __typename?: 'RootMutationType';
  createDownPaymentLink?: string | null | undefined;
};

export type ExecuteDownPaymentMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  bank: Scalars['String']['input'];
  plaidAccountId: Scalars['String']['input'];
  plaidToken: Scalars['String']['input'];
  fraudSessionId: Scalars['String']['input'];
  idempotencyId: Scalars['String']['input'];
  dealId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ExecuteDownPaymentMutation = {
  __typename?: 'RootMutationType';
  executeDownPayment?:
    | {
        __typename?: 'finixTransfer';
        id?: string | null | undefined;
        amount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type ExecuteCardDownPaymentMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  fraudSessionId: Scalars['String']['input'];
  idempotencyId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  dealId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethod: PaymentMethod;
}>;

export type ExecuteCardDownPaymentMutation = {
  __typename?: 'RootMutationType';
  executeCardDownPayment?:
    | {
        __typename?: 'finixTransfer';
        id?: string | null | undefined;
        amount?: number | null | undefined;
        cardBrand?: string | null | undefined;
        cardType?: CardType | null | undefined;
        failureCode?: string | null | undefined;
        maskedNumber?: string | null | undefined;
        state?: PaymentState | null | undefined;
      }
    | null
    | undefined;
};

export type GetDownPaymentAmountByMethodQueryVariables = Exact<{
  dealId: Scalars['ID']['input'];
  paymentType: PaymentInstrumentType;
}>;

export type GetDownPaymentAmountByMethodQuery = {
  __typename?: 'RootQueryType';
  getDownPaymentAmountByMethod?: number | null | undefined;
};

export type CreateFeedbackMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
  improvements: Scalars['String']['input'];
  deal_id: Scalars['ID']['input'];
}>;

export type CreateFeedbackMutation = {
  __typename?: 'RootMutationType';
  createFeedback?: any | null | undefined;
};

export type TemporaryInfoUpsertMutationVariables = Exact<{
  info?: InputMaybe<TemporaryInfoInput>;
}>;

export type TemporaryInfoUpsertMutation = {
  __typename?: 'RootMutationType';
  temporaryInfoUpsert?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        status?: TemporaryInfoStatusEnumType | null | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          license_plate_number?: string | null | undefined;
          license_plate_state?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          color?: string | null | undefined;
          mileage?: number | null | undefined;
          vehicle_payoff?: number | null | undefined;
          phone_number?: string | null | undefined;
          email?: string | null | undefined;
          current_accordion_panel?: string | null | undefined;
          lienholder?: string | null | undefined;
          lienholder_slug?: string | null | undefined;
          other_lienholder?: string | null | undefined;
          lienholderLogo?: string | null | undefined;
          first_name?: string | null | undefined;
          last_name?: string | null | undefined;
          address_line?: string | null | undefined;
          address_line_2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          county?: string | null | undefined;
          zip?: string | null | undefined;
          taxes?: any | null | undefined;
          vehicle_registration_fee?: number | null | undefined;
          doc_fee?: number | null | undefined;
          previous_address_line?: string | null | undefined;
          previous_address_line_2?: string | null | undefined;
          previous_city?: string | null | undefined;
          previous_state?: string | null | undefined;
          previous_zip?: string | null | undefined;
          previous_county?: string | null | undefined;
          scheduled_call_datetime?: any | null | undefined;
          scheduled_call_datetime_tz?: string | null | undefined;
          primary_residence_type?: string | null | undefined;
          primary_residence_years?: number | null | undefined;
          primary_residence_months?: number | null | undefined;
          primary_residence_monthly_payment?: number | null | undefined;
          has_same_address_as_cobuyer?: boolean | null | undefined;
          secondary_residence_years?: number | null | undefined;
          secondary_residence_months?: number | null | undefined;
          dob?: any | null | undefined;
          employment_status?: string | null | undefined;
          employer_name?: string | null | undefined;
          job_title?: string | null | undefined;
          employer_phone_number?: string | null | undefined;
          years_at_job?: number | null | undefined;
          months_at_job?: number | null | undefined;
          salary?: number | null | undefined;
          pay_frequency?: string | null | undefined;
          previous_employer_name?: string | null | undefined;
          previous_job_title?: string | null | undefined;
          previous_employer_phone_number?: string | null | undefined;
          previous_years_at_job?: number | null | undefined;
          previous_months_at_job?: number | null | undefined;
          retail_book_value?: number | null | undefined;
          kbb_retail_mileage_adjustment?: number | null | undefined;
          kbb_retail_option_adjustment?: number | null | undefined;
          book_value?: number | null | undefined;
          kbb_lending_mileage_adjustment?: number | null | undefined;
          kbb_lending_option_adjustment?: number | null | undefined;
          kbb_valuation_date?: string | null | undefined;
          kbb_vehicle_name?: string | null | undefined;
          kbb_trim_name?: string | null | undefined;
          kbb_selected_options?: any | null | undefined;
          good_through_date?: any | null | undefined;
          credit_score?: number | null | undefined;
          term?: number | null | undefined;
          rate?: number | null | undefined;
          down_payment?: number | null | undefined;
          taxes_fees?: number | null | undefined;
          payment_estimate_low?: number | null | undefined;
          payment_estimate_high?: number | null | undefined;
          payoff_includes_sales_tax?: boolean | null | undefined;
          lh_account_number?: string | null | undefined;
          include_cobuyer?: boolean | null | undefined;
          cobuyer_first_name?: string | null | undefined;
          cobuyer_last_name?: string | null | undefined;
          cobuyer_employment_status?: string | null | undefined;
          cobuyer_employer_name?: string | null | undefined;
          cobuyer_employer_phone_number?: string | null | undefined;
          cobuyer_job_title?: string | null | undefined;
          cobuyer_years_at_job?: number | null | undefined;
          cobuyer_months_at_job?: number | null | undefined;
          cobuyer_salary?: number | null | undefined;
          cobuyer_pay_frequency?: string | null | undefined;
          cobuyer_previous_employer_name?: string | null | undefined;
          cobuyer_previous_employer_phone_number?: string | null | undefined;
          cobuyer_previous_job_title?: string | null | undefined;
          cobuyer_previous_years_at_job?: number | null | undefined;
          cobuyer_previous_months_at_job?: number | null | undefined;
          cobuyer_phone_number?: string | null | undefined;
          cobuyer_email?: string | null | undefined;
          cobuyer_dob?: any | null | undefined;
          cobuyer_address_line?: string | null | undefined;
          cobuyer_address_line_2?: string | null | undefined;
          cobuyer_city?: string | null | undefined;
          cobuyer_state?: string | null | undefined;
          cobuyer_zip?: string | null | undefined;
          cobuyer_county?: string | null | undefined;
          cobuyer_primary_residence_monthly_payment?: number | null | undefined;
          cobuyer_primary_residence_type?: string | null | undefined;
          cobuyer_primary_residence_years?: number | null | undefined;
          cobuyer_primary_residence_months?: number | null | undefined;
          cobuyer_previous_address_line?: string | null | undefined;
          cobuyer_previous_address_line_2?: string | null | undefined;
          cobuyer_previous_city?: string | null | undefined;
          cobuyer_previous_state?: string | null | undefined;
          cobuyer_previous_zip?: string | null | undefined;
          cobuyer_previous_county?: string | null | undefined;
          cobuyer_secondary_residence_years?: number | null | undefined;
          cobuyer_secondary_residence_months?: number | null | undefined;
          cobuyer_relation_to_buyer?: RelationToBuyerEnum | null | undefined;
          cobuyer_marital_status?: MaritalStatusEnum | null | undefined;
          old_lease_payment?: number | null | undefined;
          vehicle_offer?: number | null | undefined;
          estimated_dealer_fees?: number | null | undefined;
          condition_report?: any | null | undefined;
          mmr_value?: number | null | undefined;
          marital_status?: MaritalStatusEnum | null | undefined;
          last_url?: string | null | undefined;
          manual_entry?: boolean | null | undefined;
          uploaded_files?: any | null | undefined;
          prequalification_success?: boolean | null | undefined;
          is_hard_pull?: boolean | null | undefined;
          prequalification_errors?:
            | Array<LeExperianErrorCodeEnum | null | undefined>
            | null
            | undefined;
          cobuyer_prequalification_errors?:
            | Array<LeExperianErrorCodeEnum | null | undefined>
            | null
            | undefined;
          shopping_cart?:
            | {
                __typename?: 'temporaryShoppingCartData';
                buyout?: number | null | undefined;
                credit_score?: number | null | undefined;
                down_payment?: number | null | undefined;
                loan_term?: number | null | undefined;
                payment_estimate_low?: number | null | undefined;
                payment_estimate_high?: number | null | undefined;
                estimated_fees?: number | null | undefined;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type CreateTemporaryInfoMutationVariables = Exact<{
  vin?: InputMaybe<Scalars['String']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  extra_input?: InputMaybe<ExtraTempInfoInputType>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  ignore_existing_deal?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateTemporaryInfoMutation = {
  __typename?: 'RootMutationType';
  createTemporaryInfo?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          license_plate_number?: string | null | undefined;
          license_plate_state?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          device_type?: string | null | undefined;
          zip?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type CreateManualEntryTemporaryInfoMutationVariables = Exact<{
  license_plate?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  extra_input?: InputMaybe<ExtraTempInfoInputType>;
  query_params?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateManualEntryTemporaryInfoMutation = {
  __typename?: 'RootMutationType';
  createManualEntryTemporaryInfo?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          license_plate_number?: string | null | undefined;
          license_plate_state?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          device_type?: string | null | undefined;
          zip?: string | null | undefined;
          kbb_trim_name?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type CreateAffiliateTemporaryInfoMutationVariables = Exact<{
  device_type?: InputMaybe<Scalars['String']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateAffiliateTemporaryInfoMutation = {
  __typename?: 'RootMutationType';
  createAffiliateTemporaryInfo?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        data: {
          __typename?: 'temporaryData';
          first_name?: string | null | undefined;
          last_name?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type DecodeAffiliateCarDetailsQueryVariables = Exact<{
  vin?: InputMaybe<Scalars['String']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
}>;

export type DecodeAffiliateCarDetailsQuery = {
  __typename?: 'RootQueryType';
  decodeAffiliateCarDetails?:
    | {
        __typename?: 'temporaryData';
        license_plate_number?: string | null | undefined;
        license_plate_state?: string | null | undefined;
        vin?: string | null | undefined;
        year?: string | null | undefined;
        make?: string | null | undefined;
        model?: string | null | undefined;
        fuel_type?: string | null | undefined;
        vehicle_type?: string | null | undefined;
        kbb_trim_name?: string | null | undefined;
      }
    | null
    | undefined;
};

export type TemporaryInfoUpsertAndImportMutationVariables = Exact<{
  info?: InputMaybe<TemporaryInfoInput>;
  transformType: TransformType;
}>;

export type TemporaryInfoUpsertAndImportMutation = {
  __typename?: 'RootMutationType';
  temporaryInfoUpsertAndImport?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        deal_id?: string | null | undefined;
        data: {
          __typename?: 'temporaryData';
          phone_number?: string | null | undefined;
          email?: string | null | undefined;
          scheduled_call_datetime?: any | null | undefined;
          scheduled_call_datetime_tz?: string | null | undefined;
          first_name?: string | null | undefined;
          lienholder?: string | null | undefined;
          other_lienholder?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type TemporaryInfoImportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  transformType: TransformType;
  importType?: InputMaybe<ImportType>;
}>;

export type TemporaryInfoImportMutation = {
  __typename?: 'RootMutationType';
  temporaryInfoImport?:
    | {
        __typename?: 'deal';
        id?: string | null | undefined;
        customer?: { __typename?: 'customer'; id?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export type UnlinkTemporaryInfoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UnlinkTemporaryInfoMutation = {
  __typename?: 'RootMutationType';
  unlinkTemporaryInfo?:
    | { __typename?: 'temporaryInfo'; id?: string | null | undefined }
    | null
    | undefined;
};

export type TemporaryInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TemporaryInfoQuery = {
  __typename?: 'RootQueryType';
  temporaryInfo?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          color?: string | null | undefined;
          mileage?: number | null | undefined;
          vehicle_payoff?: number | null | undefined;
          user_entered_total_payoff?: number | null | undefined;
          phone_number?: string | null | undefined;
          email?: string | null | undefined;
          current_accordion_panel?: string | null | undefined;
          lienholder?: string | null | undefined;
          lienholderLogo?: string | null | undefined;
          lienholder_slug?: string | null | undefined;
          other_lienholder?: string | null | undefined;
          first_name?: string | null | undefined;
          middle_name?: string | null | undefined;
          last_name?: string | null | undefined;
          address_line?: string | null | undefined;
          address_line_2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          county?: string | null | undefined;
          zip?: string | null | undefined;
          taxes?: any | null | undefined;
          vehicle_registration_fee?: number | null | undefined;
          doc_fee?: number | null | undefined;
          has_full_ssn?: boolean | null | undefined;
          previous_address_line?: string | null | undefined;
          previous_address_line_2?: string | null | undefined;
          previous_city?: string | null | undefined;
          previous_state?: string | null | undefined;
          previous_zip?: string | null | undefined;
          previous_county?: string | null | undefined;
          scheduled_call_datetime?: any | null | undefined;
          scheduled_call_datetime_tz?: string | null | undefined;
          primary_residence_type?: string | null | undefined;
          primary_residence_years?: number | null | undefined;
          primary_residence_months?: number | null | undefined;
          primary_residence_monthly_payment?: number | null | undefined;
          has_same_address_as_cobuyer?: boolean | null | undefined;
          secondary_residence_years?: number | null | undefined;
          secondary_residence_months?: number | null | undefined;
          dob?: any | null | undefined;
          employment_status?: string | null | undefined;
          employer_name?: string | null | undefined;
          job_title?: string | null | undefined;
          employer_phone_number?: string | null | undefined;
          years_at_job?: number | null | undefined;
          months_at_job?: number | null | undefined;
          salary?: number | null | undefined;
          pay_frequency?: string | null | undefined;
          previous_employer_name?: string | null | undefined;
          previous_job_title?: string | null | undefined;
          previous_employer_phone_number?: string | null | undefined;
          previous_years_at_job?: number | null | undefined;
          previous_months_at_job?: number | null | undefined;
          retail_book_value?: number | null | undefined;
          kbb_retail_mileage_adjustment?: number | null | undefined;
          kbb_retail_option_adjustment?: number | null | undefined;
          book_value?: number | null | undefined;
          kbb_lending_mileage_adjustment?: number | null | undefined;
          kbb_lending_option_adjustment?: number | null | undefined;
          kbb_valuation_date?: string | null | undefined;
          kbb_vehicle_name?: string | null | undefined;
          kbb_vehicle_id?: number | null | undefined;
          kbb_all_options?: any | null | undefined;
          kbb_trim_name?: string | null | undefined;
          kbb_selected_options?: any | null | undefined;
          good_through_date?: any | null | undefined;
          credit_score?: number | null | undefined;
          term?: number | null | undefined;
          rate?: number | null | undefined;
          down_payment?: number | null | undefined;
          taxes_fees?: number | null | undefined;
          payment_estimate_low?: number | null | undefined;
          payment_estimate_high?: number | null | undefined;
          payoff_includes_sales_tax?: boolean | null | undefined;
          lh_account_number?: string | null | undefined;
          include_cobuyer?: boolean | null | undefined;
          cobuyer_first_name?: string | null | undefined;
          cobuyer_last_name?: string | null | undefined;
          cobuyer_employment_status?: string | null | undefined;
          cobuyer_employer_name?: string | null | undefined;
          cobuyer_employer_phone_number?: string | null | undefined;
          cobuyer_job_title?: string | null | undefined;
          cobuyer_years_at_job?: number | null | undefined;
          cobuyer_months_at_job?: number | null | undefined;
          cobuyer_salary?: number | null | undefined;
          cobuyer_pay_frequency?: string | null | undefined;
          cobuyer_previous_employer_name?: string | null | undefined;
          cobuyer_previous_employer_phone_number?: string | null | undefined;
          cobuyer_previous_job_title?: string | null | undefined;
          cobuyer_previous_years_at_job?: number | null | undefined;
          cobuyer_previous_months_at_job?: number | null | undefined;
          cobuyer_phone_number?: string | null | undefined;
          cobuyer_email?: string | null | undefined;
          cobuyer_dob?: any | null | undefined;
          cobuyer_address_line?: string | null | undefined;
          cobuyer_address_line_2?: string | null | undefined;
          cobuyer_city?: string | null | undefined;
          cobuyer_state?: string | null | undefined;
          cobuyer_zip?: string | null | undefined;
          cobuyer_county?: string | null | undefined;
          cobuyer_primary_residence_monthly_payment?: number | null | undefined;
          cobuyer_primary_residence_type?: string | null | undefined;
          cobuyer_primary_residence_years?: number | null | undefined;
          cobuyer_primary_residence_months?: number | null | undefined;
          cobuyer_previous_address_line?: string | null | undefined;
          cobuyer_previous_address_line_2?: string | null | undefined;
          cobuyer_previous_city?: string | null | undefined;
          cobuyer_previous_state?: string | null | undefined;
          cobuyer_previous_zip?: string | null | undefined;
          cobuyer_previous_county?: string | null | undefined;
          cobuyer_secondary_residence_years?: number | null | undefined;
          cobuyer_secondary_residence_months?: number | null | undefined;
          cobuyer_relation_to_buyer?: RelationToBuyerEnum | null | undefined;
          cobuyer_marital_status?: MaritalStatusEnum | null | undefined;
          old_lease_payment?: number | null | undefined;
          vehicle_offer?: number | null | undefined;
          estimated_dealer_fees?: number | null | undefined;
          condition_report?: any | null | undefined;
          mmr_value?: number | null | undefined;
          license_plate_number?: string | null | undefined;
          license_plate_state?: string | null | undefined;
          marital_status?: MaritalStatusEnum | null | undefined;
          sales_tax_from_payoff?: number | null | undefined;
          last_url?: string | null | undefined;
          query_params?: string | null | undefined;
          manual_entry?: boolean | null | undefined;
          uploaded_files?: any | null | undefined;
          prequalification_success?: boolean | null | undefined;
          is_hard_pull?: boolean | null | undefined;
          prequalification_errors?:
            | Array<LeExperianErrorCodeEnum | null | undefined>
            | null
            | undefined;
          cobuyer_prequalification_errors?:
            | Array<LeExperianErrorCodeEnum | null | undefined>
            | null
            | undefined;
          prequal_id?: string | null | undefined;
          shopping_cart?:
            | {
                __typename?: 'temporaryShoppingCartData';
                buyout?: number | null | undefined;
                credit_score?: number | null | undefined;
                down_payment?: number | null | undefined;
                loan_term?: number | null | undefined;
                payment_estimate_low?: number | null | undefined;
                payment_estimate_high?: number | null | undefined;
                estimated_fees?: number | null | undefined;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type KbbVinQueryVariables = Exact<{
  vin: Scalars['String']['input'];
}>;

export type KbbVinQuery = {
  __typename?: 'RootQueryType';
  kbbVin?:
    | {
        __typename?: 'kbbVinObjectResult';
        warning?: boolean | null | undefined;
        vinResults?:
          | Array<
              | {
                  __typename?: 'kbbVinResult';
                  vehicleId?: number | null | undefined;
                  modelName?: string | null | undefined;
                  trimName?: string | null | undefined;
                  makeName?: string | null | undefined;
                  yearId?: string | null | undefined;
                  vehicleName?: string | null | undefined;
                  vehicleOptions?:
                    | Array<
                        | {
                            __typename?: 'kbbOption';
                            categoryGroup?: string | null | undefined;
                            hasRelationships?: boolean | null | undefined;
                            isConfigurable?: boolean | null | undefined;
                            isConsumer?: boolean | null | undefined;
                            isTypical?: boolean | null | undefined;
                            isVinDecoded?: boolean | null | undefined;
                            optionName?: string | null | undefined;
                            optionType?: string | null | undefined;
                            sortOrder?: number | null | undefined;
                            vehicleId?: number | null | undefined;
                            vehicleOptionId?: number | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type KbbValuesQueryVariables = Exact<{
  data: KbbValuesDataType;
}>;

export type KbbValuesQuery = {
  __typename?: 'RootQueryType';
  kbbValues?:
    | {
        __typename?: 'kbbValuesResult';
        retail?: number | null | undefined;
        retailMileageAdjustment?: number | null | undefined;
        retailOptionAdjustment?: number | null | undefined;
        retailOptionPrices?: any | null | undefined;
        lending?: number | null | undefined;
        lendingMileageAdjustment?: number | null | undefined;
        lendingOptionAdjustment?: number | null | undefined;
        lendingOptionPrices?: any | null | undefined;
        valuationDate?: string | null | undefined;
        kbbSelectedOptions?:
          | Array<
              | {
                  __typename?: 'kbbSelectedOptionsType';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                  type?: string | null | undefined;
                  removed?: boolean | null | undefined;
                  lendingOptionPrice?: number | null | undefined;
                  retailOptionPrice?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CalculateOptionsQueryVariables = Exact<{
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>> | InputMaybe<KbbOptionInput>>;
  startingVehicleOptions?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>
  >;
  configToModify?: InputMaybe<Array<InputMaybe<KbbConfigType>> | InputMaybe<KbbConfigType>>;
  vin: Scalars['String']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
}>;

export type CalculateOptionsQuery = {
  __typename?: 'RootQueryType';
  calculateOptions?:
    | Array<
        | {
            __typename?: 'kbbOption';
            vehicleOptionId?: number | null | undefined;
            optionName?: string | null | undefined;
            optionType?: string | null | undefined;
            categoryGroup?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CreateExternalTempInfoUploadUrlMutationVariables = Exact<{
  guid: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  fileDescription: Scalars['String']['input'];
}>;

export type CreateExternalTempInfoUploadUrlMutation = {
  __typename?: 'RootMutationType';
  createExternalTempInfoUploadUrl?:
    | { __typename?: 'externalTempInfoUploadUrl'; url?: string | null | undefined }
    | null
    | undefined;
};

export type CreateExternalDealUploadUrlMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
  fileDescription: Scalars['String']['input'];
  dealId: Scalars['ID']['input'];
}>;

export type CreateExternalDealUploadUrlMutation = {
  __typename?: 'RootMutationType';
  createExternalDealUploadUrl?:
    | {
        __typename?: 'externalDealUploadUrl';
        key?: string | null | undefined;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteComFlowFileMutationVariables = Exact<{
  key: Scalars['String']['input'];
}>;

export type DeleteComFlowFileMutation = {
  __typename?: 'RootMutationType';
  deleteComFlowFile?: any | null | undefined;
};

export type CreateFetchUrlsFromComQueryVariables = Exact<{
  keys: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type CreateFetchUrlsFromComQuery = {
  __typename?: 'RootQueryType';
  createFetchUrlsFromCom: Array<string>;
};

export type MediaDeleteFromComMutationVariables = Exact<{
  dealId: Scalars['ID']['input'];
  filePath: Scalars['String']['input'];
}>;

export type MediaDeleteFromComMutation = {
  __typename?: 'RootMutationType';
  mediaDeleteFromCom?: boolean | null | undefined;
};

export type MediaInsertComMutationVariables = Exact<{
  key: Scalars['String']['input'];
  type: Scalars['String']['input'];
  dealId: Scalars['ID']['input'];
}>;

export type MediaInsertComMutation = {
  __typename?: 'RootMutationType';
  mediaInsertCom?:
    | {
        __typename?: 'dealMedia';
        id?: string | null | undefined;
        deal_id?: string | null | undefined;
        key?: string | null | undefined;
        signed_url?: string | null | undefined;
        type?: DealMediaTypeEnum | null | undefined;
      }
    | null
    | undefined;
};

export type MediaFromComQueryVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type MediaFromComQuery = {
  __typename?: 'RootQueryType';
  mediaFromCom?:
    | Array<{
        __typename?: 'dealMedia';
        id?: string | null | undefined;
        deal_id?: string | null | undefined;
        key?: string | null | undefined;
        signed_url?: string | null | undefined;
        type?: DealMediaTypeEnum | null | undefined;
        uploaded_by_customer?: boolean | null | undefined;
      }>
    | null
    | undefined;
};

export type MediaUpdateComMutationVariables = Exact<{
  dealId: Scalars['ID']['input'];
  oldKey: Scalars['String']['input'];
  newFileName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;

export type MediaUpdateComMutation = {
  __typename?: 'RootMutationType';
  mediaUpdateCom?:
    | {
        __typename?: 'dealMedia';
        id?: string | null | undefined;
        deal_id?: string | null | undefined;
        key?: string | null | undefined;
        signed_url?: string | null | undefined;
        type?: DealMediaTypeEnum | null | undefined;
      }
    | null
    | undefined;
};

export type GetOperatingHoursAndTimeSlotsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOperatingHoursAndTimeSlotsQuery = {
  __typename?: 'RootQueryType';
  getOperatingHoursAndTimeSlots?:
    | {
        __typename?: 'operatingHoursTimeSlots';
        isOpen?: boolean | null | undefined;
        utcTimeSlots?: Array<any | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export type CreateOptEventMutationVariables = Exact<{
  event: OptEventInput;
  token?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateOptEventMutation = {
  __typename?: 'RootMutationType';
  createOptEvent?: { __typename?: 'optEvent'; id?: string | null | undefined } | null | undefined;
};

export type PaymentEstimateQueryVariables = Exact<{
  data?: InputMaybe<PaymentEstimateInput>;
}>;

export type PaymentEstimateQuery = {
  __typename?: 'RootQueryType';
  paymentEstimate?:
    | {
        __typename?: 'paymentEstimate';
        paymentEstimateLow: number;
        paymentEstimateHigh: number;
        estimatedTaxesAndFees: number;
        totalEstimatedLoan: number;
        estimatedAnnualPercentageRate: number;
        feeDetails: {
          __typename?: 'paymentEstimateFeeDetails';
          baseTaxAmount: number;
          warrantyTaxAmount: number;
          docFeeTaxAmount: number;
          docFee: number;
          titleFee: number;
          registrationFee: number;
        };
      }
    | null
    | undefined;
};

export type SendPaymentEstimateEmailAndTextMutationVariables = Exact<{
  data: PaymentEstimateInput;
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}>;

export type SendPaymentEstimateEmailAndTextMutation = {
  __typename?: 'RootMutationType';
  sendPaymentEstimateEmailAndText?: boolean | null | undefined;
};

export type PayoffRequestQueryVariables = Exact<{ [key: string]: never }>;

export type PayoffRequestQuery = {
  __typename?: 'RootQueryType';
  payoffRequest?:
    | {
        __typename?: 'payoffRequest';
        temporary_info_id?: string | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        good_through_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type CreatePayoffRequestMutationVariables = Exact<{ [key: string]: never }>;

export type CreatePayoffRequestMutation = {
  __typename?: 'RootMutationType';
  createPayoffRequest?:
    | {
        __typename?: 'payoffRequest';
        temporary_info_id?: string | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        good_through_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type CancelPayoffRequestMutationVariables = Exact<{ [key: string]: never }>;

export type CancelPayoffRequestMutation = {
  __typename?: 'RootMutationType';
  cancelPayoffRequest?:
    | {
        __typename?: 'payoffRequest';
        temporary_info_id?: string | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        good_through_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type PayoffRequestUpdateMutationVariables = Exact<{
  payoffRequest: PayoffRequestInput;
}>;

export type PayoffRequestUpdateMutation = {
  __typename?: 'RootMutationType';
  payoffRequestUpdate?:
    | {
        __typename?: 'payoffRequest';
        temporary_info_id?: string | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        good_through_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type OnPayoffRequestUpdateSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnPayoffRequestUpdateSubscription = {
  __typename?: 'RootSubscriptionType';
  onPayoffRequestUpdate?:
    | {
        __typename?: 'payoffRequestPopulated';
        temporary_info_id?: string | null | undefined;
        status?: PayoffRequestStatus | null | undefined;
        vehicle_payoff?: number | null | undefined;
        sales_tax?: number | null | undefined;
        completed_date?: any | null | undefined;
        good_through_date?: any | null | undefined;
      }
    | null
    | undefined;
};

export type GetPodHoursQueryVariables = Exact<{ [key: string]: never }>;

export type GetPodHoursQuery = {
  __typename?: 'RootQueryType';
  podHours?:
    | Array<
        | {
            __typename?: 'podHours';
            id?: number | null | undefined;
            label?: string | null | undefined;
            day?: string | null | undefined;
            beginTime?: string | null | undefined;
            endTime?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ExecuteExternalSoftPullMutationVariables = Exact<{
  paymentEstimateInput: PaymentEstimateInput;
  devCreditScore?: InputMaybe<Scalars['Int']['input']>;
  devOverride?: InputMaybe<Scalars['Boolean']['input']>;
  isHardPull?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ExecuteExternalSoftPullMutation = {
  __typename?: 'RootMutationType';
  executeExternalSoftPull?: boolean | null | undefined;
};

export type MockDealProcessingMutationVariables = Exact<{
  deal_id: Scalars['ID']['input'];
  is_conditioned: Scalars['Boolean']['input'];
  has_gap: Scalars['Boolean']['input'];
  has_vsc: Scalars['Boolean']['input'];
}>;

export type MockDealProcessingMutation = {
  __typename?: 'RootMutationType';
  mockDealProcessing?: any | null | undefined;
};

export type GetVisibleReviewsQueryVariables = Exact<{ [key: string]: never }>;

export type GetVisibleReviewsQuery = {
  __typename?: 'RootQueryType';
  visibleReviews?:
    | Array<
        | {
            __typename?: 'review';
            id?: string | null | undefined;
            customer_name?: string | null | undefined;
            review?: string | null | undefined;
            created_at?: any | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetR1CustomerCopyQueryVariables = Exact<{
  dealJacketID: Scalars['String']['input'];
  includeExtraPDFBinaries: Scalars['Boolean']['input'];
}>;

export type GetR1CustomerCopyQuery = {
  __typename?: 'RootQueryType';
  getR1CustomerCopy?:
    | Array<
        | {
            __typename?: 'DealDocument';
            dealJacketId?: string | null | undefined;
            documentId?: string | null | undefined;
            documentName?: string | null | undefined;
            displayName?: string | null | undefined;
            documentType?: string | null | undefined;
            mimeType?: string | null | undefined;
            wetSignature?: boolean | null | undefined;
            docStatCode?: string | null | undefined;
            data?: string | null | undefined;
            signAttributes?:
              | Array<
                  | {
                      __typename?: 'DealDocumentSignAttribute';
                      signRequired?: boolean | null | undefined;
                      initialsType?: boolean | null | undefined;
                      signCaptureTms?: string | null | undefined;
                      actorName?: string | null | undefined;
                      actorType?: ActorType | null | undefined;
                      dateFieldName?: string | null | undefined;
                      signFieldName?: string | null | undefined;
                      signPadText?: string | null | undefined;
                      dateCoordinates?:
                        | {
                            __typename?: 'Coordinate';
                            width?: number | null | undefined;
                            height?: number | null | undefined;
                            pageNumber?: number | null | undefined;
                            xPosition?: number | null | undefined;
                            yPosition?: number | null | undefined;
                          }
                        | null
                        | undefined;
                      signatureCoordinates?:
                        | {
                            __typename?: 'Coordinate';
                            height?: number | null | undefined;
                            pageNumber?: number | null | undefined;
                            width?: number | null | undefined;
                            xPosition?: number | null | undefined;
                            yPosition?: number | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetR1DealDocumentQueryVariables = Exact<{
  dealJacketID: Scalars['String']['input'];
  documentID: Scalars['String']['input'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetR1DealDocumentQuery = {
  __typename?: 'RootQueryType';
  getR1DealDocument?:
    | {
        __typename?: 'DealDocument';
        dealJacketId?: string | null | undefined;
        documentId?: string | null | undefined;
        documentName?: string | null | undefined;
        displayName?: string | null | undefined;
        documentType?: string | null | undefined;
        mimeType?: string | null | undefined;
        wetSignature?: boolean | null | undefined;
        docStatCode?: string | null | undefined;
        data?: string | null | undefined;
        signAttributes?:
          | Array<
              | {
                  __typename?: 'DealDocumentSignAttribute';
                  signRequired?: boolean | null | undefined;
                  initialsType?: boolean | null | undefined;
                  signCaptureTms?: string | null | undefined;
                  actorName?: string | null | undefined;
                  actorType?: ActorType | null | undefined;
                  dateFieldName?: string | null | undefined;
                  signFieldName?: string | null | undefined;
                  signPadText?: string | null | undefined;
                  dateCoordinates?:
                    | {
                        __typename?: 'Coordinate';
                        width?: number | null | undefined;
                        height?: number | null | undefined;
                        pageNumber?: number | null | undefined;
                        xPosition?: number | null | undefined;
                        yPosition?: number | null | undefined;
                      }
                    | null
                    | undefined;
                  signatureCoordinates?:
                    | {
                        __typename?: 'Coordinate';
                        height?: number | null | undefined;
                        pageNumber?: number | null | undefined;
                        width?: number | null | undefined;
                        xPosition?: number | null | undefined;
                        yPosition?: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GenerateTestDocumentsQueryVariables = Exact<{
  update_deal_id: Scalars['ID']['input'];
}>;

export type GenerateTestDocumentsQuery = {
  __typename?: 'RootQueryType';
  generateTestDocuments?: boolean | null | undefined;
};

export type ApplyDocumentSignatureMutationVariables = Exact<{
  dealId: Scalars['ID']['input'];
  dealJacketID: Scalars['String']['input'];
  documentID: Scalars['String']['input'];
  documentName: Scalars['String']['input'];
  signatureFieldName: Scalars['String']['input'];
  signatureData: Scalars['String']['input'];
  isLastSignature?: InputMaybe<Scalars['Boolean']['input']>;
  isCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ApplyDocumentSignatureMutation = {
  __typename?: 'RootMutationType';
  applyDocumentSignature?: any | null | undefined;
};

export type FinalizeR1SigningMutationVariables = Exact<{
  dealId: Scalars['ID']['input'];
  dealJacketID: Scalars['String']['input'];
}>;

export type FinalizeR1SigningMutation = {
  __typename?: 'RootMutationType';
  finalizeR1Signing?: any | null | undefined;
};

export type StoreSignatureDataMutationVariables = Exact<{
  customer_id: Scalars['ID']['input'];
  signature_data_url?: InputMaybe<Scalars['String']['input']>;
  signature_strokes?: InputMaybe<Scalars['String']['input']>;
  initials_data_url?: InputMaybe<Scalars['String']['input']>;
  initials_strokes?: InputMaybe<Scalars['String']['input']>;
}>;

export type StoreSignatureDataMutation = {
  __typename?: 'RootMutationType';
  storeSignatureData?: boolean | null | undefined;
};

export type GetSignDataQueryVariables = Exact<{
  dealJacketID: Scalars['String']['input'];
}>;

export type GetSignDataQuery = {
  __typename?: 'RootQueryType';
  retrieveDealDocsUploadedToR1?:
    | Array<
        | {
            __typename?: 'DealDocument';
            dealJacketId?: string | null | undefined;
            documentId?: string | null | undefined;
            docStatCode?: string | null | undefined;
            signAttributes?:
              | Array<
                  | {
                      __typename?: 'DealDocumentSignAttribute';
                      signFieldName?: string | null | undefined;
                      signRequired?: boolean | null | undefined;
                      initialsType?: boolean | null | undefined;
                      signCaptureTms?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type LogViewedDocumentEventMutationVariables = Exact<{
  documentViewedEvent: SigningAuditDocumentViewedEvent;
}>;

export type LogViewedDocumentEventMutation = {
  __typename?: 'RootMutationType';
  logViewedDocumentEvent?: any | null | undefined;
};

export type LogSigningErrorMutationVariables = Exact<{
  error: SigningErrorInput;
}>;

export type LogSigningErrorMutation = {
  __typename?: 'RootMutationType';
  logSigningError?:
    | { __typename?: 'signingError'; id?: string | null | undefined }
    | null
    | undefined;
};

export type GetTtJurisdictionQueryVariables = Exact<{
  state: StateAbbreviation;
  types: Array<TtProductType> | TtProductType;
  conditions?: InputMaybe<TtConditionFilterInput>;
}>;

export type GetTtJurisdictionQuery = {
  __typename?: 'RootQueryType';
  jurisdiction?:
    | {
        __typename?: 'TTJurisdiction';
        id?: string | null | undefined;
        hasRegistrationOnly?: boolean | null | undefined;
        hasTitleOnly?: boolean | null | undefined;
        hasTitleAndRegistration?: boolean | null | undefined;
        allowPlateTransfer?: boolean | null | undefined;
        requireWalkIn?: boolean | null | undefined;
        products?:
          | {
              __typename?: 'TTTTProductFieldsConnection';
              count?: number | null | undefined;
              pageInfo?:
                | {
                    __typename?: 'TTPageInfo';
                    startCursor?: string | null | undefined;
                    endCursor?: string | null | undefined;
                  }
                | null
                | undefined;
              items?:
                | Array<
                    | {
                        __typename?: 'TTProductFields';
                        type?: TtProductType | null | undefined;
                        validations?:
                          | Array<
                              | {
                                  __typename?: 'TTValidation';
                                  name?: string | null | undefined;
                                  description?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                        documents?:
                          | {
                              __typename?: 'TTTTDocumentFieldsConnection';
                              count?: number | null | undefined;
                              items?:
                                | Array<
                                    | {
                                        __typename?: 'TTDocumentFields';
                                        supportsImageExtraction?: boolean | null | undefined;
                                        productId?: string | null | undefined;
                                        documentTypeId?: string | null | undefined;
                                        type?:
                                          | {
                                              __typename?: 'TTDocumentTypeFields';
                                              name?: string | null | undefined;
                                              parameters?:
                                                | Array<
                                                    | {
                                                        __typename?: 'TTParameterFields';
                                                        name?: string | null | undefined;
                                                        description?: string | null | undefined;
                                                        type?: string | null | undefined;
                                                      }
                                                    | null
                                                    | undefined
                                                  >
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined;
                                        conditions?:
                                          | Array<
                                              | {
                                                  __typename?: 'TTDocumentConditionsFields';
                                                  description?: string | null | undefined;
                                                  variables?:
                                                    | Array<string | null | undefined>
                                                    | null
                                                    | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                        validations?:
                                          | Array<
                                              | {
                                                  __typename?: 'TTValidation';
                                                  name?: string | null | undefined;
                                                  description?: string | null | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UploadDocumentMutationVariables = Exact<{
  bytes: Scalars['String']['input'];
  documentType: TtDocumentName;
}>;

export type UploadDocumentMutation = {
  __typename?: 'RootMutationType';
  uploadDocument?:
    | {
        __typename?: 'ExtractedDocumentGQL';
        documentName?: string | null | undefined;
        key?: string | null | undefined;
        fields?:
          | Array<
              | {
                  __typename?: 'ExtractedField';
                  confidence?: number | null | undefined;
                  name?: string | null | undefined;
                  value?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UploadDriversLicenseMutationVariables = Exact<{
  bytes: Scalars['String']['input'];
}>;

export type UploadDriversLicenseMutation = {
  __typename?: 'RootMutationType';
  uploadDriversLicense?:
    | {
        __typename?: 'DriversLicenseGQL';
        firstName?: string | null | undefined;
        middleName?: string | null | undefined;
        lastName?: string | null | undefined;
        address?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        zip?: string | null | undefined;
        expirationDate?: any | null | undefined;
      }
    | null
    | undefined;
};

export type VehicleMileageQueryVariables = Exact<{
  vin: Scalars['String']['input'];
}>;

export type VehicleMileageQuery = {
  __typename?: 'RootQueryType';
  vehicleMileage?:
    | {
        __typename?: 'VehicleMileageGQL';
        estimatedAverageMiles?: number | null | undefined;
        eventCount?: number | null | undefined;
        lastOdometer?: number | null | undefined;
        lastOdometerDate?: string | null | undefined;
        resultCode?: number | null | undefined;
        resultMessage?: string | null | undefined;
        rollback?: string | null | undefined;
        vin?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetFeesQueryVariables = Exact<{
  state: StateAbbreviation;
  previouslyTitledState: StateAbbreviation;
  city: Scalars['String']['input'];
  county: Scalars['String']['input'];
  zip: Scalars['String']['input'];
  vin: Scalars['String']['input'];
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  year: Scalars['Int']['input'];
  vehicleType: TtVehicleType;
  fuelType: TtFuelType;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  retailBookValue: Scalars['Float']['input'];
  bookValue: Scalars['Float']['input'];
  docFee: Scalars['Float']['input'];
  payoff: Scalars['Float']['input'];
  estimatedPayoff: Scalars['Float']['input'];
  ssn: Scalars['String']['input'];
  warranty: Scalars['Float']['input'];
  source: Scalars['String']['input'];
}>;

export type GetFeesQuery = {
  __typename?: 'RootQueryType';
  getFees?:
    | {
        __typename?: 'FeeGQL';
        state?: StateAbbreviation | null | undefined;
        totalFeeAmount?: number | null | undefined;
        registrationTransferFee?: number | null | undefined;
        salesTaxRate?: number | null | undefined;
        baseTaxAmount?: number | null | undefined;
        warrantyTaxAmount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type EndSalesFlowMutationVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']['input']>;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
  importType: ImportType;
  reason: EndSalesFlowReasonEnum;
}>;

export type EndSalesFlowMutation = {
  __typename?: 'RootMutationType';
  endSalesFlow?: boolean | null | undefined;
};

export type UserDealQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
  isCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UserDealQuery = {
  __typename?: 'RootQueryType';
  userDeal?:
    | {
        __typename?: 'deal';
        id?: string | null | undefined;
        type?: DealType | null | undefined;
        state?: string | null | undefined;
        setter_id?: string | null | undefined;
        paperwork_type?: PaperworkType | null | undefined;
        request_boot?: boolean | null | undefined;
        boot_reason?: any | null | undefined;
        sales_visibility?: boolean | null | undefined;
        r1_jacket_id?: string | null | undefined;
        r1_jacket_id_created_date_utc?: any | null | undefined;
        r1_contract_generation_date_utc?: any | null | undefined;
        signing_on_com?: boolean | null | undefined;
        closer_id?: string | null | undefined;
        closer2_id?: string | null | undefined;
        funding_clerk_id?: string | null | undefined;
        isCobuyer?: boolean | null | undefined;
        force_title_only_helper_link?:
          | {
              __typename?: 'forceTitleOnlyHelperLink';
              name?: string | null | undefined;
              link?: string | null | undefined;
            }
          | null
          | undefined;
        setter?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        closer?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        closer2?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        funding_clerk?:
          | {
              __typename?: 'user';
              id?: string | null | undefined;
              name?: string | null | undefined;
            }
          | null
          | undefined;
        car?:
          | {
              __typename?: 'car';
              id?: string | null | undefined;
              vin?: string | null | undefined;
              year?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              mileage?: number | null | undefined;
              color?: string | null | undefined;
              book_value?: number | null | undefined;
              retail_book_value?: number | null | undefined;
              registration_expiration?: any | null | undefined;
              vehicle_type?: string | null | undefined;
              fuel_type?: string | null | undefined;
              kbb_trim_name?: string | null | undefined;
              mmr?: number | null | undefined;
              payoff?:
                | {
                    __typename?: 'payoff';
                    id?: string | null | undefined;
                    lienholder_name?: string | null | undefined;
                    lienholder_slug?: string | null | undefined;
                    account_number?: string | null | undefined;
                    vehicle_payoff?: number | null | undefined;
                    good_through_date?: any | null | undefined;
                    lease_term?: number | null | undefined;
                    remaining_payments?: number | null | undefined;
                    msrp?: number | null | undefined;
                    residual_percentage?: number | null | undefined;
                    sales_price?: number | null | undefined;
                    cap_reduction?: number | null | undefined;
                    money_down?: number | null | undefined;
                    money_factor?: number | null | undefined;
                    termination_fees?: number | null | undefined;
                    cap_cost?: number | null | undefined;
                    residual_amount?: number | null | undefined;
                    estimated_payoff?: number | null | undefined;
                    old_lease_payment?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        customer?:
          | {
              __typename?: 'customer';
              id?: string | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
              home_phone_number?: string | null | undefined;
              email?: string | null | undefined;
              no_email?: boolean | null | undefined;
              dob?: any | null | undefined;
              dl_expiration_date?: any | null | undefined;
              last_authenticated_route?: string | null | undefined;
              dashboard_visited?: boolean | null | undefined;
              verified?: boolean | null | undefined;
              finished_signing?: boolean | null | undefined;
              address?:
                | {
                    __typename?: 'address';
                    id?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    county?: string | null | undefined;
                    address_line?: string | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                    moved_states?: boolean | null | undefined;
                  }
                | null
                | undefined;
              drivers_license_address?:
                | {
                    __typename?: 'address';
                    id?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    county?: string | null | undefined;
                    address_line?: string | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                    moved_states?: boolean | null | undefined;
                  }
                | null
                | undefined;
              prev_address?:
                | {
                    __typename?: 'address';
                    id?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    county?: string | null | undefined;
                    address_line?: string | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                  }
                | null
                | undefined;
              employment?:
                | {
                    __typename?: 'employment';
                    id?: string | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              prev_employment?:
                | {
                    __typename?: 'employment';
                    id?: string | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              proof_of_insurance?:
                | {
                    __typename?: 'proofOfInsurance';
                    company_name?: string | null | undefined;
                    expires?: any | null | undefined;
                    policy_number?: string | null | undefined;
                    state?: string | null | undefined;
                    first_name?: string | null | undefined;
                    middle_name?: string | null | undefined;
                    last_name?: string | null | undefined;
                  }
                | null
                | undefined;
              signature_data?:
                | {
                    __typename?: 'SignatureData';
                    signature_data_url?: string | null | undefined;
                    signature_strokes?: string | null | undefined;
                    initials_data_url?: string | null | undefined;
                    initials_strokes?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        cobuyer?:
          | {
              __typename?: 'customer';
              id?: string | null | undefined;
              first_name?: string | null | undefined;
              middle_name?: string | null | undefined;
              last_name?: string | null | undefined;
              phone_number?: string | null | undefined;
              home_phone_number?: string | null | undefined;
              email?: string | null | undefined;
              no_email?: boolean | null | undefined;
              dob?: any | null | undefined;
              dashboard_visited?: boolean | null | undefined;
              verified?: boolean | null | undefined;
              relation_to_buyer?: RelationToBuyerEnum | null | undefined;
              finished_signing?: boolean | null | undefined;
              address?:
                | {
                    __typename?: 'address';
                    id?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    county?: string | null | undefined;
                    address_line?: string | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                  }
                | null
                | undefined;
              prev_address?:
                | {
                    __typename?: 'address';
                    id?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    county?: string | null | undefined;
                    address_line?: string | null | undefined;
                    residence_type?: string | null | undefined;
                    years_at_home?: number | null | undefined;
                    months_at_home?: number | null | undefined;
                    monthly_payment?: number | null | undefined;
                  }
                | null
                | undefined;
              employment?:
                | {
                    __typename?: 'employment';
                    id?: string | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              prev_employment?:
                | {
                    __typename?: 'employment';
                    id?: string | null | undefined;
                    name?: string | null | undefined;
                    job_title?: string | null | undefined;
                    phone_number?: string | null | undefined;
                    years_at_job?: number | null | undefined;
                    months_at_job?: number | null | undefined;
                    gross_income?: number | null | undefined;
                    pay_frequency?: string | null | undefined;
                  }
                | null
                | undefined;
              signature_data?:
                | {
                    __typename?: 'SignatureData';
                    signature_data_url?: string | null | undefined;
                    signature_strokes?: string | null | undefined;
                    initials_data_url?: string | null | undefined;
                    initials_strokes?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        financial_info?:
          | {
              __typename?: 'financialInfo';
              id?: string | null | undefined;
              deal_id?: string | null | undefined;
              money_down?: number | null | undefined;
              base_tax_amount?: number | null | undefined;
              warranty_tax_amount?: number | null | undefined;
              title_registration_option?: TitleRegistrationOption | null | undefined;
              title_fee?: number | null | undefined;
              new_registration_fee?: number | null | undefined;
              registration_transfer_fee?: number | null | undefined;
              doc_fee?: number | null | undefined;
              sales_tax_rate?: number | null | undefined;
              vsc_price?: number | null | undefined;
              vsc_cost?: number | null | undefined;
              gap_price?: number | null | undefined;
              gap_cost?: number | null | undefined;
              days_to_payment?: number | null | undefined;
              first_payment_date?: any | null | undefined;
              sell_rate?: number | null | undefined;
              buy_rate?: number | null | undefined;
              term?: number | null | undefined;
              bank?: string | null | undefined;
              vsc_term?: string | null | undefined;
              payment?: number | null | undefined;
              amount_financed?: number | null | undefined;
              profit?: number | null | undefined;
              setter_commission?: number | null | undefined;
              closer_commission?: number | null | undefined;
              option_type?: string | null | undefined;
              plate_transfer?: boolean | null | undefined;
              pen_vsc_session_id?: string | null | undefined;
              pen_vsc_rate_id?: number | null | undefined;
              pen_vsc_form_id?: number | null | undefined;
              pen_gap_session_id?: string | null | undefined;
              pen_gap_rate_id?: number | null | undefined;
              pen_gap_form_id?: number | null | undefined;
              credit_debit_payment_amount_allowed?: number | null | undefined;
              credit_payment_amount_allowed?: number | null | undefined;
              debit_payment_amount_allowed?: number | null | undefined;
              credit_payment_amount_limit?: number | null | undefined;
              debit_payment_amount_limit?: number | null | undefined;
            }
          | null
          | undefined;
        financial_info_acquisition?:
          | {
              __typename?: 'financialInfoAcquisition';
              id?: string | null | undefined;
              deal_id?: string | null | undefined;
              kbb_lending?: number | null | undefined;
              appraised_value?: number | null | undefined;
              max_cash_to_customer?: number | null | undefined;
              max_total_cost?: number | null | undefined;
              cash_to_customer?: number | null | undefined;
              is_approved?: boolean | null | undefined;
              auction_fees?: number | null | undefined;
              transport_cost?: number | null | undefined;
              total_cost?: number | null | undefined;
              auction_id?: string | null | undefined;
              gross_profit?: number | null | undefined;
              sell_price?: number | null | undefined;
              offer?: number | null | undefined;
            }
          | null
          | undefined;
        condition_report?:
          | {
              __typename?: 'conditionReport';
              id?: string | null | undefined;
              deal_id?: string | null | undefined;
              accidents?: AccidentsEnumType | null | undefined;
              tires?: TiresEnumType | null | undefined;
              exterior?: Array<ExteriorEnumType | null | undefined> | null | undefined;
              interior?: Array<InteriorEnumType | null | undefined> | null | undefined;
              smoked_in?: boolean | null | undefined;
              lights_on_dash?: boolean | null | undefined;
              overall_condition?: OverallConditionEnumType | null | undefined;
              score?: number | null | undefined;
            }
          | null
          | undefined;
        document_info?:
          | { __typename?: 'documentInfo'; adobe_agreement_id?: string | null | undefined }
          | null
          | undefined;
        product?:
          | {
              __typename?: 'product';
              selected_package?: string | null | undefined;
              gap_selected?: boolean | null | undefined;
            }
          | null
          | undefined;
        deal_states?:
          | Array<
              | {
                  __typename?: 'dealState';
                  state?: string | null | undefined;
                  updated_date_utc?: any | null | undefined;
                  created_at?: any | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserDealReadyQueryVariables = Exact<{
  tempInfoId: Scalars['ID']['input'];
}>;

export type UserDealReadyQuery = {
  __typename?: 'RootQueryType';
  userDealReady?: DealReadyType | null | undefined;
};

export type UserDealsQueryVariables = Exact<{ [key: string]: never }>;

export type UserDealsQuery = {
  __typename?: 'RootQueryType';
  userDeals?:
    | Array<
        | {
            __typename?: 'deals';
            id?: string | null | undefined;
            sales_visibility?: boolean | null | undefined;
            type?: DealType | null | undefined;
            isCobuyer?: boolean | null | undefined;
            car?:
              | {
                  __typename?: 'car';
                  id?: string | null | undefined;
                  vin?: string | null | undefined;
                  year?: string | null | undefined;
                  make?: string | null | undefined;
                  model?: string | null | undefined;
                  mileage?: number | null | undefined;
                  color?: string | null | undefined;
                  book_value?: number | null | undefined;
                  retail_book_value?: number | null | undefined;
                  registration_expiration?: any | null | undefined;
                  vehicle_type?: string | null | undefined;
                  fuel_type?: string | null | undefined;
                  kbb_trim_name?: string | null | undefined;
                  mmr?: number | null | undefined;
                }
              | null
              | undefined;
            customer?:
              | {
                  __typename?: 'customer';
                  full_name?: string | null | undefined;
                  email?: string | null | undefined;
                }
              | null
              | undefined;
            cobuyer?:
              | {
                  __typename?: 'customer';
                  full_name?: string | null | undefined;
                  email?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetComPackageOptionsQueryVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetComPackageOptionsQuery = {
  __typename?: 'RootQueryType';
  getComPackageOptions?:
    | {
        __typename?: 'getPackageOptions';
        isGapOffered?: boolean | null | undefined;
        isVscOffered?: boolean | null | undefined;
        gapMonthlyCost?: number | null | undefined;
        vscPackageOptions?:
          | {
              __typename?: 'vsc';
              startingIndex?: number | null | undefined;
              noProductsPmt?: number | null | undefined;
              vscOffers?:
                | Array<
                    | {
                        __typename?: 'vscOffers';
                        type?: string | null | undefined;
                        name?: string | null | undefined;
                        details?:
                          | {
                              __typename?: 'vscDetails';
                              years?: number | null | undefined;
                              miles?: number | null | undefined;
                              deductible?: number | null | undefined;
                              price?: number | null | undefined;
                              payment?: number | null | undefined;
                            }
                          | null
                          | undefined;
                        coverage?:
                          | Array<
                              | {
                                  __typename?: 'vscCoverages';
                                  category?: string | null | undefined;
                                  slug?: string | null | undefined;
                                  components?: Array<string | null | undefined> | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AddressDetailDocument = gql`
  query addressDetail($address: String) {
    addressDetail(address: $address) {
      city
      state
      county
    }
  }
`;

/**
 * __useAddressDetailQuery__
 *
 * To run a query within a React component, call `useAddressDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressDetailQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddressDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressDetailQuery, AddressDetailQueryVariables>(
    AddressDetailDocument,
    options,
  );
}
export function useAddressDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressDetailQuery, AddressDetailQueryVariables>(
    AddressDetailDocument,
    options,
  );
}
export type AddressDetailQueryHookResult = ReturnType<typeof useAddressDetailQuery>;
export type AddressDetailLazyQueryHookResult = ReturnType<typeof useAddressDetailLazyQuery>;
export type AddressDetailQueryResult = Apollo.QueryResult<
  AddressDetailQuery,
  AddressDetailQueryVariables
>;
export const ValidZipDocument = gql`
  query validZip($zipCode: String!) {
    validZip(zipCode: $zipCode)
  }
`;

/**
 * __useValidZipQuery__
 *
 * To run a query within a React component, call `useValidZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidZipQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useValidZipQuery(
  baseOptions: Apollo.QueryHookOptions<ValidZipQuery, ValidZipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidZipQuery, ValidZipQueryVariables>(ValidZipDocument, options);
}
export function useValidZipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidZipQuery, ValidZipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidZipQuery, ValidZipQueryVariables>(ValidZipDocument, options);
}
export type ValidZipQueryHookResult = ReturnType<typeof useValidZipQuery>;
export type ValidZipLazyQueryHookResult = ReturnType<typeof useValidZipLazyQuery>;
export type ValidZipQueryResult = Apollo.QueryResult<ValidZipQuery, ValidZipQueryVariables>;
export const ResetPasswordAndSendEmailDocument = gql`
  mutation resetPasswordAndSendEmail($access_token: String, $password: String) {
    resetPasswordAndSendEmail(access_token: $access_token, password: $password)
  }
`;
export type ResetPasswordAndSendEmailMutationFn = Apollo.MutationFunction<
  ResetPasswordAndSendEmailMutation,
  ResetPasswordAndSendEmailMutationVariables
>;

/**
 * __useResetPasswordAndSendEmailMutation__
 *
 * To run a mutation, you first call `useResetPasswordAndSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordAndSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordAndSendEmailMutation, { data, loading, error }] = useResetPasswordAndSendEmailMutation({
 *   variables: {
 *      access_token: // value for 'access_token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordAndSendEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordAndSendEmailMutation,
    ResetPasswordAndSendEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordAndSendEmailMutation,
    ResetPasswordAndSendEmailMutationVariables
  >(ResetPasswordAndSendEmailDocument, options);
}
export type ResetPasswordAndSendEmailMutationHookResult = ReturnType<
  typeof useResetPasswordAndSendEmailMutation
>;
export type ResetPasswordAndSendEmailMutationResult =
  Apollo.MutationResult<ResetPasswordAndSendEmailMutation>;
export type ResetPasswordAndSendEmailMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordAndSendEmailMutation,
  ResetPasswordAndSendEmailMutationVariables
>;
export const CheckExistingAccountByEmailDocument = gql`
  query checkExistingAccountByEmail($email: String!) {
    checkExistingAccountByEmail(email: $email)
  }
`;

/**
 * __useCheckExistingAccountByEmailQuery__
 *
 * To run a query within a React component, call `useCheckExistingAccountByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistingAccountByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistingAccountByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckExistingAccountByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckExistingAccountByEmailQuery,
    CheckExistingAccountByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckExistingAccountByEmailQuery,
    CheckExistingAccountByEmailQueryVariables
  >(CheckExistingAccountByEmailDocument, options);
}
export function useCheckExistingAccountByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckExistingAccountByEmailQuery,
    CheckExistingAccountByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckExistingAccountByEmailQuery,
    CheckExistingAccountByEmailQueryVariables
  >(CheckExistingAccountByEmailDocument, options);
}
export type CheckExistingAccountByEmailQueryHookResult = ReturnType<
  typeof useCheckExistingAccountByEmailQuery
>;
export type CheckExistingAccountByEmailLazyQueryHookResult = ReturnType<
  typeof useCheckExistingAccountByEmailLazyQuery
>;
export type CheckExistingAccountByEmailQueryResult = Apollo.QueryResult<
  CheckExistingAccountByEmailQuery,
  CheckExistingAccountByEmailQueryVariables
>;
export const CheckFlowStatusDocument = gql`
  query checkFlowStatus($guid: String) {
    checkFlowStatus(guid: $guid) {
      inFlow
      guid
      url
      car {
        id
        make
        model
        year
        mileage
        vin
        vehicle_type
        color
      }
      dealId
      customerId
    }
  }
`;

/**
 * __useCheckFlowStatusQuery__
 *
 * To run a query within a React component, call `useCheckFlowStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckFlowStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckFlowStatusQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useCheckFlowStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>(
    CheckFlowStatusDocument,
    options,
  );
}
export function useCheckFlowStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>(
    CheckFlowStatusDocument,
    options,
  );
}
export type CheckFlowStatusQueryHookResult = ReturnType<typeof useCheckFlowStatusQuery>;
export type CheckFlowStatusLazyQueryHookResult = ReturnType<typeof useCheckFlowStatusLazyQuery>;
export type CheckFlowStatusQueryResult = Apollo.QueryResult<
  CheckFlowStatusQuery,
  CheckFlowStatusQueryVariables
>;
export const BankLogoDocument = gql`
  query bankLogo($bank_name: String!) {
    bankLogo(bank_name: $bank_name)
  }
`;

/**
 * __useBankLogoQuery__
 *
 * To run a query within a React component, call `useBankLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankLogoQuery({
 *   variables: {
 *      bank_name: // value for 'bank_name'
 *   },
 * });
 */
export function useBankLogoQuery(
  baseOptions: Apollo.QueryHookOptions<BankLogoQuery, BankLogoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankLogoQuery, BankLogoQueryVariables>(BankLogoDocument, options);
}
export function useBankLogoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankLogoQuery, BankLogoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankLogoQuery, BankLogoQueryVariables>(BankLogoDocument, options);
}
export type BankLogoQueryHookResult = ReturnType<typeof useBankLogoQuery>;
export type BankLogoLazyQueryHookResult = ReturnType<typeof useBankLogoLazyQuery>;
export type BankLogoQueryResult = Apollo.QueryResult<BankLogoQuery, BankLogoQueryVariables>;
export const GetExternalAutoStructureBankLogosDocument = gql`
  query getExternalAutoStructureBankLogos {
    getExternalAutoStructureBankLogos {
      bank_name
      logo_url
    }
  }
`;

/**
 * __useGetExternalAutoStructureBankLogosQuery__
 *
 * To run a query within a React component, call `useGetExternalAutoStructureBankLogosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalAutoStructureBankLogosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalAutoStructureBankLogosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExternalAutoStructureBankLogosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExternalAutoStructureBankLogosQuery,
    GetExternalAutoStructureBankLogosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExternalAutoStructureBankLogosQuery,
    GetExternalAutoStructureBankLogosQueryVariables
  >(GetExternalAutoStructureBankLogosDocument, options);
}
export function useGetExternalAutoStructureBankLogosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalAutoStructureBankLogosQuery,
    GetExternalAutoStructureBankLogosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExternalAutoStructureBankLogosQuery,
    GetExternalAutoStructureBankLogosQueryVariables
  >(GetExternalAutoStructureBankLogosDocument, options);
}
export type GetExternalAutoStructureBankLogosQueryHookResult = ReturnType<
  typeof useGetExternalAutoStructureBankLogosQuery
>;
export type GetExternalAutoStructureBankLogosLazyQueryHookResult = ReturnType<
  typeof useGetExternalAutoStructureBankLogosLazyQuery
>;
export type GetExternalAutoStructureBankLogosQueryResult = Apollo.QueryResult<
  GetExternalAutoStructureBankLogosQuery,
  GetExternalAutoStructureBankLogosQueryVariables
>;
export const CurrentConsentAgreementTextDocument = gql`
  query currentConsentAgreementText($agreementType: consentAgreementTypeEnum!, $version: Int) {
    currentConsentAgreementText(agreementType: $agreementType, version: $version) {
      id
      text
    }
  }
`;

/**
 * __useCurrentConsentAgreementTextQuery__
 *
 * To run a query within a React component, call `useCurrentConsentAgreementTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentConsentAgreementTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentConsentAgreementTextQuery({
 *   variables: {
 *      agreementType: // value for 'agreementType'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useCurrentConsentAgreementTextQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentConsentAgreementTextQuery,
    CurrentConsentAgreementTextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentConsentAgreementTextQuery,
    CurrentConsentAgreementTextQueryVariables
  >(CurrentConsentAgreementTextDocument, options);
}
export function useCurrentConsentAgreementTextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentConsentAgreementTextQuery,
    CurrentConsentAgreementTextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentConsentAgreementTextQuery,
    CurrentConsentAgreementTextQueryVariables
  >(CurrentConsentAgreementTextDocument, options);
}
export type CurrentConsentAgreementTextQueryHookResult = ReturnType<
  typeof useCurrentConsentAgreementTextQuery
>;
export type CurrentConsentAgreementTextLazyQueryHookResult = ReturnType<
  typeof useCurrentConsentAgreementTextLazyQuery
>;
export type CurrentConsentAgreementTextQueryResult = Apollo.QueryResult<
  CurrentConsentAgreementTextQuery,
  CurrentConsentAgreementTextQueryVariables
>;
export const SaveConsentDocument = gql`
  mutation saveConsent($type: consentNameEnumType!, $dealId: ID, $version: Int) {
    saveConsent(type: $type, dealId: $dealId, version: $version) {
      consent {
        id
      }
    }
  }
`;
export type SaveConsentMutationFn = Apollo.MutationFunction<
  SaveConsentMutation,
  SaveConsentMutationVariables
>;

/**
 * __useSaveConsentMutation__
 *
 * To run a mutation, you first call `useSaveConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConsentMutation, { data, loading, error }] = useSaveConsentMutation({
 *   variables: {
 *      type: // value for 'type'
 *      dealId: // value for 'dealId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useSaveConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveConsentMutation, SaveConsentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveConsentMutation, SaveConsentMutationVariables>(
    SaveConsentDocument,
    options,
  );
}
export type SaveConsentMutationHookResult = ReturnType<typeof useSaveConsentMutation>;
export type SaveConsentMutationResult = Apollo.MutationResult<SaveConsentMutation>;
export type SaveConsentMutationOptions = Apollo.BaseMutationOptions<
  SaveConsentMutation,
  SaveConsentMutationVariables
>;
export const GetApprovedCreditDecisionsByDealIdDocument = gql`
  query getApprovedCreditDecisionsByDealId($dealId: ID!) {
    getApprovedCreditDecisionsByDealId(dealId: $dealId) {
      id
      bank {
        name
        logo_url
      }
    }
  }
`;

/**
 * __useGetApprovedCreditDecisionsByDealIdQuery__
 *
 * To run a query within a React component, call `useGetApprovedCreditDecisionsByDealIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedCreditDecisionsByDealIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedCreditDecisionsByDealIdQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetApprovedCreditDecisionsByDealIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApprovedCreditDecisionsByDealIdQuery,
    GetApprovedCreditDecisionsByDealIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApprovedCreditDecisionsByDealIdQuery,
    GetApprovedCreditDecisionsByDealIdQueryVariables
  >(GetApprovedCreditDecisionsByDealIdDocument, options);
}
export function useGetApprovedCreditDecisionsByDealIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApprovedCreditDecisionsByDealIdQuery,
    GetApprovedCreditDecisionsByDealIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApprovedCreditDecisionsByDealIdQuery,
    GetApprovedCreditDecisionsByDealIdQueryVariables
  >(GetApprovedCreditDecisionsByDealIdDocument, options);
}
export type GetApprovedCreditDecisionsByDealIdQueryHookResult = ReturnType<
  typeof useGetApprovedCreditDecisionsByDealIdQuery
>;
export type GetApprovedCreditDecisionsByDealIdLazyQueryHookResult = ReturnType<
  typeof useGetApprovedCreditDecisionsByDealIdLazyQuery
>;
export type GetApprovedCreditDecisionsByDealIdQueryResult = Apollo.QueryResult<
  GetApprovedCreditDecisionsByDealIdQuery,
  GetApprovedCreditDecisionsByDealIdQueryVariables
>;
export const ExternalCustomerUpdateDocument = gql`
  mutation externalCustomerUpdate($customerId: ID!, $customer: ExternalCustomerInput) {
    externalCustomerUpdate(customerId: $customerId, customer: $customer) {
      id
    }
  }
`;
export type ExternalCustomerUpdateMutationFn = Apollo.MutationFunction<
  ExternalCustomerUpdateMutation,
  ExternalCustomerUpdateMutationVariables
>;

/**
 * __useExternalCustomerUpdateMutation__
 *
 * To run a mutation, you first call `useExternalCustomerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalCustomerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalCustomerUpdateMutation, { data, loading, error }] = useExternalCustomerUpdateMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useExternalCustomerUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalCustomerUpdateMutation,
    ExternalCustomerUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalCustomerUpdateMutation,
    ExternalCustomerUpdateMutationVariables
  >(ExternalCustomerUpdateDocument, options);
}
export type ExternalCustomerUpdateMutationHookResult = ReturnType<
  typeof useExternalCustomerUpdateMutation
>;
export type ExternalCustomerUpdateMutationResult =
  Apollo.MutationResult<ExternalCustomerUpdateMutation>;
export type ExternalCustomerUpdateMutationOptions = Apollo.BaseMutationOptions<
  ExternalCustomerUpdateMutation,
  ExternalCustomerUpdateMutationVariables
>;
export const GetByAuth0IdDocument = gql`
  query getByAuth0Id {
    getByAuth0Id {
      first_name
      last_name
      full_name
      email
    }
  }
`;

/**
 * __useGetByAuth0IdQuery__
 *
 * To run a query within a React component, call `useGetByAuth0IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetByAuth0IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetByAuth0IdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetByAuth0IdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetByAuth0IdQuery, GetByAuth0IdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetByAuth0IdQuery, GetByAuth0IdQueryVariables>(
    GetByAuth0IdDocument,
    options,
  );
}
export function useGetByAuth0IdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetByAuth0IdQuery, GetByAuth0IdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetByAuth0IdQuery, GetByAuth0IdQueryVariables>(
    GetByAuth0IdDocument,
    options,
  );
}
export type GetByAuth0IdQueryHookResult = ReturnType<typeof useGetByAuth0IdQuery>;
export type GetByAuth0IdLazyQueryHookResult = ReturnType<typeof useGetByAuth0IdLazyQuery>;
export type GetByAuth0IdQueryResult = Apollo.QueryResult<
  GetByAuth0IdQuery,
  GetByAuth0IdQueryVariables
>;
export const LinkAccountDocument = gql`
  mutation linkAccount($ssn_last_4: String, $deal_id: ID, $is_cobuyer: Boolean) {
    linkAccount(ssn_last_4: $ssn_last_4, deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;
export type LinkAccountMutationFn = Apollo.MutationFunction<
  LinkAccountMutation,
  LinkAccountMutationVariables
>;

/**
 * __useLinkAccountMutation__
 *
 * To run a mutation, you first call `useLinkAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkAccountMutation, { data, loading, error }] = useLinkAccountMutation({
 *   variables: {
 *      ssn_last_4: // value for 'ssn_last_4'
 *      deal_id: // value for 'deal_id'
 *      is_cobuyer: // value for 'is_cobuyer'
 *   },
 * });
 */
export function useLinkAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkAccountMutation, LinkAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkAccountMutation, LinkAccountMutationVariables>(
    LinkAccountDocument,
    options,
  );
}
export type LinkAccountMutationHookResult = ReturnType<typeof useLinkAccountMutation>;
export type LinkAccountMutationResult = Apollo.MutationResult<LinkAccountMutation>;
export type LinkAccountMutationOptions = Apollo.BaseMutationOptions<
  LinkAccountMutation,
  LinkAccountMutationVariables
>;
export const OptOutDocument = gql`
  mutation optOut($deal_id: ID, $is_cobuyer: Boolean) {
    optOut(deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;
export type OptOutMutationFn = Apollo.MutationFunction<OptOutMutation, OptOutMutationVariables>;

/**
 * __useOptOutMutation__
 *
 * To run a mutation, you first call `useOptOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optOutMutation, { data, loading, error }] = useOptOutMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      is_cobuyer: // value for 'is_cobuyer'
 *   },
 * });
 */
export function useOptOutMutation(
  baseOptions?: Apollo.MutationHookOptions<OptOutMutation, OptOutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OptOutMutation, OptOutMutationVariables>(OptOutDocument, options);
}
export type OptOutMutationHookResult = ReturnType<typeof useOptOutMutation>;
export type OptOutMutationResult = Apollo.MutationResult<OptOutMutation>;
export type OptOutMutationOptions = Apollo.BaseMutationOptions<
  OptOutMutation,
  OptOutMutationVariables
>;
export const VerifyAccountDocument = gql`
  mutation verifyAccount($ssn_last_4: String!, $deal_id: ID!, $is_cobuyer: Boolean) {
    verifyAccount(ssn_last_4: $ssn_last_4, deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;
export type VerifyAccountMutationFn = Apollo.MutationFunction<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      ssn_last_4: // value for 'ssn_last_4'
 *      deal_id: // value for 'deal_id'
 *      is_cobuyer: // value for 'is_cobuyer'
 *   },
 * });
 */
export function useVerifyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(
    VerifyAccountDocument,
    options,
  );
}
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>;
export const LinkUnlinkedCustomersDocument = gql`
  mutation linkUnlinkedCustomers {
    linkUnlinkedCustomers
  }
`;
export type LinkUnlinkedCustomersMutationFn = Apollo.MutationFunction<
  LinkUnlinkedCustomersMutation,
  LinkUnlinkedCustomersMutationVariables
>;

/**
 * __useLinkUnlinkedCustomersMutation__
 *
 * To run a mutation, you first call `useLinkUnlinkedCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkUnlinkedCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkUnlinkedCustomersMutation, { data, loading, error }] = useLinkUnlinkedCustomersMutation({
 *   variables: {
 *   },
 * });
 */
export function useLinkUnlinkedCustomersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkUnlinkedCustomersMutation,
    LinkUnlinkedCustomersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkUnlinkedCustomersMutation, LinkUnlinkedCustomersMutationVariables>(
    LinkUnlinkedCustomersDocument,
    options,
  );
}
export type LinkUnlinkedCustomersMutationHookResult = ReturnType<
  typeof useLinkUnlinkedCustomersMutation
>;
export type LinkUnlinkedCustomersMutationResult =
  Apollo.MutationResult<LinkUnlinkedCustomersMutation>;
export type LinkUnlinkedCustomersMutationOptions = Apollo.BaseMutationOptions<
  LinkUnlinkedCustomersMutation,
  LinkUnlinkedCustomersMutationVariables
>;
export const ExternalUpdateDealDocument = gql`
  mutation externalUpdateDeal($dealId: ID, $tempInfoId: ID, $data: ExternalDealInputType!) {
    externalUpdateDeal(dealId: $dealId, tempInfoId: $tempInfoId, data: $data) {
      id
    }
  }
`;
export type ExternalUpdateDealMutationFn = Apollo.MutationFunction<
  ExternalUpdateDealMutation,
  ExternalUpdateDealMutationVariables
>;

/**
 * __useExternalUpdateDealMutation__
 *
 * To run a mutation, you first call `useExternalUpdateDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalUpdateDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalUpdateDealMutation, { data, loading, error }] = useExternalUpdateDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      tempInfoId: // value for 'tempInfoId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExternalUpdateDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalUpdateDealMutation,
    ExternalUpdateDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExternalUpdateDealMutation, ExternalUpdateDealMutationVariables>(
    ExternalUpdateDealDocument,
    options,
  );
}
export type ExternalUpdateDealMutationHookResult = ReturnType<typeof useExternalUpdateDealMutation>;
export type ExternalUpdateDealMutationResult = Apollo.MutationResult<ExternalUpdateDealMutation>;
export type ExternalUpdateDealMutationOptions = Apollo.BaseMutationOptions<
  ExternalUpdateDealMutation,
  ExternalUpdateDealMutationVariables
>;
export const DealAdvisorsDocument = gql`
  query dealAdvisors($dealId: ID) {
    dealAdvisors(dealId: $dealId) {
      setter {
        name
      }
      closer {
        name
      }
      funding_clerk {
        name
      }
      title_clerk {
        name
      }
    }
  }
`;

/**
 * __useDealAdvisorsQuery__
 *
 * To run a query within a React component, call `useDealAdvisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealAdvisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealAdvisorsQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useDealAdvisorsQuery(
  baseOptions?: Apollo.QueryHookOptions<DealAdvisorsQuery, DealAdvisorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealAdvisorsQuery, DealAdvisorsQueryVariables>(
    DealAdvisorsDocument,
    options,
  );
}
export function useDealAdvisorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealAdvisorsQuery, DealAdvisorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealAdvisorsQuery, DealAdvisorsQueryVariables>(
    DealAdvisorsDocument,
    options,
  );
}
export type DealAdvisorsQueryHookResult = ReturnType<typeof useDealAdvisorsQuery>;
export type DealAdvisorsLazyQueryHookResult = ReturnType<typeof useDealAdvisorsLazyQuery>;
export type DealAdvisorsQueryResult = Apollo.QueryResult<
  DealAdvisorsQuery,
  DealAdvisorsQueryVariables
>;
export const TrackComEventDocument = gql`
  mutation trackComEvent(
    $dealId: ID!
    $approvalOnDealProcessing: Boolean
    $reachedDocumentsScreen: Boolean
    $finishedDocumentsScreen: Boolean
  ) {
    trackComEvent(
      dealId: $dealId
      approvalOnDealProcessing: $approvalOnDealProcessing
      reachedDocumentsScreen: $reachedDocumentsScreen
      finishedDocumentsScreen: $finishedDocumentsScreen
    )
  }
`;
export type TrackComEventMutationFn = Apollo.MutationFunction<
  TrackComEventMutation,
  TrackComEventMutationVariables
>;

/**
 * __useTrackComEventMutation__
 *
 * To run a mutation, you first call `useTrackComEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackComEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackComEventMutation, { data, loading, error }] = useTrackComEventMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      approvalOnDealProcessing: // value for 'approvalOnDealProcessing'
 *      reachedDocumentsScreen: // value for 'reachedDocumentsScreen'
 *      finishedDocumentsScreen: // value for 'finishedDocumentsScreen'
 *   },
 * });
 */
export function useTrackComEventMutation(
  baseOptions?: Apollo.MutationHookOptions<TrackComEventMutation, TrackComEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackComEventMutation, TrackComEventMutationVariables>(
    TrackComEventDocument,
    options,
  );
}
export type TrackComEventMutationHookResult = ReturnType<typeof useTrackComEventMutation>;
export type TrackComEventMutationResult = Apollo.MutationResult<TrackComEventMutation>;
export type TrackComEventMutationOptions = Apollo.BaseMutationOptions<
  TrackComEventMutation,
  TrackComEventMutationVariables
>;
export const TotalCompletedDealsDocument = gql`
  query totalCompletedDeals {
    totalCompletedDeals
  }
`;

/**
 * __useTotalCompletedDealsQuery__
 *
 * To run a query within a React component, call `useTotalCompletedDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalCompletedDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalCompletedDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalCompletedDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TotalCompletedDealsQuery,
    TotalCompletedDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalCompletedDealsQuery, TotalCompletedDealsQueryVariables>(
    TotalCompletedDealsDocument,
    options,
  );
}
export function useTotalCompletedDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TotalCompletedDealsQuery,
    TotalCompletedDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TotalCompletedDealsQuery, TotalCompletedDealsQueryVariables>(
    TotalCompletedDealsDocument,
    options,
  );
}
export type TotalCompletedDealsQueryHookResult = ReturnType<typeof useTotalCompletedDealsQuery>;
export type TotalCompletedDealsLazyQueryHookResult = ReturnType<
  typeof useTotalCompletedDealsLazyQuery
>;
export type TotalCompletedDealsQueryResult = Apollo.QueryResult<
  TotalCompletedDealsQuery,
  TotalCompletedDealsQueryVariables
>;
export const GetUnlinkedDealsDocument = gql`
  query getUnlinkedDeals {
    getUnlinkedDeals {
      id
      car {
        year
        make
        model
        vin
        vehicle_type
        color
      }
      isCobuyer
      type
    }
  }
`;

/**
 * __useGetUnlinkedDealsQuery__
 *
 * To run a query within a React component, call `useGetUnlinkedDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnlinkedDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnlinkedDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnlinkedDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUnlinkedDealsQuery, GetUnlinkedDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnlinkedDealsQuery, GetUnlinkedDealsQueryVariables>(
    GetUnlinkedDealsDocument,
    options,
  );
}
export function useGetUnlinkedDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnlinkedDealsQuery, GetUnlinkedDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnlinkedDealsQuery, GetUnlinkedDealsQueryVariables>(
    GetUnlinkedDealsDocument,
    options,
  );
}
export type GetUnlinkedDealsQueryHookResult = ReturnType<typeof useGetUnlinkedDealsQuery>;
export type GetUnlinkedDealsLazyQueryHookResult = ReturnType<typeof useGetUnlinkedDealsLazyQuery>;
export type GetUnlinkedDealsQueryResult = Apollo.QueryResult<
  GetUnlinkedDealsQuery,
  GetUnlinkedDealsQueryVariables
>;
export const DocumentsInfoUpdateDocument = gql`
  mutation documentsInfoUpdate($data: documentsInfoUpdateInput!) {
    documentsInfoUpdate(data: $data)
  }
`;
export type DocumentsInfoUpdateMutationFn = Apollo.MutationFunction<
  DocumentsInfoUpdateMutation,
  DocumentsInfoUpdateMutationVariables
>;

/**
 * __useDocumentsInfoUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentsInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsInfoUpdateMutation, { data, loading, error }] = useDocumentsInfoUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDocumentsInfoUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentsInfoUpdateMutation,
    DocumentsInfoUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DocumentsInfoUpdateMutation, DocumentsInfoUpdateMutationVariables>(
    DocumentsInfoUpdateDocument,
    options,
  );
}
export type DocumentsInfoUpdateMutationHookResult = ReturnType<
  typeof useDocumentsInfoUpdateMutation
>;
export type DocumentsInfoUpdateMutationResult = Apollo.MutationResult<DocumentsInfoUpdateMutation>;
export type DocumentsInfoUpdateMutationOptions = Apollo.BaseMutationOptions<
  DocumentsInfoUpdateMutation,
  DocumentsInfoUpdateMutationVariables
>;
export const CompletedPayoffRequestsJobDocument = gql`
  mutation completedPayoffRequestsJob {
    completedPayoffRequestsJob
  }
`;
export type CompletedPayoffRequestsJobMutationFn = Apollo.MutationFunction<
  CompletedPayoffRequestsJobMutation,
  CompletedPayoffRequestsJobMutationVariables
>;

/**
 * __useCompletedPayoffRequestsJobMutation__
 *
 * To run a mutation, you first call `useCompletedPayoffRequestsJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletedPayoffRequestsJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completedPayoffRequestsJobMutation, { data, loading, error }] = useCompletedPayoffRequestsJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompletedPayoffRequestsJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompletedPayoffRequestsJobMutation,
    CompletedPayoffRequestsJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompletedPayoffRequestsJobMutation,
    CompletedPayoffRequestsJobMutationVariables
  >(CompletedPayoffRequestsJobDocument, options);
}
export type CompletedPayoffRequestsJobMutationHookResult = ReturnType<
  typeof useCompletedPayoffRequestsJobMutation
>;
export type CompletedPayoffRequestsJobMutationResult =
  Apollo.MutationResult<CompletedPayoffRequestsJobMutation>;
export type CompletedPayoffRequestsJobMutationOptions = Apollo.BaseMutationOptions<
  CompletedPayoffRequestsJobMutation,
  CompletedPayoffRequestsJobMutationVariables
>;
export const CreateDownPaymentLinkDocument = gql`
  mutation createDownPaymentLink {
    createDownPaymentLink
  }
`;
export type CreateDownPaymentLinkMutationFn = Apollo.MutationFunction<
  CreateDownPaymentLinkMutation,
  CreateDownPaymentLinkMutationVariables
>;

/**
 * __useCreateDownPaymentLinkMutation__
 *
 * To run a mutation, you first call `useCreateDownPaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDownPaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDownPaymentLinkMutation, { data, loading, error }] = useCreateDownPaymentLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDownPaymentLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDownPaymentLinkMutation,
    CreateDownPaymentLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDownPaymentLinkMutation, CreateDownPaymentLinkMutationVariables>(
    CreateDownPaymentLinkDocument,
    options,
  );
}
export type CreateDownPaymentLinkMutationHookResult = ReturnType<
  typeof useCreateDownPaymentLinkMutation
>;
export type CreateDownPaymentLinkMutationResult =
  Apollo.MutationResult<CreateDownPaymentLinkMutation>;
export type CreateDownPaymentLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateDownPaymentLinkMutation,
  CreateDownPaymentLinkMutationVariables
>;
export const ExecuteDownPaymentDocument = gql`
  mutation executeDownPayment(
    $firstName: String!
    $lastName: String!
    $email: String!
    $amount: Float!
    $phoneNumber: String
    $bank: String!
    $plaidAccountId: String!
    $plaidToken: String!
    $fraudSessionId: String!
    $idempotencyId: String!
    $dealId: ID
  ) {
    executeDownPayment(
      firstName: $firstName
      lastName: $lastName
      email: $email
      amount: $amount
      phoneNumber: $phoneNumber
      bank: $bank
      plaidAccountId: $plaidAccountId
      plaidToken: $plaidToken
      fraudSessionId: $fraudSessionId
      idempotencyId: $idempotencyId
      dealId: $dealId
    ) {
      id
      amount
    }
  }
`;
export type ExecuteDownPaymentMutationFn = Apollo.MutationFunction<
  ExecuteDownPaymentMutation,
  ExecuteDownPaymentMutationVariables
>;

/**
 * __useExecuteDownPaymentMutation__
 *
 * To run a mutation, you first call `useExecuteDownPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteDownPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeDownPaymentMutation, { data, loading, error }] = useExecuteDownPaymentMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      amount: // value for 'amount'
 *      phoneNumber: // value for 'phoneNumber'
 *      bank: // value for 'bank'
 *      plaidAccountId: // value for 'plaidAccountId'
 *      plaidToken: // value for 'plaidToken'
 *      fraudSessionId: // value for 'fraudSessionId'
 *      idempotencyId: // value for 'idempotencyId'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useExecuteDownPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecuteDownPaymentMutation,
    ExecuteDownPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExecuteDownPaymentMutation, ExecuteDownPaymentMutationVariables>(
    ExecuteDownPaymentDocument,
    options,
  );
}
export type ExecuteDownPaymentMutationHookResult = ReturnType<typeof useExecuteDownPaymentMutation>;
export type ExecuteDownPaymentMutationResult = Apollo.MutationResult<ExecuteDownPaymentMutation>;
export type ExecuteDownPaymentMutationOptions = Apollo.BaseMutationOptions<
  ExecuteDownPaymentMutation,
  ExecuteDownPaymentMutationVariables
>;
export const ExecuteCardDownPaymentDocument = gql`
  mutation executeCardDownPayment(
    $firstName: String!
    $lastName: String!
    $email: String!
    $amount: Float!
    $phoneNumber: String
    $fraudSessionId: String!
    $idempotencyId: String!
    $token: String!
    $dealId: ID
    $paymentMethod: paymentMethod!
  ) {
    executeCardDownPayment(
      firstName: $firstName
      lastName: $lastName
      email: $email
      amount: $amount
      phoneNumber: $phoneNumber
      fraudSessionId: $fraudSessionId
      idempotencyId: $idempotencyId
      token: $token
      dealId: $dealId
      paymentMethod: $paymentMethod
    ) {
      id
      amount
      cardBrand
      cardType
      failureCode
      maskedNumber
      state
    }
  }
`;
export type ExecuteCardDownPaymentMutationFn = Apollo.MutationFunction<
  ExecuteCardDownPaymentMutation,
  ExecuteCardDownPaymentMutationVariables
>;

/**
 * __useExecuteCardDownPaymentMutation__
 *
 * To run a mutation, you first call `useExecuteCardDownPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteCardDownPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeCardDownPaymentMutation, { data, loading, error }] = useExecuteCardDownPaymentMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      amount: // value for 'amount'
 *      phoneNumber: // value for 'phoneNumber'
 *      fraudSessionId: // value for 'fraudSessionId'
 *      idempotencyId: // value for 'idempotencyId'
 *      token: // value for 'token'
 *      dealId: // value for 'dealId'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useExecuteCardDownPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecuteCardDownPaymentMutation,
    ExecuteCardDownPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExecuteCardDownPaymentMutation,
    ExecuteCardDownPaymentMutationVariables
  >(ExecuteCardDownPaymentDocument, options);
}
export type ExecuteCardDownPaymentMutationHookResult = ReturnType<
  typeof useExecuteCardDownPaymentMutation
>;
export type ExecuteCardDownPaymentMutationResult =
  Apollo.MutationResult<ExecuteCardDownPaymentMutation>;
export type ExecuteCardDownPaymentMutationOptions = Apollo.BaseMutationOptions<
  ExecuteCardDownPaymentMutation,
  ExecuteCardDownPaymentMutationVariables
>;
export const GetDownPaymentAmountByMethodDocument = gql`
  query getDownPaymentAmountByMethod($dealId: ID!, $paymentType: paymentInstrumentType!) {
    getDownPaymentAmountByMethod(dealId: $dealId, paymentType: $paymentType)
  }
`;

/**
 * __useGetDownPaymentAmountByMethodQuery__
 *
 * To run a query within a React component, call `useGetDownPaymentAmountByMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownPaymentAmountByMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownPaymentAmountByMethodQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      paymentType: // value for 'paymentType'
 *   },
 * });
 */
export function useGetDownPaymentAmountByMethodQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDownPaymentAmountByMethodQuery,
    GetDownPaymentAmountByMethodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDownPaymentAmountByMethodQuery,
    GetDownPaymentAmountByMethodQueryVariables
  >(GetDownPaymentAmountByMethodDocument, options);
}
export function useGetDownPaymentAmountByMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDownPaymentAmountByMethodQuery,
    GetDownPaymentAmountByMethodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDownPaymentAmountByMethodQuery,
    GetDownPaymentAmountByMethodQueryVariables
  >(GetDownPaymentAmountByMethodDocument, options);
}
export type GetDownPaymentAmountByMethodQueryHookResult = ReturnType<
  typeof useGetDownPaymentAmountByMethodQuery
>;
export type GetDownPaymentAmountByMethodLazyQueryHookResult = ReturnType<
  typeof useGetDownPaymentAmountByMethodLazyQuery
>;
export type GetDownPaymentAmountByMethodQueryResult = Apollo.QueryResult<
  GetDownPaymentAmountByMethodQuery,
  GetDownPaymentAmountByMethodQueryVariables
>;
export const CreateFeedbackDocument = gql`
  mutation createFeedback(
    $name: String!
    $email: String!
    $rating: Int!
    $improvements: String!
    $deal_id: ID!
  ) {
    createFeedback(
      name: $name
      email: $email
      rating: $rating
      improvements: $improvements
      deal_id: $deal_id
    )
  }
`;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables
>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      rating: // value for 'rating'
 *      improvements: // value for 'improvements'
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useCreateFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(
    CreateFeedbackDocument,
    options,
  );
}
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables
>;
export const TemporaryInfoUpsertDocument = gql`
  mutation temporaryInfoUpsert($info: temporaryInfoInput) {
    temporaryInfoUpsert(info: $info) {
      id
      data {
        vin
        license_plate_number
        license_plate_state
        year
        make
        model
        fuel_type
        vehicle_type
        color
        mileage
        vehicle_payoff
        phone_number
        email
        current_accordion_panel
        lienholder
        lienholder_slug
        other_lienholder
        lienholderLogo
        first_name
        last_name
        address_line
        address_line_2
        city
        state
        county
        zip
        taxes
        vehicle_registration_fee
        doc_fee
        previous_address_line
        previous_address_line_2
        previous_city
        previous_state
        previous_zip
        previous_county
        scheduled_call_datetime
        scheduled_call_datetime_tz
        primary_residence_type
        primary_residence_years
        primary_residence_months
        primary_residence_monthly_payment
        has_same_address_as_cobuyer
        secondary_residence_years
        secondary_residence_months
        dob
        employment_status
        employer_name
        job_title
        employer_phone_number
        years_at_job
        months_at_job
        salary
        pay_frequency
        previous_employer_name
        previous_job_title
        previous_employer_phone_number
        previous_years_at_job
        previous_months_at_job
        retail_book_value
        kbb_retail_mileage_adjustment
        kbb_retail_option_adjustment
        book_value
        kbb_lending_mileage_adjustment
        kbb_lending_option_adjustment
        kbb_valuation_date
        kbb_vehicle_name
        kbb_trim_name
        kbb_selected_options
        good_through_date
        credit_score
        term
        rate
        down_payment
        taxes_fees
        payment_estimate_low
        payment_estimate_high
        payoff_includes_sales_tax
        lh_account_number
        include_cobuyer
        cobuyer_first_name
        cobuyer_last_name
        cobuyer_employment_status
        cobuyer_employer_name
        cobuyer_employer_phone_number
        cobuyer_job_title
        cobuyer_years_at_job
        cobuyer_months_at_job
        cobuyer_salary
        cobuyer_pay_frequency
        cobuyer_previous_employer_name
        cobuyer_previous_employer_phone_number
        cobuyer_previous_job_title
        cobuyer_previous_years_at_job
        cobuyer_previous_months_at_job
        cobuyer_phone_number
        cobuyer_email
        cobuyer_dob
        cobuyer_address_line
        cobuyer_address_line_2
        cobuyer_city
        cobuyer_state
        cobuyer_zip
        cobuyer_county
        cobuyer_primary_residence_monthly_payment
        cobuyer_primary_residence_type
        cobuyer_primary_residence_years
        cobuyer_primary_residence_months
        cobuyer_previous_address_line
        cobuyer_previous_address_line_2
        cobuyer_previous_city
        cobuyer_previous_state
        cobuyer_previous_zip
        cobuyer_previous_county
        cobuyer_secondary_residence_years
        cobuyer_secondary_residence_months
        cobuyer_relation_to_buyer
        cobuyer_marital_status
        old_lease_payment
        vehicle_offer
        estimated_dealer_fees
        condition_report
        mmr_value
        shopping_cart {
          buyout
          credit_score
          down_payment
          loan_term
          payment_estimate_low
          payment_estimate_high
          estimated_fees
        }
        marital_status
        last_url
        manual_entry
        uploaded_files
        prequalification_success
        is_hard_pull
        prequalification_errors
        cobuyer_prequalification_errors
      }
      status
    }
  }
`;
export type TemporaryInfoUpsertMutationFn = Apollo.MutationFunction<
  TemporaryInfoUpsertMutation,
  TemporaryInfoUpsertMutationVariables
>;

/**
 * __useTemporaryInfoUpsertMutation__
 *
 * To run a mutation, you first call `useTemporaryInfoUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [temporaryInfoUpsertMutation, { data, loading, error }] = useTemporaryInfoUpsertMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useTemporaryInfoUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemporaryInfoUpsertMutation,
    TemporaryInfoUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TemporaryInfoUpsertMutation, TemporaryInfoUpsertMutationVariables>(
    TemporaryInfoUpsertDocument,
    options,
  );
}
export type TemporaryInfoUpsertMutationHookResult = ReturnType<
  typeof useTemporaryInfoUpsertMutation
>;
export type TemporaryInfoUpsertMutationResult = Apollo.MutationResult<TemporaryInfoUpsertMutation>;
export type TemporaryInfoUpsertMutationOptions = Apollo.BaseMutationOptions<
  TemporaryInfoUpsertMutation,
  TemporaryInfoUpsertMutationVariables
>;
export const CreateTemporaryInfoDocument = gql`
  mutation createTemporaryInfo(
    $vin: String
    $license_plate_number: String
    $license_plate_state: String
    $device_type: String
    $extra_input: extraTempInfoInputType
    $query_params: String
    $ignore_existing_deal: Boolean
  ) {
    createTemporaryInfo(
      vin: $vin
      license_plate_number: $license_plate_number
      license_plate_state: $license_plate_state
      device_type: $device_type
      extra_info: $extra_input
      query_params: $query_params
      ignore_existing_deal: $ignore_existing_deal
    ) {
      id
      data {
        vin
        license_plate_number
        license_plate_state
        year
        make
        model
        fuel_type
        vehicle_type
        device_type
        zip
      }
    }
  }
`;
export type CreateTemporaryInfoMutationFn = Apollo.MutationFunction<
  CreateTemporaryInfoMutation,
  CreateTemporaryInfoMutationVariables
>;

/**
 * __useCreateTemporaryInfoMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryInfoMutation, { data, loading, error }] = useCreateTemporaryInfoMutation({
 *   variables: {
 *      vin: // value for 'vin'
 *      license_plate_number: // value for 'license_plate_number'
 *      license_plate_state: // value for 'license_plate_state'
 *      device_type: // value for 'device_type'
 *      extra_input: // value for 'extra_input'
 *      query_params: // value for 'query_params'
 *      ignore_existing_deal: // value for 'ignore_existing_deal'
 *   },
 * });
 */
export function useCreateTemporaryInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTemporaryInfoMutation,
    CreateTemporaryInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTemporaryInfoMutation, CreateTemporaryInfoMutationVariables>(
    CreateTemporaryInfoDocument,
    options,
  );
}
export type CreateTemporaryInfoMutationHookResult = ReturnType<
  typeof useCreateTemporaryInfoMutation
>;
export type CreateTemporaryInfoMutationResult = Apollo.MutationResult<CreateTemporaryInfoMutation>;
export type CreateTemporaryInfoMutationOptions = Apollo.BaseMutationOptions<
  CreateTemporaryInfoMutation,
  CreateTemporaryInfoMutationVariables
>;
export const CreateManualEntryTemporaryInfoDocument = gql`
  mutation createManualEntryTemporaryInfo(
    $license_plate: String
    $state: String
    $vin: String!
    $year: String
    $make: String
    $model: String
    $fuel_type: String
    $vehicle_type: String
    $kbb_trim_name: String
    $device_type: String
    $extra_input: extraTempInfoInputType
    $query_params: String
  ) {
    createManualEntryTemporaryInfo(
      license_plate: $license_plate
      state: $state
      vin: $vin
      year: $year
      make: $make
      model: $model
      fuel_type: $fuel_type
      vehicle_type: $vehicle_type
      kbb_trim_name: $kbb_trim_name
      device_type: $device_type
      extra_info: $extra_input
      query_params: $query_params
    ) {
      id
      data {
        vin
        license_plate_number
        license_plate_state
        year
        make
        model
        fuel_type
        vehicle_type
        device_type
        zip
        kbb_trim_name
      }
    }
  }
`;
export type CreateManualEntryTemporaryInfoMutationFn = Apollo.MutationFunction<
  CreateManualEntryTemporaryInfoMutation,
  CreateManualEntryTemporaryInfoMutationVariables
>;

/**
 * __useCreateManualEntryTemporaryInfoMutation__
 *
 * To run a mutation, you first call `useCreateManualEntryTemporaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualEntryTemporaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualEntryTemporaryInfoMutation, { data, loading, error }] = useCreateManualEntryTemporaryInfoMutation({
 *   variables: {
 *      license_plate: // value for 'license_plate'
 *      state: // value for 'state'
 *      vin: // value for 'vin'
 *      year: // value for 'year'
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      fuel_type: // value for 'fuel_type'
 *      vehicle_type: // value for 'vehicle_type'
 *      kbb_trim_name: // value for 'kbb_trim_name'
 *      device_type: // value for 'device_type'
 *      extra_input: // value for 'extra_input'
 *      query_params: // value for 'query_params'
 *   },
 * });
 */
export function useCreateManualEntryTemporaryInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManualEntryTemporaryInfoMutation,
    CreateManualEntryTemporaryInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManualEntryTemporaryInfoMutation,
    CreateManualEntryTemporaryInfoMutationVariables
  >(CreateManualEntryTemporaryInfoDocument, options);
}
export type CreateManualEntryTemporaryInfoMutationHookResult = ReturnType<
  typeof useCreateManualEntryTemporaryInfoMutation
>;
export type CreateManualEntryTemporaryInfoMutationResult =
  Apollo.MutationResult<CreateManualEntryTemporaryInfoMutation>;
export type CreateManualEntryTemporaryInfoMutationOptions = Apollo.BaseMutationOptions<
  CreateManualEntryTemporaryInfoMutation,
  CreateManualEntryTemporaryInfoMutationVariables
>;
export const CreateAffiliateTemporaryInfoDocument = gql`
  mutation createAffiliateTemporaryInfo($device_type: String, $query_params: String) {
    createAffiliateTemporaryInfo(device_type: $device_type, query_params: $query_params) {
      id
      data {
        first_name
        last_name
      }
    }
  }
`;
export type CreateAffiliateTemporaryInfoMutationFn = Apollo.MutationFunction<
  CreateAffiliateTemporaryInfoMutation,
  CreateAffiliateTemporaryInfoMutationVariables
>;

/**
 * __useCreateAffiliateTemporaryInfoMutation__
 *
 * To run a mutation, you first call `useCreateAffiliateTemporaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliateTemporaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliateTemporaryInfoMutation, { data, loading, error }] = useCreateAffiliateTemporaryInfoMutation({
 *   variables: {
 *      device_type: // value for 'device_type'
 *      query_params: // value for 'query_params'
 *   },
 * });
 */
export function useCreateAffiliateTemporaryInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAffiliateTemporaryInfoMutation,
    CreateAffiliateTemporaryInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAffiliateTemporaryInfoMutation,
    CreateAffiliateTemporaryInfoMutationVariables
  >(CreateAffiliateTemporaryInfoDocument, options);
}
export type CreateAffiliateTemporaryInfoMutationHookResult = ReturnType<
  typeof useCreateAffiliateTemporaryInfoMutation
>;
export type CreateAffiliateTemporaryInfoMutationResult =
  Apollo.MutationResult<CreateAffiliateTemporaryInfoMutation>;
export type CreateAffiliateTemporaryInfoMutationOptions = Apollo.BaseMutationOptions<
  CreateAffiliateTemporaryInfoMutation,
  CreateAffiliateTemporaryInfoMutationVariables
>;
export const DecodeAffiliateCarDetailsDocument = gql`
  query decodeAffiliateCarDetails(
    $vin: String
    $license_plate_number: String
    $license_plate_state: String
  ) {
    decodeAffiliateCarDetails(
      vin: $vin
      license_plate_number: $license_plate_number
      license_plate_state: $license_plate_state
    ) {
      license_plate_number
      license_plate_state
      vin
      year
      make
      model
      fuel_type
      vehicle_type
      kbb_trim_name
    }
  }
`;

/**
 * __useDecodeAffiliateCarDetailsQuery__
 *
 * To run a query within a React component, call `useDecodeAffiliateCarDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecodeAffiliateCarDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecodeAffiliateCarDetailsQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *      license_plate_number: // value for 'license_plate_number'
 *      license_plate_state: // value for 'license_plate_state'
 *   },
 * });
 */
export function useDecodeAffiliateCarDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DecodeAffiliateCarDetailsQuery,
    DecodeAffiliateCarDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DecodeAffiliateCarDetailsQuery, DecodeAffiliateCarDetailsQueryVariables>(
    DecodeAffiliateCarDetailsDocument,
    options,
  );
}
export function useDecodeAffiliateCarDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DecodeAffiliateCarDetailsQuery,
    DecodeAffiliateCarDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DecodeAffiliateCarDetailsQuery,
    DecodeAffiliateCarDetailsQueryVariables
  >(DecodeAffiliateCarDetailsDocument, options);
}
export type DecodeAffiliateCarDetailsQueryHookResult = ReturnType<
  typeof useDecodeAffiliateCarDetailsQuery
>;
export type DecodeAffiliateCarDetailsLazyQueryHookResult = ReturnType<
  typeof useDecodeAffiliateCarDetailsLazyQuery
>;
export type DecodeAffiliateCarDetailsQueryResult = Apollo.QueryResult<
  DecodeAffiliateCarDetailsQuery,
  DecodeAffiliateCarDetailsQueryVariables
>;
export const TemporaryInfoUpsertAndImportDocument = gql`
  mutation temporaryInfoUpsertAndImport($info: temporaryInfoInput, $transformType: transformType!) {
    temporaryInfoUpsertAndImport(info: $info, transformType: $transformType) {
      id
      deal_id
      data {
        phone_number
        email
        scheduled_call_datetime
        scheduled_call_datetime_tz
        first_name
        lienholder
        other_lienholder
      }
    }
  }
`;
export type TemporaryInfoUpsertAndImportMutationFn = Apollo.MutationFunction<
  TemporaryInfoUpsertAndImportMutation,
  TemporaryInfoUpsertAndImportMutationVariables
>;

/**
 * __useTemporaryInfoUpsertAndImportMutation__
 *
 * To run a mutation, you first call `useTemporaryInfoUpsertAndImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoUpsertAndImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [temporaryInfoUpsertAndImportMutation, { data, loading, error }] = useTemporaryInfoUpsertAndImportMutation({
 *   variables: {
 *      info: // value for 'info'
 *      transformType: // value for 'transformType'
 *   },
 * });
 */
export function useTemporaryInfoUpsertAndImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemporaryInfoUpsertAndImportMutation,
    TemporaryInfoUpsertAndImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TemporaryInfoUpsertAndImportMutation,
    TemporaryInfoUpsertAndImportMutationVariables
  >(TemporaryInfoUpsertAndImportDocument, options);
}
export type TemporaryInfoUpsertAndImportMutationHookResult = ReturnType<
  typeof useTemporaryInfoUpsertAndImportMutation
>;
export type TemporaryInfoUpsertAndImportMutationResult =
  Apollo.MutationResult<TemporaryInfoUpsertAndImportMutation>;
export type TemporaryInfoUpsertAndImportMutationOptions = Apollo.BaseMutationOptions<
  TemporaryInfoUpsertAndImportMutation,
  TemporaryInfoUpsertAndImportMutationVariables
>;
export const TemporaryInfoImportDocument = gql`
  mutation temporaryInfoImport($id: ID!, $transformType: transformType!, $importType: importType) {
    temporaryInfoImport(id: $id, transformType: $transformType, importType: $importType) {
      id
      customer {
        id
      }
    }
  }
`;
export type TemporaryInfoImportMutationFn = Apollo.MutationFunction<
  TemporaryInfoImportMutation,
  TemporaryInfoImportMutationVariables
>;

/**
 * __useTemporaryInfoImportMutation__
 *
 * To run a mutation, you first call `useTemporaryInfoImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [temporaryInfoImportMutation, { data, loading, error }] = useTemporaryInfoImportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      transformType: // value for 'transformType'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useTemporaryInfoImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemporaryInfoImportMutation,
    TemporaryInfoImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TemporaryInfoImportMutation, TemporaryInfoImportMutationVariables>(
    TemporaryInfoImportDocument,
    options,
  );
}
export type TemporaryInfoImportMutationHookResult = ReturnType<
  typeof useTemporaryInfoImportMutation
>;
export type TemporaryInfoImportMutationResult = Apollo.MutationResult<TemporaryInfoImportMutation>;
export type TemporaryInfoImportMutationOptions = Apollo.BaseMutationOptions<
  TemporaryInfoImportMutation,
  TemporaryInfoImportMutationVariables
>;
export const UnlinkTemporaryInfoDocument = gql`
  mutation unlinkTemporaryInfo($id: ID!) {
    unlinkTemporaryInfo(id: $id) {
      id
    }
  }
`;
export type UnlinkTemporaryInfoMutationFn = Apollo.MutationFunction<
  UnlinkTemporaryInfoMutation,
  UnlinkTemporaryInfoMutationVariables
>;

/**
 * __useUnlinkTemporaryInfoMutation__
 *
 * To run a mutation, you first call `useUnlinkTemporaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkTemporaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkTemporaryInfoMutation, { data, loading, error }] = useUnlinkTemporaryInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlinkTemporaryInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkTemporaryInfoMutation,
    UnlinkTemporaryInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnlinkTemporaryInfoMutation, UnlinkTemporaryInfoMutationVariables>(
    UnlinkTemporaryInfoDocument,
    options,
  );
}
export type UnlinkTemporaryInfoMutationHookResult = ReturnType<
  typeof useUnlinkTemporaryInfoMutation
>;
export type UnlinkTemporaryInfoMutationResult = Apollo.MutationResult<UnlinkTemporaryInfoMutation>;
export type UnlinkTemporaryInfoMutationOptions = Apollo.BaseMutationOptions<
  UnlinkTemporaryInfoMutation,
  UnlinkTemporaryInfoMutationVariables
>;
export const TemporaryInfoDocument = gql`
  query temporaryInfo($id: ID!) {
    temporaryInfo(id: $id) {
      id
      data {
        vin
        year
        make
        model
        fuel_type
        vehicle_type
        color
        mileage
        vehicle_payoff
        user_entered_total_payoff
        phone_number
        email
        current_accordion_panel
        lienholder
        lienholderLogo
        lienholder_slug
        other_lienholder
        first_name
        middle_name
        last_name
        address_line
        address_line_2
        city
        state
        county
        zip
        taxes
        vehicle_registration_fee
        doc_fee
        has_full_ssn
        previous_address_line
        previous_address_line_2
        previous_city
        previous_state
        previous_zip
        previous_county
        scheduled_call_datetime
        scheduled_call_datetime_tz
        primary_residence_type
        primary_residence_years
        primary_residence_months
        primary_residence_monthly_payment
        has_same_address_as_cobuyer
        secondary_residence_years
        secondary_residence_months
        dob
        employment_status
        employer_name
        job_title
        employer_phone_number
        years_at_job
        months_at_job
        salary
        pay_frequency
        previous_employer_name
        previous_job_title
        previous_employer_phone_number
        previous_years_at_job
        previous_months_at_job
        retail_book_value
        kbb_retail_mileage_adjustment
        kbb_retail_option_adjustment
        book_value
        kbb_lending_mileage_adjustment
        kbb_lending_option_adjustment
        kbb_valuation_date
        kbb_vehicle_name
        kbb_vehicle_id
        kbb_all_options
        kbb_trim_name
        kbb_selected_options
        good_through_date
        credit_score
        term
        rate
        down_payment
        taxes_fees
        payment_estimate_low
        payment_estimate_high
        payoff_includes_sales_tax
        lh_account_number
        include_cobuyer
        cobuyer_first_name
        cobuyer_last_name
        cobuyer_employment_status
        cobuyer_employer_name
        cobuyer_employer_phone_number
        cobuyer_job_title
        cobuyer_years_at_job
        cobuyer_months_at_job
        cobuyer_salary
        cobuyer_pay_frequency
        cobuyer_previous_employer_name
        cobuyer_previous_employer_phone_number
        cobuyer_previous_job_title
        cobuyer_previous_years_at_job
        cobuyer_previous_months_at_job
        cobuyer_phone_number
        cobuyer_email
        cobuyer_dob
        cobuyer_address_line
        cobuyer_address_line_2
        cobuyer_city
        cobuyer_state
        cobuyer_zip
        cobuyer_county
        cobuyer_primary_residence_monthly_payment
        cobuyer_primary_residence_type
        cobuyer_primary_residence_years
        cobuyer_primary_residence_months
        cobuyer_previous_address_line
        cobuyer_previous_address_line_2
        cobuyer_previous_city
        cobuyer_previous_state
        cobuyer_previous_zip
        cobuyer_previous_county
        cobuyer_secondary_residence_years
        cobuyer_secondary_residence_months
        cobuyer_relation_to_buyer
        cobuyer_marital_status
        old_lease_payment
        vehicle_offer
        estimated_dealer_fees
        condition_report
        mmr_value
        license_plate_number
        license_plate_state
        shopping_cart {
          buyout
          credit_score
          down_payment
          loan_term
          payment_estimate_low
          payment_estimate_high
          estimated_fees
        }
        marital_status
        sales_tax_from_payoff
        last_url
        query_params
        manual_entry
        uploaded_files
        prequalification_success
        is_hard_pull
        prequalification_errors
        cobuyer_prequalification_errors
        prequal_id
      }
    }
  }
`;

/**
 * __useTemporaryInfoQuery__
 *
 * To run a query within a React component, call `useTemporaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemporaryInfoQuery(
  baseOptions: Apollo.QueryHookOptions<TemporaryInfoQuery, TemporaryInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemporaryInfoQuery, TemporaryInfoQueryVariables>(
    TemporaryInfoDocument,
    options,
  );
}
export function useTemporaryInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemporaryInfoQuery, TemporaryInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemporaryInfoQuery, TemporaryInfoQueryVariables>(
    TemporaryInfoDocument,
    options,
  );
}
export type TemporaryInfoQueryHookResult = ReturnType<typeof useTemporaryInfoQuery>;
export type TemporaryInfoLazyQueryHookResult = ReturnType<typeof useTemporaryInfoLazyQuery>;
export type TemporaryInfoQueryResult = Apollo.QueryResult<
  TemporaryInfoQuery,
  TemporaryInfoQueryVariables
>;
export const KbbVinDocument = gql`
  query kbbVin($vin: String!) {
    kbbVin(vin: $vin) {
      warning
      vinResults {
        vehicleId
        vehicleOptions {
          categoryGroup
          hasRelationships
          isConfigurable
          isConsumer
          isTypical
          isVinDecoded
          optionName
          optionType
          sortOrder
          vehicleId
          vehicleOptionId
        }
        modelName
        trimName
        makeName
        yearId
        vehicleName
      }
    }
  }
`;

/**
 * __useKbbVinQuery__
 *
 * To run a query within a React component, call `useKbbVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbbVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbbVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useKbbVinQuery(
  baseOptions: Apollo.QueryHookOptions<KbbVinQuery, KbbVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KbbVinQuery, KbbVinQueryVariables>(KbbVinDocument, options);
}
export function useKbbVinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KbbVinQuery, KbbVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KbbVinQuery, KbbVinQueryVariables>(KbbVinDocument, options);
}
export type KbbVinQueryHookResult = ReturnType<typeof useKbbVinQuery>;
export type KbbVinLazyQueryHookResult = ReturnType<typeof useKbbVinLazyQuery>;
export type KbbVinQueryResult = Apollo.QueryResult<KbbVinQuery, KbbVinQueryVariables>;
export const KbbValuesDocument = gql`
  query kbbValues($data: kbbValuesDataType!) {
    kbbValues(data: $data) {
      retail
      retailMileageAdjustment
      retailOptionAdjustment
      retailOptionPrices
      lending
      lendingMileageAdjustment
      lendingOptionAdjustment
      lendingOptionPrices
      valuationDate
      kbbSelectedOptions {
        id
        name
        type
        removed
        lendingOptionPrice
        retailOptionPrice
      }
    }
  }
`;

/**
 * __useKbbValuesQuery__
 *
 * To run a query within a React component, call `useKbbValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbbValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbbValuesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useKbbValuesQuery(
  baseOptions: Apollo.QueryHookOptions<KbbValuesQuery, KbbValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KbbValuesQuery, KbbValuesQueryVariables>(KbbValuesDocument, options);
}
export function useKbbValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KbbValuesQuery, KbbValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KbbValuesQuery, KbbValuesQueryVariables>(KbbValuesDocument, options);
}
export type KbbValuesQueryHookResult = ReturnType<typeof useKbbValuesQuery>;
export type KbbValuesLazyQueryHookResult = ReturnType<typeof useKbbValuesLazyQuery>;
export type KbbValuesQueryResult = Apollo.QueryResult<KbbValuesQuery, KbbValuesQueryVariables>;
export const CalculateOptionsDocument = gql`
  query calculateOptions(
    $vehicleId: Int
    $allKbbVehicleOptions: [kbbOptionInput]
    $startingVehicleOptions: [Int]
    $configToModify: [kbbConfigType]
    $vin: String!
    $color: String
  ) {
    calculateOptions(
      vehicleId: $vehicleId
      allKbbVehicleOptions: $allKbbVehicleOptions
      startingVehicleOptions: $startingVehicleOptions
      configToModify: $configToModify
      vin: $vin
      color: $color
    ) {
      vehicleOptionId
      optionName
      optionType
      categoryGroup
    }
  }
`;

/**
 * __useCalculateOptionsQuery__
 *
 * To run a query within a React component, call `useCalculateOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateOptionsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      allKbbVehicleOptions: // value for 'allKbbVehicleOptions'
 *      startingVehicleOptions: // value for 'startingVehicleOptions'
 *      configToModify: // value for 'configToModify'
 *      vin: // value for 'vin'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCalculateOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateOptionsQuery, CalculateOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalculateOptionsQuery, CalculateOptionsQueryVariables>(
    CalculateOptionsDocument,
    options,
  );
}
export function useCalculateOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateOptionsQuery, CalculateOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalculateOptionsQuery, CalculateOptionsQueryVariables>(
    CalculateOptionsDocument,
    options,
  );
}
export type CalculateOptionsQueryHookResult = ReturnType<typeof useCalculateOptionsQuery>;
export type CalculateOptionsLazyQueryHookResult = ReturnType<typeof useCalculateOptionsLazyQuery>;
export type CalculateOptionsQueryResult = Apollo.QueryResult<
  CalculateOptionsQuery,
  CalculateOptionsQueryVariables
>;
export const CreateExternalTempInfoUploadUrlDocument = gql`
  mutation createExternalTempInfoUploadUrl(
    $guid: ID!
    $fileName: String!
    $fileDescription: String!
  ) {
    createExternalTempInfoUploadUrl(
      guid: $guid
      fileName: $fileName
      fileDescription: $fileDescription
    ) {
      url
    }
  }
`;
export type CreateExternalTempInfoUploadUrlMutationFn = Apollo.MutationFunction<
  CreateExternalTempInfoUploadUrlMutation,
  CreateExternalTempInfoUploadUrlMutationVariables
>;

/**
 * __useCreateExternalTempInfoUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateExternalTempInfoUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalTempInfoUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalTempInfoUploadUrlMutation, { data, loading, error }] = useCreateExternalTempInfoUploadUrlMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      fileName: // value for 'fileName'
 *      fileDescription: // value for 'fileDescription'
 *   },
 * });
 */
export function useCreateExternalTempInfoUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalTempInfoUploadUrlMutation,
    CreateExternalTempInfoUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalTempInfoUploadUrlMutation,
    CreateExternalTempInfoUploadUrlMutationVariables
  >(CreateExternalTempInfoUploadUrlDocument, options);
}
export type CreateExternalTempInfoUploadUrlMutationHookResult = ReturnType<
  typeof useCreateExternalTempInfoUploadUrlMutation
>;
export type CreateExternalTempInfoUploadUrlMutationResult =
  Apollo.MutationResult<CreateExternalTempInfoUploadUrlMutation>;
export type CreateExternalTempInfoUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateExternalTempInfoUploadUrlMutation,
  CreateExternalTempInfoUploadUrlMutationVariables
>;
export const CreateExternalDealUploadUrlDocument = gql`
  mutation createExternalDealUploadUrl(
    $fileName: String!
    $fileDescription: String!
    $dealId: ID!
  ) {
    createExternalDealUploadUrl(
      fileName: $fileName
      fileDescription: $fileDescription
      dealId: $dealId
    ) {
      key
      url
    }
  }
`;
export type CreateExternalDealUploadUrlMutationFn = Apollo.MutationFunction<
  CreateExternalDealUploadUrlMutation,
  CreateExternalDealUploadUrlMutationVariables
>;

/**
 * __useCreateExternalDealUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateExternalDealUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalDealUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalDealUploadUrlMutation, { data, loading, error }] = useCreateExternalDealUploadUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileDescription: // value for 'fileDescription'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useCreateExternalDealUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalDealUploadUrlMutation,
    CreateExternalDealUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalDealUploadUrlMutation,
    CreateExternalDealUploadUrlMutationVariables
  >(CreateExternalDealUploadUrlDocument, options);
}
export type CreateExternalDealUploadUrlMutationHookResult = ReturnType<
  typeof useCreateExternalDealUploadUrlMutation
>;
export type CreateExternalDealUploadUrlMutationResult =
  Apollo.MutationResult<CreateExternalDealUploadUrlMutation>;
export type CreateExternalDealUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateExternalDealUploadUrlMutation,
  CreateExternalDealUploadUrlMutationVariables
>;
export const DeleteComFlowFileDocument = gql`
  mutation deleteComFlowFile($key: String!) {
    deleteComFlowFile(key: $key)
  }
`;
export type DeleteComFlowFileMutationFn = Apollo.MutationFunction<
  DeleteComFlowFileMutation,
  DeleteComFlowFileMutationVariables
>;

/**
 * __useDeleteComFlowFileMutation__
 *
 * To run a mutation, you first call `useDeleteComFlowFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteComFlowFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteComFlowFileMutation, { data, loading, error }] = useDeleteComFlowFileMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteComFlowFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteComFlowFileMutation,
    DeleteComFlowFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteComFlowFileMutation, DeleteComFlowFileMutationVariables>(
    DeleteComFlowFileDocument,
    options,
  );
}
export type DeleteComFlowFileMutationHookResult = ReturnType<typeof useDeleteComFlowFileMutation>;
export type DeleteComFlowFileMutationResult = Apollo.MutationResult<DeleteComFlowFileMutation>;
export type DeleteComFlowFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteComFlowFileMutation,
  DeleteComFlowFileMutationVariables
>;
export const CreateFetchUrlsFromComDocument = gql`
  query createFetchUrlsFromCom($keys: [String]!) {
    createFetchUrlsFromCom(keys: $keys)
  }
`;

/**
 * __useCreateFetchUrlsFromComQuery__
 *
 * To run a query within a React component, call `useCreateFetchUrlsFromComQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateFetchUrlsFromComQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateFetchUrlsFromComQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useCreateFetchUrlsFromComQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateFetchUrlsFromComQuery,
    CreateFetchUrlsFromComQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CreateFetchUrlsFromComQuery, CreateFetchUrlsFromComQueryVariables>(
    CreateFetchUrlsFromComDocument,
    options,
  );
}
export function useCreateFetchUrlsFromComLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateFetchUrlsFromComQuery,
    CreateFetchUrlsFromComQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CreateFetchUrlsFromComQuery, CreateFetchUrlsFromComQueryVariables>(
    CreateFetchUrlsFromComDocument,
    options,
  );
}
export type CreateFetchUrlsFromComQueryHookResult = ReturnType<
  typeof useCreateFetchUrlsFromComQuery
>;
export type CreateFetchUrlsFromComLazyQueryHookResult = ReturnType<
  typeof useCreateFetchUrlsFromComLazyQuery
>;
export type CreateFetchUrlsFromComQueryResult = Apollo.QueryResult<
  CreateFetchUrlsFromComQuery,
  CreateFetchUrlsFromComQueryVariables
>;
export const MediaDeleteFromComDocument = gql`
  mutation mediaDeleteFromCom($dealId: ID!, $filePath: String!) {
    mediaDeleteFromCom(dealId: $dealId, filePath: $filePath)
  }
`;
export type MediaDeleteFromComMutationFn = Apollo.MutationFunction<
  MediaDeleteFromComMutation,
  MediaDeleteFromComMutationVariables
>;

/**
 * __useMediaDeleteFromComMutation__
 *
 * To run a mutation, you first call `useMediaDeleteFromComMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaDeleteFromComMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaDeleteFromComMutation, { data, loading, error }] = useMediaDeleteFromComMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      filePath: // value for 'filePath'
 *   },
 * });
 */
export function useMediaDeleteFromComMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MediaDeleteFromComMutation,
    MediaDeleteFromComMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MediaDeleteFromComMutation, MediaDeleteFromComMutationVariables>(
    MediaDeleteFromComDocument,
    options,
  );
}
export type MediaDeleteFromComMutationHookResult = ReturnType<typeof useMediaDeleteFromComMutation>;
export type MediaDeleteFromComMutationResult = Apollo.MutationResult<MediaDeleteFromComMutation>;
export type MediaDeleteFromComMutationOptions = Apollo.BaseMutationOptions<
  MediaDeleteFromComMutation,
  MediaDeleteFromComMutationVariables
>;
export const MediaInsertComDocument = gql`
  mutation mediaInsertCom($key: String!, $type: String!, $dealId: ID!) {
    mediaInsertCom(key: $key, type: $type, dealId: $dealId) {
      id
      deal_id
      key
      signed_url
      type
    }
  }
`;
export type MediaInsertComMutationFn = Apollo.MutationFunction<
  MediaInsertComMutation,
  MediaInsertComMutationVariables
>;

/**
 * __useMediaInsertComMutation__
 *
 * To run a mutation, you first call `useMediaInsertComMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaInsertComMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaInsertComMutation, { data, loading, error }] = useMediaInsertComMutation({
 *   variables: {
 *      key: // value for 'key'
 *      type: // value for 'type'
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useMediaInsertComMutation(
  baseOptions?: Apollo.MutationHookOptions<MediaInsertComMutation, MediaInsertComMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MediaInsertComMutation, MediaInsertComMutationVariables>(
    MediaInsertComDocument,
    options,
  );
}
export type MediaInsertComMutationHookResult = ReturnType<typeof useMediaInsertComMutation>;
export type MediaInsertComMutationResult = Apollo.MutationResult<MediaInsertComMutation>;
export type MediaInsertComMutationOptions = Apollo.BaseMutationOptions<
  MediaInsertComMutation,
  MediaInsertComMutationVariables
>;
export const MediaFromComDocument = gql`
  query mediaFromCom($dealId: ID) {
    mediaFromCom(dealId: $dealId) {
      id
      deal_id
      key
      signed_url
      type
      uploaded_by_customer
    }
  }
`;

/**
 * __useMediaFromComQuery__
 *
 * To run a query within a React component, call `useMediaFromComQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaFromComQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaFromComQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useMediaFromComQuery(
  baseOptions?: Apollo.QueryHookOptions<MediaFromComQuery, MediaFromComQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaFromComQuery, MediaFromComQueryVariables>(
    MediaFromComDocument,
    options,
  );
}
export function useMediaFromComLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaFromComQuery, MediaFromComQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaFromComQuery, MediaFromComQueryVariables>(
    MediaFromComDocument,
    options,
  );
}
export type MediaFromComQueryHookResult = ReturnType<typeof useMediaFromComQuery>;
export type MediaFromComLazyQueryHookResult = ReturnType<typeof useMediaFromComLazyQuery>;
export type MediaFromComQueryResult = Apollo.QueryResult<
  MediaFromComQuery,
  MediaFromComQueryVariables
>;
export const MediaUpdateComDocument = gql`
  mutation mediaUpdateCom($dealId: ID!, $oldKey: String!, $newFileName: String, $type: String) {
    mediaUpdateCom(dealId: $dealId, oldKey: $oldKey, newFileName: $newFileName, type: $type) {
      id
      deal_id
      key
      signed_url
      type
    }
  }
`;
export type MediaUpdateComMutationFn = Apollo.MutationFunction<
  MediaUpdateComMutation,
  MediaUpdateComMutationVariables
>;

/**
 * __useMediaUpdateComMutation__
 *
 * To run a mutation, you first call `useMediaUpdateComMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaUpdateComMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaUpdateComMutation, { data, loading, error }] = useMediaUpdateComMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      oldKey: // value for 'oldKey'
 *      newFileName: // value for 'newFileName'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMediaUpdateComMutation(
  baseOptions?: Apollo.MutationHookOptions<MediaUpdateComMutation, MediaUpdateComMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MediaUpdateComMutation, MediaUpdateComMutationVariables>(
    MediaUpdateComDocument,
    options,
  );
}
export type MediaUpdateComMutationHookResult = ReturnType<typeof useMediaUpdateComMutation>;
export type MediaUpdateComMutationResult = Apollo.MutationResult<MediaUpdateComMutation>;
export type MediaUpdateComMutationOptions = Apollo.BaseMutationOptions<
  MediaUpdateComMutation,
  MediaUpdateComMutationVariables
>;
export const GetOperatingHoursAndTimeSlotsDocument = gql`
  query getOperatingHoursAndTimeSlots {
    getOperatingHoursAndTimeSlots {
      isOpen
      utcTimeSlots
    }
  }
`;

/**
 * __useGetOperatingHoursAndTimeSlotsQuery__
 *
 * To run a query within a React component, call `useGetOperatingHoursAndTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatingHoursAndTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatingHoursAndTimeSlotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOperatingHoursAndTimeSlotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >(GetOperatingHoursAndTimeSlotsDocument, options);
}
export function useGetOperatingHoursAndTimeSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >(GetOperatingHoursAndTimeSlotsDocument, options);
}
export type GetOperatingHoursAndTimeSlotsQueryHookResult = ReturnType<
  typeof useGetOperatingHoursAndTimeSlotsQuery
>;
export type GetOperatingHoursAndTimeSlotsLazyQueryHookResult = ReturnType<
  typeof useGetOperatingHoursAndTimeSlotsLazyQuery
>;
export type GetOperatingHoursAndTimeSlotsQueryResult = Apollo.QueryResult<
  GetOperatingHoursAndTimeSlotsQuery,
  GetOperatingHoursAndTimeSlotsQueryVariables
>;
export const CreateOptEventDocument = gql`
  mutation createOptEvent($event: optEventInput!, $token: String) {
    createOptEvent(event: $event, token: $token) {
      id
    }
  }
`;
export type CreateOptEventMutationFn = Apollo.MutationFunction<
  CreateOptEventMutation,
  CreateOptEventMutationVariables
>;

/**
 * __useCreateOptEventMutation__
 *
 * To run a mutation, you first call `useCreateOptEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptEventMutation, { data, loading, error }] = useCreateOptEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateOptEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOptEventMutation, CreateOptEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOptEventMutation, CreateOptEventMutationVariables>(
    CreateOptEventDocument,
    options,
  );
}
export type CreateOptEventMutationHookResult = ReturnType<typeof useCreateOptEventMutation>;
export type CreateOptEventMutationResult = Apollo.MutationResult<CreateOptEventMutation>;
export type CreateOptEventMutationOptions = Apollo.BaseMutationOptions<
  CreateOptEventMutation,
  CreateOptEventMutationVariables
>;
export const PaymentEstimateDocument = gql`
  query paymentEstimate($data: paymentEstimateInput) {
    paymentEstimate(data: $data) {
      paymentEstimateLow
      paymentEstimateHigh
      estimatedTaxesAndFees
      totalEstimatedLoan
      estimatedAnnualPercentageRate
      feeDetails {
        baseTaxAmount
        warrantyTaxAmount
        docFeeTaxAmount
        docFee
        titleFee
        registrationFee
      }
    }
  }
`;

/**
 * __usePaymentEstimateQuery__
 *
 * To run a query within a React component, call `usePaymentEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentEstimateQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePaymentEstimateQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentEstimateQuery, PaymentEstimateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentEstimateQuery, PaymentEstimateQueryVariables>(
    PaymentEstimateDocument,
    options,
  );
}
export function usePaymentEstimateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentEstimateQuery, PaymentEstimateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentEstimateQuery, PaymentEstimateQueryVariables>(
    PaymentEstimateDocument,
    options,
  );
}
export type PaymentEstimateQueryHookResult = ReturnType<typeof usePaymentEstimateQuery>;
export type PaymentEstimateLazyQueryHookResult = ReturnType<typeof usePaymentEstimateLazyQuery>;
export type PaymentEstimateQueryResult = Apollo.QueryResult<
  PaymentEstimateQuery,
  PaymentEstimateQueryVariables
>;
export const SendPaymentEstimateEmailAndTextDocument = gql`
  mutation sendPaymentEstimateEmailAndText(
    $data: paymentEstimateInput!
    $email: String!
    $phoneNumber: String!
  ) {
    sendPaymentEstimateEmailAndText(data: $data, email: $email, phoneNumber: $phoneNumber)
  }
`;
export type SendPaymentEstimateEmailAndTextMutationFn = Apollo.MutationFunction<
  SendPaymentEstimateEmailAndTextMutation,
  SendPaymentEstimateEmailAndTextMutationVariables
>;

/**
 * __useSendPaymentEstimateEmailAndTextMutation__
 *
 * To run a mutation, you first call `useSendPaymentEstimateEmailAndTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPaymentEstimateEmailAndTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPaymentEstimateEmailAndTextMutation, { data, loading, error }] = useSendPaymentEstimateEmailAndTextMutation({
 *   variables: {
 *      data: // value for 'data'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSendPaymentEstimateEmailAndTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPaymentEstimateEmailAndTextMutation,
    SendPaymentEstimateEmailAndTextMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPaymentEstimateEmailAndTextMutation,
    SendPaymentEstimateEmailAndTextMutationVariables
  >(SendPaymentEstimateEmailAndTextDocument, options);
}
export type SendPaymentEstimateEmailAndTextMutationHookResult = ReturnType<
  typeof useSendPaymentEstimateEmailAndTextMutation
>;
export type SendPaymentEstimateEmailAndTextMutationResult =
  Apollo.MutationResult<SendPaymentEstimateEmailAndTextMutation>;
export type SendPaymentEstimateEmailAndTextMutationOptions = Apollo.BaseMutationOptions<
  SendPaymentEstimateEmailAndTextMutation,
  SendPaymentEstimateEmailAndTextMutationVariables
>;
export const PayoffRequestDocument = gql`
  query payoffRequest {
    payoffRequest {
      temporary_info_id
      status
      vehicle_payoff
      sales_tax
      completed_date
      good_through_date
    }
  }
`;

/**
 * __usePayoffRequestQuery__
 *
 * To run a query within a React component, call `usePayoffRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoffRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayoffRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<PayoffRequestQuery, PayoffRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayoffRequestQuery, PayoffRequestQueryVariables>(
    PayoffRequestDocument,
    options,
  );
}
export function usePayoffRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayoffRequestQuery, PayoffRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayoffRequestQuery, PayoffRequestQueryVariables>(
    PayoffRequestDocument,
    options,
  );
}
export type PayoffRequestQueryHookResult = ReturnType<typeof usePayoffRequestQuery>;
export type PayoffRequestLazyQueryHookResult = ReturnType<typeof usePayoffRequestLazyQuery>;
export type PayoffRequestQueryResult = Apollo.QueryResult<
  PayoffRequestQuery,
  PayoffRequestQueryVariables
>;
export const CreatePayoffRequestDocument = gql`
  mutation createPayoffRequest {
    createPayoffRequest {
      temporary_info_id
      status
      vehicle_payoff
      sales_tax
      completed_date
      good_through_date
    }
  }
`;
export type CreatePayoffRequestMutationFn = Apollo.MutationFunction<
  CreatePayoffRequestMutation,
  CreatePayoffRequestMutationVariables
>;

/**
 * __useCreatePayoffRequestMutation__
 *
 * To run a mutation, you first call `useCreatePayoffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayoffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayoffRequestMutation, { data, loading, error }] = useCreatePayoffRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePayoffRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayoffRequestMutation,
    CreatePayoffRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePayoffRequestMutation, CreatePayoffRequestMutationVariables>(
    CreatePayoffRequestDocument,
    options,
  );
}
export type CreatePayoffRequestMutationHookResult = ReturnType<
  typeof useCreatePayoffRequestMutation
>;
export type CreatePayoffRequestMutationResult = Apollo.MutationResult<CreatePayoffRequestMutation>;
export type CreatePayoffRequestMutationOptions = Apollo.BaseMutationOptions<
  CreatePayoffRequestMutation,
  CreatePayoffRequestMutationVariables
>;
export const CancelPayoffRequestDocument = gql`
  mutation cancelPayoffRequest {
    cancelPayoffRequest {
      temporary_info_id
      status
      vehicle_payoff
      sales_tax
      completed_date
      good_through_date
    }
  }
`;
export type CancelPayoffRequestMutationFn = Apollo.MutationFunction<
  CancelPayoffRequestMutation,
  CancelPayoffRequestMutationVariables
>;

/**
 * __useCancelPayoffRequestMutation__
 *
 * To run a mutation, you first call `useCancelPayoffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPayoffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPayoffRequestMutation, { data, loading, error }] = useCancelPayoffRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelPayoffRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPayoffRequestMutation,
    CancelPayoffRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPayoffRequestMutation, CancelPayoffRequestMutationVariables>(
    CancelPayoffRequestDocument,
    options,
  );
}
export type CancelPayoffRequestMutationHookResult = ReturnType<
  typeof useCancelPayoffRequestMutation
>;
export type CancelPayoffRequestMutationResult = Apollo.MutationResult<CancelPayoffRequestMutation>;
export type CancelPayoffRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelPayoffRequestMutation,
  CancelPayoffRequestMutationVariables
>;
export const PayoffRequestUpdateDocument = gql`
  mutation payoffRequestUpdate($payoffRequest: payoffRequestInput!) {
    payoffRequestUpdate(payoffRequest: $payoffRequest) {
      temporary_info_id
      status
      vehicle_payoff
      sales_tax
      completed_date
      good_through_date
    }
  }
`;
export type PayoffRequestUpdateMutationFn = Apollo.MutationFunction<
  PayoffRequestUpdateMutation,
  PayoffRequestUpdateMutationVariables
>;

/**
 * __usePayoffRequestUpdateMutation__
 *
 * To run a mutation, you first call `usePayoffRequestUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoffRequestUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoffRequestUpdateMutation, { data, loading, error }] = usePayoffRequestUpdateMutation({
 *   variables: {
 *      payoffRequest: // value for 'payoffRequest'
 *   },
 * });
 */
export function usePayoffRequestUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayoffRequestUpdateMutation,
    PayoffRequestUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayoffRequestUpdateMutation, PayoffRequestUpdateMutationVariables>(
    PayoffRequestUpdateDocument,
    options,
  );
}
export type PayoffRequestUpdateMutationHookResult = ReturnType<
  typeof usePayoffRequestUpdateMutation
>;
export type PayoffRequestUpdateMutationResult = Apollo.MutationResult<PayoffRequestUpdateMutation>;
export type PayoffRequestUpdateMutationOptions = Apollo.BaseMutationOptions<
  PayoffRequestUpdateMutation,
  PayoffRequestUpdateMutationVariables
>;
export const OnPayoffRequestUpdateDocument = gql`
  subscription onPayoffRequestUpdate {
    onPayoffRequestUpdate {
      temporary_info_id
      status
      vehicle_payoff
      sales_tax
      completed_date
      good_through_date
    }
  }
`;

/**
 * __useOnPayoffRequestUpdateSubscription__
 *
 * To run a query within a React component, call `useOnPayoffRequestUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPayoffRequestUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPayoffRequestUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnPayoffRequestUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnPayoffRequestUpdateSubscription,
    OnPayoffRequestUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnPayoffRequestUpdateSubscription,
    OnPayoffRequestUpdateSubscriptionVariables
  >(OnPayoffRequestUpdateDocument, options);
}
export type OnPayoffRequestUpdateSubscriptionHookResult = ReturnType<
  typeof useOnPayoffRequestUpdateSubscription
>;
export type OnPayoffRequestUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnPayoffRequestUpdateSubscription>;
export const GetPodHoursDocument = gql`
  query getPodHours {
    podHours {
      id
      label
      day
      beginTime
      endTime
    }
  }
`;

/**
 * __useGetPodHoursQuery__
 *
 * To run a query within a React component, call `useGetPodHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPodHoursQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPodHoursQuery, GetPodHoursQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPodHoursQuery, GetPodHoursQueryVariables>(GetPodHoursDocument, options);
}
export function useGetPodHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPodHoursQuery, GetPodHoursQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPodHoursQuery, GetPodHoursQueryVariables>(
    GetPodHoursDocument,
    options,
  );
}
export type GetPodHoursQueryHookResult = ReturnType<typeof useGetPodHoursQuery>;
export type GetPodHoursLazyQueryHookResult = ReturnType<typeof useGetPodHoursLazyQuery>;
export type GetPodHoursQueryResult = Apollo.QueryResult<
  GetPodHoursQuery,
  GetPodHoursQueryVariables
>;
export const ExecuteExternalSoftPullDocument = gql`
  mutation executeExternalSoftPull(
    $paymentEstimateInput: paymentEstimateInput!
    $devCreditScore: Int
    $devOverride: Boolean
    $isHardPull: Boolean
  ) {
    executeExternalSoftPull(
      paymentEstimateInput: $paymentEstimateInput
      devCreditScore: $devCreditScore
      devOverride: $devOverride
      isHardPull: $isHardPull
    )
  }
`;
export type ExecuteExternalSoftPullMutationFn = Apollo.MutationFunction<
  ExecuteExternalSoftPullMutation,
  ExecuteExternalSoftPullMutationVariables
>;

/**
 * __useExecuteExternalSoftPullMutation__
 *
 * To run a mutation, you first call `useExecuteExternalSoftPullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteExternalSoftPullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeExternalSoftPullMutation, { data, loading, error }] = useExecuteExternalSoftPullMutation({
 *   variables: {
 *      paymentEstimateInput: // value for 'paymentEstimateInput'
 *      devCreditScore: // value for 'devCreditScore'
 *      devOverride: // value for 'devOverride'
 *      isHardPull: // value for 'isHardPull'
 *   },
 * });
 */
export function useExecuteExternalSoftPullMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecuteExternalSoftPullMutation,
    ExecuteExternalSoftPullMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExecuteExternalSoftPullMutation,
    ExecuteExternalSoftPullMutationVariables
  >(ExecuteExternalSoftPullDocument, options);
}
export type ExecuteExternalSoftPullMutationHookResult = ReturnType<
  typeof useExecuteExternalSoftPullMutation
>;
export type ExecuteExternalSoftPullMutationResult =
  Apollo.MutationResult<ExecuteExternalSoftPullMutation>;
export type ExecuteExternalSoftPullMutationOptions = Apollo.BaseMutationOptions<
  ExecuteExternalSoftPullMutation,
  ExecuteExternalSoftPullMutationVariables
>;
export const MockDealProcessingDocument = gql`
  mutation mockDealProcessing(
    $deal_id: ID!
    $is_conditioned: Boolean!
    $has_gap: Boolean!
    $has_vsc: Boolean!
  ) {
    mockDealProcessing(
      deal_id: $deal_id
      is_conditioned: $is_conditioned
      has_gap: $has_gap
      has_vsc: $has_vsc
    )
  }
`;
export type MockDealProcessingMutationFn = Apollo.MutationFunction<
  MockDealProcessingMutation,
  MockDealProcessingMutationVariables
>;

/**
 * __useMockDealProcessingMutation__
 *
 * To run a mutation, you first call `useMockDealProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMockDealProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mockDealProcessingMutation, { data, loading, error }] = useMockDealProcessingMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      is_conditioned: // value for 'is_conditioned'
 *      has_gap: // value for 'has_gap'
 *      has_vsc: // value for 'has_vsc'
 *   },
 * });
 */
export function useMockDealProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MockDealProcessingMutation,
    MockDealProcessingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MockDealProcessingMutation, MockDealProcessingMutationVariables>(
    MockDealProcessingDocument,
    options,
  );
}
export type MockDealProcessingMutationHookResult = ReturnType<typeof useMockDealProcessingMutation>;
export type MockDealProcessingMutationResult = Apollo.MutationResult<MockDealProcessingMutation>;
export type MockDealProcessingMutationOptions = Apollo.BaseMutationOptions<
  MockDealProcessingMutation,
  MockDealProcessingMutationVariables
>;
export const GetVisibleReviewsDocument = gql`
  query getVisibleReviews {
    visibleReviews {
      id
      customer_name
      review
      created_at
    }
  }
`;

/**
 * __useGetVisibleReviewsQuery__
 *
 * To run a query within a React component, call `useGetVisibleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisibleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisibleReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVisibleReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVisibleReviewsQuery, GetVisibleReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVisibleReviewsQuery, GetVisibleReviewsQueryVariables>(
    GetVisibleReviewsDocument,
    options,
  );
}
export function useGetVisibleReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVisibleReviewsQuery,
    GetVisibleReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVisibleReviewsQuery, GetVisibleReviewsQueryVariables>(
    GetVisibleReviewsDocument,
    options,
  );
}
export type GetVisibleReviewsQueryHookResult = ReturnType<typeof useGetVisibleReviewsQuery>;
export type GetVisibleReviewsLazyQueryHookResult = ReturnType<typeof useGetVisibleReviewsLazyQuery>;
export type GetVisibleReviewsQueryResult = Apollo.QueryResult<
  GetVisibleReviewsQuery,
  GetVisibleReviewsQueryVariables
>;
export const GetR1CustomerCopyDocument = gql`
  query getR1CustomerCopy($dealJacketID: String!, $includeExtraPDFBinaries: Boolean!) {
    getR1CustomerCopy(
      dealJacketID: $dealJacketID
      includeExtraPDFBinaries: $includeExtraPDFBinaries
    ) {
      dealJacketId
      documentId
      documentName
      displayName
      documentType
      mimeType
      signAttributes {
        signRequired
        initialsType
        signCaptureTms
        actorName
        actorType
        dateCoordinates {
          width
          height
          pageNumber
          xPosition
          yPosition
        }
        dateFieldName
        signFieldName
        signPadText
        signatureCoordinates {
          height
          pageNumber
          width
          xPosition
          yPosition
        }
      }
      wetSignature
      docStatCode
      data
    }
  }
`;

/**
 * __useGetR1CustomerCopyQuery__
 *
 * To run a query within a React component, call `useGetR1CustomerCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetR1CustomerCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetR1CustomerCopyQuery({
 *   variables: {
 *      dealJacketID: // value for 'dealJacketID'
 *      includeExtraPDFBinaries: // value for 'includeExtraPDFBinaries'
 *   },
 * });
 */
export function useGetR1CustomerCopyQuery(
  baseOptions: Apollo.QueryHookOptions<GetR1CustomerCopyQuery, GetR1CustomerCopyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetR1CustomerCopyQuery, GetR1CustomerCopyQueryVariables>(
    GetR1CustomerCopyDocument,
    options,
  );
}
export function useGetR1CustomerCopyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetR1CustomerCopyQuery,
    GetR1CustomerCopyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetR1CustomerCopyQuery, GetR1CustomerCopyQueryVariables>(
    GetR1CustomerCopyDocument,
    options,
  );
}
export type GetR1CustomerCopyQueryHookResult = ReturnType<typeof useGetR1CustomerCopyQuery>;
export type GetR1CustomerCopyLazyQueryHookResult = ReturnType<typeof useGetR1CustomerCopyLazyQuery>;
export type GetR1CustomerCopyQueryResult = Apollo.QueryResult<
  GetR1CustomerCopyQuery,
  GetR1CustomerCopyQueryVariables
>;
export const GetR1DealDocumentDocument = gql`
  query getR1DealDocument(
    $dealJacketID: String!
    $documentID: String!
    $includePdfBinary: Boolean
  ) {
    getR1DealDocument(
      dealJacketID: $dealJacketID
      documentID: $documentID
      includePdfBinary: $includePdfBinary
    ) {
      dealJacketId
      documentId
      documentName
      displayName
      documentType
      mimeType
      signAttributes {
        signRequired
        initialsType
        signCaptureTms
        actorName
        actorType
        dateCoordinates {
          width
          height
          pageNumber
          xPosition
          yPosition
        }
        dateFieldName
        signFieldName
        signPadText
        signatureCoordinates {
          height
          pageNumber
          width
          xPosition
          yPosition
        }
      }
      wetSignature
      docStatCode
      data
    }
  }
`;

/**
 * __useGetR1DealDocumentQuery__
 *
 * To run a query within a React component, call `useGetR1DealDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetR1DealDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetR1DealDocumentQuery({
 *   variables: {
 *      dealJacketID: // value for 'dealJacketID'
 *      documentID: // value for 'documentID'
 *      includePdfBinary: // value for 'includePdfBinary'
 *   },
 * });
 */
export function useGetR1DealDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<GetR1DealDocumentQuery, GetR1DealDocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetR1DealDocumentQuery, GetR1DealDocumentQueryVariables>(
    GetR1DealDocumentDocument,
    options,
  );
}
export function useGetR1DealDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetR1DealDocumentQuery,
    GetR1DealDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetR1DealDocumentQuery, GetR1DealDocumentQueryVariables>(
    GetR1DealDocumentDocument,
    options,
  );
}
export type GetR1DealDocumentQueryHookResult = ReturnType<typeof useGetR1DealDocumentQuery>;
export type GetR1DealDocumentLazyQueryHookResult = ReturnType<typeof useGetR1DealDocumentLazyQuery>;
export type GetR1DealDocumentQueryResult = Apollo.QueryResult<
  GetR1DealDocumentQuery,
  GetR1DealDocumentQueryVariables
>;
export const GenerateTestDocumentsDocument = gql`
  query generateTestDocuments($update_deal_id: ID!) {
    generateTestDocuments(update_deal_id: $update_deal_id)
  }
`;

/**
 * __useGenerateTestDocumentsQuery__
 *
 * To run a query within a React component, call `useGenerateTestDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateTestDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateTestDocumentsQuery({
 *   variables: {
 *      update_deal_id: // value for 'update_deal_id'
 *   },
 * });
 */
export function useGenerateTestDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenerateTestDocumentsQuery,
    GenerateTestDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateTestDocumentsQuery, GenerateTestDocumentsQueryVariables>(
    GenerateTestDocumentsDocument,
    options,
  );
}
export function useGenerateTestDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateTestDocumentsQuery,
    GenerateTestDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateTestDocumentsQuery, GenerateTestDocumentsQueryVariables>(
    GenerateTestDocumentsDocument,
    options,
  );
}
export type GenerateTestDocumentsQueryHookResult = ReturnType<typeof useGenerateTestDocumentsQuery>;
export type GenerateTestDocumentsLazyQueryHookResult = ReturnType<
  typeof useGenerateTestDocumentsLazyQuery
>;
export type GenerateTestDocumentsQueryResult = Apollo.QueryResult<
  GenerateTestDocumentsQuery,
  GenerateTestDocumentsQueryVariables
>;
export const ApplyDocumentSignatureDocument = gql`
  mutation applyDocumentSignature(
    $dealId: ID!
    $dealJacketID: String!
    $documentID: String!
    $documentName: String!
    $signatureFieldName: String!
    $signatureData: String!
    $isLastSignature: Boolean
    $isCobuyer: Boolean
  ) {
    applyDocumentSignature(
      dealId: $dealId
      dealJacketID: $dealJacketID
      documentID: $documentID
      documentName: $documentName
      signatureFieldName: $signatureFieldName
      signatureData: $signatureData
      isLastSignature: $isLastSignature
      isCobuyer: $isCobuyer
    )
  }
`;
export type ApplyDocumentSignatureMutationFn = Apollo.MutationFunction<
  ApplyDocumentSignatureMutation,
  ApplyDocumentSignatureMutationVariables
>;

/**
 * __useApplyDocumentSignatureMutation__
 *
 * To run a mutation, you first call `useApplyDocumentSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyDocumentSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyDocumentSignatureMutation, { data, loading, error }] = useApplyDocumentSignatureMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      dealJacketID: // value for 'dealJacketID'
 *      documentID: // value for 'documentID'
 *      documentName: // value for 'documentName'
 *      signatureFieldName: // value for 'signatureFieldName'
 *      signatureData: // value for 'signatureData'
 *      isLastSignature: // value for 'isLastSignature'
 *      isCobuyer: // value for 'isCobuyer'
 *   },
 * });
 */
export function useApplyDocumentSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyDocumentSignatureMutation,
    ApplyDocumentSignatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyDocumentSignatureMutation,
    ApplyDocumentSignatureMutationVariables
  >(ApplyDocumentSignatureDocument, options);
}
export type ApplyDocumentSignatureMutationHookResult = ReturnType<
  typeof useApplyDocumentSignatureMutation
>;
export type ApplyDocumentSignatureMutationResult =
  Apollo.MutationResult<ApplyDocumentSignatureMutation>;
export type ApplyDocumentSignatureMutationOptions = Apollo.BaseMutationOptions<
  ApplyDocumentSignatureMutation,
  ApplyDocumentSignatureMutationVariables
>;
export const FinalizeR1SigningDocument = gql`
  mutation finalizeR1Signing($dealId: ID!, $dealJacketID: String!) {
    finalizeR1Signing(dealId: $dealId, dealJacketID: $dealJacketID)
  }
`;
export type FinalizeR1SigningMutationFn = Apollo.MutationFunction<
  FinalizeR1SigningMutation,
  FinalizeR1SigningMutationVariables
>;

/**
 * __useFinalizeR1SigningMutation__
 *
 * To run a mutation, you first call `useFinalizeR1SigningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeR1SigningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeR1SigningMutation, { data, loading, error }] = useFinalizeR1SigningMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      dealJacketID: // value for 'dealJacketID'
 *   },
 * });
 */
export function useFinalizeR1SigningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeR1SigningMutation,
    FinalizeR1SigningMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeR1SigningMutation, FinalizeR1SigningMutationVariables>(
    FinalizeR1SigningDocument,
    options,
  );
}
export type FinalizeR1SigningMutationHookResult = ReturnType<typeof useFinalizeR1SigningMutation>;
export type FinalizeR1SigningMutationResult = Apollo.MutationResult<FinalizeR1SigningMutation>;
export type FinalizeR1SigningMutationOptions = Apollo.BaseMutationOptions<
  FinalizeR1SigningMutation,
  FinalizeR1SigningMutationVariables
>;
export const StoreSignatureDataDocument = gql`
  mutation storeSignatureData(
    $customer_id: ID!
    $signature_data_url: String
    $signature_strokes: String
    $initials_data_url: String
    $initials_strokes: String
  ) {
    storeSignatureData(
      customer_id: $customer_id
      signature_data_url: $signature_data_url
      signature_strokes: $signature_strokes
      initials_data_url: $initials_data_url
      initials_strokes: $initials_strokes
    )
  }
`;
export type StoreSignatureDataMutationFn = Apollo.MutationFunction<
  StoreSignatureDataMutation,
  StoreSignatureDataMutationVariables
>;

/**
 * __useStoreSignatureDataMutation__
 *
 * To run a mutation, you first call `useStoreSignatureDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreSignatureDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeSignatureDataMutation, { data, loading, error }] = useStoreSignatureDataMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      signature_data_url: // value for 'signature_data_url'
 *      signature_strokes: // value for 'signature_strokes'
 *      initials_data_url: // value for 'initials_data_url'
 *      initials_strokes: // value for 'initials_strokes'
 *   },
 * });
 */
export function useStoreSignatureDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StoreSignatureDataMutation,
    StoreSignatureDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreSignatureDataMutation, StoreSignatureDataMutationVariables>(
    StoreSignatureDataDocument,
    options,
  );
}
export type StoreSignatureDataMutationHookResult = ReturnType<typeof useStoreSignatureDataMutation>;
export type StoreSignatureDataMutationResult = Apollo.MutationResult<StoreSignatureDataMutation>;
export type StoreSignatureDataMutationOptions = Apollo.BaseMutationOptions<
  StoreSignatureDataMutation,
  StoreSignatureDataMutationVariables
>;
export const GetSignDataDocument = gql`
  query getSignData($dealJacketID: String!) {
    retrieveDealDocsUploadedToR1(dealJacketID: $dealJacketID, includePdfBinary: false) {
      dealJacketId
      documentId
      signAttributes {
        signFieldName
        signRequired
        initialsType
        signCaptureTms
      }
      docStatCode
    }
  }
`;

/**
 * __useGetSignDataQuery__
 *
 * To run a query within a React component, call `useGetSignDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignDataQuery({
 *   variables: {
 *      dealJacketID: // value for 'dealJacketID'
 *   },
 * });
 */
export function useGetSignDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetSignDataQuery, GetSignDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSignDataQuery, GetSignDataQueryVariables>(GetSignDataDocument, options);
}
export function useGetSignDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSignDataQuery, GetSignDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSignDataQuery, GetSignDataQueryVariables>(
    GetSignDataDocument,
    options,
  );
}
export type GetSignDataQueryHookResult = ReturnType<typeof useGetSignDataQuery>;
export type GetSignDataLazyQueryHookResult = ReturnType<typeof useGetSignDataLazyQuery>;
export type GetSignDataQueryResult = Apollo.QueryResult<
  GetSignDataQuery,
  GetSignDataQueryVariables
>;
export const LogViewedDocumentEventDocument = gql`
  mutation logViewedDocumentEvent($documentViewedEvent: signingAuditDocumentViewedEvent!) {
    logViewedDocumentEvent(documentViewedEvent: $documentViewedEvent)
  }
`;
export type LogViewedDocumentEventMutationFn = Apollo.MutationFunction<
  LogViewedDocumentEventMutation,
  LogViewedDocumentEventMutationVariables
>;

/**
 * __useLogViewedDocumentEventMutation__
 *
 * To run a mutation, you first call `useLogViewedDocumentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogViewedDocumentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logViewedDocumentEventMutation, { data, loading, error }] = useLogViewedDocumentEventMutation({
 *   variables: {
 *      documentViewedEvent: // value for 'documentViewedEvent'
 *   },
 * });
 */
export function useLogViewedDocumentEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogViewedDocumentEventMutation,
    LogViewedDocumentEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LogViewedDocumentEventMutation,
    LogViewedDocumentEventMutationVariables
  >(LogViewedDocumentEventDocument, options);
}
export type LogViewedDocumentEventMutationHookResult = ReturnType<
  typeof useLogViewedDocumentEventMutation
>;
export type LogViewedDocumentEventMutationResult =
  Apollo.MutationResult<LogViewedDocumentEventMutation>;
export type LogViewedDocumentEventMutationOptions = Apollo.BaseMutationOptions<
  LogViewedDocumentEventMutation,
  LogViewedDocumentEventMutationVariables
>;
export const LogSigningErrorDocument = gql`
  mutation logSigningError($error: signingErrorInput!) {
    logSigningError(error: $error) {
      id
    }
  }
`;
export type LogSigningErrorMutationFn = Apollo.MutationFunction<
  LogSigningErrorMutation,
  LogSigningErrorMutationVariables
>;

/**
 * __useLogSigningErrorMutation__
 *
 * To run a mutation, you first call `useLogSigningErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogSigningErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logSigningErrorMutation, { data, loading, error }] = useLogSigningErrorMutation({
 *   variables: {
 *      error: // value for 'error'
 *   },
 * });
 */
export function useLogSigningErrorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogSigningErrorMutation,
    LogSigningErrorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogSigningErrorMutation, LogSigningErrorMutationVariables>(
    LogSigningErrorDocument,
    options,
  );
}
export type LogSigningErrorMutationHookResult = ReturnType<typeof useLogSigningErrorMutation>;
export type LogSigningErrorMutationResult = Apollo.MutationResult<LogSigningErrorMutation>;
export type LogSigningErrorMutationOptions = Apollo.BaseMutationOptions<
  LogSigningErrorMutation,
  LogSigningErrorMutationVariables
>;
export const GetTtJurisdictionDocument = gql`
  query getTTJurisdiction(
    $state: StateAbbreviation!
    $types: [TTProductType!]!
    $conditions: TTConditionFilterInput
  ) {
    jurisdiction(state: $state) {
      id
      hasRegistrationOnly
      hasTitleOnly
      hasTitleAndRegistration
      allowPlateTransfer
      requireWalkIn
      products(filter: { types: $types }) {
        count
        pageInfo {
          startCursor
          endCursor
        }
        items {
          type
          validations {
            name
            description
          }
          documents(conditions: $conditions) {
            count
            items {
              supportsImageExtraction
              productId
              documentTypeId
              type {
                name
                parameters {
                  name
                  description
                  type
                }
              }
              conditions {
                description
                variables
              }
              validations {
                name
                description
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetTtJurisdictionQuery__
 *
 * To run a query within a React component, call `useGetTtJurisdictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTtJurisdictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTtJurisdictionQuery({
 *   variables: {
 *      state: // value for 'state'
 *      types: // value for 'types'
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetTtJurisdictionQuery(
  baseOptions: Apollo.QueryHookOptions<GetTtJurisdictionQuery, GetTtJurisdictionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTtJurisdictionQuery, GetTtJurisdictionQueryVariables>(
    GetTtJurisdictionDocument,
    options,
  );
}
export function useGetTtJurisdictionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTtJurisdictionQuery,
    GetTtJurisdictionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTtJurisdictionQuery, GetTtJurisdictionQueryVariables>(
    GetTtJurisdictionDocument,
    options,
  );
}
export type GetTtJurisdictionQueryHookResult = ReturnType<typeof useGetTtJurisdictionQuery>;
export type GetTtJurisdictionLazyQueryHookResult = ReturnType<typeof useGetTtJurisdictionLazyQuery>;
export type GetTtJurisdictionQueryResult = Apollo.QueryResult<
  GetTtJurisdictionQuery,
  GetTtJurisdictionQueryVariables
>;
export const UploadDocumentDocument = gql`
  mutation uploadDocument($bytes: String!, $documentType: TTDocumentName!) {
    uploadDocument(bytes: $bytes, documentType: $documentType) {
      documentName
      key
      fields {
        confidence
        name
        value
      }
    }
  }
`;
export type UploadDocumentMutationFn = Apollo.MutationFunction<
  UploadDocumentMutation,
  UploadDocumentMutationVariables
>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      bytes: // value for 'bytes'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useUploadDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(
    UploadDocumentDocument,
    options,
  );
}
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentMutation,
  UploadDocumentMutationVariables
>;
export const UploadDriversLicenseDocument = gql`
  mutation uploadDriversLicense($bytes: String!) {
    uploadDriversLicense(bytes: $bytes) {
      firstName
      middleName
      lastName
      address
      city
      state
      zip
      expirationDate
    }
  }
`;
export type UploadDriversLicenseMutationFn = Apollo.MutationFunction<
  UploadDriversLicenseMutation,
  UploadDriversLicenseMutationVariables
>;

/**
 * __useUploadDriversLicenseMutation__
 *
 * To run a mutation, you first call `useUploadDriversLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDriversLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDriversLicenseMutation, { data, loading, error }] = useUploadDriversLicenseMutation({
 *   variables: {
 *      bytes: // value for 'bytes'
 *   },
 * });
 */
export function useUploadDriversLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadDriversLicenseMutation,
    UploadDriversLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDriversLicenseMutation, UploadDriversLicenseMutationVariables>(
    UploadDriversLicenseDocument,
    options,
  );
}
export type UploadDriversLicenseMutationHookResult = ReturnType<
  typeof useUploadDriversLicenseMutation
>;
export type UploadDriversLicenseMutationResult =
  Apollo.MutationResult<UploadDriversLicenseMutation>;
export type UploadDriversLicenseMutationOptions = Apollo.BaseMutationOptions<
  UploadDriversLicenseMutation,
  UploadDriversLicenseMutationVariables
>;
export const VehicleMileageDocument = gql`
  query vehicleMileage($vin: String!) {
    vehicleMileage(vin: $vin) {
      estimatedAverageMiles
      eventCount
      lastOdometer
      lastOdometerDate
      resultCode
      resultMessage
      rollback
      vin
    }
  }
`;

/**
 * __useVehicleMileageQuery__
 *
 * To run a query within a React component, call `useVehicleMileageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleMileageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleMileageQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useVehicleMileageQuery(
  baseOptions: Apollo.QueryHookOptions<VehicleMileageQuery, VehicleMileageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleMileageQuery, VehicleMileageQueryVariables>(
    VehicleMileageDocument,
    options,
  );
}
export function useVehicleMileageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VehicleMileageQuery, VehicleMileageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleMileageQuery, VehicleMileageQueryVariables>(
    VehicleMileageDocument,
    options,
  );
}
export type VehicleMileageQueryHookResult = ReturnType<typeof useVehicleMileageQuery>;
export type VehicleMileageLazyQueryHookResult = ReturnType<typeof useVehicleMileageLazyQuery>;
export type VehicleMileageQueryResult = Apollo.QueryResult<
  VehicleMileageQuery,
  VehicleMileageQueryVariables
>;
export const GetFeesDocument = gql`
  query getFees(
    $state: StateAbbreviation!
    $previouslyTitledState: StateAbbreviation!
    $city: String!
    $county: String!
    $zip: String!
    $vin: String!
    $make: String!
    $model: String!
    $year: Int!
    $vehicleType: TTVehicleType!
    $fuelType: TTFuelType!
    $firstName: String!
    $lastName: String!
    $retailBookValue: Float!
    $bookValue: Float!
    $docFee: Float!
    $payoff: Float!
    $estimatedPayoff: Float!
    $ssn: String!
    $warranty: Float!
    $source: String!
  ) {
    getFees(
      state: $state
      previouslyTitledState: $previouslyTitledState
      city: $city
      county: $county
      zip: $zip
      vin: $vin
      make: $make
      model: $model
      year: $year
      vehicleType: $vehicleType
      fuelType: $fuelType
      firstName: $firstName
      lastName: $lastName
      retailBookValue: $retailBookValue
      bookValue: $bookValue
      docFee: $docFee
      payoff: $payoff
      estimatedPayoff: $estimatedPayoff
      ssn: $ssn
      warranty: $warranty
      source: $source
    ) {
      state
      totalFeeAmount
      registrationTransferFee
      salesTaxRate
      baseTaxAmount
      warrantyTaxAmount
    }
  }
`;

/**
 * __useGetFeesQuery__
 *
 * To run a query within a React component, call `useGetFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeesQuery({
 *   variables: {
 *      state: // value for 'state'
 *      previouslyTitledState: // value for 'previouslyTitledState'
 *      city: // value for 'city'
 *      county: // value for 'county'
 *      zip: // value for 'zip'
 *      vin: // value for 'vin'
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *      vehicleType: // value for 'vehicleType'
 *      fuelType: // value for 'fuelType'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      retailBookValue: // value for 'retailBookValue'
 *      bookValue: // value for 'bookValue'
 *      docFee: // value for 'docFee'
 *      payoff: // value for 'payoff'
 *      estimatedPayoff: // value for 'estimatedPayoff'
 *      ssn: // value for 'ssn'
 *      warranty: // value for 'warranty'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useGetFeesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFeesQuery, GetFeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeesQuery, GetFeesQueryVariables>(GetFeesDocument, options);
}
export function useGetFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeesQuery, GetFeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeesQuery, GetFeesQueryVariables>(GetFeesDocument, options);
}
export type GetFeesQueryHookResult = ReturnType<typeof useGetFeesQuery>;
export type GetFeesLazyQueryHookResult = ReturnType<typeof useGetFeesLazyQuery>;
export type GetFeesQueryResult = Apollo.QueryResult<GetFeesQuery, GetFeesQueryVariables>;
export const EndSalesFlowDocument = gql`
  mutation endSalesFlow(
    $dealId: ID
    $tempInfoId: ID
    $importType: importType!
    $reason: endSalesFlowReasonEnum!
  ) {
    endSalesFlow(dealId: $dealId, tempInfoId: $tempInfoId, importType: $importType, reason: $reason)
  }
`;
export type EndSalesFlowMutationFn = Apollo.MutationFunction<
  EndSalesFlowMutation,
  EndSalesFlowMutationVariables
>;

/**
 * __useEndSalesFlowMutation__
 *
 * To run a mutation, you first call `useEndSalesFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSalesFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSalesFlowMutation, { data, loading, error }] = useEndSalesFlowMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      tempInfoId: // value for 'tempInfoId'
 *      importType: // value for 'importType'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useEndSalesFlowMutation(
  baseOptions?: Apollo.MutationHookOptions<EndSalesFlowMutation, EndSalesFlowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndSalesFlowMutation, EndSalesFlowMutationVariables>(
    EndSalesFlowDocument,
    options,
  );
}
export type EndSalesFlowMutationHookResult = ReturnType<typeof useEndSalesFlowMutation>;
export type EndSalesFlowMutationResult = Apollo.MutationResult<EndSalesFlowMutation>;
export type EndSalesFlowMutationOptions = Apollo.BaseMutationOptions<
  EndSalesFlowMutation,
  EndSalesFlowMutationVariables
>;
export const UserDealDocument = gql`
  query userDeal($id: ID, $tempInfoId: ID, $isCobuyer: Boolean) {
    userDeal(id: $id, tempInfoId: $tempInfoId, isCobuyer: $isCobuyer) {
      id
      type
      state
      setter_id
      paperwork_type
      request_boot
      boot_reason
      sales_visibility
      r1_jacket_id
      r1_jacket_id_created_date_utc
      r1_contract_generation_date_utc
      force_title_only_helper_link {
        name
        link
      }
      signing_on_com
      setter {
        id
        name
      }
      closer_id
      closer {
        id
        name
      }
      closer2_id
      closer2 {
        id
        name
      }
      funding_clerk_id
      funding_clerk {
        id
        name
      }
      car {
        id
        vin
        year
        make
        model
        mileage
        color
        book_value
        retail_book_value
        registration_expiration
        vehicle_type
        fuel_type
        kbb_trim_name
        mmr
        payoff {
          id
          lienholder_name
          lienholder_slug
          account_number
          vehicle_payoff
          good_through_date
          lease_term
          remaining_payments
          msrp
          residual_percentage
          sales_price
          cap_reduction
          money_down
          money_factor
          termination_fees
          cap_cost
          residual_amount
          estimated_payoff
          old_lease_payment
        }
      }
      customer {
        id
        first_name
        middle_name
        last_name
        phone_number
        home_phone_number
        email
        no_email
        dob
        dl_expiration_date
        last_authenticated_route
        dashboard_visited
        verified
        address {
          id
          city
          state
          zip
          county
          address_line
          residence_type
          years_at_home
          months_at_home
          monthly_payment
          moved_states
        }
        drivers_license_address {
          id
          city
          state
          zip
          county
          address_line
          residence_type
          years_at_home
          months_at_home
          monthly_payment
          moved_states
        }
        prev_address {
          id
          city
          state
          zip
          county
          address_line
          residence_type
          years_at_home
          months_at_home
          monthly_payment
        }
        employment {
          id
          name
          job_title
          phone_number
          years_at_job
          months_at_job
          gross_income
          pay_frequency
        }
        prev_employment {
          id
          name
          job_title
          phone_number
          years_at_job
          months_at_job
          gross_income
          pay_frequency
        }
        proof_of_insurance {
          company_name
          expires
          policy_number
          state
          first_name
          middle_name
          last_name
        }
        signature_data {
          signature_data_url
          signature_strokes
          initials_data_url
          initials_strokes
        }
        finished_signing
      }
      cobuyer {
        id
        first_name
        middle_name
        last_name
        phone_number
        home_phone_number
        email
        no_email
        dob
        dashboard_visited
        verified
        address {
          id
          city
          state
          zip
          county
          address_line
          residence_type
          years_at_home
          months_at_home
          monthly_payment
        }
        prev_address {
          id
          city
          state
          zip
          county
          address_line
          residence_type
          years_at_home
          months_at_home
          monthly_payment
        }
        employment {
          id
          name
          job_title
          phone_number
          years_at_job
          months_at_job
          gross_income
          pay_frequency
        }
        prev_employment {
          id
          name
          job_title
          phone_number
          years_at_job
          months_at_job
          gross_income
          pay_frequency
        }
        signature_data {
          signature_data_url
          signature_strokes
          initials_data_url
          initials_strokes
        }
        relation_to_buyer
        finished_signing
      }
      financial_info {
        id
        deal_id
        money_down
        base_tax_amount
        warranty_tax_amount
        title_registration_option
        title_fee
        new_registration_fee
        registration_transfer_fee
        doc_fee
        sales_tax_rate
        vsc_price
        vsc_cost
        gap_price
        gap_cost
        days_to_payment
        first_payment_date
        sell_rate
        buy_rate
        term
        bank
        vsc_term
        payment
        amount_financed
        profit
        setter_commission
        closer_commission
        option_type
        plate_transfer
        pen_vsc_session_id
        pen_vsc_rate_id
        pen_vsc_form_id
        pen_gap_session_id
        pen_gap_rate_id
        pen_gap_form_id
        credit_debit_payment_amount_allowed
        credit_payment_amount_allowed
        debit_payment_amount_allowed
        credit_payment_amount_limit
        debit_payment_amount_limit
      }
      financial_info_acquisition {
        id
        deal_id
        kbb_lending
        appraised_value
        max_cash_to_customer
        max_total_cost
        cash_to_customer
        is_approved
        auction_fees
        transport_cost
        total_cost
        auction_id
        gross_profit
        sell_price
        offer
      }
      condition_report {
        id
        deal_id
        accidents
        tires
        exterior
        interior
        smoked_in
        lights_on_dash
        overall_condition
        score
      }
      document_info {
        adobe_agreement_id
      }
      product {
        selected_package
        gap_selected
      }
      deal_states {
        state
        updated_date_utc
        created_at
      }
      isCobuyer
    }
  }
`;

/**
 * __useUserDealQuery__
 *
 * To run a query within a React component, call `useUserDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDealQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tempInfoId: // value for 'tempInfoId'
 *      isCobuyer: // value for 'isCobuyer'
 *   },
 * });
 */
export function useUserDealQuery(
  baseOptions?: Apollo.QueryHookOptions<UserDealQuery, UserDealQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDealQuery, UserDealQueryVariables>(UserDealDocument, options);
}
export function useUserDealLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserDealQuery, UserDealQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDealQuery, UserDealQueryVariables>(UserDealDocument, options);
}
export type UserDealQueryHookResult = ReturnType<typeof useUserDealQuery>;
export type UserDealLazyQueryHookResult = ReturnType<typeof useUserDealLazyQuery>;
export type UserDealQueryResult = Apollo.QueryResult<UserDealQuery, UserDealQueryVariables>;
export const UserDealReadyDocument = gql`
  query userDealReady($tempInfoId: ID!) {
    userDealReady(tempInfoId: $tempInfoId)
  }
`;

/**
 * __useUserDealReadyQuery__
 *
 * To run a query within a React component, call `useUserDealReadyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDealReadyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDealReadyQuery({
 *   variables: {
 *      tempInfoId: // value for 'tempInfoId'
 *   },
 * });
 */
export function useUserDealReadyQuery(
  baseOptions: Apollo.QueryHookOptions<UserDealReadyQuery, UserDealReadyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDealReadyQuery, UserDealReadyQueryVariables>(
    UserDealReadyDocument,
    options,
  );
}
export function useUserDealReadyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserDealReadyQuery, UserDealReadyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDealReadyQuery, UserDealReadyQueryVariables>(
    UserDealReadyDocument,
    options,
  );
}
export type UserDealReadyQueryHookResult = ReturnType<typeof useUserDealReadyQuery>;
export type UserDealReadyLazyQueryHookResult = ReturnType<typeof useUserDealReadyLazyQuery>;
export type UserDealReadyQueryResult = Apollo.QueryResult<
  UserDealReadyQuery,
  UserDealReadyQueryVariables
>;
export const UserDealsDocument = gql`
  query userDeals {
    userDeals {
      id
      sales_visibility
      type
      car {
        id
        vin
        year
        make
        model
        mileage
        color
        book_value
        retail_book_value
        registration_expiration
        vehicle_type
        fuel_type
        kbb_trim_name
        mmr
      }
      customer {
        full_name
        email
      }
      cobuyer {
        full_name
        email
      }
      isCobuyer
    }
  }
`;

/**
 * __useUserDealsQuery__
 *
 * To run a query within a React component, call `useUserDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserDealsQuery, UserDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDealsQuery, UserDealsQueryVariables>(UserDealsDocument, options);
}
export function useUserDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserDealsQuery, UserDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDealsQuery, UserDealsQueryVariables>(UserDealsDocument, options);
}
export type UserDealsQueryHookResult = ReturnType<typeof useUserDealsQuery>;
export type UserDealsLazyQueryHookResult = ReturnType<typeof useUserDealsLazyQuery>;
export type UserDealsQueryResult = Apollo.QueryResult<UserDealsQuery, UserDealsQueryVariables>;
export const GetComPackageOptionsDocument = gql`
  query getComPackageOptions($dealId: ID) {
    getComPackageOptions(dealId: $dealId) {
      isGapOffered
      isVscOffered
      vscPackageOptions {
        vscOffers {
          details {
            years
            miles
            deductible
            price
            payment
          }
          type
          name
          coverage {
            category
            slug
            components
          }
        }
        startingIndex
        noProductsPmt
      }
      gapMonthlyCost
    }
  }
`;

/**
 * __useGetComPackageOptionsQuery__
 *
 * To run a query within a React component, call `useGetComPackageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComPackageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComPackageOptionsQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetComPackageOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetComPackageOptionsQuery,
    GetComPackageOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetComPackageOptionsQuery, GetComPackageOptionsQueryVariables>(
    GetComPackageOptionsDocument,
    options,
  );
}
export function useGetComPackageOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComPackageOptionsQuery,
    GetComPackageOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetComPackageOptionsQuery, GetComPackageOptionsQueryVariables>(
    GetComPackageOptionsDocument,
    options,
  );
}
export type GetComPackageOptionsQueryHookResult = ReturnType<typeof useGetComPackageOptionsQuery>;
export type GetComPackageOptionsLazyQueryHookResult = ReturnType<
  typeof useGetComPackageOptionsLazyQuery
>;
export type GetComPackageOptionsQueryResult = Apollo.QueryResult<
  GetComPackageOptionsQuery,
  GetComPackageOptionsQueryVariables
>;
