import React from 'react';

import { LEAccordion, LEAccordionItem } from '../../components/LEAccordion';
import LEAccordionHeader from '../../components/LEAccordion/LEAccordionHeader';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import Subtitle from '../../components/Subtitle';
import KbbOptions from './components/KbbOptions';

const FinalAdjustments = () => {
  return (
    <LEAccordionContainer>
      <LEAccordionHeader>Last Second Items</LEAccordionHeader>
      <Subtitle textAlign="center" w={{ base: '100%', lg: '75%' }} mb="10px">
        We have these last few things to check before you finish submitting your application!
      </Subtitle>
      <LEAccordion name={ACCORDION_NAME.FINAL_ADJUSTMENTS_ACCORDION} showProgress={false}>
        <LEAccordionItem accordionKey="vehicleOptions" title="VEHICLE OPTIONS">
          <KbbOptions />
        </LEAccordionItem>
      </LEAccordion>
    </LEAccordionContainer>
  );
};

export default FinalAdjustments;
