import { Box, Card, Flex } from '@chakra-ui/react';

import CarDetails from '../../../components/CarDetails';
import { LDFlags } from '../../../constants/flags';
import useFlag from '../../../hooks/useFlag';
import { DealStateEnum } from '../../../utils/types/deal';
import DashboardDealSummaryCard from '../components/DashboardDealSummaryCard';
import DealTerms from '../components/DealTerms';
import MyDocuments from '../components/MyDocuments';
import StatusDetails from '../components/StatusDetails';
import UploadDocuments from '../components/UploadDocuments';
import YourAdvisorTeamCard from '../components/YourAdvisorTeamCard';
import YourTeam from '../components/YourTeam';
import DashboardDocumentsCard from '../components/documents/DashboardDocumentsCard';
import { DEAL_TERMS_STATES, DealWithFarthestState, PRE_SIGNED_DEAL_STATES } from '../utils';
import ClosingDashboardContent from './ClosingDashboardContent';

const MainDashboard = ({ deal }: { deal: DealWithFarthestState | undefined }) => {
  const dashboardDownPaymentEnabled = useFlag(LDFlags.COM_DASHBOARD_DOWN_PAYMENT);
  const isDealClosing = deal?.farthestState === DealStateEnum.Closing;

  if (dashboardDownPaymentEnabled) {
    return (
      <Flex flexDirection={{ base: 'column', lg: 'row' }} columnGap="20px" rowGap="20px">
        <Flex flexDirection="column" gap="20px" w={{ base: 'full', lg: '72%' }}>
          {!isDealClosing && <DashboardDealSummaryCard deal={deal} />}
          {isDealClosing ? <ClosingDashboardContent deal={deal} /> : <StatusDetails deal={deal} />}
        </Flex>
        <Box w={{ base: 'full', lg: '28%' }}>
          <DashboardDocumentsCard deal={deal} />
          <YourAdvisorTeamCard />
        </Box>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems="start"
        columnGap="20px"
        rowGap="20px"
      >
        <Box w={{ base: 'full', md: 3 / 4 }}>
          {isDealClosing ? <ClosingDashboardContent deal={deal} /> : <StatusDetails deal={deal} />}
          <YourTeam display={{ base: 'none', md: 'block' }} wrap="wrap" />
        </Box>
        <Flex flexDirection="column" gap="20px" w={{ base: 'full', md: 1 / 4 }}>
          {!PRE_SIGNED_DEAL_STATES.includes(deal?.farthestState as DealStateEnum) && (
            <Card>
              <MyDocuments deal={deal} />
            </Card>
          )}
          <Card>
            <CarDetails
              car={deal?.car}
              title={`MY VEHICLE${deal?.isCobuyer ? ' (CO-BUYER)' : ''}`}
            />
          </Card>
          {DEAL_TERMS_STATES.includes(deal?.farthestState as DealStateEnum) && (
            <Card>
              <DealTerms deal={deal} title="MY LOAN" />
            </Card>
          )}
          <Card>
            <UploadDocuments deal={deal} title="UPLOAD DOCUMENTS" />
          </Card>
        </Flex>
      </Flex>
      <Flex flexDirection="column" alignItems="center" display={{ base: 'block', md: 'none' }}>
        <YourTeam />
      </Flex>
    </>
  );
};

export default MainDashboard;
