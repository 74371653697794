import { Box, Center, Checkbox, Text, VStack } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from '../../components/Loader';
import Title from '../../components/Title';
import LicensePlateOrVinInput from '../../components/licensePlateOrVinInput/LicensePlateOrVinInput';
import ContinueContainer from '../../components/ui/GetStarted/ContinueContainer';
import { isProd } from '../../config';
import useCheckFlowStatus from '../../hooks/useCheckFlowStatus';
import { isDevActiveAtom } from '../dashboard/components/DashboardDevToolbar';
import { getFlowParams } from './utils';

const FlowEntry = () => {
  const { search } = useLocation();
  const flowParams = getFlowParams(search);
  const [initialized, setInitialized] = useState(false);
  const [isDevActive, setIsDevActive] = useAtom(isDevActiveAtom);

  const hasData = !!flowParams.vin || (!!flowParams.licensePlate && !!flowParams.licensePlateState);

  const {
    inFlow,
    enterFlow,
    canStartOver,
    startOver,
    loading: flowCheckLoading,
  } = useCheckFlowStatus();

  useEffect(() => {
    if (inFlow && !flowCheckLoading) {
      setInitialized(true);
    }
  }, [inFlow, flowCheckLoading]);

  return (
    <Box minH="85vh" mt="80px">
      {!flowCheckLoading && initialized && (
        <Title fontSize={{ base: '36px', md: '52px' }}>
          {inFlow
            ? 'Seems you already started the lease end process, continue by clicking on the button below'
            : hasData
            ? "We've encountered an issue"
            : 'Enter your license plate number and state or VIN to get started'}
        </Title>
      )}
      <Center mb="30px" mt={inFlow ? '50px' : '30px'}>
        {flowCheckLoading ? (
          <Loader />
        ) : inFlow ? (
          <ContinueContainer
            enterFlow={enterFlow}
            canStartOver={canStartOver}
            startOver={startOver}
          />
        ) : (
          <LicensePlateOrVinInput
            autoFocus
            flowParams={flowParams}
            onReady={() => setInitialized(true)}
          />
        )}
      </Center>
      {!isProd && (
        <VStack
          borderWidth={2}
          borderColor="grayBlueish"
          padding="10px"
          borderRadius="lg"
          mt="50px"
        >
          <Text>Dev Tools:</Text>
          <Checkbox isChecked={isDevActive} onChange={(e) => setIsDevActive(e.target.checked)}>
            Allow duplicate vin
          </Checkbox>
        </VStack>
      )}
    </Box>
  );
};

export default FlowEntry;
