import { useAtomValue } from 'jotai';

import { delayDigitalResponseAtom, digitallyFoundAtom } from '../..';
import { GreenCheckPurpleGuy } from '../../../../assets/Images';
import { LEAccordionItem } from '../../../../components/LEAccordion';
import { LDFlags } from '../../../../constants/flags';
import { useFlag } from '../../../../hooks';
import Content from './Content';

const EnterYourPayoffItem = () => {
  const delayDigitalResponse = useAtomValue(delayDigitalResponseAtom);
  const digitallyFound = useAtomValue(digitallyFoundAtom);
  const showPurplePeople = useFlag(LDFlags.SHOW_PURPLE_PEOPLE);

  return (
    <LEAccordionItem
      accordionKey="enterYourPayoff"
      title={digitallyFound && !delayDigitalResponse ? 'PAYOFF FOUND' : 'ENTER YOUR PAYOFF'}
      imgSrc={showPurplePeople && GreenCheckPurpleGuy}
    >
      <Content />
    </LEAccordionItem>
  );
};

export default EnterYourPayoffItem;
