import {
  Box,
  Input as ChakraInput,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabelProps,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ReactNode } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import FormErrorMessage from './FormErrorMessage';
import Label from './Label';

interface Props extends Omit<FormControlProps, 'label'> {
  name: NonNullable<InputProps['name']>;
  placeholder?: NonNullable<InputProps['placeholder']>;
  type?: NonNullable<InputProps['type']>;
  label?: string | ReactNode;
  maxLength?: NonNullable<InputProps['maxLength']>;
  debounce?: boolean;
  autoFocus?: InputProps['autoFocus'];
  readOnly?: InputProps['isReadOnly'];
  max?: number;
  min?: number;
  showThousandSeparator?: boolean;
  allowNegative?: boolean;
  isMoney?: boolean;
  isWhole?: boolean;
  isPercentage?: boolean;
  isLoading?: boolean;
  errorMessageWidth?: string;
  onValueChange?: (values: number) => void;
  labelProps?: FormLabelProps;
  _input?: InputProps;
  icon?: ReactNode;
  helperText?: string;
  size?: InputProps['size'];
  _container?: StackProps;
  invalid?: boolean;
}

const NumberInput = ({
  name,
  placeholder,
  type = 'tel',
  label,
  maxLength,
  autoFocus = false,
  readOnly = false,
  max = Infinity,
  min = -Infinity,
  showThousandSeparator,
  allowNegative = false,
  isMoney = false,
  isWhole = false,
  isPercentage = false,
  isLoading = false,
  errorMessageWidth,
  onValueChange,
  labelProps = {},
  icon,
  _input,
  helperText,
  size,
  _container,
  invalid = false,
  ...rest
}: Props) => {
  const [field, meta, { setValue }] = useField(name);

  const moneyProps = isMoney
    ? {
        thousandSeparator: true,
        prefix: '$',
        decimalSeparator: '.',
        decimalScale: 2,
      }
    : {};

  const wholeNumberProps = isWhole
    ? {
        decimalScale: 0,
      }
    : {};

  const percentageProps = isPercentage ? { suffix: '%' } : {};

  const handleValueChange = onValueChange ?? setValue;

  const isInvalid = (meta.touched && meta.error != null) || invalid;

  return (
    <VStack w="100%" spacing={0} h="100%" {..._container}>
      <FormControl isInvalid={isInvalid} {...rest}>
        {label && <Label {...labelProps}>{label}</Label>}
        <InputGroup>
          <ChakraInput
            as={NumericFormat}
            {...field}
            {...moneyProps}
            {...wholeNumberProps}
            {...percentageProps}
            type={type}
            readOnly={readOnly}
            thousandSeparator={
              showThousandSeparator === undefined && isMoney ? true : showThousandSeparator
            }
            allowNegative={allowNegative}
            onValueChange={(values: NumberFormatValues) =>
              handleValueChange(values.floatValue || 0)
            }
            onBlur={() => {
              let boundedValue = field.value;
              if (boundedValue > max) {
                boundedValue = max;
              }
              if (boundedValue < min) {
                boundedValue = min;
              }
              handleValueChange(boundedValue);
            }}
            placeholder={placeholder}
            _placeholder={{ color: placeholder === 'search term' ? 'transparent' : 'unset' }}
            isInvalid={isInvalid}
            size={size}
            maxLength={maxLength}
            name={name}
            autoFocus={autoFocus}
            textTransform="none"
            onChange={() => undefined}
            {..._input}
          />
          {isLoading && (
            <InputRightElement>
              <Spinner size="sm" mt="2" />
            </InputRightElement>
          )}
          {!isLoading && icon && <InputRightElement h="100%">{icon}</InputRightElement>}
        </InputGroup>
        {(isInvalid || helperText) && (
          <Box mt="5px" ml="5px" w={errorMessageWidth}>
            {isInvalid ? (
              <FormErrorMessage color="leaseEndRed">{meta.error}</FormErrorMessage>
            ) : (
              <FormHelperText color="taupeGray">{helperText}</FormHelperText>
            )}
          </Box>
        )}
      </FormControl>
    </VStack>
  );
};

export default NumberInput;
