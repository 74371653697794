import { Box, Button, Center } from '@chakra-ui/react';
import { useState } from 'react';

import { Deal } from '../../../../gql/generated/graphql';
import LeaseEndDocs from './LeaseEndDocs';
import MyDocs from './MyDocs';

interface Props {
  deal?: Deal;
  setUploadDocuments: (a: boolean) => void;
}

const DocumentsSections = ({ deal, setUploadDocuments }: Props) => {
  const [documentsSections, setDocumentsSections] = useState({
    sectionOneOpen: false,
    sectionTwoOpen: false,
  });

  return (
    <Box color="black">
      <LeaseEndDocs
        documentsSections={documentsSections}
        setDocumentsSections={setDocumentsSections}
        deal={deal}
      />
      <MyDocs documentsSections={documentsSections} setDocumentsSections={setDocumentsSections} />
      <Center>
        <Button fontWeight="bold" variant="link" onClick={() => setUploadDocuments(true)}>
          UPLOAD DOCUMENTS
        </Button>
      </Center>
    </Box>
  );
};

export default DocumentsSections;
