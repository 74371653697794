import { Grid, GridItem } from '@chakra-ui/react';

import { statesArray } from '../../constants/states';
import AutocompleteGoogleAddress from './AutocompleteGoogleAddress';
import Input from './Input';
import Select from './Select';

interface AddressInputProps {
  label?: string;
  prefix?: string;
  dropLabel?: boolean;
  autoCompleteDisabled?: boolean;
}

const AddressInput = ({ label, prefix, dropLabel, autoCompleteDisabled }: AddressInputProps) => {
  const formattedPrefix = prefix ? `${prefix}_` : '';

  const getAutoComplete = (type: string) => {
    if (autoCompleteDisabled) {
      return 'off';
    }
    return `${prefix}_${type}`;
  };

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr 1fr',
      }}
      gridColumnGap="16px"
      gridRowGap="20px"
      alignItems={dropLabel ? 'start' : 'end'}
    >
      <GridItem
        colSpan={{
          base: 1,
          md: 2,
        }}
      >
        <AutocompleteGoogleAddress
          label={label}
          prefix={formattedPrefix}
          name={`${formattedPrefix}address_line`}
          placeholder={autoCompleteDisabled ? 'Search Street' : 'Street'}
          dropLabel={dropLabel}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <Input
          name={`${formattedPrefix}address_line_2`}
          autoCompleteDisabled={autoCompleteDisabled}
          placeholder="Apt., Suite, Unit"
          _input={{
            autoComplete: getAutoComplete('address_line_2'),
          }}
        />
      </GridItem>
      <GridItem
        colSpan={{
          base: 1,
          md: 2,
        }}
      >
        <Input
          name={`${formattedPrefix}city`}
          autoCompleteDisabled={autoCompleteDisabled}
          placeholder="City"
          _input={{
            autoComplete: getAutoComplete('city'),
          }}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <Select
          name={`${formattedPrefix}state`}
          options={statesArray}
          placeholder="Search State"
          _input={{
            autoComplete: getAutoComplete('state'),
          }}
        />
      </GridItem>
      <GridItem
        colSpan={{
          base: 1,
          md: 2,
        }}
      >
        <Input
          name={`${formattedPrefix}zip`}
          autoCompleteDisabled={autoCompleteDisabled}
          placeholder="Zip"
          _input={{
            autoComplete: getAutoComplete('zip'),
          }}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <Input
          name={`${formattedPrefix}county`}
          autoCompleteDisabled={autoCompleteDisabled}
          placeholder="County"
          _input={{
            autoComplete: getAutoComplete('county'),
          }}
        />
      </GridItem>
    </Grid>
  );
};

export default AddressInput;
