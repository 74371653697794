import { Box, Flex, FlexProps, Image, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';

import Loader from '../../../components/Loader';
import { DEFAULT_LOAN_TERM, WORST_RATE } from '../../../constants/paymentCalculator';
import { Deal, Maybe, useBankLogoQuery } from '../../../gql/generated/graphql';
import { getDay, getMonth, getUTCDate } from '../../../utils/dates';
import { formatMoney } from '../../../utils/helpers';

interface Props extends FlexProps {
  deal: Maybe<Deal>;
  title?: string;
}

/**
 * @deprecated because of LDFlags.COM_DASHBOARD_DOWN_PAYMENT being true
 */

const DealTerms: React.FC<Props> = ({ deal, title, ...props }) => {
  const { data: bankLogoData, loading: bankLoading } = useBankLogoQuery({
    skip: !deal?.financial_info?.bank,
    variables: {
      bank_name: deal?.financial_info?.bank as string,
    },
  });

  if (!deal || bankLoading) {
    return <Loader padding="20px" />;
  }

  const firstPaymentDate = getUTCDate(deal?.financial_info?.first_payment_date);
  const firstPaymentDay = getDay(firstPaymentDate);
  const firstPaymentMonth = getMonth(firstPaymentDate, 'short');

  const rate = deal?.financial_info?.sell_rate || WORST_RATE;
  const term = deal?.financial_info?.term || DEFAULT_LOAN_TERM;

  return (
    <Flex direction="column" textAlign="center" alignItems="center" p="15px" {...props}>
      {title && (
        <Text fontSize="14px" fontWeight="bold">
          {title}
        </Text>
      )}
      <Image
        src={bankLogoData?.bankLogo ?? ''}
        alt={`${deal?.financial_info?.bank} Logo`}
        w={{ base: '150px', lg: '125px' }}
        my="10px"
      />
      <SimpleGrid columns={2} spacingY={2} spacingX={4} w="100%">
        <Box textAlign="right">
          <Text fontSize={{ base: '16px', lg: '18px' }} fontWeight="bold">
            {formatMoney(deal?.financial_info?.payment)}
          </Text>
          <Text fontSize="14px" color="taupeGray" textTransform="uppercase">
            Mo. Payment
          </Text>
        </Box>
        <Box textAlign="left">
          <Text fontSize={{ base: '16px', lg: '18px' }} fontWeight="bold">
            {term}
          </Text>
          <Text fontSize="14px" color="taupeGray" textTransform="uppercase">
            Term
          </Text>
        </Box>
        <Box textAlign="right">
          <Text fontSize={{ base: '16px', lg: '18px' }} fontWeight="bold">
            {rate}%
          </Text>
          <Text fontSize="14px" color="taupeGray" textTransform="uppercase">
            Interest Rate
          </Text>
        </Box>
        <Box textAlign="left">
          <Text
            fontSize={{ base: '16px', lg: '18px' }}
            fontWeight="bold"
          >{`${firstPaymentMonth} ${firstPaymentDay}`}</Text>
          <Text fontSize="14px" color="taupeGray" textTransform="uppercase">
            First Payment
          </Text>
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default DealTerms;
