import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';

import { handleColorChange } from './utils';

interface Props {
  rating: number;
  changeRating: (str: string, star: number) => void;
}

const Stars = ({ rating, changeRating }: Props) => {
  const stars = [1, 2, 3, 4, 5];
  const [colors, setColors] = useState<string[]>([
    rating > 0 ? 'leaseEndGreen' : 'gainsboro',
    rating > 1 ? 'leaseEndGreen' : 'gainsboro',
    rating > 2 ? 'leaseEndGreen' : 'gainsboro',
    rating > 3 ? 'leaseEndGreen' : 'gainsboro',
    rating > 4 ? 'leaseEndGreen' : 'gainsboro',
  ]);
  const [clicked, setClicked] = useState<boolean>(false);

  const handleClick = (star: number) => {
    changeRating('rating', star);
    handleColorChange(setColors, star);
    setClicked(true);
  };
  return (
    <Flex width="100%" justifyContent="center">
      {stars.map((star) => (
        <Box
          key={star}
          cursor="pointer"
          onClick={() => handleClick(star)}
          onMouseOver={() => {
            if (!clicked) {
              handleColorChange(setColors, star);
            }
          }}
          onMouseOut={() => {
            if (!clicked) {
              handleColorChange(setColors, rating);
            }
          }}
        >
          <Box
            w="50px"
            aspectRatio="1"
            backgroundColor={colors[star - 1]}
            clipPath="polygon(50% 0,79% 90%,2% 35%,98% 35%,21% 90%)"
          />
        </Box>
      ))}
    </Flex>
  );
};

export default Stars;
