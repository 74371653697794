import { Box, Button, Flex, FlexProps, Input, Text, useToast } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Dropzone, { FileRejection } from 'react-dropzone';
import * as Yup from 'yup';

import { DropImage } from '../../../../components/DragAndDrop';
import Select from '../../../../components/formComponents/Select';
import { Deal, DealMediaTypeEnum } from '../../../../gql/generated/graphql';
import { MediaContext, MediaContextType } from '../../../../hooks/useMediaCollection';
import { logger } from '../../../../services/sentry';
import { INPUT_FILE_TYPES, MAX_FILE_SIZE_MB } from '../../../../utils/media';
import { createSuccessToast } from '../../../../utils/toast';
import { buyerDocTypeOptions, coBuyerDocTypeOptions } from '../UploadDocuments/utils';

interface Props extends FlexProps {
  deal?: Deal;
  setUploadDocuments: (a: boolean) => void;
}

const DashboardUploadDocuments = ({ deal, setUploadDocuments }: Props) => {
  const toast = useToast();

  const { uploadImage } = useContext<MediaContextType>(MediaContext);

  const [error, setError] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object({
    type: Yup.string().required('Required'),
    file: Yup.mixed(),
  });

  const initialValues = {
    type: '',
    file: null,
  };

  type FormFields = Yup.InferType<typeof validationSchema>;

  const handleFileSelected = async (
    acceptedFiles: File[],
    setFieldValue: FormikHelpers<FormFields>['setFieldValue'],
  ) => {
    setError('');

    const fileToUpload = acceptedFiles[0] as File;

    setFieldValue('file', fileToUpload);
  };

  const handleRejection = (fileRejections: FileRejection[]) => {
    if (fileRejections[0].errors[0].code === 'file-invalid-type') {
      setError(
        `Files must be a valid format (${INPUT_FILE_TYPES.map((filetype) =>
          filetype.split('/')[1].toUpperCase(),
        ).join(', ')}).`,
      );
    } else if (fileRejections[0].errors[0].code === 'file-too-large') {
      setError(`Files cannot exceed ${MAX_FILE_SIZE_MB}MB.`);
    } else if (fileRejections[0].errors[0].code === 'too-many-files') {
      setError('Upload one file at a time.');
    } else {
      setError('There was an error uploading your file.');
    }
  };

  const handleUpload = async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    try {
      await uploadImage({
        mediaType: values.type as DealMediaTypeEnum,
        dealId: deal?.id ?? '',
        file: values.file,
        deletePrev: false,
        allowPdf: true,
      });
      toast(
        createSuccessToast({
          title: 'File uploaded successfully',
        }),
      );
      setUploadDocuments(false);
    } catch (e) {
      logger.error(
        'dashboard/documents/DashboardUploadDocuments.tsx:handleUpload',
        'Failed uploading file',
        null,
        e,
      );
      setError((e as Error).message);
    } finally {
      if (inputRef.current) {
        const ref = inputRef;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ref.current!.value = '';
      }
      helpers.setSubmitting(false);
      helpers.resetForm();
    }
  };

  return (
    <Formik<FormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpload}
    >
      {({ values, isSubmitting, setFieldValue, resetForm }) => (
        <Form>
          {!values.file ? (
            <>
              <DndProvider backend={HTML5Backend}>
                <DropImage canDrop set={() => undefined}>
                  {(isOver) => (
                    <Dropzone
                      onDropAccepted={(acceptedFiles) =>
                        handleFileSelected(acceptedFiles, setFieldValue)
                      }
                      onDropRejected={(fileRejections) => {
                        handleRejection(fileRejections);
                        setFieldValue('file', null);
                      }}
                      accept={[...INPUT_FILE_TYPES, 'application/pdf']}
                      maxFiles={1}
                      noClick
                      noKeyboard
                      multiple={false}
                      maxSize={MAX_FILE_SIZE_MB * 1000000}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <Flex
                            {...getRootProps()}
                            opacity={isOver || isDragActive ? '80%' : '100%'}
                            borderRadius="10px"
                            cursor={isSubmitting ? 'not-allowed' : 'default'}
                            onClick={() => inputRef.current?.click()}
                            border="2px dashed"
                            borderColor={isOver || isDragActive ? 'bostonBlue' : 'gray.200'}
                            flexDir="column"
                            p={4}
                            gap={3}
                            justifyContent="center"
                          >
                            <Text display={{ base: 'none', xl: 'block' }} textAlign="center">
                              DRAG FILES HERE
                            </Text>
                            <Text display={{ base: 'none', xl: 'block' }} textAlign="center">
                              - OR -
                            </Text>
                            <Button mx="auto">SELECT FILE</Button>
                            <Button
                              variant="link"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setUploadDocuments(false);
                              }}
                            >
                              CANCEL
                            </Button>
                            <Input
                              {...getInputProps()}
                              size={undefined}
                              display="none"
                              ref={inputRef}
                            />
                          </Flex>
                        );
                      }}
                    </Dropzone>
                  )}
                </DropImage>
              </DndProvider>
              {error && (
                <Text mt={5} fontSize="12px" wordBreak="break-word" color="leaseEndRed">
                  {error}
                </Text>
              )}
            </>
          ) : (
            <Box mt={5} textAlign="center" w="full">
              <Box>
                <Text mb={2}>{values.file.name}</Text>
                <Select
                  name="type"
                  placeholder="Select a document type"
                  options={deal?.isCobuyer ? coBuyerDocTypeOptions : buyerDocTypeOptions}
                />
                <Flex flexDir="column" alignItems="center">
                  <Button type="submit" mt={4} isLoading={isSubmitting}>
                    UPLOAD
                  </Button>
                  <Button
                    variant="link"
                    type="button"
                    size="sm"
                    mt={4}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      resetForm();
                      setUploadDocuments(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Box>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DashboardUploadDocuments;
