import config from '../config';

// ----- Marketing urls -----
const mktUrlBase = config.mktUrl;
export const ABOUT_US = `${mktUrlBase}/about-us`;
export const FAQS = `${mktUrlBase}/faqs`;
export const MKT_HOME = `${mktUrlBase}/`;
export const HOW_IT_WORKS = `${mktUrlBase}/how-it-works`;
export const PRIVACY_POLICY = `${mktUrlBase}/privacy-policy`;
export const CONTACT_US = `${mktUrlBase}/contact-us`;
export const PRESS = `${mktUrlBase}/press`;
export const REVIEWS = `${mktUrlBase}/reviews`;
export const PAID_SEARCH = `${mktUrlBase}/paid-media`;
export const CONSUMER_VOICE = `${mktUrlBase}/cv`;
export const CJ = `${mktUrlBase}/cj`;
export const MONTHLY_PAYMENT_CALCULATOR = `${mktUrlBase}/monthly-payment-calculator`;
export const OPT_OUT = `${mktUrlBase}/opt-out`;
export const BEST_MONEY = `${mktUrlBase}/bestmoney`;
export const BUY_YOUR_LEASED_CAR = `${mktUrlBase}/buy-your-leased-car`;
// ----- End Marketing urls -----

export const HOME = '/';

// Accounts
export const ACCOUNT_LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ONE_CLICK_LOGIN = '/one-click-login';
export const CREATE_ACCOUNT = '/create-account';
export const LINK_MY_ACCOUNT = '/link-my-account';

// Dashboard
export const DASHBOARD = '/dashboard';
export const PAYMENTS_DASHBOARD = '/dashboard/payments';

export const REDIRECT_URLS = {
  // leaseend.com/KEY: 'new-url'
  '/payments/ach':
    'https://live.payments-checkout.com/payment_link?bearer_token=MDE4ZWU4MzctNWNiMC03ZDk5LTg3OWItOTRjNzA5OGJlMmUxLVBBWU1FTlRfTElOSw==',
  '/payments/card': 'https://link.payments-checkout.com/kCyAsM ',
};

// ----- Flow Steps -----
// Flow Starting Pages
export const FLOW_ENTRY = '/start';
export const PAYOFF_REENTER = '/payoff-received';
export const FLOW_REENTER = '/flow/welcome-back';
export const AFFILIATE_ENTRY = '/flow/affiliate';

// Main Flow
export const GETTING_STARTED = '/flow/getting-started'; // Redesign
export const PAYOFF_INSTRUCTIONS = '/flow/payoff-instructions'; // Redesign
export const LOAN_APPLICATION = '/flow/loan-application'; // Redesign

// Co-Buyer Loan Application
export const COBUYER_LOAN_APPLICATION = '/flow/loan-application/co-buyer'; // Redesign

// Buyout Continued
export const NEXT_STEPS = '/flow/next-steps';
export const DEAL_PROCESSING = '/flow/deal-processing';

// Finished
export const ALL_DONE = '/flow/all-done';

export const FINAL_ADJUSTMENTS = '/flow/final-adjustments';

// Lease Peace
export const LEASE_PEACE = '/lease-peace';

// User Feedback
export const FEEDBACK = '/feedback';

export const TEMP_INFO_PAGES = [GETTING_STARTED, PAYOFF_INSTRUCTIONS, NEXT_STEPS, LOAN_APPLICATION];

export const DEAL_PAGES = [DEAL_PROCESSING];

// ----- End Flow Steps -----

// Social Media
export const FACEBOOK = 'https://www.facebook.com/leaseendadvisors';
export const LINKEDIN = 'https://www.linkedin.com/company/lease-end/';
export const VIMEO = 'https://vimeo.com/user156421697';
export const YOUTUBE = 'https://www.youtube.com/channel/UCSzt538qbD3gTXiKAARWW1A';
export const INSTAGRAM = 'https://www.instagram.com/lease.end/';
export const TWITTER = 'https://x.com/Lease_End_';

// Inc 500
export const INC_5000 =
  'https://www.globenewswire.com/en/news-release/2022/08/16/2498919/0/en/Lease-End-Ranks-No-171-on-the-2022-Inc-5000-Annual-List-of-Fastest-Growing-Companies-with-4-650-Growth.html';

// Google
export const GOOGLE =
  'https://www.google.com/maps/place/Lease+End+Headquarters/@42.5373242,-113.7824247,17z/data=!4m8!3m7!1s0x54ab37a3cba77799:0x4ce861bc3fb705f!8m2!3d42.5373203!4d-113.780236!9m1!1b1!16s%2Fg%2F11s34wqfgx';

// Careers
export const CAREERS = 'https://www.indeed.com/cmp/Lease-End';

// Knowledge Center Articles
export const LEARN = 'https://learn.leaseend.com';
