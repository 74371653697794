import { number } from 'yup';

export const numbersOnly = /\d+/;
export const numberRequiredValidation = number().nullable().required('Please enter a number');
export const salaryRequiredAndMaxValidation = number()
  .nullable()
  .required('Please enter a number')
  .max(999999999.99, 'Salary must be less than or equal to 999,999,999.99');
export const numberOptionalValidation = number().notRequired().nullable();
export const monthsValidation = number().nullable().max(11, 'Please enter a value less than 12');
