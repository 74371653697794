import { Box, Container } from '@chakra-ui/react';
import { atom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ServiceDownBanner from '../../components/ServiceDownBanner';
import { isProd } from '../../config';
import { LDFlags } from '../../constants/flags';
import { DASHBOARD, LINK_MY_ACCOUNT, PAYMENTS_DASHBOARD } from '../../constants/urls';
import {
  DealType,
  UserDealQuery,
  useExternalCustomerUpdateMutation,
  useGetUnlinkedDealsQuery,
  useLinkUnlinkedCustomersMutation,
  useUserDealQuery,
  useUserDealsQuery,
} from '../../gql/generated/graphql';
import { useFlag, useMediaCollection } from '../../hooks';
import useCheckFlowStatus from '../../hooks/useCheckFlowStatus';
import { MediaContext } from '../../hooks/useMediaCollection';
import { rudderanalytics } from '../../utils/rudderstack';
import { DealStateEnum } from '../../utils/types/deal';
import DashboardContent from './components/DashboardContent';
import DashboardDevToolbar, {
  devDealSTypeAtom,
  devDealStateAtom,
  isDevActiveAtom,
} from './components/DashboardDevToolbar';
import DashboardFooter from './components/DashboardFooter';
import DashboardHeader from './components/DashboardHeader';
import DashboardLoadingSkeleton from './components/DashboardLoadingSkeleton';
import TimeLine from './components/Timeline/Timeline';
import WelcomeModal from './components/WelcomeModal';
import {
  DealStateMap,
  DealStateMapReverse,
  DealWithFarthestState,
  IGNORED_DEAL_STATES,
  RESET_DEAL_STATES,
} from './utils';

export interface DealIdentifier {
  id: string;
  isCobuyer?: boolean;
}

export const NEW_DEAL_VALUE = {
  id: 'new-deal',
};

export const dashboardDealInfoAtom = atom<DealIdentifier | undefined>(undefined);

type DealStates = NonNullable<UserDealQuery['userDeal']>['deal_states'];

const getFarthestDealState = (dealStates: DealStates) => {
  if (!dealStates || !dealStates.length) return DealStateEnum.Structuring;
  // sort by date so they are in chronological order
  const sortedDealStates = [...dealStates].sort((a, b) => {
    const aDate = new Date(a?.updated_date_utc);
    const bDate = new Date(b?.updated_date_utc);
    return aDate.getTime() - bDate.getTime();
  });
  // Gets the farthest deal state since the last time a deal was in signed.
  // except for ignored states
  const farthestStateValue = sortedDealStates?.reduce((prev, curr) => {
    if (!curr?.state) {
      return prev;
    }
    const currState = curr.state as DealStateEnum;
    const currStateValue = DealStateMap[currState];

    if (
      !IGNORED_DEAL_STATES.includes(currState) &&
      (RESET_DEAL_STATES.includes(currState) || currStateValue > prev)
    ) {
      return currStateValue;
    }
    return prev;
  }, 0);

  const farthestDealState = DealStateMapReverse[farthestStateValue || 0];
  return farthestDealState;
};

const Dashboard = () => {
  const dealId = useAtomValue(dashboardDealInfoAtom);
  const mediaCollection = useMediaCollection({ dealId: dealId?.id });
  const serviceDownBannerMessage = useFlag(LDFlags.SERVICE_DOWN_BANNER);
  const inDashVerification = useFlag(LDFlags.SSN_DASH_VERIFICATION);

  const history = useHistory();
  const { pathname } = useLocation();

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [customerUpdateMutation] = useExternalCustomerUpdateMutation();
  const [loading, setLoading] = useState(true);
  const [dealIsPending, setDealIsPending] = useState(true);

  const { data: userDeals, loading: dealsLoading } = useUserDealsQuery();
  const { inFlow, car } = useCheckFlowStatus();

  const isNewDeal = dealId?.id === NEW_DEAL_VALUE.id;
  const hasNoDeals = !userDeals?.userDeals?.length;

  const { data: userDeal } = useUserDealQuery({
    fetchPolicy: 'network-only',
    skip: hasNoDeals || dealsLoading,
    variables: {
      id: isNewDeal ? undefined : dealId?.id,
      isCobuyer: dealId?.isCobuyer,
    },
    onCompleted({ userDeal: deal }) {
      if (
        deal &&
        (deal?.isCobuyer ? !deal?.cobuyer?.dashboard_visited : !deal?.customer?.dashboard_visited)
      ) {
        setShowWelcomeModal(true);
        customerUpdateMutation({
          variables: {
            customerId: (deal?.isCobuyer ? deal?.cobuyer?.id : deal?.customer?.id) || '',
            customer: {
              dashboard_visited: true,
            },
          },
        });
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (userDeal?.userDeal?.id && userDeal?.userDeal?.customer?.id) {
      rudderanalytics.identify({
        deal_id: userDeal?.userDeal?.id,
        customer_id: userDeal?.userDeal?.customer?.id,
      });
    }
  }, [userDeal]);

  useEffect(() => {
    if (!dealsLoading && hasNoDeals) {
      setLoading(false);
    }
  }, [hasNoDeals, dealsLoading]);

  const [linkUnlinkedDeals] = useLinkUnlinkedCustomersMutation();
  const { refetch } = useUserDealsQuery({ fetchPolicy: 'network-only' });

  useGetUnlinkedDealsQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    onCompleted: async ({ getUnlinkedDeals }) => {
      if (getUnlinkedDeals && getUnlinkedDeals.length > 0) {
        if (!inDashVerification) {
          history.replace(LINK_MY_ACCOUNT);
        } else {
          await linkUnlinkedDeals();
          refetch();
        }
      }
    },
  });

  let farthestDealState = useMemo(() => {
    if (!userDeal?.userDeal?.deal_states) return DealStateEnum.Structuring;

    const farState = getFarthestDealState(userDeal?.userDeal?.deal_states as DealStates);
    setDealIsPending(farState === DealStateEnum.Structuring);
    return farState;
  }, [userDeal?.userDeal?.deal_states]);
  let state = userDeal?.userDeal?.state;

  // Forces booted state in case Deal was previously in the finalized state.
  let isDealBooted = userDeal?.userDeal?.state === DealStateEnum.Booted;
  let type = userDeal?.userDeal?.type;

  const isDevActive = useAtomValue(isDevActiveAtom);
  const devDealState = useAtomValue(devDealStateAtom);
  const devDealType = useAtomValue(devDealSTypeAtom);

  if (isDevActive) {
    farthestDealState = DealStateMapReverse[DealStateMap[devDealState]];
    state = devDealState;
    isDealBooted = devDealState === DealStateEnum.Booted;
    type = devDealType;
  }

  let deal = userDeal?.userDeal as DealWithFarthestState;
  if (deal) {
    deal = { ...userDeal?.userDeal, farthestState: farthestDealState, state, type };
  }
  const isRefi = deal?.type === DealType.Refi;
  const isDashboard = pathname === DASHBOARD;
  const isDealPending = isDevActive
    ? farthestDealState === DealStateEnum.Structuring
    : dealIsPending;
  const showTimeline = !isDealPending && !isDealBooted && !hasNoDeals && !isNewDeal && isDashboard;

  return (
    <MediaContext.Provider value={mediaCollection}>
      {serviceDownBannerMessage && (
        <ServiceDownBanner serviceDownBannerMessage={serviceDownBannerMessage} />
      )}
      <Box bgColor={pathname !== PAYMENTS_DASHBOARD ? 'grayBackground' : 'white'} h="100%">
        <Box minHeight="100vh">
          <DashboardHeader inFlow={inFlow} car={car} />
          {loading ? (
            <DashboardLoadingSkeleton />
          ) : (
            <>
              {showTimeline && (
                <TimeLine loading={loading} farthestDealState={farthestDealState} isRefi={isRefi} />
              )}
              <Container maxW="container.xl" mt="20px">
                <DashboardContent
                  deal={deal}
                  hasNoDeals={hasNoDeals}
                  isNewDeal={isNewDeal}
                  isDealBooted={isDealBooted}
                  isDealPending={isDealPending}
                  inFlow={inFlow}
                  car={car}
                />
                {!isProd && <DashboardDevToolbar deal={deal} />}
                <DashboardFooter />
              </Container>
            </>
          )}
        </Box>
      </Box>
      <WelcomeModal
        isRefi={isRefi}
        isOpen={showWelcomeModal}
        onClose={() => setShowWelcomeModal(false)}
      />
    </MediaContext.Provider>
  );
};

export default Dashboard;
