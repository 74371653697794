import { Box, Collapse, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { FaChevronRight } from 'react-icons/fa';

import { MediaContext, MediaContextType } from '../../../../hooks/useMediaCollection';
import DocListItem from './DocListItem';

interface Props {
  documentsSections: { sectionOneOpen: boolean; sectionTwoOpen: boolean };
  setDocumentsSections: (sections: { sectionOneOpen: boolean; sectionTwoOpen: boolean }) => void;
}

const MyDocs = ({ documentsSections, setDocumentsSections }: Props) => {
  const { customerUploadedMedia } = useContext<MediaContextType>(MediaContext);

  return (
    <Box py={4}>
      <Flex
        as="button"
        alignItems="center"
        w="full"
        onClick={() =>
          setDocumentsSections({
            ...documentsSections,
            sectionTwoOpen: !documentsSections.sectionTwoOpen,
          })
        }
      >
        <Icon
          transform={documentsSections.sectionTwoOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
          transition="transform 0.3s ease"
          as={FaChevronRight}
          mr="2"
        />
        <Text fontWeight="semibold">UPLOAD HISTORY ({customerUploadedMedia.length})</Text>
      </Flex>
      <Collapse in={documentsSections.sectionTwoOpen} animateOpacity>
        {customerUploadedMedia.length === 0 ? (
          <Box pt={2} px={4}>
            No downloads at this time
          </Box>
        ) : (
          <Flex pt={2} px={4} flexDir="column">
            {customerUploadedMedia.map((media) => {
              const splittedName = media?.key?.split('.') ?? [];
              const extension = splittedName[splittedName.length - 1];

              return (
                <DocListItem
                  media={media}
                  key={media.key}
                  onDownloadClick={() =>
                    saveAs(media.signed_url as string, `${media.type}.${extension}`)
                  }
                  downloading={false}
                />
              );
            })}
          </Flex>
        )}
      </Collapse>
      <Divider borderColor="grayDarkBackground" pt={4} />
    </Box>
  );
};

export default MyDocs;
