import { Box, Center, useBreakpoint } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from '../../components/Loader';
import Title from '../../components/Title';
import { MKT_HOME } from '../../constants/urls';
import {
  CreateAffiliateTemporaryInfoMutation,
  TemporaryData,
  useCreateAffiliateTemporaryInfoMutation,
} from '../../gql/generated/graphql';
import { CookieKeys, clearCookie, useCookie } from '../../hooks/useCookie';
import { rudderanalytics } from '../../utils/rudderstack';
import { capitalizeFirstLetter } from '../../utils/text/text';
import AffiliateWidget from './AffiliateWidget';
import { AFFILIATE_PARAMS } from './utils';

const AffiliateEntry = () => {
  const [, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [showDecoder, setShowDecoder] = useState(false);
  const [tempInfoData, setTempInfoData] = useState<TemporaryData>();

  const { search } = useLocation();
  const device = useBreakpoint();

  const [createTempInfo] = useCreateAffiliateTemporaryInfoMutation({
    context: {
      isErrorHandled: true,
    },
  });

  const returnHome = () => {
    window.location.href = MKT_HOME;
  };

  const initialize = async () => {
    const tempInfoMutation: CreateAffiliateTemporaryInfoMutation = await new Promise(
      (resolve, reject) =>
        createTempInfo({
          variables: {
            device_type: device,
            query_params: search,
          },
          onCompleted: resolve,
          onError: () => {
            returnHome();
            reject();
          },
        }),
    );

    const guid = tempInfoMutation.createAffiliateTemporaryInfo?.id as string;
    setGuid(guid);
    setTempInfoData(tempInfoMutation.createAffiliateTemporaryInfo?.data);
    clearCookie(CookieKeys.PREQUAL_PARAMS);
    rudderanalytics.identify({ guid });

    setShowDecoder(true);
  };

  const isInitialized = useRef(false);
  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      const params = new URLSearchParams(search);
      const affiliateId = params.get(AFFILIATE_PARAMS.AFFILIATE_ID);

      if (affiliateId) {
        initialize();
      } else {
        returnHome();
      }
    }
  }, [search]);

  return (
    <Box minH="85vh" mt="80px">
      {!showDecoder ? (
        <Loader />
      ) : (
        <>
          <Title fontSize={{ base: '36px', md: '52px' }}>
            Finalize Your Application, {capitalizeFirstLetter(tempInfoData?.first_name)}
          </Title>
          <Center mb="30px" mt="30px">
            <AffiliateWidget />
          </Center>
        </>
      )}
    </Box>
  );
};

export default AffiliateEntry;
