import { ID } from 'graphql-ws';
import { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';

export type FeedbackFormFields = {
  name: string;
  email: string;
  rating: number;
  improvements: string;
  deal_id: ID;
};

export const feedbackVSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  rating: Yup.number()
    .required('Rating is required')
    .test('is-star', 'Rating is required', (val) => (val ? val > 0 : false)),
  improvements: Yup.string().required('Please answer the question'),
});

export const handleColorChange = (set: Dispatch<SetStateAction<string[]>>, star: number) => {
  switch (star) {
    case 1:
      set(['leaseEndGreen', 'gainsboro', 'gainsboro', 'gainsboro', 'gainsboro']);
      break;
    case 2:
      set(['leaseEndGreen', 'leaseEndGreen', 'gainsboro', 'gainsboro', 'gainsboro']);
      break;
    case 3:
      set(['leaseEndGreen', 'leaseEndGreen', 'leaseEndGreen', 'gainsboro', 'gainsboro']);
      break;
    case 4:
      set(['leaseEndGreen', 'leaseEndGreen', 'leaseEndGreen', 'leaseEndGreen', 'gainsboro']);
      break;
    case 5:
      set(['leaseEndGreen', 'leaseEndGreen', 'leaseEndGreen', 'leaseEndGreen', 'leaseEndGreen']);
      break;
    default:
      set(['gainsboro', 'gainsboro', 'gainsboro', 'gainsboro', 'gainsboro']);
      break;
  }
};
