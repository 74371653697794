import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import Triangle from '../../../../components/Triangle';

const TimelineFlag = ({
  last,
  status,
  children,
  text,
}: {
  last?: boolean;
  status: 'completed' | 'inprogress' | 'upcoming';
  children: React.ReactNode;
  text?: string;
}) => {
  const bg = {
    upcoming: 'silver',
    inprogress: 'oceanBoatBlue',
    completed: 'leaseEndGreen',
  }[status];

  return (
    <Flex direction={last ? 'row-reverse' : 'row'}>
      <Flex direction="column" width="150px">
        <Flex w="full" mt="8px" direction={last ? 'row-reverse' : 'row'}>
          <Box>
            <Triangle
              verticalDirection="up"
              horizontalDirection={last ? 'left' : 'right'}
              color={bg}
              size={16}
            />
            <Triangle
              verticalDirection="down"
              horizontalDirection={last ? 'left' : 'right'}
              color={bg}
              size={16}
            />
          </Box>
          <Box
            bg={bg}
            h="32px"
            w="full"
            color="white"
            fontWeight="bold"
            lineHeight="32px"
            textAlign="center"
          >
            {text}
          </Box>
        </Flex>
        <Box py="20px">{children}</Box>
      </Flex>
      <Box bg={bg} h="full" w="4px" position="relative">
        <Box h="8px" w="8px" bg={bg} borderRadius="50%" position="absolute" left="-50%" top="0px" />
        <Box
          h="8px"
          w="8px"
          bg={bg}
          borderRadius="50%"
          position="absolute"
          left="-50%"
          bottom="0px"
        />
      </Box>
    </Flex>
  );
};

export default TimelineFlag;
