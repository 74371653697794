import { Text } from '@chakra-ui/react';
import React, { useContext } from 'react';

import { LienholderContext, LienholderContextType } from '../../../../hooks/useLienholderInfo';

interface CopyProps {
  lienholder?: string;
  afterHours?: boolean;
}

const CanWeHelp: React.FC<{ lienholder?: string }> = () => {
  return (
    <Text color="leaseEndBlue" textAlign="center" mt="20px">
      Our Advisors are standing by to get your Payoff Amount
    </Text>
  );
};

const ForceHelp: React.FC<{ lienholder?: string }> = ({ lienholder }) => {
  return (
    <Text color="leaseEndBlue" textAlign="center" mt="20px">
      <Text as="b">{lienholder?.toUpperCase()}</Text> lets us automatically find your Payoff Amount
      with just a couple pieces of information!
    </Text>
  );
};

const AfterHours: React.FC = () => {
  return (
    <Text textAlign="center" mt="20px">
      We’d love to help you get your payoff unfortunately our advisors are only available during
      business hours (see below). Whatcha think?
    </Text>
  );
};

const Copy: React.FC<CopyProps> = ({ lienholder, afterHours }) => {
  const { allowsDigital } = useContext<LienholderContextType>(LienholderContext);

  if (!allowsDigital && afterHours) {
    return <AfterHours />;
  }

  return allowsDigital ? <ForceHelp lienholder={lienholder} /> : <CanWeHelp />;
};

export default Copy;
