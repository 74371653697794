import { Box, Container, Flex, Skeleton } from '@chakra-ui/react';

const DashboardLoadingSkeleton = () => (
  <Box>
    <Skeleton h={{ base: '146px', md: '230px' }} />
    <Container maxW="container.xl">
      <Flex mt="20px" gap="20px">
        <Skeleton h={{ base: '400px', md: '280px' }} rounded="md" w={{ base: '100%', md: '60%' }} />
        <Skeleton display={{ base: 'none', md: 'block' }} h="280px" rounded="md" w="40%" />
      </Flex>
      <Flex mt="20px" gap="20px">
        <Skeleton h="395px" rounded="md" w={{ base: '100%', md: '60%' }} />
        <Skeleton display={{ base: 'none', md: 'block' }} h="230px" rounded="md" w="40%" />
      </Flex>
    </Container>
  </Box>
);

export default DashboardLoadingSkeleton;
