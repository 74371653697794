import { Flex, Icon, IconButton, Image, Spinner, Text } from '@chakra-ui/react';
import { BsFiles } from 'react-icons/bs';
import { MdOutlineFileDownload } from 'react-icons/md';

import { DealMedia } from '../../../../gql/generated/graphql';
import { snakeCaseToHuman } from '../../../../utils/strings';

interface Props {
  areLeaseEndDocs?: boolean;
  onDownloadClick: () => void;
  downloading: boolean;
  media?: DealMedia;
}

const DocListItem = ({ media, areLeaseEndDocs, onDownloadClick, downloading }: Props) => {
  const isPdf = media?.key?.toLocaleLowerCase().endsWith('.pdf');

  return (
    <Flex
      mb={2}
      w="full"
      alignItems="center"
      justifyContent="space-between"
      bgColor="grayBackground"
      p={4}
      borderRadius="10px"
    >
      <Flex alignItems="center">
        {!areLeaseEndDocs && media ? (
          <>
            {isPdf ? (
              <Icon boxSize="20px" as={BsFiles} mr={2} />
            ) : (
              <Image boxSize="20px" objectFit="cover" mr={2} src={media.signed_url as string} />
            )}
            <Text mt="1px">{snakeCaseToHuman(media.type)}</Text>
          </>
        ) : (
          <>
            <Icon boxSize="20px" as={BsFiles} mr={2} />
            <Text mt="1px">Signed Docs</Text>
          </>
        )}
      </Flex>
      <IconButton
        onClick={onDownloadClick}
        variant="ghost"
        aria-label="download"
        icon={
          downloading ? <Spinner size="sm" /> : <Icon fontSize="20px" as={MdOutlineFileDownload} />
        }
      />
    </Flex>
  );
};

export default DocListItem;
