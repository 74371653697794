import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { Input } from '../../components/formComponents';
import TextBox from '../../components/formComponents/TextBox';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { useCreateFeedbackMutation } from '../../gql/generated/graphql';
import Stars from './Stars';
import { FeedbackFormFields, feedbackVSchema } from './utils';

const Feedback = () => {
  const [submitted, setSubmitted] = useState(false);
  const [createFeedback] = useCreateFeedbackMutation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const feedbackInitials = {
    name: searchParams.get('name')?.replace('+', ' ') ?? '',
    email: searchParams.get('email') ?? '',
    rating: Number(searchParams.get('stars')) ?? 0,
    improvements: '',
    deal_id: searchParams.get('id') ?? '',
  };

  const handleSubmit = async (vals: FeedbackFormFields) => {
    await createFeedback({
      variables: {
        name: vals.name,
        email: vals.email,
        rating: vals.rating,
        improvements: vals.improvements,
        deal_id: vals.deal_id,
      },
    });

    setSubmitted(true);
  };

  if (submitted) {
    return (
      <Container>
        <Box w="100%" pt="75px" h="75vh">
          <Text m="0 auto" w="95%" textAlign="center" fontSize="28px">
            Thank you for sharing your thoughts! We value all feedback and use it to continually
            enhance our customer experience.
          </Text>
        </Box>
      </Container>
    );
  }

  return (
    <Formik
      validationSchema={feedbackVSchema}
      initialValues={feedbackInitials}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ setFieldValue, isSubmitting, errors, values }) => (
        <Form>
          <Container>
            <Flex textAlign="left" mb="80px" flexDir="column">
              <TitleContainer>
                <Title>Driver Experience</Title>
              </TitleContainer>
              <Flex
                textAlign="left"
                gap="15px"
                flexDir="column"
                border="1px solid"
                borderColor="grayBackground"
                width={{ base: '95%', md: '75%', xl: '650px' }}
                m="0 auto"
                p={{ base: '24px 18px', md: '18px 36px' }}
                borderRadius="15px"
                boxShadow="md"
                fontSize="16px"
                bgColor="white"
              >
                <Input
                  name="name"
                  value={values.name}
                  placeholder="Name"
                  _input={{
                    maxLength: 60,
                  }}
                />
                <Input
                  name="email"
                  value={values.email}
                  placeholder="Email"
                  _input={{
                    maxLength: 60,
                  }}
                />
                <Stars rating={values.rating} changeRating={setFieldValue} />
                {errors.rating && (
                  <Text color="leaseEndRed" fontSize="12.25px" ml="5px">
                    {errors.rating}
                  </Text>
                )}
                <TextBox
                  name="improvements"
                  placeholder="Max 300 characters"
                  max={300}
                  title="What did you enjoy about your experience with Lease End, and how can we improve?"
                />
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                  SUBMIT
                </PrimaryButton>
              </Flex>
            </Flex>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default Feedback;
