import { MagnifyingGlassPurpleGuy } from '../../../../assets/Images';
import { LEAccordionItem } from '../../../../components/LEAccordion';
import { LDFlags } from '../../../../constants/flags';
import { useFlag } from '../../../../hooks';
import Content from './Content';

interface Props {
  isOtherLienholder: boolean;
  forceCustomerFindPayoff: boolean;
}

const PayoffEducationItem = ({ isOtherLienholder, forceCustomerFindPayoff }: Props) => {
  const showPurplePeople = useFlag(LDFlags.SHOW_PURPLE_PEOPLE);

  return (
    <LEAccordionItem
      accordionKey="payoffEducation"
      title={isOtherLienholder ? 'GETTING YOUR PAYOFF' : "NOW LET'S GET YOUR PAYOFF AMOUNT!"}
      imgSrc={showPurplePeople && MagnifyingGlassPurpleGuy}
    >
      <Content forceCustomerFindPayoff={forceCustomerFindPayoff} />
    </LEAccordionItem>
  );
};

export default PayoffEducationItem;
