import { LEAccordionItem } from '../../../components/LEAccordion';
import Content from './EnterYourPayoffItem/Content';

const PayoffAmountAndInstructions = () => {
  return (
    <LEAccordionItem accordionKey="payoffAmountAndInstructions" title="PAYOFF AMOUNT">
      <Content />
    </LEAccordionItem>
  );
};

export default PayoffAmountAndInstructions;
