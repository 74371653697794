import { LDFlags } from '../constants/flags';
import {
  AFFILIATE_ENTRY,
  ALL_DONE,
  COBUYER_LOAN_APPLICATION,
  DASHBOARD,
  DEAL_PROCESSING,
  FINAL_ADJUSTMENTS,
  FLOW_ENTRY,
  GETTING_STARTED,
  LOAN_APPLICATION,
  MKT_HOME,
  NEXT_STEPS,
  PAYOFF_INSTRUCTIONS,
  PAYOFF_REENTER,
} from '../constants/urls';

export const defaultBack = () => '';
export const defaultNext = () => '';

export type BackOptions = {
  hasCobuyer?: boolean;
  isGapOffered?: boolean;
  redirect?: boolean;
};

export type NextOptions = {
  isGapOffered?: boolean;
  isBuyout?: boolean;
  isJourneyFinished?: boolean;
  hasPackageOptions?: boolean;
  isCobuyerSameResidence?: boolean;
  hasCobuyer?: boolean;
  isAdditionalInfoNeeded?: boolean;
  isPayoffUploaded?: boolean;
  redirectToKbbOptionsPage?: boolean;
};

export interface Step {
  url: string;
  back: (options: BackOptions, checkFlag: (flag: LDFlags) => boolean) => string;
  next: (options: NextOptions, checkFlag: (flag: LDFlags) => boolean) => string;
}

export const itineraries: Step[] = [
  {
    url: FLOW_ENTRY,
    back: defaultBack,
    next: () => GETTING_STARTED,
  },
  {
    url: AFFILIATE_ENTRY,
    back: defaultBack,
    next: () => GETTING_STARTED,
  },
  {
    url: DASHBOARD,
    back: defaultBack,
    next: () => GETTING_STARTED,
  },
  {
    url: PAYOFF_INSTRUCTIONS,
    back: () => GETTING_STARTED,
    next: () => LOAN_APPLICATION,
  },
  {
    url: PAYOFF_REENTER,
    back: () => MKT_HOME,
    next: () => PAYOFF_INSTRUCTIONS,
  },
  {
    url: LOAN_APPLICATION,
    back: () => PAYOFF_INSTRUCTIONS,
    next: ({ hasCobuyer }) => {
      if (hasCobuyer) {
        return COBUYER_LOAN_APPLICATION;
      }

      return NEXT_STEPS;
    },
  },
  {
    url: COBUYER_LOAN_APPLICATION,
    back: () => LOAN_APPLICATION,
    next: () => NEXT_STEPS,
  },
  {
    url: NEXT_STEPS,
    back: () => LOAN_APPLICATION,
    next: ({ redirectToKbbOptionsPage }) => {
      if (redirectToKbbOptionsPage) {
        return FINAL_ADJUSTMENTS;
      }

      return DEAL_PROCESSING;
    },
  },
  {
    url: FINAL_ADJUSTMENTS,
    back: defaultBack,
    next: () => DEAL_PROCESSING,
  },
  {
    url: ALL_DONE,
    back: () => NEXT_STEPS,
    next: defaultNext,
  },
  {
    url: DEAL_PROCESSING,
    back: defaultBack,
    next: () => ALL_DONE,
  },
  {
    url: GETTING_STARTED,
    back: () => MKT_HOME,
    next: () => PAYOFF_INSTRUCTIONS,
  },
];
