import React from 'react';

import { CuriousPurpleGuy } from '../../../../assets/Images';
import { LEAccordionItem } from '../../../../components/LEAccordion';
import { LDFlags } from '../../../../constants/flags';
import { useFlag } from '../../../../hooks';
import Content from './Content';
import Subtitle from './Subtitle';

const WeCanHelpItem = () => {
  const showPurplePeople = useFlag(LDFlags.SHOW_PURPLE_PEOPLE);

  return (
    <LEAccordionItem
      accordionKey="weCanHelp"
      title="WE CAN HELP"
      subtitle={<Subtitle />}
      imgSrc={showPurplePeople && CuriousPurpleGuy}
    >
      <Content />
    </LEAccordionItem>
  );
};

export default WeCanHelpItem;
