import { Box, BoxProps, Text } from '@chakra-ui/react';
import { SyntheticEvent, useMemo } from 'react';

import { parseStringForDynamicContent } from '../utils/helpers';

interface Props extends BoxProps {
  content: string;
  onDynamicContentClick?: (e: SyntheticEvent) => void;
}

const DynamicLinkContent = ({ content, onDynamicContentClick, ...props }: Props) => {
  const isPlainText = !onDynamicContentClick;

  const dynamicContent = useMemo(() => {
    const parsed = parseStringForDynamicContent(content || '');
    return {
      beforeDC: parsed.strings[0],
      dynamicContent: parsed.dynamicContent,
      afterDC: parsed.strings[1],
    };
  }, [content]);

  return (
    <Box minW={{ base: '250', md: '450px' }} {...props}>
      {dynamicContent.beforeDC}
      <Text
        display="inline"
        _hover={{ textDecor: isPlainText ? '' : 'underline' }}
        textColor={isPlainText ? 'unset' : 'trueBlue'}
        onClick={isPlainText ? undefined : onDynamicContentClick}
        cursor={isPlainText ? 'unset' : 'pointer'}
      >
        {dynamicContent.dynamicContent}
      </Text>
      {dynamicContent.afterDC}
    </Box>
  );
};

export default DynamicLinkContent;
