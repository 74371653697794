import { Box, Collapse, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import { useAtomValue } from 'jotai';
import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';

import { DOC_FOLDER_NAME } from '../../../../constants/documents';
import { LDFlags } from '../../../../constants/flags';
import {
  Deal,
  DealDocument,
  useGetR1CustomerCopyLazyQuery,
  useGetSignDataQuery,
} from '../../../../gql/generated/graphql';
import { useFlag } from '../../../../hooks';
import { createErrorToast } from '../../../../utils/toast';
import VerifyAccountModal from '../../../auth/components/VerifyAccountModal';
import { documentsSigned, isDevActiveAtom } from '../DashboardDevToolbar';
import DocListItem from './DocListItem';

interface Props {
  documentsSections: { sectionOneOpen: boolean; sectionTwoOpen: boolean };
  setDocumentsSections: (sections: { sectionOneOpen: boolean; sectionTwoOpen: boolean }) => void;
  deal?: Deal;
}

const LeaseEndDocs = ({ documentsSections, setDocumentsSections, deal }: Props) => {
  const [downloadEnabled, setDownloadEnabled] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [docs, setDocs] = useState<DealDocument[]>([]);
  const isDevActive = useAtomValue(isDevActiveAtom);
  const devDocumentsSigned = useAtomValue(documentsSigned);
  const [ssnVerificationIsOpen, setSsnVerificationIsOpen] = useState<boolean>(false);
  const inDashVerification = useFlag(LDFlags.SSN_DASH_VERIFICATION);

  const [getR1Docs] = useGetR1CustomerCopyLazyQuery();
  const { loading, data: signData } = useGetSignDataQuery({
    variables: {
      dealJacketID: deal?.r1_jacket_id ?? '',
    },
    skip: !deal?.r1_jacket_id,
    onCompleted: ({ retrieveDealDocsUploadedToR1 }) => {
      setDocs(retrieveDealDocsUploadedToR1 as DealDocument[]);
    },
  });

  useEffect(() => {
    const checkAllPartiesSigned = async () => {
      if (!deal?.r1_jacket_id) {
        return;
      }

      if (isDevActive && devDocumentsSigned) {
        setDownloadEnabled(true);
        return;
      }

      const allPartiesSigned = docs
        ?.map((doc) => doc.signAttributes)
        .flat()
        .every((sig) => sig?.signCaptureTms || !sig?.signRequired);

      setDownloadEnabled(allPartiesSigned);
    };

    checkAllPartiesSigned();
  }, [deal, docs, isDevActive, devDocumentsSigned, signData]);

  const showErrorToast = () => {
    createErrorToast({
      title: 'Unable to download documents',
      description: 'An error occured while downloading your documents.',
      errorMessage: 'Failed to download customer documents.',
    });
  };

  const handleDownload = async () => {
    if (!deal?.r1_jacket_id || !signData?.retrieveDealDocsUploadedToR1) {
      showErrorToast();
      return;
    }
    setDownloading(true);

    try {
      const { data } = await getR1Docs({
        variables: {
          dealJacketID: deal?.r1_jacket_id ?? '',
          includeExtraPDFBinaries: true,
        },
      });
      const files = data?.getR1CustomerCopy;
      const zip = new JSZip();
      const docsFolder = zip.folder(DOC_FOLDER_NAME);
      files?.forEach((file, i) => {
        if (docsFolder && file?.data) {
          docsFolder.file(`${file.displayName ?? `document${i + 1}`}.pdf`, file.data, {
            base64: true,
          });
        }
      });
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, 'documents.zip');
    } catch (e) {
      showErrorToast();
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Box py={4}>
      <Flex
        as="button"
        alignItems="center"
        w="full"
        onClick={() =>
          setDocumentsSections({
            ...documentsSections,
            sectionOneOpen: !documentsSections.sectionOneOpen,
          })
        }
      >
        <Icon
          transform={documentsSections.sectionOneOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
          transition="transform 0.3s ease"
          as={FaChevronRight}
          mr="2"
        />
        <Text fontWeight="semibold">LEASE END DOWNLOADS ({downloadEnabled ? 1 : 0})</Text>
      </Flex>
      <Collapse in={documentsSections.sectionOneOpen} animateOpacity>
        <Flex pt={2} px={4} flexDir="column">
          {!loading && downloadEnabled ? (
            <DocListItem
              areLeaseEndDocs
              onDownloadClick={() => {
                if (inDashVerification) {
                  setSsnVerificationIsOpen(true);
                  return;
                }
                handleDownload();
              }}
              downloading={downloading}
            />
          ) : (
            'No downloads at this time'
          )}
        </Flex>
      </Collapse>
      <Divider borderColor="grayDarkBackground" pt={4} />
      {inDashVerification && (
        <VerifyAccountModal
          open={ssnVerificationIsOpen}
          onClose={() => setSsnVerificationIsOpen(false)}
          deal={deal}
          download
          handleNextSubmit={handleDownload}
        />
      )}
    </Box>
  );
};

export default LeaseEndDocs;
