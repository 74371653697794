import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';

import { helpAtom, requirementsCompletedAtom } from '../..';
import { Logo } from '../../../../assets/Images';
import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import LienholderPayoffInstructions from '../../../../components/LienholderPayoffInstructions';
import Loader from '../../../../components/Loader';
import TextWithTooltip from '../../../../components/TextWithTooltip';
import YellowContainer from '../../../../components/YellowContainer';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { mercedesBenzSlug, mercedesLienholder } from '../../../../constants/lienholders';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import LienholderLogo from '../LienholderLogo';

const tooltips = {
  whatsAPayoffAmount: {
    text: 'Payoff Amount?',
    label: (
      <Text>
        <Text as="b">Payoff Amount</Text> includes the residual value of the car when the lease term
        began, the amount of payments remaining including interest, and a car purchase fee
        (depending on the company).
      </Text>
    ),
  },
  residual: {
    text: 'Residual Value',
    label: (
      <Text>
        The <Text as="b">Residual Value</Text> is set at the start of your lease by the leasing
        company, which may be the car dealership or another financier. It's the anticipated value of
        the car at the end of the lease and is used to determine your monthly lease payments.
      </Text>
    ),
  },
  amountYouStillOwe: {
    text: 'Amount You Still Owe',
    label: (
      <Text>
        The <Text as="b">Amount You Still Owe</Text> on your lease is he sum of the remaining
        payments you have on your lease, including any interest.
      </Text>
    ),
  },
};

interface Props {
  failed?: boolean;
  forceCustomerFindPayoff?: boolean;
}

const Content = ({ failed = false, forceCustomerFindPayoff = false }: Props) => {
  const { updateState, index, setOpenAccordionIndex } = useLEAccordionItemContext();
  const { info } = useTempInfo();
  const tempInfoData = info?.data;

  const setHelp = useSetAtom(helpAtom);
  const setRequirementsCompleted = useSetAtom(requirementsCompletedAtom);

  const handleNext = () => {
    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  const handleRegret = () => {
    updateState({ status: 'none' });
    setRequirementsCompleted(false);
    setHelp(true);
  };

  return (
    <Box color="leaseEndBlue" mt={2}>
      <YellowContainer textAlign="center" color="leaseEndBlue">
        <Text as="span">
          <Text as="b">What's a </Text>
          <TextWithTooltip
            textProps={{
              color: 'leaseEndBlue',
              fontWeight: 'bold',
            }}
            label={tooltips.whatsAPayoffAmount.label}
          >
            {tooltips.whatsAPayoffAmount.text}
          </TextWithTooltip>{' '}
          This is your vehicle's{' '}
          <TextWithTooltip
            textProps={{
              color: 'leaseEndBlue',
              fontWeight: 'bold',
            }}
            label={tooltips.residual.label}
          >
            {tooltips.residual.text}
          </TextWithTooltip>{' '}
          in addition to the{' '}
          <TextWithTooltip
            textProps={{
              color: 'leaseEndBlue',
              fontWeight: 'bold',
            }}
            label={tooltips.amountYouStillOwe.label}
          >
            {tooltips.amountYouStillOwe.text}
          </TextWithTooltip>
          , including interest.
        </Text>
      </YellowContainer>
      {!tempInfoData ? (
        <Loader />
      ) : (
        <Flex flexDir="column" alignItems="center">
          <LienholderLogo />
          {tempInfoData?.lienholder_slug === mercedesBenzSlug && (
            <Text m="-20px 0px 10px">{mercedesLienholder}</Text>
          )}
          <Box w="95%" p={{ md: '10px 15px' }}>
            <LienholderPayoffInstructions info={info} />
          </Box>
          <PrimaryButton mt="30px" onClick={handleNext}>
            NEXT
          </PrimaryButton>
          {!forceCustomerFindPayoff && !failed && (
            <Button
              display="inline-flex"
              variant="link"
              mt={{ base: '20px', md: '40px' }}
              onClick={handleRegret}
              fontSize={{ base: '12px', md: '14px' }}
            >
              Actually, can{' '}
              <Image mx={2} w={{ base: '80px', md: '100px' }} src={Logo} alt="Lease End Logo" /> get
              it for me?
            </Button>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default Content;
