import { Spinner, Stack, StackProps, Text } from '@chakra-ui/react';
import React from 'react';

interface LoaderProps extends StackProps {
  loading?: boolean;
  children: React.ReactNode;
}

const StackWithLoader: React.FC<LoaderProps> = ({ loading, children, ...rest }) => {
  return (
    <Stack {...rest}>
      {loading ? (
        <>
          <Spinner />
          <Text>Loading...</Text>
        </>
      ) : (
        <>{children}</>
      )}
    </Stack>
  );
};

export default StackWithLoader;
