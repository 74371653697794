import { CardProps } from '@chakra-ui/react';
import { useState } from 'react';
import { FaArchive } from 'react-icons/fa';

import { DealWithFarthestState } from '../../utils';
import DashboardCard from '../DashboardCard';
import DashboardUploadDocuments from './DashboardUploadDocuments';
import DocumentsSections from './DocumentsSections';

type Props = {
  deal?: DealWithFarthestState;
} & CardProps;

const DashboardDocumentsCard = ({ deal, ...props }: Props) => {
  const [uploadDocuments, setUploadDocuments] = useState(false);

  return (
    <DashboardCard
      title="Document Center"
      icon={FaArchive}
      headerColor="gainsboro"
      hasBorder
      {...props}
    >
      {uploadDocuments ? (
        <DashboardUploadDocuments deal={deal} setUploadDocuments={setUploadDocuments} />
      ) : (
        <DocumentsSections deal={deal} setUploadDocuments={setUploadDocuments} />
      )}
    </DashboardCard>
  );
};

export default DashboardDocumentsCard;
