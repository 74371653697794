import { Button, Divider, Flex, Icon, Spinner, Text } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import { useAtom, useAtomValue } from 'jotai';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';
import { MdOutlineUpdate } from 'react-icons/md';

import { DOC_FOLDER_NAME } from '../../../constants/documents';
import { LDFlags } from '../../../constants/flags';
import {
  Deal,
  DealDocument,
  useGetR1CustomerCopyLazyQuery,
  useGetSignDataQuery,
} from '../../../gql/generated/graphql';
import { useFlag } from '../../../hooks';
import { createErrorToast } from '../../../utils/toast';
import VerifyAccountModal from '../../auth/components/VerifyAccountModal';
import { documentsSigned, isDevActiveAtom } from './DashboardDevToolbar';

interface MyDocumentsProps {
  deal?: Deal;
}

/**
 * @deprecated because of LDFlags.COM_DASHBOARD_DOWN_PAYMENT being true
 */

const MyDocuments = ({ deal, ...props }: MyDocumentsProps) => {
  const [getR1Docs] = useGetR1CustomerCopyLazyQuery();
  const { loading, data: signData } = useGetSignDataQuery({
    variables: {
      dealJacketID: deal?.r1_jacket_id ?? '',
    },
    skip: !deal?.r1_jacket_id,
  });
  const [downloadEnabled, setDownloadEnabled] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isDevActive] = useAtom(isDevActiveAtom);
  const devDocumentsSigned = useAtomValue(documentsSigned);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inDashVerification = useFlag(LDFlags.SSN_DASH_VERIFICATION);

  useEffect(() => {
    const checkAllPartiesSigned = async () => {
      if (!deal?.r1_jacket_id) {
        return;
      }

      if (isDevActive && devDocumentsSigned) {
        setDownloadEnabled(true);
        return;
      }

      const docs = signData?.retrieveDealDocsUploadedToR1 as DealDocument[];
      const allPartiesSigned = docs
        ?.map((doc) => doc.signAttributes)
        .flat()
        .every((sig) => sig?.signCaptureTms || !sig?.signRequired);

      setDownloadEnabled(allPartiesSigned);
    };

    checkAllPartiesSigned();
  }, [deal, isDevActive, devDocumentsSigned, signData]);

  const showErrorToast = () => {
    createErrorToast({
      title: 'Unable to download documents',
      description: 'An error occured while downloading your documents.',
      errorMessage: 'Failed to download customer documents.',
    });
  };

  const handleDownload = async () => {
    if (!deal?.r1_jacket_id || !signData?.retrieveDealDocsUploadedToR1) {
      showErrorToast();
      return;
    }
    setDownloading(true);

    try {
      const { data } = await getR1Docs({
        variables: {
          dealJacketID: deal?.r1_jacket_id ?? '',
          includeExtraPDFBinaries: true,
        },
      });
      const files = data?.getR1CustomerCopy;
      const zip = new JSZip();
      const docsFolder = zip.folder(DOC_FOLDER_NAME);
      files?.forEach((file, i) => {
        if (docsFolder && file?.data) {
          docsFolder.file(`${file.displayName ?? `document${i + 1}`}.pdf`, file.data, {
            base64: true,
          });
        }
      });
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, 'documents.zip');
    } catch (e) {
      showErrorToast();
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Flex direction="column" textAlign="center" alignItems="center" p="15px" {...props}>
      <Text fontSize="14px" fontWeight="bold" textTransform="uppercase" mb={1}>
        My Documents
      </Text>
      <Text fontSize="12px">Download your documents below</Text>
      <Divider my={2} w={{ base: '70%', md: '85%' }} />
      {loading ? (
        <Spinner mt={2} />
      ) : (
        <Button
          leftIcon={!downloadEnabled ? <Icon as={MdOutlineUpdate} w={6} h={6} /> : undefined}
          mt={2}
          textTransform="uppercase"
          onClick={() => {
            if (inDashVerification) {
              setIsOpen(true);
              return;
            }
            handleDownload();
          }}
          isLoading={loading || downloading}
          isDisabled={!downloadEnabled}
          whiteSpace="normal"
          maxW="100%"
        >
          {downloadEnabled ? 'Signed Documents' : 'Finalizing Docs'}
        </Button>
      )}
      {inDashVerification && (
        <VerifyAccountModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          deal={deal}
          download
          handleNextSubmit={handleDownload}
        />
      )}
    </Flex>
  );
};

export default MyDocuments;
