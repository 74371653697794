import { Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import Switch from '../../components/Switch';
import { Input, Select } from '../../components/formComponents';
import {
  LICENSE_PLATE_NAME,
  VIN_NAME,
  licensePlateValidationSchema,
  vinValidationSchema,
} from '../../components/licensePlateOrVinInput/LicensePlateOrVinInput';
import { stateAbbreviations, statesArray } from '../../constants/states';
import { ERROR_TYPES } from '../../constants/tempInfos';
import {
  useDecodeAffiliateCarDetailsLazyQuery,
  useTemporaryInfoUpsertMutation,
} from '../../gql/generated/graphql';
import { useStep } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { INVALID_LICENSE_PLATE_MSG } from '../../utils/validation/licenseNumberValidator';

export const DECODING_SERVICE_ISSUE_MSG_SHORT =
  'Our decoding service is experiencing issues. Please try again later.';

type FormFields = Yup.InferType<typeof licensePlateValidationSchema & typeof vinValidationSchema>;

const AffiliateWidget = () => {
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const { pathname } = useLocation();
  const step = useStep();

  const [decodeCar] = useDecodeAffiliateCarDetailsLazyQuery();
  const [upsertTemporaryInfo] = useTemporaryInfoUpsertMutation();

  const handleSubmit = async (values: FormFields, { setFieldError }: FormikHelpers<FormFields>) => {
    const vin = values.vin?.toUpperCase();
    const licensePlate = values.licensePlate?.replace(/\s/g, '')?.toUpperCase();
    const state = values.state?.toUpperCase();

    const isVin = !!vin;

    if (!isVin && (!licensePlate || !stateAbbreviations.includes(state))) {
      return;
    }

    const { data: decodedCar, error } = await decodeCar({
      variables: {
        vin,
        license_plate_number: licensePlate,
        license_plate_state: state,
      },
    });

    if (error) {
      // should we print rudderstack here?
      rudderanalytics.track(RudderEvent.Error, {
        error_message: error.message,
        vin,
        license_plate: licensePlate,
        license_plate_state: state,
        pathname,
        date: new Date().toISOString(),
      });
      if (error.message === ERROR_TYPES.DECODING_SERVICE_ISSUE) {
        setFieldError('vin', DECODING_SERVICE_ISSUE_MSG_SHORT);
      }
      if (error.message === ERROR_TYPES.INVALID_LICENSE_PLATE) {
        setFieldError('licensePlate', INVALID_LICENSE_PLATE_MSG);
      }
      return;
    }

    const carDetails = decodedCar?.decodeAffiliateCarDetails;

    const { data: tempInfo } = await upsertTemporaryInfo({
      variables: {
        info: {
          id: guid,
          data: {
            license_plate_number: carDetails?.license_plate_number,
            license_plate_state: carDetails?.license_plate_state,
            vin: carDetails?.vin,
            year: carDetails?.year,
            make: carDetails?.make,
            model: carDetails?.model,
            fuel_type: carDetails?.fuel_type,
            vehicle_type: carDetails?.vehicle_type,
            kbb_trim_name: carDetails?.kbb_trim_name,
          },
        },
      },
    });

    const tempInfoData = tempInfo?.temporaryInfoUpsert?.data;

    rudderanalytics.track(RudderEvent.VinProvided, {
      year: tempInfoData?.year || undefined,
      make: tempInfoData?.make || undefined,
      model: tempInfoData?.model || undefined,
      vin: tempInfoData?.vin || undefined,
      zip: tempInfoData?.zip || undefined,
      license_plate: tempInfoData?.license_plate_number || undefined,
      license_plate_state: tempInfoData?.license_plate_state || undefined,
      fuel_type: tempInfoData?.fuel_type || undefined,
      vehicle_type: tempInfoData?.vehicle_type || undefined,
    });

    step.moveNext({}, true);
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        licensePlate: '',
        state: '',
        vin: '',
      }}
      validationSchema={isLicensePlate ? licensePlateValidationSchema : vinValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setValues, setErrors }) => (
        <Form style={{ width: '100%' }}>
          <Flex
            bg={{ base: '#ffffffcc', md: 'white' }}
            border="1px solid"
            borderColor="gray.200"
            shadow="2xl"
            mx="auto"
            maxW="370px"
            rounded="10px"
            p="4"
            flexDirection="column"
          >
            <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
              <Text
                noOfLines={2}
                mr={{ md: '3' }}
                textAlign={{ base: 'center', md: 'left' }}
                mt={1}
                mb={{ sm: '6', md: '0' }}
              >
                GET STARTED WITH YOUR {isLicensePlate ? 'LICENSE PLATE' : `VEHICLE'S VIN`}
              </Text>
              <Flex h="40px" ml={{ md: '1' }} minW="170px">
                <Switch
                  firstOption={LICENSE_PLATE_NAME}
                  secondOption={VIN_NAME}
                  onChange={(val) => {
                    setCurrentInput(val);
                    setValues({ vin: '', licensePlate: '', state: '' });
                    setErrors({});
                  }}
                  selectedOption={currentInput}
                />
              </Flex>
            </Flex>
            <Flex mt="4" mb={1} minH="50px">
              {isLicensePlate ? (
                <>
                  <Input
                    name="licensePlate"
                    placeholder="License Plate"
                    pr="5px"
                    autoFocus
                    textTransform="uppercase"
                  />
                  <Flex maxW={{ sm: '110px', md: '100%' }}>
                    <Select name="state" placeholder="State" options={statesArray} />
                  </Flex>
                </>
              ) : (
                <Input name="vin" placeholder="VIN" textTransform="uppercase" />
              )}
            </Flex>
            <Flex direction="column" alignItems="center">
              <Button type="submit" w="100%" py="20px" fontWeight="600" isLoading={isSubmitting}>
                GET STARTED
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default AffiliateWidget;
